import * as R from 'ramda';
import * as firebase from 'firebase/app';
import { Alert, Button, Spinner, Table, Row, Col, OverlayTrigger } from 'react-bootstrap';
import { IElasticResult, ISearchRecord } from '../../types/search';
import React, { useState, useRef, useEffect } from 'react';
import mapImage from '../../assets/search-map.png';
import { CSVLink } from 'react-csv';
import { FileText } from 'react-feather';
import { search } from '../../services/firebase';
import { IAuthContext } from '../../context/auth/auth-context';
import SearchPagination from './SearchPagination';
import SearchResultMap from './map';
import { TIERS } from '../../types/tiers';
import { ceresApp } from '../../services/firebase';
import mediaQuery from '../../util/media-query';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../../context/auth/AuthConsumer';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

interface ISearchResultContainer {
  result: IElasticResult<ISearchRecord> | null;
  docSearchEPNResult: any | null;
  error?: any;
  quickSerch: string;
  setQuickSerch: any;
  currentQueryText: any;
  isLoading: boolean;
  isQuickFilter: boolean;
  setQuickFilter: any;
  onPreviousClick: () => void;
  onNextClick: () => void;
  doTextSearch: any;
  sourceType: CUSTOM_SEARCH_TYPE;
  applyFilterToQuery: any;
  filter: any;
}

const TableContainer = styled.div`
  margin: 0 1.4em 0;
  padding: 1em 2em;
  background-color: #fff;
  ${mediaQuery.phone`
    margin: 0 1em;
    padding: 1em 0;
 `}
  ${mediaQuery.tablet`
    margin: 0 1em;
    display: grid;
  `}

  .back-btn {
    position: fixed;
    bottom: 3%;
    min-width: 15%;
    border-radius: 50px;
    margin: 0 42.5%;
    background-color: #5dc5c4 !important;
  }
`;

const NotFoundContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`;

const ContentTD = styled.td`
  ${mediaQuery.phone`
    font-size: 13px;
  `}
  && {
    padding: 0.75em;
  }
  em {
    font-style: normal;
    font-weight: 700;
  }
`;

const RecordTR = styled.tr`
  && {
    background: #fff;
    background: var(--light);
  }
`;

const RecordTD = styled.td`
  ${mediaQuery.phone`
    font-size: 13px;
  `}
  && {
    padding: 5px 0.75em;
    vertical-align: middle;
  }
`;
const TotalRecordTextContainer = styled.div`
  color: var(--secondary);
`;
const DownloadTD = styled<any>(RecordTD)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  button {
    width: 65px;
    height: 32px;
    padding: 2px 5px;
    font-size: 14px;
    svg {
      width: 20px;
      margin-right: 5px;
    }
    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }
`;
const STR = styled.tr<{ isAuth: boolean }>`
  position: sticky;
  top: ${(props) => (props.isAuth ? '138px' : '100px')};
  background-color: #e9ecef;
  ${mediaQuery.phone`
    top: 0;
 `}
  & th {
    ${mediaQuery.phone`
      font-size: 13px;
    `}
  }
`;
const Download = styled.button`
  background-color: #336195;
  color: #fff;
`;

const getFileTypeText = (type: string) => {
  switch (type) {
    case 'pdf':
      return 'PDF';
    default:
      break;
  }
  return '';
};

const headers = [
  { label: 'Relevance Score', key: 'relevance_score' },
  { label: 'Entity Name', key: 'entity_name' },
  { label: 'Type', key: 'type' },
  { label: 'Release Date', key: 'date' },
  { label: 'Category', key: 'category' },
  { label: 'Regulatory ID', key: 'regulatory_id' },
  { label: 'File', key: 'file_url' },
  { label: 'Text Snippet', key: 'text_snippet' },
];

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */
const doStorageDownload = R.contains(R.__, ['tceq_novell']);

const getStorageRef = R.cond<string, firebase.storage.Storage | null>([
  [R.equals('tceq_novell'), R.always(ceresApp.storage(`gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
  [R.T, R.always(null)],
]);

const SearchResultContainer: React.FC<ISearchResultContainer & { context: IAuthContext }> = (props) => {
  const records = props.result ? props.result.records : [];
  const history = useHistory();
  const { applyFilterToQuery, quickSerch, currentQueryText, filter, sourceType } = props;
  const csvInstance: any = useRef();
  const context = props.context;
  const [showWarning, setShowWarning] = useState(!context.isAuthenticated);
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const isDownloading = (contentId: string) => R.contains(contentId, downloadTriggers);
  const [showFreeTierWarning, setShowFreeTierWarning] = useState(context.isTier(TIERS.FREE));
  const [csvData, setCSVData] = useState<any>([]);
  const [isLoader, setLoader] = useState(false);
  const [showMap, setShowMap] = useState<any>(false);
  const [mapSpinner, setMapSpinner] = useState<any>(false);
  const store: any = useSelector<any>((state): any => state);
  const isBackButton = !!store.globalFlags.previousPage && store.globalFlags.previousPage === 'myenviroaiToAgency';
  const csvDataGet = async () => {
    let jsonArray: any = '';
    const from = 0;
    const size = 100;
    setLoader(true);
    await search(
      applyFilterToQuery(
        {
          query: {
            from,
            size,
            text: currentQueryText + (quickSerch ? ' ' + quickSerch : ''),
            source_type: sourceType,
          },
        },
        filter || {},
        CUSTOM_SEARCH_TYPE.DEFAULT
      )
    )
      .then((result) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        if (data.records.length > 0) {
          data.records.map((record) => {
            const dataCSV = [
              {
                relevance_score: record.relevanceScore,
                entity_name: record.entityName,
                type: record.title,
                date: record.releaseDate,
                category: record.seriesName,
                regulatory_id: record.entityNumber,
                file_url: record.fileUrl,
                text_snippet: record.hitContent,
              },
            ];
            if (jsonArray) {
              jsonArray = jsonArray.concat(dataCSV);
            } else {
              jsonArray = dataCSV;
            }
            return true;
          });
          setCSVData(jsonArray);
          csvInstance.current.link.click();
        }
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (showMap && mapSpinner) {
      setMapSpinner(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMap]);

  const handleBackButton = (e: any) => {
    // flag use to clear chat data when user visit enviro page not from here
    localStorage.setItem('isBackToEnviroPage', 'true');
    history.replace('myenviroai');
  };

  if (props.result && props.result.records.length <= 0) {
    return (
      <TableContainer>
        <NotFoundContainer>
          <h3>No results found.</h3>
        </NotFoundContainer>
        {isBackButton && <Button variant="primary" className="back-btn" size="lg" onClick={handleBackButton}>Back</Button>}
      </TableContainer>
    );
  }
  if (!props.result) {
    return <></>;
  }

  return (
    <div>
      <TableContainer>
        <Alert variant="warning" show={showWarning} onClose={() => setShowWarning(false)} dismissible>
          <Alert.Heading>Get access to better results!</Alert.Heading>
          <p>
            <Alert.Link href="/login">Login</Alert.Link> to your account, to get better search results, including file
            downloads!
          </p>
        </Alert>
        <Alert
          variant="warning"
          show={!showWarning && showFreeTierWarning}
          onClose={() => setShowFreeTierWarning(false)}
          dismissible
        >
          <Alert.Heading>Get access to better results!</Alert.Heading>
          <p>
            Upgrade your account to{' '}
            <em>
              <b>Basic</b>
            </em>{' '}
            or{' '}
            <em>
              <b>Platinum</b>
            </em>{' '}
            tier, to get better search results, including file downloads!
          </p>
        </Alert>
        <Row>
          <Col sm={12} md={6}>
            <TotalRecordTextContainer>
              About <b className="records-count">{(props.result && props.result.total.value) || 0}</b> records
            </TotalRecordTextContainer>
          </Col>
          {/* <Col sm={12} md={6}>
            <Form
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();

                if (currentQueryText) {
                  props.setQuickFilter(true);
                  props.doTextSearch(currentQueryText, 0, sourceType);
                }
              }}
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Search Within</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  onChange={(e: any) => {
                    props.setQuickSerch(e.target.value);
                  }}
                  value={props.quickSerch}
                  placeholder={
                    'Use "Search Within" to further refine search and improve relevancy'
                  }
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Prepend>
                  <LoadingButton isLoading={props.isLoading || false}>
                    <Search />
                  </LoadingButton>
                </InputGroup.Prepend>
              </InputGroup>
            </Form>
          </Col> */}
        </Row>
        <Table id="searchResult" responsive="md" bordered hover>
          <thead>
            <STR isAuth={context.isAuthenticated}>
              <th>Relevance Score</th>
              <th>Entity Name</th>
              <th>Type</th>
              <th>Release Date</th>
              <th>Category</th>
              <th>Regulatory ID</th>
              <th>File</th>
            </STR>
          </thead>
          <tbody>
            {records.map((record, i) => {
              const isFileDownloading = isDownloading(record.contentId);
              return (
                <React.Fragment key={i}>
                  <RecordTR>
                    <RecordTD>{`${record.relevanceScore}%`}</RecordTD>
                    <RecordTD>{record.entityName}</RecordTD>
                    <RecordTD>{record.title}</RecordTD>
                    <RecordTD>{record.releaseDate}</RecordTD>
                    <RecordTD>{record.seriesName}</RecordTD>
                    <RecordTD>{record.entityNumber}</RecordTD>
                    <DownloadTD
                      onClick={() => {
                        if (!context.isAuthenticated) {
                          return toast.warn(
                            <span>
                              Please <Alert.Link href="/login">login</Alert.Link> to download files.
                            </span>,
                            {
                              position: toast.POSITION.BOTTOM_CENTER,
                            }
                          );
                        }
                        if (!context.isLubrizol()) {
                          if (context.isTier(TIERS.FREE)) {
                            return toast.warn(<span>Please upgrade to BASIC or PLATINUM to download files.</span>, {
                              position: toast.POSITION.BOTTOM_CENTER,
                            });
                          }
                        }
                        if (doStorageDownload(record.entitySource)) {
                          const storageRef = getStorageRef(record.entitySource);
                          if (R.isNil(storageRef)) {
                            return toast.error(`Oops, could not find the file.`);
                          }
                          setDownloadTriggers(R.append(record.contentId, downloadTriggers));
                          return storageRef
                            .ref(record.fileName)
                            .getDownloadURL()
                            .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                            .catch((err) => toast.error(err.message))
                            .finally(() => setDownloadTriggers(R.without([record.contentId], downloadTriggers)));
                        }
                        if (!record.fileUrl) {
                          return toast.warn(
                            <span>
                              No associated file was found for the content id <b>{record.contentId}</b>
                            </span>
                          );
                        }
                        if(record.entitySource.includes('tceq')) {
                          const encodedUri = encodeURIComponent(record.fileUrl);
                          const  contentFileUrlNew = `${process.env.REACT_APP_CERES_URL}/GetDocs?id=${record.contentId}&u=${encodedUri}`;
                          window.open(contentFileUrlNew, '_blank');
                        } else {
                          window.open(record.fileUrl, '_blank');
                        }
                      }}
                    >
                      <OverlayTrigger
                  overlay={<ReactTooltip place="top" effect="solid" />}
                    >
                   <Button data-tip="Click to download" variant="secondary" disabled={isFileDownloading}>
                        {isFileDownloading && <Spinner variant="light" animation="border" />}
                        {!isFileDownloading && (
                          <>
                            <FileText />
                            <span>{getFileTypeText(record.fileType)}</span>
                          </>
                        )}
                      </Button>
              </OverlayTrigger>
                    </DownloadTD>
                  </RecordTR>
                  <tr>
                    <ContentTD
                      colSpan={7}
                      dangerouslySetInnerHTML={{
                        __html:
                          record.hitContent.length > 0
                            ? record.hitContent
                                // Remove non-ascii chars.
                                .map((str: string) => {
                                  /*eslint no-control-regex: 0*/
                                  let wordGroup = '';
                                  currentQueryText
                                    .trim()
                                    .split(' ')
                                    .map((srcWord: string) => {
                                      return (wordGroup += `<em>${srcWord.replace(/['",-]+/g, '')}</em>|`);
                                    });
                                  return props.isQuickFilter && currentQueryText
                                    ? str.replace(/[^\x00-\x7F]/g, '').replace(new RegExp(wordGroup, 'gi'), (word) => {
                                        return word.replace(/<[^>]*>?/gm, '');
                                      })
                                    : str.replace(/[^\x00-\x7F]/g, '');
                                })
                                .join('<br />')
                            : '<h3>No Preview Available</h3>',
                      }}
                    />
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>

        <SearchPagination
          isDisabled={false}
          isFirstPage={(props.result && props.result.from === 0) || false}
          isLastPage={(props.result && props.result.from + props.result.size >= props.result.total.value) || false}
          totalRecords={(props.result && props.result.total.value) || 0}
          onPreviousClick={props.onPreviousClick}
          onNextClick={props.onNextClick}
        />
        <Download className="btn btn-primary" onClick={csvDataGet} disabled={isLoader ? true : false}>
          {isLoader && <Spinner animation="border" variant="light" size="sm" />} Download CSV
        </Download>
        {csvData.length > 0 && (
          <CSVLink
            data={csvData}
            headers={headers}
            filename={`${currentQueryText + (quickSerch ? ' ' + quickSerch : '')}_100.csv`}
            style={{ visibility: 'hidden' }}
            ref={csvInstance}
          >
            Download CSV
          </CSVLink>
        )}

        {showMap && (context.isTier(TIERS.SUPER_ADMIN) ||
          ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) ||
          (context.isLubrizol() && context.isAuthenticated)) && (
          <SearchResultMap records={records} docSearchEPNResult={props.docSearchEPNResult} context={context} />
        )}
        {
          !showMap &&
          <div className="card mb-3" style={{maxWidth: '1200px', margin: '1rem auto'}}>
            <div className="row no-gutters">
              <div className="col-md-5">
                <img style={{height: '100%'}} src={mapImage} className="card-img-top" alt="search-map" />
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <h5 className="card-title">Map of Document Locations</h5>
                  <p className="card-text">Click the below button to see all the site locations in the map for your search</p>
                  <p className="card-text"><small className="text-muted">We found matches for your search</small></p>
                  <button className="btn btn-primary" onClick={() => {
                    setMapSpinner(true);
                    setTimeout(() => setShowMap(true), 2000);
                  }}>
                    Click here to expand the Map &nbsp;
                    {mapSpinner && <Spinner variant="light" animation="border" size="sm" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
        {isBackButton && <Button variant="primary" className="back-btn" size="lg" onClick={handleBackButton}>Back</Button>}
      </TableContainer>
    </div>
  );
};

export default withAuthContext(SearchResultContainer);
