import React from 'react';
import styled from 'styled-components';
import InfoModal from './InfoModal';
import TabResultsModal from './TabResultsModal';
import { Popover } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import InternetSearchTab from '../../../assets/internet-search-tab.png';
import TrackbotGraphicTab from '../../../assets/trackbot-graphic.png';

const CustomPopover = styled(Popover)`
  width: 42rem;
  max-height: 35rem;
  overflow: auto;
  padding: 3px;
  max-width: none !important;
  & table {
    font-size: 0.7rem;
    /* line-height: 14px; */
    text-align: center;
    margin-bottom: 0;
  }
`;
const PopoverHeader = styled.div`
  display: flex;
  padding: 2px 6px;
  flex-direction: column;
  line-height: 16px;
  font-size: 12px;
  & div {
    display: flex;
    justify-content: space-between;
  }
`;
const PopoverContent = styled(Popover.Content)`
  display: flex;
  flex-direction: column;
  padding: 2px 6px;
  line-height: 16px;
  font-size: 12px;
`;
const PopoverTable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const EmissionTable = styled(Table)`
  & .header-fixed {
    position: sticky;
    top: 0;
    background-color: #efefef;
    z-index: 9;
  }
  & th {
    padding: 0.4rem !important;
    border-bottom-width: 1px !important;
  }
`;
const ETD = styled.td`
  padding: 6px 2px !important;
`;
const ETR = styled.tr`
  line-height: 12px;
`;
const EmissionTR = styled.tr`
  font-size: 16px;
  line-height: 14px;
  background-color: #dbdbdb;
  & th {
    border-bottom-width: 0 !important;
    padding: 11px !important;
    font-size: 14px;
  }
`;

const TabImg = styled.img`
  height: 70px;
  width: auto;
`;
const FinTable = styled(Table)`
  margin-bottom: 0.5rem;
  & th {
    padding: 10px 5px;
    vertical-align: middle !important;
    min-width: 65px;
  }
  & td {
    padding: 10px 5px;
    vertical-align: middle;
  }
  & .header-fixed {
    position: sticky;
    top: -1;
    background-color: #e9e99e;
    z-index: 9;
  }
`;
const ButtonGroupArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
  & .MuiButton-root {
    font-size: 12px;
    padding: 5px 5px;
    width: 130px;
    height: 60px;
    line-height: 16px;
    margin-top: 6px;
  }
`;
const ProcessButton = styled(Button)`
  background-color: #000 !important;
  color: #fff !important;
`;
const EmissionButton = styled(Button)`
  background-color: #fefe33 !important;
  color: #000 !important;
`;
const ControlsButton = styled(Button)`
  background-color: #00bfff !important;
  color: #fff !important;
`;
const AgencyButton = styled(Button)`
  background-color: #eb4c42 !important;
  color: #fff !important;
`;
const SpellLow = styled.span`
  text-transform: lowercase;
`;

export const renderEPNInfo = (
  details: any,
  entityName: string,
  entity: string,
  openInfoModal: boolean,
  setInfoModal: (openInfoModal: boolean) => void,
  openTabResultsModal: boolean,
  setTabResultsModal: (setTabResultsModal: boolean) => void,
  openTabResultsType: string,
  setTabResultsType: (openTabResultsType: string) => void,
  setTrackbotTabModal: (param: boolean) => void,
  tabKeyword: string,
  setTabKeyword: (tabKeyword: string) => void,
  setShow: (show: boolean) => void,
  modalTitle: string,
  setModalTitle: (modalTitle: string) => void,
  selected: string,
  setSelected: (selected: string) => void,
  context: any,
  containerRef: HTMLDivElement
) => {
  const detailsObj = details[Object.keys(details)[0]];

  const tr = [];
  if (detailsObj) {
    const fins = detailsObj.FINS;

    for (const key in fins) {
      if (fins.hasOwnProperty(key)) {
        const row: any = (
          <tr
            key={fins[key].FIN + fins[key].Name}
            style={{ backgroundColor: selected === key ? '#f3f3df' : '#fff' }}
            onClick={() => setSelected(key)}
          >
            <td>{fins[key].FIN}</td>
            <td>{fins[key].Name}</td>
            <td>{fins[key]['FIN Group']}</td>
            <td>{fins[key]['FIN Profile']}</td>
          </tr>
        );
        tr.push(row);
      }
    }
    return (
      <CustomPopover id={detailsObj.toString()}>
        <PopoverHeader>
          <div>
            <span>{detailsObj.Company}</span>
            <span>
              <b>{detailsObj.EPN}</b>
            </span>
          </div>
          <div>
            <span>{detailsObj['EPN Profile']}</span>
          </div>
        </PopoverHeader>
        <PopoverContent>
          <PopoverTable>
            <Scrollbars
              style={{
                height: '190px',
                width: '55%',
              }}
            >
              <FinTable bordered hover style={{ height: '100%' }}>
                <thead className="header-fixed">
                  <tr>
                    <th>{detailsObj.lbl_source_id}</th>
                    <th>{detailsObj.lbl_source_description}</th>
                    <th>{detailsObj.lbl_source_type}</th>
                    <th>{detailsObj.lbl_source_status}</th>
                  </tr>
                </thead>
                <tbody>{tr}</tbody>
              </FinTable>
            </Scrollbars>
            <Scrollbars
              style={{
                height: '190px',
                width: '45%',
              }}
            >
              {fins &&
                fins.map((finsInfo: any, index: number) => (
                  <div
                    style={{
                      display: parseInt(selected, 0) === index ? 'block' : 'none',
                    }}
                    key={index}
                  >
                    <EmissionTable bordered key={index}>
                      <thead className="header-fixed">
                        <EmissionTR>
                          <th colSpan={5}>Emissions</th>
                        </EmissionTR>
                        <tr>
                          <th></th>
                          <th colSpan={2}>{detailsObj.lbl_actual}</th>
                          <th colSpan={2}>{detailsObj.lbl_permitted}</th>
                        </tr>
                        <ETR>
                          <th>Pollutant</th>
                          <th>
                            Hourly
                            <br />
                            <small>lbs/hr</small>
                          </th>
                          <th>
                            Annual
                            <br />
                            <small>tons/yr</small>
                          </th>
                          <th>
                            Hourly
                            <br />
                            <small>lbs/hr</small>
                          </th>
                          <th>
                            Annual
                            <br />
                            <small>tons/yr</small>
                          </th>
                        </ETR>
                      </thead>
                      <tbody>
                        {finsInfo.emission_summary && finsInfo.emission_summary.length > 0 ? (
                          finsInfo.emission_summary.map((sec: any, keys: number) => (
                            <tr key={keys}>
                              <ETD>{sec.pollutant}</ETD>
                              <ETD>{sec.ah}</ETD>
                              <ETD>{sec.aa}</ETD>
                              <ETD>{sec.ph}</ETD>
                              <ETD>{sec.pa}</ETD>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <ETD
                              colSpan={5}
                              style={{
                                height: '80px',
                                backgroundColor: '#fff',
                              }}
                            >
                              No data found!
                            </ETD>
                          </tr>
                        )}
                      </tbody>
                    </EmissionTable>
                  </div>
                ))}
            </Scrollbars>
          </PopoverTable>
          <ButtonGroupArea>
            <ProcessButton
              variant="contained"
              onClick={() => {
                setShow(false);
                setInfoModal(true);
                setModalTitle('Process/ Equipment Information');
              }}
            >
              Process/ Equipment Information
            </ProcessButton>

            <EmissionButton
              variant="contained"
              onClick={() => {
                setShow(false);
                setInfoModal(true);
                setModalTitle('Emission Details');
              }}
            >
              Emission Details
            </EmissionButton>

            <ControlsButton
              variant="contained"
              onClick={() => {
                setShow(false);
                setInfoModal(true);
                setModalTitle('Controls');
              }}
            >
              Controls
            </ControlsButton>

            <AgencyButton
              variant="contained"
              onClick={() => {
                setShow(false);
                setTabResultsModal(true);
                setTabResultsType(detailsObj.agency_doc_source_type);
                setTabKeyword(
                  `${
                    detailsObj.agency_doc_regulated_entity_number
                  } ${detailsObj.agency_doc_downloaded_file_content.replace(/['"/]+/g, '')}`
                );
              }}
            >
              Agency Doc<SpellLow>s</SpellLow>
            </AgencyButton>

            <div
              onClick={() => {
                setShow(false);
                setTabResultsModal(true);
                setTabResultsType('INTERNET');
                setTabKeyword(entity);
              }}
            >
              <TabImg src={InternetSearchTab} alt="EnviroAI" />
            </div>

            <div onClick={() => setTrackbotTabModal(true)}>
              <TabImg src={TrackbotGraphicTab} alt="EnviroAI" />
            </div>
          </ButtonGroupArea>
        </PopoverContent>
        <InfoModal
          open={openInfoModal}
          setOpen={setInfoModal}
          entityName={entityName}
          epn={detailsObj.EPN}
          modalTitle={modalTitle}
          setShowPopover={setShow}
          detailsObj={detailsObj}
          containerRef={containerRef}
        />
        <TabResultsModal
          open={openTabResultsModal}
          setOpen={setTabResultsModal}
          queryText={tabKeyword}
          context={context}
          setShowPopover={setShow}
          type={openTabResultsType}
          containerRef={containerRef}
        />
      </CustomPopover>
    );
  }
};
