import * as React from 'react';
import { Route, Switch } from 'react-router';
import Page404 from '../404';
import UsersPanel from './UsersPanel';
import { Breadcrumb } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const AdminPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 2;
  .breadcrumb {
    background: var(--light);
  }
  min-height: calc(100vh - 80px);
  margin: 4em;
`;
const BodyContent = styled.div`
  background-color: #fff;
  padding: 10px 25px;
`;

const AdminPanel: React.FC = () => {
  return (
    <AdminPanelContainer>
      <Breadcrumb>
        <li className="breadcrumb-item">
          <Link to="/admin">Admin Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/admin/users">Manage Users</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/admin/validatorUsage">Validator Usage</Link>
        </li>
      </Breadcrumb>
      <Switch>
        <Route path="/admin" exact component={() => <BodyContent>Nothing to see here.</BodyContent>} />
        <Route path="/admin/users" exact component={UsersPanel} />
        <Route component={Page404} />
      </Switch>
    </AdminPanelContainer>
  );
};

export default AdminPanel;
