import React from 'react';
export const notificationInitialState = {
  notifications: []
};

const NotificationContext = React.createContext<INotificationContext>(
  notificationInitialState
);

export interface INotificationContext {
  notifications: any[];
}
export default NotificationContext;
