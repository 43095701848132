import React from 'react';
import styled from 'styled-components';
import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner,
} from 'react-bootstrap';
import { Search as SearchIcon } from 'react-feather';

interface ISearch {
  sentimentAnalyzer: (keyword: any) => void;
  setQueryText: any;
  isLoading: any;
  queryText: any;
}

const SearchArea = styled.div``;
const Title = styled.h1`
  font-size: 60px;
  font-weight: 500;
  font-family: impact;
  color: #005fbc;
  text-align: center;
  line-height: 80px;
  margin-top: 30px;
  & span {
    color: #0070c0;
  }
`;
const Content = styled.p`
  color: #005fbc;
  font-size: 20px;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
  line-height: 27px;
`;
const SearchInput = styled.div`
  width: 450px;
  margin: 0 auto;
  text-align: center;
  background-color: #0070c0;
  padding: 20px 30px 30px;
  border-radius: 20px;
  & h4 {
    color: #fff;
    font-size: 30px;
  }
`;

const SearchFormContainer = styled(Form)`
  margin-top: 1.65em;
`;
const SearchBarInputContainer = styled.div`
  width: 100%;
`;

const Search: React.FC<ISearch> = (props) => {
  return (
    <SearchArea>
      <Title>
        Environmental <span>Sentiment Analysis</span>
      </Title>
      <Content>
        Analyzes Environmental Agency Files using Machine-Learning for Sentiment
      </Content>
      <SearchInput>
        <h4>Enter Company, Person, or Keyword(s) of Interest</h4>
        <SearchFormContainer
          onSubmit={(event: React.FormEvent) => {
            event.preventDefault();
            props.sentimentAnalyzer('doc_sentiment_score_avg-desc');
            props.sentimentAnalyzer('doc_sentiment_score_avg-asc');
          }}
        >
          <SearchBarInputContainer>
            <InputGroup>
              <FormControl
                onChange={(e: any) => props.setQueryText(e.target.value)}
                placeholder=""
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={props.queryText}
              />
              <InputGroup.Append>
                <Button
                  color="primary"
                  type="submit"
                  style={{ padding: '5px' }}
                >
                  {props.isLoading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    <SearchIcon />
                  )}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </SearchBarInputContainer>
        </SearchFormContainer>
      </SearchInput>
    </SearchArea>
  );
};
export default Search;
