import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Search from './Search';
import TableContent from './TableContent';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import axios from 'axios';
import { projectId } from '../../services/firebase';

const storeLink = 'https://storage.googleapis.com/ceres-maps/ceres_store/';

interface ISentiment {
  match: any;
}

const MainkWrapper = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 50px;
`;
const Document = styled.div`
  width: 340px;
  margin: 35px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 126px;
  color: #fff;
  font-size: 28px;
  padding: 20px 40px;
  border-radius: 20px;
  line-height: 36px;
  font-weight: 500;
`;

const Sentiment: React.FC<
  RouteComponentProps<any> &
  ISentiment & {
    context: IAuthContext;
  }
> = (props) => {
  const recordsNumShow = 10;
  const [queryText, setQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [positiveData, setPositive] = useState<any>([]);
  const [negativeData, setNegative] = useState<any>([]);
  const GifPlayer = require('react-gif-player');

  const sentimentAnalyzer = (sort: any, from = 0, text = '') => {
    setIsLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      params: {
        action: 'search_doc',
        keyword: queryText || text,
        sorting: sort,
        records_num_show: recordsNumShow,
        records_num_from: from,
      },
    };
    axios
      .get('https://us-central1-' + projectId + '.cloudfunctions.net/SentimentAnalyzer', config)
      .then((response) => {
        response.data.sorting === 'doc_sentiment_score_avg-desc'
          ? setPositive(response.data)
          : setNegative(response.data);
        window.scrollBy(0, 100);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if(window.top !== window.self) {
      const query = new URLSearchParams(props.location.search);
      const rn: any = query.get('rn');
      setQueryText(rn);
      sentimentAnalyzer('doc_sentiment_score_avg-desc', 0, rn);
      sentimentAnalyzer('doc_sentiment_score_avg-asc', 0, rn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainkWrapper>
      <Search sentimentAnalyzer={sentimentAnalyzer} setQueryText={setQueryText} isLoading={isLoading} queryText={queryText} />
      {(positiveData.total_records > 0 || negativeData.total_records > 0) && (
        <>
          <p style={{ margin: '60px 0 0' }}>Results found: {positiveData.total_records}</p>
          <Row>
            <Col md={5}>
              <Document style={{ backgroundColor: '#005FBC' }}>Most Positive Document</Document>
            </Col>
            <Col md={2}>
              <GifPlayer
                gif={storeLink + 'sentiment.gif'}
                // still={storeLink + 'search.jpg'}
                autoplay={true}
                width="100%"
              />
            </Col>
            <Col md={5}>
              <Document style={{ backgroundColor: '#0070c0' }}>Most Negative Document</Document>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <TableContent
                environmenData={positiveData}
                queryText={queryText}
                sentimentAnalyzer={sentimentAnalyzer}
                setIsLoading={setIsLoading}
                color={'244, 134, 12'}
              />
            </Col>
            <Col md={6}>
              <TableContent
                environmenData={negativeData}
                queryText={queryText}
                sentimentAnalyzer={sentimentAnalyzer}
                setIsLoading={setIsLoading}
                color={'0, 112, 192'}
              />
            </Col>
          </Row>
        </>
      )}
    </MainkWrapper>
  );
};

export default withAuthContext(Sentiment);
