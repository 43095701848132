import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { keyframes } from 'styled-components';
import { Button, Card, Form, InputGroup, OverlayTrigger, Spinner } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { saveProfile } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';

const chatAnimation = keyframes`
  0% {
    right: -50%;
  }
  100% {
    right: 0;
  }
`;

const ChatSummaryContainer = styled.div`
  font-size: 22px;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 998;
  height: 100%;
  background-color: #f3f3f3;
  color: white;
  animation: ${chatAnimation} 1s 1;
`;

const TitleContainer = styled.div`
  display: flex;
  background-color: #2c190e !important;
  font-family: 'Sans Pro';
  padding: 7px 7px;

  .title-head {
    margin: auto;
  }

  .icon-container {
    padding-right: 20px;
  }

  & .close-profile {
    position: absolute;
    font-size: 1.5rem;
    top: 12px;
    right: 0.75rem;
    color: #ffffff;
    border: none;
    background: none;
    cursor: pointer;
    font-family: monospace;
  }
`;

const ProfileBody = styled.div`
  padding: 10px;

  .card-body {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .card-title {
    margin-bottom: 0;
  }

  button {
    font-family: 'Sans Pro';
  }

  .profile-list {
    .current-profile-label {
      overflow: hidden;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .profile-label {
      margin: 1rem;
      overflow: hidden;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 67px;
  height: 34px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }

  .slider:before {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export default function Profile({ closeProfile, onToggleProfile }: any) {
  const [profile, setProfile]: any = useState(null);
  const [activeProfile, setActiveProfile] = useState<number | null>(null);
  const [profileHeadline, setProfileHeadline] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [changedProfileId, setChangedProfileId] = useState<number | null>(null);
  const [changedProfile, setChangedProfile] = useState<any>(null);

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getProfileData() {
    try {
      setShowSpinner(true);

      const response = await saveProfile({ type: 'getAll' });

      if (hasValidProfileData(response)) {
        const { id, payload: { profileData = [] } } = response.data[0];

        setProfile({ id, profileData });

        const activeIndex = findActiveProfileIndex(profileData);
        setActiveProfile(activeIndex);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
    }
  }

  function hasValidProfileData(response: any) {
    return response && response.data && response.data.length > 0;
  }

  function findActiveProfileIndex(profileData: any) {
    const activeIndex = profileData.findIndex((item: any) => item.enable === true);
    return activeIndex >= 0 ? activeIndex : null;
  }

  async function addProfile() {
    if (!profileHeadline) {
      return;
    }

    setShowSpinner(true);

    try {
      const response = await (profile
        ? updateExistingProfile()
        : createNewProfile());

      if (response) {
        handleAddProfileSuccess();
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
    }
  }

  async function updateExistingProfile() {
    return await saveProfile({
      type: 'update',
      id: profile.id,
      record: { profileData: [...profile.profileData, { title: profileHeadline, enable: false }] }
    });
  }

  async function createNewProfile() {
    return await saveProfile({
      type: 'create',
      record: { profileData: [{ title: profileHeadline, enable: true }] }
    });
  }

  function handleAddProfileSuccess() {
    showToastMessage({ type: 'success', actionType: 'create', title: 'Data created successfully.', description: `Created profile: ${profileHeadline}` });
    setProfileHeadline('');
    getProfileData();
  }

  async function updateProfile(profileIndex: number) {
    if (!profile || !profile.profileData) {
      return;
    }

    setChangedProfileId(profileIndex);

    const updatedProfileData = profile.profileData.map((item: any, i: number) => ({
      title: item.title,
      enable: profileIndex === i ? !item.enable : false
    }));

    const profileTitle = updatedProfileData.find((item: any, i: number) => item && profileIndex === i);
    setActiveProfile(profileTitle.enable ? profileIndex : null);

    const response: any = await saveProfile({
      type: 'update',
      id: profile.id,
      record: { profileData: updatedProfileData }
    });

    if (response) {
      handleUpdateProfileSuccess(updatedProfileData, profileTitle.enable ? profileTitle.title : null);
    }

    setChangedProfileId(null);
  }

  function handleUpdateProfileSuccess(updatedProfileData: any, enabledProfileTitle: string | null) {
    showToastMessage({ type: 'success', actionType: 'update', title: 'Data updated successfully.', description: `${enabledProfileTitle ? 'Activated profile: ' + enabledProfileTitle : 'Profile deactivated'}`});
    setProfile({ ...profile, profileData: updatedProfileData });
    onToggleProfile(enabledProfileTitle);
  }

  const updateProfileTitle = (titleIndex: number, updatedTitle: string) => {
    setChangedProfile({
      index: titleIndex,
      title: updatedTitle
    });
  };

  const saveUpdatedProfileTitle = async () => {
    if (!changedProfile || !profile || !profile.profileData) {
      return;
    }

    const { index, title } = changedProfile;
    const currentProfileData = profile.profileData[index];

    if (!currentProfileData || !currentProfileData.title) {
      return;
    }

    currentProfileData.title = title;

    setChangedProfileId(index);

    try {
      const response = await saveProfile({
        type: 'update',
        id: profile.id,
        record: { profileData: profile.profileData }
      });

      if (response) {
        onToggleProfile(title);
        showToastMessage({ type: 'success', actionType: 'update', title: 'Data updated successfully.', description: `Updated profile: ${title}`});
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setChangedProfile(null);
      setChangedProfileId(null);
    }
  };

  const deleteProfile = async (index: number) => {
    if (!profile || !profile.profileData || !profile.profileData[index]) {
      return;
    }

    try {
      setChangedProfileId(index);

      const updatedProfileData = profile.profileData.filter((_: any, i: number) => i !== index);

      const response: any = await saveProfile({
        type: 'update',
        id: profile.id,
        record: { profileData: updatedProfileData }
      });

      if (response) {
        showToastMessage({ type: 'success', actionType: 'delete', title: 'Data deleted successfully.', description: `Deleted profile: ${profile.profileData[index].title}`});
        if (activeProfile === index) {
          setActiveProfile(null);
          onToggleProfile(null);
        }

        profile.profileData.splice(index, 1);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setChangedProfileId(null);
    }
  };

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  return (
    <ChatSummaryContainer>
      <TitleContainer>
          <button id="back-btn" className="btn btn-outline-light" onClick={() => closeProfile(false)}><ArrowBackIosIcon fontSize="small"/> Back</button>
        <div className="title-head title-head-fullscreen">
          <h2>
            Profile
          </h2>
        </div>

        <div className="icon-container">
         <button className="close-profile" onClick={() => closeProfile(false)}>X</button>
        </div>
      </TitleContainer>
      <ProfileBody>
        <InputGroup className="mb-3">
            <Form.Control
              placeholder="Profile Headline"
              name="profileHeadline"
              value={profileHeadline}
              onChange={(e: any) => setProfileHeadline(e.target.value)}
            />
             <Button variant="outline-secondary" size="sm" className="ml-2" id="profile-headline" onClick={addProfile} disabled={showSpinner}>
              Add Profile
            </Button>
        </InputGroup>

        <div className="profile-list">
          {
            showSpinner ? (
              <div className="d-flex justify-content-center" >
                <Spinner animation="border" variant="secondary" />
              </div>
            )
            :
            <>
              {
                activeProfile != null ?
                  (profile && profile.profileData[activeProfile] && profile.profileData[activeProfile].title &&
                    <div>
                      <h6 className="mb-3 text-primary current-profile-label" data-tip={profile.profileData[activeProfile].title}>Current enabled Profile: { profile.profileData[activeProfile].title }</h6>
                      <StyledTooltip place="bottom" effect="solid"/>
                    </div>)
                  :
                  (<h6 className="mb-3 text-primary current-profile-label">Current enabled Profile: No Profile selected</h6>)
              }
              {
                profile && profile.profileData && profile.profileData.length ? profile.profileData.map((item: any, i: number) => (
                  <Card key={i} text="primary">
                    <Card.Body>
                           <Card.Title
                            data-tip= {item.title}
                            className="w-100 fs-15 profile-label"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onInput={(e: any) => updateProfileTitle(i, e.currentTarget.textContent)}
                          >
                            { item.title }
                          </Card.Title>
                          <StyledTooltip place="bottom" effect="solid"/>
                      { changedProfileId === i && <div><Spinner animation="border" variant="primary" className="mr-2" /></div> }
                      {
                        changedProfile && changedProfile.index === i && (
                          <OverlayTrigger
                            overlay={<ReactTooltip place="bottom" effect="solid" />}
                           >
                            <IconButton
                            data-tip="Save"
                            onClick={(e: any) => saveUpdatedProfileTitle()}
                            aria-label="fav-icon"
                            component="span"
                            className="mx-2"
                            size="small"
                          >
                            <SaveIcon style={{color: '#0f6ecd'}} />
                          </IconButton>
                         </OverlayTrigger>
                        )
                      }
                      <OverlayTrigger
                            overlay={<ReactTooltip place="bottom" effect="solid" />}
                           >
                            <IconButton
                              data-tip="Delete Profile"
                              onClick={() => deleteProfile(i)}
                              aria-label="fav-icon"
                              component="span"
                             >
                              <DeleteIcon fontSize="large" style={{color: '#f92626ed'}} />
                            </IconButton>
                         </OverlayTrigger>
                      <ToggleSwitch>
                        <input type="checkbox" checked={item.enable} onChange={() => updateProfile(i)}/>
                        <span className="slider round" data-tip={item.enable ? 'Disable Profile' : 'Activate Profile'}></span>
                      </ToggleSwitch>
                    </Card.Body>
                  </Card>
                ))
                : (
                !showSpinner && <div className="d-flex justify-content-center mt-5 h3 text-dark">
                  No Data found
                </div>
                )
              }
            </>
          }
        </div>
      </ProfileBody>
    </ChatSummaryContainer>
  );
}
