import React from 'react';
import { ZapOff } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 30px;
`;

const NotFoundIcon = styled.div`
  margin: 10px;
  svg {
    width: 60px;
    height: 60px;
  }
`;
const NotFoundText = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`;

const Page404: React.FC = () => {
  return (
    <NotFoundContainer>
      <NotFoundIcon>
        <ZapOff />
      </NotFoundIcon>
      <NotFoundText>404, page not found.</NotFoundText>
      <Link to={{ pathname: '/' }}>Go Home</Link>
    </NotFoundContainer>
  );
};

export default Page404;
