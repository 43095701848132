import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/index.scss';

import * as Sentry from '@sentry/browser';

import App from './App';
import AuthProvider from './context/auth/AuthProvider';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store';
import { toast } from 'react-toastify';

const store = configureStore();
toast.configure({
  autoClose: 4000,
  bodyClassName: 'ceres--toast-body',
  draggable: false,
  position: toast.POSITION.TOP_RIGHT,
});

// Initialize Sentry Logging
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
    <App />
    </Provider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
