import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EnviroAiTriangle from '../../assets/enviro.svg';

interface IEnviroSearchForm {
  show: boolean;
  queryText: string;
  onClose: () => void;
  setQueryText: (searchText: string) => void;
}

const EnviroSearchFilter = ({ show, onClose, queryText, setQueryText }: IEnviroSearchForm) => {
  const [searchQuestion, setSearchQuestion] = useState('');

  useEffect(() => {
    if(show) {
      setSearchQuestion(queryText);
    } else {
      setSearchQuestion('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmitBtn = () => {
    setQueryText(searchQuestion);
    onClose();
  };

  return (
    <>
      <Modal show={show} dialogClassName="enviro-filter-modal" onHide={onClose} animation={false} centered>
        <Modal.Header closeButton >
        </Modal.Header>
        <Modal.Body>
          <img src={EnviroAiTriangle} alt="enviro-logo" />
          <textarea value={searchQuestion} onChange={(e: any) => setSearchQuestion(e.target.value)} rows={6} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
          <Button variant="primary" onClick={onSubmitBtn}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnviroSearchFilter;
