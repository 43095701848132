import React from 'react';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';

interface IAirAiSummary {
  isActive: boolean;
  path: string;
  totalAmount: number;
  numIncidents: number;
  setAirEventTableOpen: any;
  airEventTableOpen: any;
}

const Floater = styled.div`
  padding: 1em;
  background-color: #7030a0;
  color: white;
`;

const AirAiSummary: React.FC<IAirAiSummary> = props => {
  const {
    isActive,
    path,
    totalAmount,
    numIncidents,
    airEventTableOpen,
    setAirEventTableOpen
  } = props;

  const show = isActive && path === '/ai-air' && numIncidents;
  const totalEvents = totalAmount ? totalAmount.toFixed(2) : null;
  if (show) {
    return (
      <Floater>
        <Row>
          <Col lg={10} sm={12}>
            Total events: {numIncidents} (since 2004) <br />
            Total released: {totalEvents} lbs (since 2004)
          </Col>
          <Col lg={2} sm={12}>
            <Button onClick={() => setAirEventTableOpen(!airEventTableOpen)}>
              {airEventTableOpen ? '<' : '>'}
            </Button>
          </Col>
        </Row>
      </Floater>
    );
  }
  return null;
};

export default AirAiSummary;
