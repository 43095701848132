import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ICustomSearchRecord } from '../../../types/search';
import mediaQuery from '../../../util/media-query';

const CardContainer = styled.div`
  margin-bottom: 10px;
  .card-title,
  .card-text {
    margin-bottom: 0;
  }
  p.card-text {
    font-size: 14px;
  }
  .card-text small {
    font-size: 15px;
  }
  .card {
    border: none;
  }
  .card-body {
    padding: 0.75em 0;
    overflow: hidden;
  }
  & .link-text {
    color: #202124;
  }
`;
const TitleLink = styled.a`
  color: #1a0dab;
  .h5 {
    line-height: 36px;
    ${mediaQuery.phone`
    line-height: 26px;
    font-size: 18px;
  `}
  }
`;

interface IProps {
  records: ICustomSearchRecord[];
}

const useQuery = () => {
  const { search: searchVal } = useLocation();

  return React.useMemo(() => new URLSearchParams(searchVal), [searchVal]);
};

const DefaultItem: React.FC<IProps> = ({ records }) => {
  const queryParam = useQuery();
  const isDirectCustomSearch: any = queryParam.get('directsearch');
  return (
    <>
      {records.map((record, index) => (
        <CardContainer key={index}>
          <Card>
            <Card.Body>
              <Row>
                {record.videoobject && (
                  <Col lg={4}>
                    <iframe
                      width="100%"
                      height="140"
                      src={record.videoobject}
                      title="Lazy Loading Templates and View Data in Laravel with Laravel Splade"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Col>
                )}
                <Col lg={record.videoobject ? 8 : 10}>
                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: record.formattedUrl,
                    }}
                  />
                  <TitleLink href={record.url} target="_blank">
                    <Card.Title
                      dangerouslySetInnerHTML={{
                        __html: record.formattedTitle,
                      }}
                    />
                  </TitleLink>

                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: record.formattedDescription,
                    }}
                  />
                </Col>
                {!record.videoobject && (
                  <Col lg={2}>
                    {record.image && record.image[0] && record.image[0].src && !isDirectCustomSearch &&(
                      <img src={record.image[0].src} alt="" style={{ width: '100%' }} />
                    )}
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </CardContainer>
      ))}
    </>
  );
};
export default DefaultItem;
