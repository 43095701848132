import React, { useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from '../../util/media-query';
import { ICustomSearchResult, ICustomSearchRecord } from '../../types/search';
import SearchPagination from './SearchPagination';
import withAuthContext from '../../context/auth/AuthConsumer';
import { IAuthContext } from '../../context/auth/auth-context';
// import { TIERS } from '../../types/tiers';
import moment from 'moment';
import DefaultItem from './customType/DefaultItem';
import ImageItem from './customType/ImageItem';
import quotesObj from '../../util/quotes/natureQuotesMap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface IGoogleCustomSearchProps {
  result: ICustomSearchResult<ICustomSearchRecord> | null;
  error?: any;
  onPreviousClick: () => void;
  onNextClick: () => void;
  handleSortOrderChange: () => void;
  isLoading: boolean;
  sort: string;
  searchType: string;
  text: string;
  tCounter: string;
}

const NotFoundContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`;
const GoogleCustomSearchContainer = styled.div`
  margin: 0 13em 4em;
  display: flex;
  flex-direction: column;
  max-width: 780px;
  width: 100%;
  min-height: calc(100vh - 120px);
  position: relative;
  ${mediaQuery.phone`
    margin: 0 1em;
    width: auto;
  `}
  ${mediaQuery.tablet`
    margin: 0 1em;
  `}

  .back-btn {
    position: fixed;
    bottom: 3%;
    min-width: 15%;
    border-radius: 50px;
    margin: 0 30%;
    background-color: #5dc5c4 !important;
  }
`;

const TotalRecordTextContainer = styled.div`
  margin: 0.4em 0 0.2em;
  color: #6c757d;
  color: var(--secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .select {
    width: 120px;
    border: none;
    padding: 0.1rem 0.75rem;
    margin-top: -10px;
    background: transparent;
  }
  .select:focus {
    box-shadow: none;
  }
`;
const GoogleSearch = styled.a`
  position: absolute;
  bottom: 20px;
  margin: auto;
  left: 0;
  right: 0;
  width: 454px;
  font-size: 18px;
  padding: 8px 5px;
  border: 0;
  color: #000;
  background-color: #e7eaeb;
  text-align: center;
  & :focus {
    outline: 0px !important;
  }
  &:hover {
    text-decoration: none;
  }
  border-radius: 30px;
  ${mediaQuery.phone`
    width: 210px;
    bottom: -10px;
  `}
`;
const MsgInsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  h3 {
    width: 50%;
  }
  h2 {
    width: 75%;
  }
  h6 {
    width: 75%;
  }
`;
const MessageBar = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  width: 19%;
  margin: 0 13%;
  @media (max-width: 1241px) {
    position: static;
    width: 100%;
  }
  padding: 100px 20px 0px 0px;
  h2 {
    font-family: 'Lazy Dog';
    color: black;
    font-size: 36px;
    text-align: center;
    margin-top: 110px;
  }
  h1 {
    font-family: 'Lazy Dog';
    color: black;
    font-size: 3rem;
    text-align: center;
    margin-top: 110px;
  }
  h3 {
    color: black;
    font-size: 17px;
    text-align: center;
  }
  h6 {
    color: rgba(128, 128, 128, 0.5);
    padding: 50px 0px 0px 0px;
    text-align: center;
    font-size: 16px;
  }
  h5 {
    color: rgba(128, 128, 128, 0.5);
    width: auto;
    text-align: right;
    font-size: 16px;
  }
`;
// const Exit = styled.div`
//   padding: 74px 14px 0px;
//   font-family: 'Lazy Dog';
//   font-size: 20px;
//   cursor: pointer;
//   line-height: 33px;
// `;
// const NumberSpan = styled.span`
//   color: #36c1f3;
// `;
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1241px) {
    flex-direction: column;
  }
`;

const GoogleCustomSearch: React.FC<IGoogleCustomSearchProps & { context: IAuthContext }> = (props) => {
  const records = props.result ? props.result.records : [];
  // const authContext = props.context;
  const { sort, handleSortOrderChange, isLoading } = props;
  const noResults = props.result && props.result.records.length <= 0;

  const today = moment().format('YYYYMMDD');
  const lastYear = moment().subtract(1, 'year').format('YYYYMMDD');
  const lastMonth = moment().subtract(1, 'month').format('YYYYMMDD');
  const lastWeek = moment().subtract(1, 'week').format('YYYYMMDD');
  const options = [
    { sort: '', label: 'Relevance' },
    { sort: 'date', label: 'Most Recent' },
    { sort: `date:r:${lastYear}:${today}`, label: 'Past Year' },
    { sort: `date:r:${lastMonth}:${today}`, label: 'Past Month' },
    { sort: `date:r:${lastWeek}:${today}`, label: 'Past Week' },
  ];
  const history = useHistory();
  const [quoteAuthor, setQuoteAuthor] = useState('abc');
  const [quote, setQuote] = useState('');
  // const [popup, setpopup] = useState(true);

  // const closepopup = () => {
  //   setpopup(false);
  // };
  const store: any = useSelector<any>((state): any => state);
  const isBackButton = !!store.globalFlags.previousPage && store.globalFlags.previousPage === 'myenviroaiToInternetResults';
  const randomNumberInRange = (min: any, max: any) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateQuote = () => {
    const randomNumber: number = randomNumberInRange(1, 50);
    const newQuote = quotesObj[randomNumber];
    setQuote(newQuote['quote']);
    setQuoteAuthor(newQuote['quoteAuthor']);
  };
  React.useEffect(() => {
    generateQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const numberWithCommas = (data: any) => {
  //   return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // };

  const handleBackButton = (e: any) => {
    // flag use to clear chat data when user visit enviro page not from here
    localStorage.setItem('isBackToEnviroPage', 'true');
    history.replace('myenviroai');
  };

  return (
    <SearchWrapper className="quote-main-container">
      <GoogleCustomSearchContainer>
        <TotalRecordTextContainer>
          <div>
            About <b className="records-count">{(props.result && props.result.size) || 0}</b> records
          </div>
          <FormControl
            as="select"
            onChange={handleSortOrderChange}
            placeholder="Sort"
            aria-label="Sort"
            aria-describedby="cse-sort"
            disabled={isLoading}
            className="select"
            value={sort}
          >
            {options.map(({ sort: sortString, label }) => (
              <option key={`${sortString}-${label}`} value={sortString}>
                {label}
              </option>
            ))}
          </FormControl>
        </TotalRecordTextContainer>

        {isLoading && <NotFoundContainer>Searching...</NotFoundContainer>}

        {noResults && !isLoading && (
          <NotFoundContainer>
            <h3>No results found.</h3>
          </NotFoundContainer>
        )}

        {!noResults && !isLoading && (
          <>{props.searchType === 'IMAGES' ? <ImageItem records={records} /> : <DefaultItem records={records} />}</>
        )}

        {!props.isLoading && (
          <>
            <GoogleSearch
              href={`https://www.google.com/search?q=${props.text}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Didn't find what you were looking for? Click here.
            </GoogleSearch>
            <SearchPagination
              // isDisabled={authContext.isTier(TIERS.FREE) && !authContext.isLubrizol()}
              isDisabled={false}
              isFirstPage={!!!(props.result && props.result.previous)}
              isLastPage={!!!(props.result && props.result.next)}
              totalRecords={(props.result && props.result.size) || 0}
              onPreviousClick={props.onPreviousClick}
              onNextClick={props.onNextClick}
            />
          </>
        )}
        {isBackButton && <Button variant="primary" className="back-btn" size="lg" onClick={handleBackButton}>Back</Button>}
      </GoogleCustomSearchContainer>
        <MessageBar className="message-bar">
          {/* <Exit onClick={closepopup}>x</Exit> */}
          <MsgInsContainer>
            <h1>BE A PLANETARY HERO!</h1>
          </MsgInsContainer>
          {/* {props.tCounter && <MsgInsContainer>
            <h3>your searches so far have reduced</h3>
            <h3>
              <NumberSpan>{numberWithCommas(props.tCounter)}</NumberSpan> kilograms of carbon
            </h3>
          </MsgInsContainer>} */}
          <MsgInsContainer>
            <h6>{quote}</h6>
          </MsgInsContainer>
          {quoteAuthor && <h5>— {quoteAuthor}</h5>}
        </MessageBar>
    </SearchWrapper>
  );
};

export default withAuthContext(GoogleCustomSearch);
