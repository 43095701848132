import * as moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputGroup, Modal, Form } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { Calendar, X } from 'react-feather';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { CeresLogger } from '../../logger';
import axios from 'axios';
import Select, { components } from 'react-select';
import { projectId } from '../../services/firebase';
import makeAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import { IDatabase } from '../../store/aiearth/types';
import { updateDatebase } from '../../store/aiearth/action';

const AdvancedSearchFilterHeader = styled(Modal.Header)`
  && {
    padding: 1rem 1.5rem;
    flex-direction: row;
  }
`;

const AdvancedSearchFilterBody = styled(Modal.Body)``;

const FilterItem = styled.div`
  margin-bottom: 15px;
`;
const DateFilterItem = styled.div`
  margin-bottom: 15px;
  button {
    line-height: 20px;
  }
  .rdt {
    flex: 1;
  }
  .filter-calender-icon {
    width: 20px;
    height: 20px;
  }
`;

const SingleFilterClearButton = styled.span`
  position: absolute;
  right: 60px;
  top: 5px;
  bottom: 0;
  color: #6c757d;
  color: var(--secondary);
  cursor: pointer;
`;
const SourceType = styled(Select)`
  flex: 1 1 auto;
  font-size: 12px;
`;
interface IAdvancedSearchFilterProps {
  show: boolean;
  onClose: () => void;
  onFilterApply: (filter: IAdvancedSearchFilter) => void;
  onFilterClear: () => void;
  rnInputRef?: React.MutableRefObject<any>;
  applyButtonRef?: React.MutableRefObject<any>;
  isLoading: boolean;
  doTextSearch: any;
  currentQueryText: any;
  sourceType: string;
  setSourceType: (type: string) => void;
}

interface IAdvancedSearchFilter {
  source_type: string;
  fromReleaseDate: moment.Moment | null;
  toReleaseDate: moment.Moment | null;
  rnNumber: string;
  cityName: string;
  entityName: string;
  zipCode: string;
  state: string;
  category: string;
  recordSeries: string;
  recordTitles: string;
  hideEmissionEvents: boolean;
}

interface IAdvancedSearchFilterState extends IAdvancedSearchFilter {}

const initialFilterState = {
  source_type: '',
  cityName: '',
  entityName: '',
  fromReleaseDate: null,
  toReleaseDate: null,
  rnNumber: '',
  zipCode: '',
  state: '',
  category: '',
  recordSeries: '',
  recordTitles: '',
  hideEmissionEvents: false,
} as IAdvancedSearchFilterState;

const AdvancedSearchFilter: React.FC<IAdvancedSearchFilterProps> = (props) => {
  const store: any = useSelector<any>((state): any => state);
  const dispatch = useDispatch();
  const databaseState: IDatabase = store.database;
  const aiToCeresRN = localStorage.getItem('RnNumber');
  const checkFilterStatus = () => {
    if (aiToCeresRN) {
      initialFilterState.rnNumber = aiToCeresRN;
      return initialFilterState;
    }
    return initialFilterState;
  };
  const [filters, setFilter] = useState<IAdvancedSearchFilterState>(
    checkFilterStatus()
  );
  const [hasAnyFilter, setHasAnyFilter] = useState(false);
  const [showFromReleaseDate, setFromShowReleaseDate] = useState(false);
  const [showToReleaseDate, setToShowReleaseDate] = useState(false);
  const [recordSeries, setRecordSeries] = useState<any>([]);
  const [seriesRecord, setSeriesRecord] = useState<any>('');
  const [titleRecord, setTitleRecord] = useState<any>([]);
  const [getCategory, setCategory] = useState<any>([]);

  const hasAnyFilterApplied = (appliedfilters: any) => {
    const hasFilter =
      Object.keys(appliedfilters).filter((key: string) => !!appliedfilters[key])
        .length > 0;
    if (!hasFilter) {
      setFilter(initialFilterState);
      props.onFilterClear();
    }
    return hasFilter;
  };
  const getRecordSeries = () => {
    try {
      axios
        .post(
          'https://us-central1-' +
            projectId +
            '.cloudfunctions.net/api/recordSeries',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/recordSeries',
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response: any) => {
          setRecordSeries(response.data[0].jsonlistb);
          dispatch(
            updateDatebase({
              ...databaseState,
              allState: response.data[0].jsonlistb,
            })
          );
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  useEffect(() => {
    if (aiToCeresRN) {
      setHasAnyFilter(true);
      if (props.applyButtonRef) {
        toast.info('Applied advanced search filters.');
        props.onFilterApply(filters);
      }
    }
    getRecordSeries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordSeriesInfo = (
    val: any,
    newFilter: IAdvancedSearchFilterState
  ) => {
    const records = getCategory.record_series;
    let jsonArray: any = '';
    if (records && val) {
      for (const info of records) {
        if (info.pid === val) {
          if (jsonArray) {
            jsonArray = jsonArray.concat([info]);
          } else {
            jsonArray = [info];
          }
        }
      }
    } else {
      setFilter(newFilter);
      setHasAnyFilter(hasAnyFilterApplied(newFilter));
    }
    setSeriesRecord(jsonArray);
    setTitleRecord('');
  };

  const recordTitle = (val: any) => {
    const records = getCategory.titles;
    let jsonArray: any = '';
    if (records && val) {
      for (const info of records) {
        if (info.pid === val) {
          if (jsonArray) {
            jsonArray = jsonArray.concat([formattedSelectJson(info)]);
          } else {
            jsonArray = [formattedSelectJson(info)];
          }
        }
      }
    }
    setTitleRecord(jsonArray);
  };
  const formattedSelectJson = (data: any) => {
    const json = {
      value: data.id,
      label: data.name,
    };
    return json;
  };
  const animatedComponents = makeAnimated();
  const IndicatorsContainer = (properties: any) => {
    return (
      <div style={{ background: '#005FBC' }}>
        <components.IndicatorsContainer {...properties} />
      </div>
    );
  };
  const indicatorStyle = (base: any) => ({
    ...base,
    color: 'white',
    padding: '8px',
  });
  const styles = {
    clearIndicator: indicatorStyle,
    dropdownIndicator: indicatorStyle,
    menu: (provided: any) => ({ ...provided, zIndex: 99 }),
  };

  useEffect(() => {
    if (props.sourceType && Object.keys(recordSeries).length > 0) {
      const findSelect: any = Object.values(recordSeries).filter(
        (src: any) => src.source_type === props.sourceType
      )[0];
      const newFilter = {
        ...filters,
        state: findSelect.state_code,
        category: '',
        recordSeries: '',
      };
      setFilter(newFilter);
      setCategory(
        Object.values(recordSeries).filter(
          (src: any) => src.state_code === findSelect.state_code
        )[0]
      );
      setSeriesRecord([]);
      setTitleRecord([]);
    } else {
      const newFilter = {
        ...filters,
        state: '',
        category: '',
        recordSeries: '',
      };
      setFilter(newFilter);
      setHasAnyFilter(hasAnyFilterApplied(newFilter));
      setCategory([]);
      setSeriesRecord([]);
      setTitleRecord([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sourceType, recordSeries]);

  return (
    <Modal
      dialogClassName="ceres--advance-search-filter"
      centered
      show={props.show}
      onHide={props.onClose}
    >
      <AdvancedSearchFilterHeader closeButton>
        <Modal.Title>Advanced Search Filter</Modal.Title>
      </AdvancedSearchFilterHeader>
      <Form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (props.currentQueryText) {
            props.doTextSearch(props.currentQueryText, 0, props.sourceType);
          }
        }}
      >
        <AdvancedSearchFilterBody>
          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="rn-number-filter">
                Entity Number
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                ref={props.rnInputRef}
                onChange={(e: any) => {
                  const newFilter = { ...filters, rnNumber: e.target.value };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                value={filters.rnNumber}
                placeholder="Entity Number"
                aria-label="Entity Number"
                aria-describedby="rn-number-filter"
              />
            </InputGroup>
          </FilterItem>
          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="city-name-filter">
                  City Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={(e: any) => {
                  const newFilter = { ...filters, cityName: e.target.value };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                value={filters.cityName}
                placeholder="City Name"
                aria-label="City Name"
                aria-describedby="city-name-filter"
              />
            </InputGroup>
          </FilterItem>

          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="entity-name-filter">
                  Entity Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={(e: any) => {
                  const newFilter = { ...filters, entityName: e.target.value };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                value={filters.entityName}
                placeholder="Entity Name"
                aria-label="Entity Name"
                aria-describedby="entity-name-filter"
              />
            </InputGroup>
          </FilterItem>
          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="zip-code-filter">Zip Code</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={(e: any) => {
                  const newFilter = { ...filters, zipCode: e.target.value };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                value={filters.zipCode}
                placeholder="Zip Code"
                aria-label="Zip Code"
                aria-describedby="zip-code-filter"
              />
            </InputGroup>
          </FilterItem>

          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="state-filter">State</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                onChange={(e: any) => {
                  const newFilter = {
                    ...filters,
                    state: e.target.value,
                    category: '',
                    recordSeries: '',
                  };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));

                  e.target.value
                    ? setCategory(
                        Object.values(recordSeries).filter(
                          (src: any) => src.state_code === e.target.value
                        )[0]
                      )
                    : setCategory([]);
                  setSeriesRecord([]);
                  setTitleRecord([]);
                  const sourceType =
                    e.target[e.target.selectedIndex].getAttribute('data-type');
                  props.setSourceType(sourceType ? sourceType : null);
                }}
                value={filters.state}
                placeholder="State"
                aria-label="State"
                aria-describedby="state-filter"
              >
                <option value="">Please Select</option>
                {recordSeries &&
                  Object.keys(recordSeries).length > 0 &&
                  Object.values(recordSeries).map((src: any, index: number) => (
                    <option
                      value={src.state_code}
                      key={index}
                      data-type={src.source_type}
                    >
                      {src.state_code}
                    </option>
                  ))}
              </FormControl>
            </InputGroup>
          </FilterItem>
          {getCategory && Object.values(getCategory).length > 0 && (
            <FilterItem>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="category-filter">
                    Category
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  onChange={(e: any) => {
                    const newFilter = {
                      ...filters,
                      category: e.target.value,
                      recordSeries: '',
                      recordTitles: '',
                    };
                    setFilter(newFilter);
                    setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    recordSeriesInfo(
                      e.target[e.target.selectedIndex].getAttribute('data-id'),
                      newFilter
                    );
                  }}
                  value={filters.category}
                  placeholder="Category"
                  aria-label="Category"
                  aria-describedby="category-filter"
                >
                  <option value="">Please Select</option>
                  {getCategory.categories &&
                    getCategory.categories.map((src: any, index: number) => (
                      <option value={src.name} data-id={src.id} key={index}>
                        {src.name}
                      </option>
                    ))}
                </FormControl>
              </InputGroup>
            </FilterItem>
          )}

          {seriesRecord.length > 0 && (
            <FilterItem>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="record-filter">
                    Record Series
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  onChange={(e: any) => {
                    const newFilter = {
                      ...filters,
                      recordSeries: e.target.value,
                      recordTitles: '',
                    };
                    setFilter(newFilter);
                    setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    recordTitle(
                      e.target[e.target.selectedIndex].getAttribute('data-id')
                    );
                  }}
                  value={filters.recordSeries}
                  placeholder="Record Series"
                  aria-label="Record Series"
                  aria-describedby="record-filter"
                >
                  <option value="">Please Select</option>
                  {seriesRecord.map((record: any) => (
                    <option
                      value={record.name}
                      data-id={record.id}
                      key={record.id}
                    >
                      {record.name}
                    </option>
                  ))}
                </FormControl>
              </InputGroup>
            </FilterItem>
          )}
          {titleRecord.length > 0 && (
            <FilterItem>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="title-filter">Titles</InputGroup.Text>
                </InputGroup.Prepend>
                <SourceType
                  value={filters.recordTitles}
                  components={{ IndicatorsContainer, animatedComponents }}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  styles={styles}
                  options={titleRecord}
                  onChange={(e: any) => {
                    const newFilter = {
                      ...filters,
                      recordTitles: e,
                    };
                    setFilter(newFilter);
                    setHasAnyFilter(hasAnyFilterApplied(newFilter));
                  }}
                />
              </InputGroup>
            </FilterItem>
          )}
          <DateFilterItem>
            <InputGroup>
              <DateTime
                value={filters.fromReleaseDate || undefined}
                open={showFromReleaseDate}
                dateFormat={'DD/MM/YYYY'}
                timeFormat={false}
                closeOnSelect={true}
                onBlur={() => setFromShowReleaseDate(false)}
                onChange={(e) => {
                  const newFilter = {
                    ...filters,
                    fromReleaseDate: e as moment.Moment,
                  };
                  setFilter(newFilter);
                  return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                inputProps={{
                  placeholder: 'From Release Date',
                  readOnly: true,
                }}
              />
              {!!filters.fromReleaseDate && (
                <SingleFilterClearButton
                  onClick={() => {
                    const newFilter = {
                      ...filters,
                      fromReleaseDate: null,
                    };
                    setFilter(newFilter);
                    return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                  }}
                >
                  <X />
                </SingleFilterClearButton>
              )}
              <InputGroup.Append>
                <Button
                  onClick={() => setFromShowReleaseDate(true)}
                  variant="outline-secondary"
                >
                  <Calendar className={'filter-calender-icon'} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </DateFilterItem>
          <DateFilterItem>
            <InputGroup>
              <DateTime
                value={filters.toReleaseDate || undefined}
                open={showToReleaseDate}
                dateFormat={'DD/MM/YYYY'}
                timeFormat={false}
                closeOnSelect={true}
                onBlur={() => setToShowReleaseDate(false)}
                onChange={(e) => {
                  const newFilter = {
                    ...filters,
                    toReleaseDate: e as moment.Moment,
                  };
                  setFilter(newFilter);
                  return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
                inputProps={{
                  placeholder: 'To Release Date',
                  readOnly: true,
                }}
              />
              {!!filters.toReleaseDate && (
                <SingleFilterClearButton
                  onClick={() => {
                    const newFilter = {
                      ...filters,
                      toReleaseDate: null,
                    };
                    setFilter(newFilter);
                    return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                  }}
                >
                  <X />
                </SingleFilterClearButton>
              )}
              <InputGroup.Append>
                <Button
                  onClick={() => setToShowReleaseDate(true)}
                  variant="outline-secondary"
                >
                  <Calendar className={'filter-calender-icon'} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </DateFilterItem>
          <FilterItem>
            <InputGroup>
              <Form.Check
                type="checkbox"
                label="Hide Emission Events"
                name="emissionEvents"
                checked={filters.hideEmissionEvents}
                onChange={(e: any) => {
                  const newFilter = {
                    ...filters,
                    hideEmissionEvents: e.target.checked,
                  };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
              />
            </InputGroup>
          </FilterItem>
        </AdvancedSearchFilterBody>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              toast.info('Cleared advanced search filters.');
              setFilter(initialFilterState);
              setHasAnyFilter(false);
              filters.rnNumber = '';
              return props.onFilterClear();
            }}
          >
            Clear Filter
          </Button>
          <Button
            ref={props.applyButtonRef}
            disabled={!hasAnyFilter}
            variant="primary"
            onClick={() => {
              if (!props.currentQueryText) {
                toast.info('Applied advanced search filters.');
              }
              props.onFilterApply(filters);
            }}
            type="submit"
            name="submitSearch"
          >
            Apply Filter
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AdvancedSearchFilter;
