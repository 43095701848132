import React, { useEffect, useState } from 'react';
import { auth, db } from '../../services/firebase';
import AuthContext, { authInitialState, IAuthContext } from './auth-context';
import { TIERS } from '../../types/tiers';
import { ICeresUser } from '../../interfaces/user';
import * as R from 'ramda';
import { CeresLogger } from '../../logger';

/**
 * Fetch hash for a given user profile detail.
 * @param userProfileDetails - User Profile Details.
 */
const getUserhash = (userProfileDetails: ICeresUser) =>
  btoa(
    `${userProfileDetails.uid}:${userProfileDetails.tier}:${userProfileDetails.stripeCustomerId}:${userProfileDetails.displayName}`
  );

/**
 * Store the generated hash in local storage.
 * @param userProfileDetails - User Profile Details
 */
const storeUserHash = (userProfileDetails: ICeresUser) => {
  const isAlreadySet = !!localStorage.getItem('u:sign');
  if (isAlreadySet && !hasSignatureChanged(userProfileDetails)) {
    // CeresLogger.info({
    //   message: `No hash change was found, skipping updating the user hash: uid: ${userProfileDetails.uid}`,
    // });
    return;
  }
  CeresLogger.info({
    message: `Setting user hash for user: ${userProfileDetails.uid}`,
    payload: userProfileDetails,
  });
  localStorage.setItem('u:sign', getUserhash(userProfileDetails));
};

/**
 * Check for hash change based on the changes in user profile details.
 * @param userProfileDetails User Profile Details
 */
const hasSignatureChanged = (userProfileDetails: ICeresUser) => {
  const currentHash = localStorage.getItem('u:sign');
  if (R.isEmpty(currentHash) || R.isNil(currentHash)) {
    // CeresLogger.info({
    //   message: `hash change - uid: ${userProfileDetails.uid} - change: ${false}`,
    // });
    return false;
  }
  const hashChange = !R.equals(getUserhash(userProfileDetails), currentHash);
  // CeresLogger.info({
  //   message: `hash change - uid: ${userProfileDetails.uid} - change: ${hashChange}`,
  // });
  return hashChange;
};

const AuthProvider: React.FC = (props) => {
  const [state, setState] = useState<IAuthContext>(authInitialState);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      const stateChange = {
        ...authInitialState,
        isAuthenticated: !!user,
        isInitialized: true,
        user,
      };
      if (user) {
        if(user.uid) {
          localStorage.setItem('uid', user.uid);
        }
        const userProfileDetails = await Promise.resolve()
          .then(() => db.collection('users').doc(user.uid).get())
          .then((userDocumentSnapshot) => {
            const data = userDocumentSnapshot.data();
            if (R.isNil(data)) {
              return null;
            }
            // Walkthrough is temporarily disabled
            // if (R.isNil(data.showTutorialWalkThrough)) {
            //   return db
            //     .collection('users')
            //     .doc(user.uid)
            //     .update({
            //       showTutorialWalkThrough: true
            //     })
            //     .then(() => ({ ...data, showTutorialWalkThrough: true }));
            // }
            return data;
          })
          .catch((error) => {
            const errorCode = error.code || '';
            if (errorCode === 'permission-denied') {
              CeresLogger.debug({
                message: `Ignoring 'permission denied' error, this could happen during signout`,
                payload: error,
              });
              return null;
            }
            CeresLogger.error({
              message: `Failed to fetch user profile details`,
              payload: error,
            });
            return null;
          });
        if (R.isNil(userProfileDetails)) {
          return setState({
            ...stateChange,
            user: null,
            isAuthenticated: false,
          });
        }
        const hasUserSignatureChanged = hasSignatureChanged(userProfileDetails as ICeresUser);
        const tokenResult = await user.getIdTokenResult(hasUserSignatureChanged);
        if (!!tokenResult.claims && !!userProfileDetails) {
          let isLubrizol = false;
          if (user.email) {
            const uEmail = user.email;
            const broken = uEmail.split('@');
            const emailDomain = broken[broken.length - 1];
            if (emailDomain.toLowerCase() === 'lubrizol.com') {
              isLubrizol = true;
            }
          }
          const updatedAuthState = {
            ...stateChange,
            claims: tokenResult.claims,
            isTier: (tier: TIERS) => tier === tokenResult.claims.tier,
            isLubrizol: () => isLubrizol,
            getUserTier: () => tokenResult.claims.tier,
            getUserEmail: () => user.email || '',
            getUserName: () => userProfileDetails.name || '',
            getUserPhone: () => userProfileDetails.phone || '',
            getClientName: () => userProfileDetails.clientname || '',
            getClientAddress: () => userProfileDetails.address || '',
            getUserDisplayName: () => user.displayName || '',
            getUserId: () => user.uid,
            getCompleteUserInfo: () =>
              ({
                uid: user.uid,
                tier: tokenResult.claims.tier,
                stripeCustomerId: userProfileDetails.stripeCustomerId,
                isEmailVerified: user.emailVerified,
                displayName: user.displayName,
                showTutorialWalkThrough: userProfileDetails.showTutorialWalkThrough,
              } as ICeresUser),
          };
          // CeresLogger.info({
          //   message: 'Printing current logged in user info',
          //   payload: updatedAuthState.getCompleteUserInfo()
          // });
          // Store the new user profile hash, if any change is found.
          storeUserHash(userProfileDetails as ICeresUser);
          return setState(updatedAuthState);
        }
      }
      return setState(stateChange);
    });
  }, []);
  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
