import React, { useEffect, useState } from 'react';
import LOADER_MESSAGES from '../../constants/loader-messages';

const MessageLoader = () => {
  const [currentMessage, setCurrentMessage] = useState(() => LOADER_MESSAGES[Math.floor(Math.random() * LOADER_MESSAGES.length)]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * LOADER_MESSAGES.length);
      setCurrentMessage(LOADER_MESSAGES[randomIndex]);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loader-message d-flex gap-2 align-items-center justify-content-center">
      <p className="mt-2 mb-0" style={{ fontSize: '14px', fontFamily: 'Roboto , sans-serif' }}>
        {currentMessage}
      </p>
      <div className="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MessageLoader;
