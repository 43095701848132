import * as firebase from 'firebase/app';
import * as R from 'ramda';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { CeresLogger } from '../logger';
import * as CryptoJS from 'crypto-js';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

export const ceresApp = firebase.initializeApp(firebaseConfig);
const functions = ceresApp.functions();

if (R.equals(process.env.REACT_APP_USE_FUNCTIONS_SIMULATOR, 'true')) {
  const functionSimulatorUrl = process.env.REACT_APP_SIMULATOR_URL || 'http://localhost:5000';
  // tslint:disable-next-line
  CeresLogger.info({
    message: `Using local function simulator. url: ${functionSimulatorUrl}`,
  });
  functions.useFunctionsEmulator(functionSimulatorUrl);
}

export const auth = ceresApp.auth();
export const db = ceresApp.firestore();
export const searchFavorite = functions.httpsCallable('searchFavorite');
export const search = functions.httpsCallable('search');
export const searchLDEQ = functions.httpsCallable('searchLDEQ');
export const searchAIEarth = functions.httpsCallable('searchAIEarth');
export const customSearch = functions.httpsCallable('customSearch');
export const placeTextSearch = functions.httpsCallable('placeTextSearch');
export const buttonFavorite = functions.httpsCallable('buttonFavorite');
export const eagleEye = functions.httpsCallable('getImageQuality');
export const fetchCeresUsers = functions.httpsCallable('fetchCeresUsers');
export const fetchValidatorUsage = functions.httpsCallable('fetchValidatorUsage');
export const updateUser = functions.httpsCallable('updateUser');
export const chatBotChat = functions.httpsCallable('chatBotChat');
export const stripeCheckout = functions.httpsCallable('stripe-checkout');
export const addJob = functions.httpsCallable('addJob');
export const addEagleEyeJob = functions.httpsCallable('addEagleEyeJob');
export const storage = ceresApp.storage();
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const key = CryptoJS.enc.Utf8.parse('0fab98deb90c6d7b122832b617c2a8b7');
export const iv = CryptoJS.enc.Utf8.parse('A58E47EE52124CFD');
export const validatorStorage = ceresApp.storage('gs://' + process.env.REACT_APP_VALIDATOR_STORAGE_BUCKET);
export const eagleEyeStorage = ceresApp.storage('gs://' + process.env.REACT_APP_EAGLEEYE_STORAGE_BUCKET);
export const saveChats = functions.httpsCallable('savedChats');
export const saveProfile = functions.httpsCallable('savedProfile');
export const savePrompts = functions.httpsCallable('savedPrompts');
export const saveLibrary = functions.httpsCallable('savedLibrary');
