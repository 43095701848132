import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IJob } from '../../../store/aiearth/types';
import { Table } from 'react-bootstrap';

const Section = styled.div``;
const TableAstm = styled(Table)`
  width: 704px;
`;
const TD = styled.td`
  font-size: 12px;
`;
const TH = styled.th`
  font-size: 12px;
  text-transform: uppercase;
`;

const DatabaseDescription: React.FunctionComponent<any> = () => {
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;
  const data = jobState.esadataFindings.database_description.programs;

  return (
    <Section>
      <TableAstm bordered hover>
        <thead>
          <tr>
            <TH>Program</TH>
            <TH>Acronymn</TH>
            <TH>Name</TH>
            <TH>Source Date</TH>
            <TH>Contact Date</TH>
            <TH>Activated Date</TH>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).length > 0 &&
            Object.keys(data).map((info, keys: number) => (
              <>
                <tr key={info + keys}>
                  <TD colSpan={6}>
                    <strong>{info}</strong>
                  </TD>
                </tr>
                {data[info].length > 0 &&
                  data[info].map((json: any, index: number) => (
                    <tr key={Object.keys(json)[0] + index}>
                      <TD>{json[Object.keys(json)[0]]['program_title']}</TD>
                      <TD>{json[Object.keys(json)[0]]['program_acronym']}</TD>
                      <TD>{json[Object.keys(json)[0]]['program_name']}</TD>
                      <TD>{json[Object.keys(json)[0]]['source_date']}</TD>
                      <TD>{json[Object.keys(json)[0]]['contact_date']}</TD>
                      <TD>{json[Object.keys(json)[0]]['activated_date']}</TD>
                    </tr>
                  ))}
              </>
            ))}
        </tbody>
      </TableAstm>
    </Section>
  );
};
export default DatabaseDescription;
