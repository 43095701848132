import {
  AIEarthActionTypes,
  AirActionTypes,
  DatabaseActionTypes,
  SearchActionTypes,
  IAIEarth,
  IActiveJob,
  IAir,
  IDatabase,
  IJob,
  IMap,
  ISearch,
  JobActionTypes,
  MapActionTypes,
  RESET_AIR,
  RESET_AISTORE,
  RESET_DATABASE,
  RESET_JOB,
  RESET_MAP,
  RESET_SEARCH,
  UPDATE_AIR,
  UPDATE_AISTORE,
  UPDATE_DATABASE,
  UPDATE_JOB,
  UPDATE_MAP,
  UPDATE_SEARCH,
  SavedChatsActionTypes,
  UPDATE_SAVED_CHATS,
  UPDATE_QUICK_PROMPTS,
  QuickPromptsActionTypes,
  GlobalFlagsActionTypes,
  UPDATE_GLOBAL_FLAGS,
  REMOVE_GLOBAL_FLAG
} from './types';

import { dummyRecord } from '../../types/search';

const initialStateAir: IAir = {
  activeRecord: dummyRecord,
};

const initialStateAIEarth: IAIEarth = {
  activeTab: 'document',
  mainDrawer: '',
  subDrawer: '',
  airListOptions: null,
  isLoading: false,
  data: null,
  isCollapse: false,
  favorites: [],
  favoritesLoaded: false,
};

const initialStateMap: IMap = {
  gMap: null,
  gMaps: null,
  subjectPropertyShowLayer: null,
  oilGasJobMaps: null,
  pipeLinesJobMaps: null,
  parcelSelectionLayer: null,
  adjoiningPropertyLayer: null,
  adjoiningPropertiesBoundaries: null,
  waterWellsPointer: null,
  monitorWellsPointer: null,
  soilsPropertyLayer: null,
  pipelineLayerRef: null,
  places: '',
  layers: [],
  jobLayers: [],
  execute: false,
  drawingManager: null,
  address: '',
  zip: '',
  state: '',
  city: '',
  mapOverLay: null,
  marker: null,
};

export const activeJobInitial: IActiveJob = {
  jobJson: '',
  active: true,
  jobStartDate: new Date(),
  jobCompleteDate: null,
  jobActualCompleteDate: null,
  numberOfDays: 0,
  jobNotes: '',
  facilityName: '',
  status: 'Active',
  zip: '',
  state: '',
  city: '',
  address: '',
  name: '',
  jobType: 'ASTM Phase 1',
  userId: '',
  jobId: '',
  selectionType: 'Parcel Select',
  gwGradient: 'NOT_DETERMINED',
  keySiteManagers: [],
  users: [],
  corridorWidth: 20,
  execute: false,
};

const initialStateJob: IJob = {
  panelType: 'tasks',
  wizardOpen: false,
  activeStep: 0,
  jobInit: false,
  jobAction: false,
  jobCreate: 'neutral',
  jobJson: null,
  esaData: {},
  isLoadEsaData: false,
  adjoiningProperties: [],
  adjoiningPropJson: null,
  jobsData: null,
  activeJob: activeJobInitial,
  topoImages: [],
  topoModal: false,
  jobFeature: {},
  jobFeatureTab: false,
  mapFindings: {},
  mapFindingTab: false,
  searchFinding: false,
  toolTipPoint: [],
  esadataFindings: {},
  mapFindingsTypes: 'Job Map Entities',
  adjoiningPropertiesBoundaries: {},
  soilSelect: {},
};

export const initialStateDatabase: IDatabase = {
  cityName: '',
  entityName: '',
  fromReleaseDate: null,
  toReleaseDate: null,
  source_type: 'tceq_initial',
  rnNumber: '',
  programId: '',
  program: '',
  state: { value: 'tx', label: 'TX' },
  county: '',
  zipCode: '',
  category: '',
  street: '',
  mapOptions: 'all',
  source: '',
  offset: 0,
  limit: 50,
  sortDirection: '',
  sortColumn: '',
  boundingBox: '',
  allState: '',
};
export const initialStateSearch: ISearch = {
  isSearch: false,
  type: '',
  customSearchType: 'google',
};

export const initalSavedChatsData: any[] = [];
export const initalFlags: any = {};
export const initalQuickPromptsData: any[] = [];

export function airReducer(state = initialStateAir, action: AirActionTypes | any): IAir {
  switch (action.type) {
    case UPDATE_AIR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_AIR: {
      return initialStateAir;
    }
    default:
      return state;
  }
}

export function aiearthReducer(state = initialStateAIEarth, action: AIEarthActionTypes | any): IAIEarth {
  switch (action.type) {
    case UPDATE_AISTORE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_AISTORE: {
      return {...initialStateAIEarth, favorites: state.favorites};
    }
    default:
      return state;
  }
}

export function mapReducer(state = initialStateMap, action: MapActionTypes | any): IMap {
  switch (action.type) {
    case UPDATE_MAP: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_MAP: {
      return initialStateMap;
    }
    default:
      return state;
  }
}

export function jobReducer(state = initialStateJob, action: JobActionTypes | any): IJob {
  switch (action.type) {
    case UPDATE_JOB: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_JOB: {
      return initialStateJob;
    }
    default:
      return state;
  }
}

export function databaseReducer(state = initialStateDatabase, action: DatabaseActionTypes | any): IDatabase {
  switch (action.type) {
    case UPDATE_DATABASE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_DATABASE: {
      return initialStateDatabase;
    }
    default:
      return state;
  }
}

export function searchReducer(state = initialStateSearch, action: SearchActionTypes | any): ISearch {
  switch (action.type) {
    case UPDATE_SEARCH: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_SEARCH: {
      return initialStateSearch;
    }
    default:
      return state;
  }
}

export function savedChatReducer(state = initalSavedChatsData, action: SavedChatsActionTypes | any): any {
  switch (action.type) {
    case UPDATE_SAVED_CHATS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export function globalFlagsReducer(state = initalFlags, action: GlobalFlagsActionTypes | any): any {
  switch (action.type) {
    case UPDATE_GLOBAL_FLAGS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_GLOBAL_FLAG: {
      delete state[action.payload];
      return state;
    }
    default:
      return state;
  }
}

export function quickPromptsReducer(state = initalQuickPromptsData, action: QuickPromptsActionTypes | any): any {
  switch (action.type) {
    case UPDATE_QUICK_PROMPTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
