import React from 'react';

export const userInitialState = {
  AiToCeresSearch: false,
  searchTerm: '',
  RnNumber: '',
  updateValues: (n: any) => {
    throw new Error('updateCount() not implemented');
  }
};

const UserContext = React.createContext<IUserContext>(userInitialState);

export interface IUserContext {
  AiToCeresSearch: boolean;
  searchTerm: string;
  RnNumber: string;
  updateValues(userContext: IUserContext): void;
}
export default UserContext;
