import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouterProps, Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Account from './Account';
import AdminPanel from './admin';
import AiAir from './ai-air';
import AiEarth from './ai-earth';
import Benchmarking from './Benchmarking';
import CorrectiveTracker from './CorrectiveTracker';
import CeresHome from './home';
import MyEnviroAi from './my-enviro-ai';
import Checkout from './checkout';
import Generator from './Generator';
import Header from '../components/Header';
import { IAuthContext } from '../context/auth/auth-context';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MyTundra from './myTundra';
import NewProject from './new-project';
import NotificationPage from './Notification';
import PeopleFinderPage from './people-finder/index';
import Sentiment from './sentiment/index';
import NotificationProvider from '../context/notifications/NotificationProvider';
import Page404 from './404';
import PageTermsConditions from './TermsConditions';
import PageTutorial from './Tutorial';
import PlansAndPricing from './PlansAndPricing';
import ProjectProvider from '../context/project/ProjectProvider';
import ProjectSwitcher from '../components/projects/ProjectSwitcher';
import ProtectedRoute from '../ProtectedRoute';
import Reports from './reporttour';
import Sidebar from '../components/Sidebar';
// import SidebarOverlay from '../components/SidebarOverlay';
import StorePricing from './store/index';
import Survey from './survey/Index';
import { TIERS } from '../types/tiers';
import TrialRequest from './TrialRequest';
import UserProvider from '../context/user/UserProvider';
import ValidatorPage from './Validator';
import ValidatorUsage from './admin/ValidatorUsage';
import { projectId } from '../services/firebase';
import styled from 'styled-components';
import withAuthContext from '../context/auth/AuthConsumer';
import { ISearch } from '../store/aiearth/types';
import { searchFavorite } from '../services/firebase';
import tundraSearch from '../assets/search/tundra_search.svg';
import {
  updateAIEarth
} from '../store/aiearth/action';
import { IAIEarth } from '../store/aiearth/types';

const ShellContainer = styled.div<{ page: string }>`
  ${(props) => (props.page === '/search' ? `background-image: url(${tundraSearch});` : '')}
  // background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  background-color: ${(props) => (props.page === '/search' ? 'transparent' : '#fff')};
`;
const MainContainer = styled.div<{ issearch: string }>`
  padding-top: ${(props) => (props.issearch ? '0' : '0')};
`;
const CenterContainer = styled.div<{ page: string }>`
  padding:  ${(props) => (props.page === '/search' ? '7% 0 0' : '0')};;
`;
const Shell: React.FC<RouterProps & { context: IAuthContext }> = (shellProps) => {
  const context = shellProps.context as IAuthContext;
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isProjectSwitcherVisible, showProjectSwitcher] = useState(false);
  const [getSearchData, setSearchData] = useState<any>('');
  const searchurl = '/search' + (getSearchData ? '/' + getSearchData : getSearchData);
  const store: any = useSelector<any>((state): any => state);
  const searchState: ISearch = store.search;
  const aiEarthState: IAIEarth = store.aiearth;
  const dispatch = useDispatch();

  useEffect(() => {
    const favoritesFn = async (requestBody: any) => {
      try {
        const result = await searchFavorite(requestBody);
        dispatch(updateAIEarth({
          ...aiEarthState,
          favorites: result.data,
          favoritesLoaded: true
        }));
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log('search fav error', error);
      }
    };

    favoritesFn({ type: 'getAll' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Collapse sidebar on search
  useEffect(() => {
    if((!!shellProps.history.location.search && shellProps.history.location.pathname === '/search') || shellProps.history.location.pathname !== '/search') {
      setSidebarCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shellProps.history.location.search, shellProps.history.location.pathname]);

  return (
    <UserProvider>
      <NotificationProvider uid={(context.user && context.user.uid) || ''}>
        <ProjectProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ShellContainer page={shellProps.history.location.pathname}>
              <Header
                isHomePage={shellProps.history.location.pathname === '/'}
                isEnviroChat={shellProps.history.location.pathname === '/myenviroai'}
                isAIEarth={shellProps.history.location.pathname === '/ai-earth'}
                isAIEarthAir={shellProps.history.location.pathname === '/ai-earth-air'}
                isAIAir={shellProps.history.location.pathname === '/ai-air'}
                isBenchmarking={shellProps.history.location.pathname === '/benchmarking'}
                isCorrectiveTracker={shellProps.history.location.pathname === '/corrective-tracker'}
                isGenerator={shellProps.history.location.pathname === '/generator'}
                isTrackbot={shellProps.history.location.pathname === '/trackbot'}
                isPeopleFinder={shellProps.history.location.pathname === '/people-finder'}
                isSentiment={shellProps.history.location.pathname === '/sentiment'}
                isStore={shellProps.history.location.pathname.includes('/store')}
                history={shellProps.history}
                isSearch={shellProps.history.location.pathname === searchurl}
                getSearchData={getSearchData}
                setSearchData={setSearchData}
                onProjectSwitcherClick={() => showProjectSwitcher(true)}
                onHamburgerClick={() => {
                  setSidebarCollapsed((prevState) => {
                    return !prevState;
                  });
                }}
                searchState={searchState}
              />
              <MainContainer issearch={searchState && searchState.isSearch ? 'yes' : ''}>
                <Sidebar
                  isCollapsed={isSidebarCollapsed}
                  onItemClick={() => {
                    setSidebarCollapsed(true);
                  }}
                  onLogoutClick={() => shellProps.history.push('/login')}
                  onLoginClick={() => shellProps.history.push('/login')}
                />
                <CenterContainer page={shellProps.history.location.pathname + shellProps.history.location.search}>
                  <Switch>
                    <Route path="/search/:query?" exact component={CeresHome} />
                    <Route path="/" exact component={() => <Redirect to="/search" />} />
                    <Route
                      path="/about"
                      exact
                      component={() => {
                        window.location.href = 'http://about.enviro.ai';
                        return null;
                      }}
                    />
                    <Route
                      path="/contact"
                      exact
                      component={() => {
                        window.location.href = 'https://about.enviro.ai/#ContactUs';
                        return null;
                      }}
                    />
                    <Route path="/tutorial" exact component={PageTutorial} />
                    <Route path="/termsandconditions" exact component={PageTermsConditions} />
                    <ProtectedRoute path="/projects/new" tiers={[TIERS.PLATINUM, TIERS.DAYPASS]} exact component={NewProject} />
                    <ProtectedRoute
                      path="/account"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN, TIERS.FREE, TIERS.BASIC]}
                      exact
                      component={Account}
                    />
                    <ProtectedRoute
                      path="/myenviroai"
                      // tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={MyEnviroAi}
                    />
                    <ProtectedRoute
                      path="/ai-earth"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={AiEarth}
                    />
                    <ProtectedRoute
                      path="/ai-earth-air"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={AiEarth}
                    />
                    {projectId === 'ceres-platform-test' && (
                      <ProtectedRoute
                        path="/ai-air"
                        tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                        exact
                        component={AiAir}
                      />
                    )}
                    <ProtectedRoute
                      path="/trackbot"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={NotificationPage}
                    />
                    <ProtectedRoute
                      path="/people-finder"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={PeopleFinderPage}
                    />
                    <ProtectedRoute
                      path="/sentiment"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={Sentiment}
                    />
                    <ProtectedRoute
                      path="/validator"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={ValidatorPage}
                    />

                    <ProtectedRoute
                      path="/benchmarking"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={Benchmarking}
                    />
                    <ProtectedRoute
                      path="/corrective-tracker"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={CorrectiveTracker}
                    />
                    <ProtectedRoute
                      path="/generator"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={Generator}
                    />
                    <ProtectedRoute
                      path="/admin/validatorUsage"
                      tiers={[TIERS.SUPER_ADMIN]}
                      exact
                      component={ValidatorUsage}
                    />
                    <ProtectedRoute path="/checkout" component={Checkout} />
                    <ProtectedRoute path="/admin" tiers={[TIERS.SUPER_ADMIN]} component={AdminPanel} />
                    <Route path="/plans-and-pricing" component={PlansAndPricing} />
                    <Route path="/store" component={StorePricing} />
                    <Route path="/store/:page" component={StorePricing} />
                    <Route path="/trial-request" component={TrialRequest} />
                    <Route path="/survey" component={Survey} />
                    <Route path="/my-tundra" component={MyTundra} />
                    <ProtectedRoute
                      path="/reporttour"
                      tiers={[TIERS.PLATINUM, TIERS.DAYPASS, TIERS.SUPER_ADMIN]}
                      exact
                      component={Reports}
                    />
                    <Route component={Page404} />
                  </Switch>
                </CenterContainer>
                {/* <SidebarOverlay
                  onClick={() => setSidebarCollapsed((prevState) => !prevState)}
                  isActive={!isSidebarCollapsed}
                /> */}
              </MainContainer>
            </ShellContainer>
            <ProjectSwitcher
              show={isProjectSwitcherVisible}
              onNewProjectClick={() => {
                showProjectSwitcher(false);
                return shellProps.history.push('/projects/new');
              }}
              handleClose={() => showProjectSwitcher(false)}
            />
          </MuiPickersUtilsProvider>
        </ProjectProvider>
      </NotificationProvider>
    </UserProvider>
  );
};

export default withAuthContext(Shell);
