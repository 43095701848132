import { Pagination } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const SearchPaginationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  .pagination {
    flex: 1 1 auto;
    justify-content: space-between;
    margin: 3px;
  }
`;

interface ISearchPaginationProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  isFirstPage: boolean;
  isLastPage: boolean;
  totalRecords: number;
  isDisabled: boolean;
}
const SearchPagination: React.FC<ISearchPaginationProps> = (props) => {
  return (
    <>
      {props.totalRecords && props.totalRecords > 5 && (
        <SearchPaginationContainer>
          <Pagination size="sm">
            <Pagination.First
              disabled={props.isFirstPage || props.isDisabled}
              onClick={props.onPreviousClick}
            />
            {props.totalRecords
              ? props.totalRecords + ' records'
              : 'No Records'}
            <Pagination.Last
              disabled={props.isLastPage || props.isDisabled}
              onClick={props.onNextClick}
            />
          </Pagination>
        </SearchPaginationContainer>
      )}
    </>
  );
};

export default SearchPagination;
