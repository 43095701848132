// React & React-DOM imports
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';

// Material-UI components and icons
import { Container } from '@material-ui/core';
import { AttachFile, CloseRounded, FileCopy } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PersonIcon from '@material-ui/icons/Person';

// External libraries
import axios, { Method } from 'axios';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

// react-chat-widget imports
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  dropMessages,
  isWidgetOpened,
  renderCustomComponent,
  setQuickButtons,
  toggleInputDisabled,
  toggleMsgLoader,
  toggleWidget,
} from 'react-chat-widget';

// Asset imports
import EnviroAiTriangle from '../../assets/enviro.svg';
import HeaderEnviroAILogo from '../../assets/header-enviroai-logo.svg';

// Services and utilities
import { chatBotChat, saveChats, saveProfile } from '../../services/firebase';
import { removeGlobalFlag, updateGlobalFlags } from '../../store/aiearth/action';
import { ISearch } from '../../store/aiearth/types';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
import showToastMessage from '../../util/showToastMessage';
import { isExxonMobileEmail } from '../Validator';

// Component imports
import LibraryQuestionsListModal from '../my-enviro-ai/LibraryQuestionsListModal';
import Profile from './Profile';
import SavedChats from './SavedChats';
import SavedLibrary from './SavedLibrary';
import SavedPrompts from './SavedPrompts';
import SavePromptsModal from './SavePromptsModal';
import { IAuthContext } from '../../context/auth/auth-context';
import LOADER_MESSAGES from '../../constants/loader-messages';

interface ILoaderContainerProps {
  message: string;
}

const ChatBox = styled.div<ILoaderContainerProps>`
  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid;
    border-top: 3px solid #2F5597;
    border-radius: 50%;
    display:flex;
    animation: spin 1s linear infinite;
    margin: 2px 0px 0px 7px;
    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
},

.rcw-launcher {
  &:not(.rcw-hide-sm) {
    background-color: unset !important;
    box-shadow: unset !important;
    // position:relative;

    > img {
      width: calc(100% + 18px);

    }
    // :hover::before{
    //   opacity:1;
    // }
    // ::before{
    //   position: absolute;
    //   content: "Ask EnviroAI";
    //   font-size: 14px;
    //   color: #FFF;
    //   background-color: #000;
    //   padding: 5px 20px;
    //   height: auto;
    //   width: max-content;
    //   border-radius: 5px;
    //   bottom:62px;
    //   left: 50%;
    //   opacity:0;
    //   z-index:9999;
    //   transform: translate(-50%, 0%);
    // }
  }
}

.rcw-message-text.label-container >p:first-child {
  background-color: #045fbc;
  color: white;
  border-radius: 20px;
}
.rcw-response > .rcw-message-text >p:first-child {
  background-color: #e6e8ec;
  color: black;
  border-radius: 20px;
  width: 357px;
  text-align: center;
},

.rcw-response {
  * {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.5rem;
  }
}

.rcw-widget-container{
  width: 450px;
  z-index: 999;
  &:not(.rcw-full-screen) {
    margin: 0 20px 20px 0 !important;
  }
  &.rcw-close-widget-container {
    margin: 0 46px 20px 0 !important;
  }
},
.rcw-conversation-container {
  justify-content: flex-end;
  box-shadow: none;

  .active-profile {
    max-width: 80%;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .preview-file {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 7px;
    gap: 7px;
    margin-right: 4px;
    flex-direction: row-reverse;

    p {
      color: black;
      margin-bottom: 0 !important;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 12px;
    }
  }
  .preview-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    min-width: 64px;
    overflow: hidden;
    position: relative;
    border: 1px solid #d3d3d3;
    border-radius: 10px;

    > img {
      width: 64px;
      display: block;
      position: absolute;
    }
  }

  .preview-img, .preview-file {
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: hsla(0, 0%, 100%, .8);
      border-radius: 50% !important;
      visibility: hidden;
      padding: 0;
      display: flex;
      justify-content: center;
      height: 20px;
    }

    &:hover .close-button {
      visibility: visible;
      opacity: 1;
      color: grey;
    }

    .rounded-close-button {
      svg {
        font-size: 1rem;
        vertical-align: text-top;
      }
    }
  }

  .rcw-sender {
    flex-direction: column;
    min-height: 60px;
    align-items: flex-start;
    margin: 0 10px 5px 10px;
    border: 1px solid #ddc5b3;
    border-radius: 6px;
    padding: 8px;
    background-color: unset;
    > .rcw-picker-btn {
      display: none;
      background: unset;
      img {
        display: none;
      }
    }
    .chat-input {
      display: flex;
      width: 100%;
      align-items: center;
      > .rcw-send {
        padding: 8px;
        border-radius: 5px;
        font-weight: bold !important;
        font-size: .875rem;
        line-height: 1.5;
        color: #2c190e;
        font-weight: 600;
        background-color: #ddc5b3;
        border-color: #ddc5b3;
        min-width: 75px;

        img {
          display: none;
        }
        &:before {
          content: "Send";
        }
      }
    }
  }
}
.rcw-full-screen {
  z-index: 995;
  width: 100%;

  .rcw-conversation-container.active {
    height: 100% !important;
  }

  .rcw-title {
    // padding-top: 0 !important;
  }

  .rcw-header {
    background-color: #2c190e !important;
  }

  .max-btn, .close-Chat {
    color: #cbdff8;
  }

  @media only screen and (max-height: 770px) {
    .rcw-conversation-container.active {
      height: unset !important;
    }
  }
  @media only screen and (min-width: 768px) {
    width: 80%;
    animation: chatAnimation 1s 1;
    animation-direction: normal;
    @keyframes chatAnimation {
      0%   {right: -80%;}
      100% {right: 0;}
    }
  }
}

.rcw-conversation-container.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw!important;
  background-color: #FFF;
  height: 664px;

  @media only screen and (max-height: 770px) {
    max-height: 770px;
    height: calc(100vh - 96px);
    min-height: calc(100vh - 96px);
  }

}
.rcw-conversation-container .rcw-header {
  background-color: #5dc5c4;
  padding: 7px 0 7px !important;
}
.rcw-header > .rcw-title {
  font-family: 'Sans Pro';
  padding: 10px;
},
.rcw-client{
  width: 80%;
  align-items: flex-end;
}
.rcw-client .rcw-timestamp {
  color: black;
}

.rcw-client > .rcw-message-text {
  background-color: #045fbc;
  font-size: 16px;
  color: #ffffff;
  max-width:100%;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
},
.rcw-response > .rcw-message-text {
    max-width: 380px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
},
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #8ebbf7!important;
  width: 100%!important;
  color: white !important;
  border: none !important;
}
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    --bgColor: #8ebbf7 !important;
  }
}
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #c5c5c5;
  width: 100%;
}
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
  --bgColor: #c5c5c5;
  }
}
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #FFF;
  color: black;
  border: 1px solid black;
  width: 100%;
}
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
  --bgColor: #FFF;
  }
}
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    --lines: 1000;
    --lineHeight: 1.5rem;
    --timePerLine: .2s;
    --widthCh: 40;
    --width: 100%;
    /* do not touch the time property!!! */
    --time: calc(var(--lines) * var(--timePerLine));
    animation: grow var(--time) steps(var(--lines));
    animation-fill-mode: forwards;
    line-height: var(--lineHeight);
    max-height: var(--lineHeight);
    overflow: hidden;
    position: relative;
    width: var(--width);

    @keyframes grow {
      0% { max-height: var(--lineHeight); }
      100% { max-height: calc(var(--lineHeight) * var(--lines)); }
    }

    @keyframes carriageReturn {
      0% { top: 0; }
      100% { top: calc(var(--lineHeight) * var(--lines)); }
    }

    @keyframes type {
      0% { width: 100%; }
      100% { width: 0%; }
    }

    @keyframes caret {
      0% { color: var(--bgColor); }
      100% { color: black; }
    }
  }
}

.rcw-message-text ul {
  margin-bottom: 0 !important;
}

.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    &:before {
      content: "";
      animation:
        type var(--timePerLine) linear none,
        carriageReturn var(--time) steps(var(--lines)) var(--lines),
        caret 1s steps(2) infinite;
      background: var(--bgColor);
      border-left: 2px solid;
      bottom: 0;
      height: 2rem;
      position: absolute;
      right: 0;
      width: 100%;
      border-left-width: 0;
      animation:
      type var(--timePerLine) steps(var(--widthCh)) infinite,
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 1s steps(2) infinite;
    }
  }
}
.rcw-launcher {
  background-color: #8ebbf7;
},
.rcw-messages-container{
  // max-height:none;
  height: calc(100vh - 60%);
  margin-bottom: auto;
},
.rcw-full-screen .rcw-messages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: auto;
  height: auto !important;
  overflow: auto;
}

.rcw-message-text li > p {
  margin: -24px 0 0;
}

.rcw-full-screen .rcw-messages-container .rcw-message-client {
  width: 98%;
}

.rcw-full-screen .rcw-messages-container .rcw-message:not(.rcw-message-client) {
  width: 31%;
  // height: 90%;
  align-self: flex-start;
}

.rcw-full-screen .rcw-messages-container > .loader {
  height: fit-content;
}
.loader-container {
  display: flex;
  align-items: center;
  max-width: 550px;
  &:before {
    content: "${props => props.message}";
    color: black;
    margin-right: 10px;
    font-size:14px;
    font-family: 'Roboto', sans-serif;
  }
}

.loader-dots {
    height: 4px;
    width: 8px;
}

.rcw-full-screen > .rcw-messages-container {
  height: 100% !important;
}

.rcw-full-screen .rcw-message:not(.rcw-message-client) .rcw-response {
  width: 100%;
}

.rcw-full-screen .rcw-response > .rcw-message-text {
  min-width: auto !important;
  max-width: none !important;
}

.rcw-full-screen .rcw-response > .rcw-message-text >p:first-child {
  background-color: #e6e8ec;
  color: black;
  border-radius: 20px;
  width: 100%;
  text-align: center;
}

.rcw-new-message{
  color: #000000;
  text-align: left;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  width: calc(100% - 98px);
  .rcw-input {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow: hidden !important;
    max-height: unset;
    .quick-buttons {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
},
.rcw-close-button {
  border: none;
  background: none !important;
  cursor: pointer;
  right: 5px !important;
}
.quick-button {
  border-radius: 49px;
  font-family: 'Sans Pro';
  color:#FFFFFF;
  background-color:#005fbc;
  border:0px;
  display:flex;
  padding: 10px 40px;
  margin-bottom: 5px;

  &:disabled {
    background-color: gray;
    cursor: no-drop;
  }
  &.btn-disabled {
    background-color: gray;
  }
}
.quick-buttons-container{
  padding: 10px;
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;
  overflow-x: unset;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */

  .quick-buttons {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// .rcw-response > .rcw-message-text {
//   @media only screen and (min-width: 760px) {
//     max-width: 75% !important;
//   }

//   @media only screen and (min-width: 380px) {
//     min-width: 380px !important;
//   }
// }
`;

const TitleContainer = styled.div`
  display: flex;
  .title-head {
    flex: auto;
  }
  // .saved-chat-btn {
  //   font-size: 1vw;
  // }

  button {
    display: flex;
    font-size: 1vw;
    grid-gap: 3px;
    gap: 3px;
    border-radius: 6px;
    align-items: center;
    border-color: #ddc5b3;
    font-family: "Sans Pro";
  }

  .environmental {
    width: 25%;
    font-family: 'Lazy Dog';
    font-weight: bold;
    align-self: center;
  }
  .environmental > p {
    font-size: 1.2vw;
    margin-bottom: 0;
  }
  .title-head-fullscreen {
    width: 23vw;
    align-self: center;
    // margin-left: 12%;
    flex: none;

    img {
      max-width: 11vw;
    }
  }
  .icon-container {
    padding-right: 20px;
  }
  & .max-btn {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 27px;
    right: 40px;
    color: #045fbc;
    border: none;
    background: none;
  }
  & .close-Chat {
    position: absolute;
    font-size: 1.5rem;
    top: 22px;
    right: 0.75rem;
    color: #045fbc;
    border: none;
    background: none;
    cursor: pointer;
    font-family:monospace;
  }

`;

const IFrameContainer = styled.div`
  // bottom: 0;
  top: 6px;
  margin: 0 500px 20px 0;
  // height: calc(74vh);
  height: calc(100vh - 100px);
  max-height: 770px;
  position: fixed;
  right: 0;
  z-index: 9999;

  @media screen and (min-height: 765px) {
    // bottom: -17px;
    top: calc(100vh - 755px);
    height: calc(100vh - (100vh - 673px));
    margin: 0 500px 0 0;
  }
  .close-iframe {
    position: absolute;
    font-size: 1.5rem;
    top: 16px;
    right: 0.75rem;
    color: #045fbc;
    border: none;
    background: none;
    cursor: pointer;
  }
  .maximize-btn {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 25px;
    right: 40px;
    color: #045fbc;
    border: none;
    background: none;
  },
  iframe {
    max-width: 450px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .iframe-title {
    border-radius: 0px 0px 10px 10px;
  },
  & container{
    & .title-container {
      background: #5dc5c4;
      height: 70px;
      border-radius: 10px 10px 0px 0px;
    }
    & .web-title {
      font-family: Lazy Dog;
      color: #ffffff;
      font-size: 34px;
      margin: 0;
      padding: 15px 19px 15px 0px;
      text-align: center;
    }
  }
`;

interface IPropsType {
  searchQueryText: string;
  frameSearchQueryText: string;
  context: IAuthContext;
  isLoading: boolean;
  routeChange: () => void;
}

const MainChatBot = ({ searchQueryText, frameSearchQueryText, context, routeChange, isLoading }: IPropsType) => {
  const useQuery = () => {
    const { search: searchVal } = useLocation();
    return React.useMemo(() => new URLSearchParams(searchVal), [searchVal]);
  };
  const queryParam = useQuery();
  const isDirectCustomSearch: any = queryParam.get('directsearch');
  const [isChatFullScreen, setIsChatFullScreen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showSavedPrompts, setShowSavedPrompts] = useState(false);
  const [showSaveLibrary, setShowSaveLibrary] = useState(false);
  const [showPrivateLibrary, setPrivateLibrary] = useState(false);
  const [showLibraryQuestionsPopup, setShowLibraryQuestionsPopup] = useState(false);
  const [libraryFavorites, setLibraryFavorites] = useState<any[]>([]);
  const [showQuestionsPopup, setShowQuestionsPopup] = useState(false);
  const [popup, setpopup] = useState(false);
  // currentQuestion flag use to handle more draft when more then 1 question
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [defaultData, setDefaultData] = useState('');
  const [defaultData1, setDefaultData1] = useState('');
  const [defaultData2, setDefaultData2] = useState('');
  const [defaultChat, setDefaultChat] = useState<any>(null);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [totalSearchQuestion, setTotalSearchQuestion] = useState(0);
  const [files, setFiles]: any[] = useState([]);
  const [savedChatId, setSavedChatId] = useState(null);
  const [activeProfile, setActiveProfile] = useState<string | null>(null);
  const [disabledSavedChatBtn, setDisabledSavedChatBtn] = useState(false);
  const [chatFilePreviews, setChatFilePreviews]: any[] = useState([]);
  const [filePreviews, setFilePreviews]: any[] = useState([]);
  const [toggleMobileScreen, setToggleMobileScreen] = useState(true);
  // eslint-disable-next-line
  const [totalDraftLoad, setTotalDraftLoad] = useState(1);
  const [openSavedChat, setOpenSavedChat] = useState(false);
  const [currentLoaderMessage, setCurrentLoaderMessage] = useState(() => LOADER_MESSAGES[Math.floor(Math.random() * LOADER_MESSAGES.length)]);
  // const [showFavoriteSpinner, setShowFavoriteSpinner] = useState(false);
  const store: any = useSelector<any>((state): any => state);
  const searchState: ISearch = store.search;
  const history = useHistory();
  const dispatch = useDispatch();
  const imageTypes = ['image/jpg','image/jpeg','image/png'];
  const rcwButton = document.querySelector('.rcw-sender');

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * LOADER_MESSAGES.length);
      setCurrentLoaderMessage(LOADER_MESSAGES[randomIndex]);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const messageBody = document.querySelector('#messages');
    if(messageBody) {
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
    adjustInputHeight();
  }, [isChatFullScreen]);

  useEffect(() => {
    toggleChatForSearch(searchQueryText);
    setTotalSearchQuestion(0);
    setFavorites([]);
    setLibraryFavorites([]);
    if(!!store.globalFlags.previousPage && openSavedChat && (store.globalFlags.previousPage === 'myenviroaiToAgency' || store.globalFlags.previousPage === 'myenviroaiToInternetResults')) {
      setIsChatFullScreen(false);
      removeGlobalFlag('previousPage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryText]);

  // Handle chat data when chat widget close and re-open
  useEffect(() => {
    const storedChats = JSON.parse(localStorage.getItem('tempChatData') as string) || { data: [] };
    if(isWidgetOpened()) {
      const div = document.getElementById('messages');

      // When search new question from new search and already same question in localStorage
      if((localStorage.getItem('lastQuestion') === searchQueryText) && (div && div.childNodes.length < 2)) {
        localStorage.removeItem('lastQuestion');
      }

      if(localStorage.getItem('lastQuestion') !== searchQueryText) {
        localStorage.removeItem('tempChatData');
        toggleChatForSearch(searchQueryText);
      } else {
        if(storedChats && storedChats.data && storedChats.data.length) {
          // Don't drop and reload messages if there are no drafts loaded previously.
          if(defaultData && defaultData1 && defaultData2) {
            dropMessages();
            setMainChatData(storedChats);
          }
          localStorage.removeItem('tempChatData');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetOpened()]);

  const toggleChatForSearch = async (text: any) => {
    // reset default chat in local storage
    localStorage.removeItem('defaultChats');
    // reset temp chat in local storage
    localStorage.removeItem('tempChatData');
    setDefaultChat(null);
    setDefaultData('');
    setDefaultData1('');
    setDefaultData2('');

    if (!text) {
      return;
    }
    if(localStorage.getItem('lastQuestion') !== text) {
      dropMessages();
    }

    if (!isWidgetOpened()) {
      return;
      // setpopup(true);
      // toggleWidget();
    }
    if(localStorage.getItem('lastQuestion') !== text) {
      await getProfileData(text);
    } else {
      dispatch(removeGlobalFlag('lastQuestion'));
      localStorage.removeItem('lastQuestion');
    }
  };

  const getProfileData = async (text: any) => {
    addUserMessage(text);
    toggleMsgLoader();
    const response: any = await saveProfile({ type: 'getAll' });
    toggleMsgLoader();
    if(response && response.data && response.data.length) {
      const profileData = response.data[0].payload.profileData.find((item: any) => item.enable === true);
      setActiveProfile(profileData ? profileData.title : null);
      if(profileData && profileData.title) {
        searchInBot(text, profileData.title);
      } else {
        searchInBot(text);
      }
    } else {
      // addUserMessage(text);
      searchInBot(text);
    }
  };

  const adjustInputHeight = () => {
    const input: any = document.querySelector('.rcw-sender');
    if (input) {
      input.style.minHeight = '56px';
      input.style.minHeight = `${input.scrollHeight}px`; // Set to the scroll height
    }
  };

  useEffect(() => {
    // Adjust height on window resize
    const handleResize = () => {
      adjustInputHeight();
    };

    window.addEventListener('input', handleResize);
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('input', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(localStorage.getItem('lastQuestion') !== queryParam.get('q')) {
      setQuickButtons([]);
    }
  }, [queryParam]);

  useEffect(() => {
    if(popup && !openSavedChat) {
      appendActiveProfileTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup, openSavedChat, activeProfile]);

  useEffect(() => {
    // tslint:disable-next-line:no-console
    console.log('saved chats default ==>>>', defaultChat);
    // tslint:disable-next-line:no-console
    console.log('saved chats localstorage default ==>>>', JSON.parse(localStorage.getItem('defaultChats') as string));
  }, [defaultChat]);

  useEffect(() => {
    const widgetContainer = document.getElementById('rcw-conversation-container');
    if (!isLoading && toggleMobileScreen && window.innerWidth <= 798 && widgetContainer) {
      toggleWidget();
      setToggleMobileScreen(false);
    }
    if(!!store.globalFlags.previousPage && localStorage.getItem('lastQuestion') !== searchQueryText) {
      if(isWidgetOpened()) {
        toggleWidget();
        setpopup(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const appendActiveProfileTag = () => {
    const activeProfileElement = document.getElementById('active-profile');
    if(activeProfileElement) {
      activeProfileElement.remove();
    }
    if(!activeProfile) {
      return;
    }

    const el = document.createElement('p');
    el.id = 'active-profile';
    el.className = 'text-center text-dark my-2 alert alert-info font-weight-bold active-profile';
    el.innerHTML = 'Enabled Profile: ' + activeProfile.substring(0, 50) + '...';
    const div = document.getElementById('messages');
    if(div && div.parentNode) {
      div.parentNode.insertBefore(el, div.nextSibling);
    }
  };

  useEffect(() => {
    if(!popup) {
      return;
    }

    const fileDiv: any = document.createElement('div');
    fileDiv.className = 'image-upload';

    const fileLabel: any = document.createElement('label');
    fileLabel.htmlFor = 'file-input';
    fileLabel.className = isExxonMobileEmail(context) ? 'text-secondary' : 'text-dark';
    fileLabel.title = isExxonMobileEmail(context) ? 'This feature is disabled for ExxonMobil. Please contact the Administrator.' : '';
    fileLabel.style = 'cursor: pointer; color: black;';

    // Create the icon element
    const icon: any = document.createElement('span');
    ReactDOM.render(<AttachFile />, icon);

    fileLabel.appendChild(icon);

    const fileInput: any = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = 'file-input';

    fileLabel.addEventListener('click', (event: MouseEvent) => {
      if (isExxonMobileEmail(context)) {
        event.preventDefault(); // Prevent the default click action
        showToastMessage({
          type: 'warn',
          title: 'Access Denied',
          description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
        });
      }
    });
    fileInput.style = 'display: none';
    fileInput.disabled = isExxonMobileEmail(context);
    fileInput.multiple = true;
    fileInput.onchange = handleUploadFiles;

    fileDiv.appendChild(fileLabel);
    fileDiv.appendChild(fileInput);

    // Find the div with the class 'rcw-sender' and append the button
    const senderDiv = document.getElementsByClassName('rcw-sender')[0];

    if (senderDiv) {
      senderDiv.prepend(fileDiv);
      addParentDiv();
    } else {
      // tslint:disable-next-line:no-console
      console.error('No elements found with the class name "rcw-sender".');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcwButton, popup]);

  // When filePreview contains data, it creates html dom for file preview
  useEffect(() => {
    filePreviewDomCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePreviews]);

    // Create file preview html dom in chat input section
  const filePreviewDomCreate = () => {
    const imgPreviews = document.querySelectorAll('.files-container');
    imgPreviews.forEach((divBlock) => {
      divBlock.remove();
    });
    const filesContainer = document.createElement('div');
    filesContainer.className = 'files-container d-flex';

    // Select the rcw-sender div
    const senderDiv: any = document.querySelector('.rcw-sender');
    if(!senderDiv) {
      return;
    }
    if(Array.isArray(filePreviews) && filePreviews.length) {
      senderDiv.style = 'min-height: 122px';
    } else {
      senderDiv.style = '';
    }

    Array.isArray(filePreviews) && filePreviews.forEach((file, index) => {
      /** File preview container for each file */
      const filePreviewContainer = document.createElement('div');
      if(imageTypes.includes(file.type)) {
        filePreviewContainer.className = 'preview-img mr-1';

        /** Image preview html element */
        const imgDiv = document.createElement('img');
        imgDiv.src = file.preview;
        imgDiv.alt = file.name;
        imgDiv.width = 200;
        imgDiv.className = 'img-container';

        // Remove button icon
        const removeButton = document.createElement('button');
        // Set attributes and class names
        removeButton.setAttribute('variant', 'outline-dark');
        removeButton.className = 'close-button btn btn-outline-dark';
        // Create the close icon element
        const closeIcon: any = document.createElement('span');
        closeIcon.className = 'rounded-close-button';
        ReactDOM.render(<CloseRounded />, closeIcon);
        removeButton.appendChild(closeIcon);
        // Step 4: Set the onclick event handler
        removeButton.onclick = () => removeAttachment(index);

        filePreviewContainer.appendChild(imgDiv);
        filePreviewContainer.appendChild(removeButton);
        filesContainer.appendChild(filePreviewContainer);
        senderDiv.prepend(filesContainer);
      } else {
        filePreviewContainer.className = 'preview-file mr-1';
        // Add file icon
        ReactDOM.render(<FileCopy color="primary" />, filePreviewContainer);

        // Create file details container div element
        const fileDetails = document.createElement('div');
        const fileNameDom: any = document.createElement('p');
        const fileTypeDom: any = document.createElement('p');
        fileNameDom.innerText = file.name;
        fileDetails.appendChild(fileNameDom);
        fileTypeDom.innerText = file.type;
        fileDetails.appendChild(fileTypeDom);

        // Remove button icon
        const removeButton = document.createElement('button');
        // Set attributes and class names
        removeButton.setAttribute('variant', 'outline-dark');
        removeButton.className = 'close-button btn btn-outline-dark';
        // Create the close icon element
        const closeIcon: any = document.createElement('span');
        closeIcon.className = 'rounded-close-button';
        ReactDOM.render(<CloseRounded />, closeIcon);
        removeButton.appendChild(closeIcon);
        // Set the onclick event handler
        removeButton.onclick = () => removeAttachment(index);

        filePreviewContainer.appendChild(fileDetails);
        filePreviewContainer.appendChild(removeButton);
        filesContainer.appendChild(filePreviewContainer);
        senderDiv.prepend(filesContainer);
      }
    });
  };

  /**
   * Remove attachment files in search input
   * @param index
   */
  const removeAttachment = (index: number) => {
    const filteredFiles = files.filter((file: any, i: number) => i !== index);
    const filteredFilePreviews = filePreviews.filter((file: any, i: number) => i !== index);
    setFiles(filteredFiles);
    setFilePreviews(filteredFilePreviews);
    setChatFilePreviews([...filteredFilePreviews]);
  };

  // Add parent div to wrap chat input row and seperate from img preview
  const addParentDiv = () => {
    // Create the new parent div
    const newParentDiv = document.createElement('div');
    newParentDiv.className = 'chat-input';

    // Select the rcw-sender div
    const senderDiv = document.querySelector('.rcw-sender');
    if(!senderDiv) {
      return;
    }

    const pickerBtn = document.querySelector('.rcw-picker-btn');
    if(pickerBtn) {
      pickerBtn.remove();
    }

    // Select the children elements
    const imageUpload = document.querySelector('.image-upload');
    const newMessage = document.querySelector('.rcw-new-message');
    const sendBtn = document.querySelector('.rcw-send');

    if(!imageUpload || !newMessage || !sendBtn) {
      return;
    }

    // Append the children to the new parent div
    newParentDiv.appendChild(imageUpload);
    newParentDiv.appendChild(newMessage);
    newParentDiv.appendChild(sendBtn);
    senderDiv.innerHTML = '';
    // Append the new parent div to the rcw-sender div
    senderDiv.appendChild(newParentDiv);
  };

  // Function to be called when button is clicked
  const handleUploadFiles = (event: any) => {
    if(!event || !event.target) {
      return;
    }
    let latestFiles: any[] = [];
    setFiles((prevState: any) => {
      latestFiles = prevState;
      return prevState;
    });

    const selectedFiles: any[] = Array.from(event.target.files);
    // Reset the file input value to allow selecting the same file again
    event.target.value = '';
    const totalFiles = [...latestFiles, ...selectedFiles];

    setFiles(totalFiles);
    setFilePreviews([]);
    totalFiles.forEach(async (file) => {
      if(imageTypes.includes(file.type)) {
        const base64: any = await blobToBase64(file);
        setFilePreviews((prevState: any) => [...prevState, {
          preview: base64,
          name: file.name,
          type: file.type
        }]);
      } else {
        setFilePreviews((prevState: any) => [...prevState, {
          preview: URL.createObjectURL(file),
          name: file.name,
          type: file.type
        }]);
      }
    });
    // tslint:disable-next-line:no-console
    console.log('files=>', files);
  };

  // Helper function to convert Blob to base64
  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const useClickOutside = (handler: any) => {
    // tslint:disable-next-line:no-shadowed-variable
    const main: any = useRef(null);
    useEffect(() => {
      const maybeHandler = (event: any) => {
        if (main && main.current && !main.current.contains(event.target)) {
          if (event.target.innerText && event.target.innerText.includes('Get Normal Search Results')) {
            return;
          }
          handler();
        }
      };

      document.addEventListener('mousedown', maybeHandler);

      return () => {
        document.removeEventListener('mousedown', maybeHandler);
      };
    });

    return main;
  };

  const main = useClickOutside(() => {
    if(showProfile || showSavedPrompts || showQuestionsPopup || showSaveLibrary || showPrivateLibrary || showLibraryQuestionsPopup) {
      return;
    }

    WidgetClose();
  });

  const WidgetClose = (() => {
    if (isWidgetOpened()) {
      if (isChatFullScreen) {
        setIsChatFullScreen(false);
      }
      // setTotalSearchQuestion(0);
      localStorage.removeItem('defaultChats');
      // set chat in localhost to display again when chat widget re-open
      setLocalhostChatData('tempChatData');
      if(!!searchQueryText && !!history.location.search) {
        dispatch(updateGlobalFlags({ lastQuestion: searchQueryText }));
        localStorage.setItem('lastQuestion', searchQueryText);
      }
      toggleWidget();
      setpopup(false);
    }
  });

  // set chat data in localhost to display again
  const setLocalhostChatData = (localStorageKey: string, storedChats?: any) => {
    const data = {
      title: currentQuestion !== '' ? currentQuestion : searchQueryText,
      a1: defaultData,
      a2: defaultData1,
      a3: defaultData2,
      attachFiles: chatFilePreviews,
      order: 3
    };

    const mainTitle = storedChats && storedChats.mainTitle ? storedChats.mainTitle : searchQueryText;

    const chatsData = defaultChat && defaultChat.data ? [...defaultChat.data, data] : [data];
    localStorage.setItem(localStorageKey, JSON.stringify({ mainTitle, data: chatsData}));
  };

  const disableDraftBtns = (val: boolean) => {
    const btnlEls: any = document.getElementsByClassName('quick-button');
    btnlEls.forEach((btnlEl: any) => {
      if (val) {
        btnlEl.setAttribute('disabled', val);
      } else {
        btnlEl.removeAttribute('disabled');
      }
    });
  };

  const customResponseComponent: any = ({ tag, text, message }: any) => {
    const generateHyperlink = (painText: string) => {
      // Regular expression to match URLs in plain text
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Check if the message already contains <a> tags
      const containsAnchorTag = /<a\s+(?:[^>]*?\s+)?href=/.test(message);

      // Replace URLs with anchor tags only if <a> tags are not already present
      const linkedText = containsAnchorTag ? message : message.replace(urlRegex, (url: string) => `<a href="${url}" target="_blank">${url}</a><br />`);

      return { __html: linkedText };
    };
    return (
      <div className="rcw-response">
        <div className="rcw-message-text label-container2">
          {tag && (<><p>{tag}</p><br /></>)}
          {text && (<><p>{text} :</p><br /></>)}
          <p dangerouslySetInnerHTML={generateHyperlink(message)}/>
        </div>
      </div>
    );
  };

  const addClassForLables = () => {
    const messageBubbleEls: any = document.getElementsByClassName('rcw-message-text');
    if (messageBubbleEls && messageBubbleEls.length) {
      messageBubbleEls.forEach((messageBubbleEl: any, index: any) => {
        if (index === 0) {
          messageBubbleEl.classList.add('label-container');
        } else {
          messageBubbleEl.classList.add('label-container2');
        }
      });
    }
  };

  const renderQuickBtn = () => {
    return [
      // { label: 'View Other Drafts', value: 'other_draft' },
      { label: 'Save', value: 'save_chat'},
      { label: 'Save Prompts', value: 'favorites'},
      { label: 'Save Library', value: 'save_library'},
    ];
  };

  const disableQuickButton = (buttonIndex: number) => {
    const btnEls = document.getElementsByClassName('quick-button');
    const btnEl = btnEls[buttonIndex];
    btnEl.className = btnEl.className + ' btn-disabled';
  };

  const resetStates = () => {
    toggleMsgLoader();
    disableDraftBtns(false);
    setDisabledSavedChatBtn(false);
    setTotalSearchQuestion(totalSearchQuestion + 1);
    setQuickButtons(renderQuickBtn());
    if(isExxonMobileEmail(context)) {
      disableQuickButton(1);
    }
    toggleInputDisabled();
    dispatch(removeGlobalFlag('lastQuestion'));
    localStorage.removeItem('lastQuestion');
  };

  const getDraftData = async (url: string, defaultQueryParam: string, requestMethod: Method, searchQuery: string, label: string, formData?: any) => {
    const reqUrl = requestMethod === 'GET' ? `${url}${defaultQueryParam}` : url;
    axios(reqUrl, { data: formData ? formData : undefined, method: requestMethod }).then((res: any) => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      // tslint:disable-next-line:no-console

      const urlParams = new URLSearchParams(window.location.search);
      const question = urlParams.get('q');

      let totalQuestion = 0;
      setTotalSearchQuestion(prevState => {
        totalQuestion = prevState;
        return prevState;
      });

      // prevent to display previous chat drafts
      if((totalQuestion === 0 && question !== searchQuery) || responseNum > 3) {
        return;
      }
      if (res && res.data) {
        if (process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
          // tslint:disable-next-line:no-console
          // console.log('test >> res', res);
          // // tslint:disable-next-line:no-console
          // console.log('test >> res.data', res.data);
          // // tslint:disable-next-line:no-console
          // console.log('test >> res.data.content', res.data.content);

          const newMainString = res.data.content;
          const newResponse = `EnviroAI Model Response #${responseNum} - ${label}\n\n${newMainString}`;
          switch (responseNum) {
            case 1:
              setDefaultData(newResponse);
              break;
            case 2:
              setDefaultData1(newResponse);
              break;
            case 3:
              setDefaultData2(newResponse);
              break;
            default:
              break;
          }
          renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: newMainString });
        } else {
          // tslint:disable-next-line:no-console
          // console.log('prod >> res',res);
          // // tslint:disable-next-line:no-console
          // console.log('prod >> res.data',res.data);
          // // tslint:disable-next-line:no-console
          // console.log('prod >> res.data.content',res.data.content);
          const mainString = res.data.content;
          const response = `EnviroAI Model Response #${responseNum} - ${label}\n\n${mainString}`;
          switch (responseNum) {
            case 1:
              setDefaultData(response);
              break;
            case 2:
              setDefaultData1(response);
              break;
            case 3:
              setDefaultData2(response);
              break;
            default:
              break;
          }
          renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: mainString });
        }
        addClassForLables();
      } else {
        chatBotChat({
          prompt: searchQuery
        }).then(response => {
          if (response.data) {
            renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: response.data });
          }
        });
      }

      if(responseNum === 1 && !isChatFullScreen) {
        setIsChatFullScreen(true);
      }
      if(responseNum === 3) {
        resetStates();
      }
      setTotalDraftLoad(responseNum + 1);
    }).catch((err: any) => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      const urlParams = new URLSearchParams(window.location.search);
      const question = urlParams.get('q');

      let totalQuestion = 0;
      setTotalSearchQuestion(prevState => {
        totalQuestion = prevState;
        return prevState;
      });

      // prevent to display previous chat drafts
      if((totalQuestion === 0 && question !== searchQuery) || responseNum > 3) {
        return;
      }

      if(responseNum === 1 && !isChatFullScreen) {
        setIsChatFullScreen(true);
      }
      if(requestMethod === 'GET') {
        const dataNotFoundMessage = `EnviroAI Model Response #${responseNum} - ${label}\n\nData Not Found`;
        addResponseMessage(dataNotFoundMessage);
        switch (responseNum) {
          case 1:
            setDefaultData(dataNotFoundMessage);
            break;
          case 2:
            setDefaultData1(dataNotFoundMessage);
            break;
          case 3:
            setDefaultData2(dataNotFoundMessage);
            break;
          default:
            break;
        }
        toast.error('Unable to load data');
        addClassForLables();
        if(responseNum === 3) {
          resetStates();
        }
        setTotalDraftLoad(responseNum + 1);
      } else {
        getDraftData(url, defaultQueryParam, 'GET', searchQuery, label);
      }
    });
  };

  const spinner = (show: boolean, buttonIndex: number) => {
    const btnEls = document.getElementsByClassName('quick-button');
    const btnEl = btnEls[buttonIndex];
    if (show) {
      const spinnerEl = document.createElement('div');
      spinnerEl.className = 'spinner';
      btnEl.appendChild(spinnerEl);
    } else {
      const spinnerEl = btnEl.querySelector('.spinner');
      if (spinnerEl) {
        spinnerEl.remove();
      }
    }
  };

  const handleQuickButtonClickFn = async (type: any, searchedText?: string) => {
    if(type === 'favorites') {
      if (isExxonMobileEmail(context)) {
        showToastMessage({
          type: 'warn',
          title: 'Access Denied',
          description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
        });
        return;
      }
      if(!favorites.length) {
        setFavorites([{id: '', question: searchQueryText, isFavorite: false}]);
      }
      setShowQuestionsPopup(true);
    }

    if(type === 'save_library') {
      if (isExxonMobileEmail(context)) {
        showToastMessage({
          type: 'warn',
          title: 'Access Denied',
          description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
        });
        return;
      }
      if(!libraryFavorites.length) {
        setLibraryFavorites([{id: '', question: searchQueryText, isFavorite: false}]);
      }
      setShowLibraryQuestionsPopup(true);
    }

    if (type === 'other_draft') {
      const searchQuery = searchedText ? searchedText : searchQueryText;
      disableDraftBtns(true);
      setDisabledSavedChatBtn(true);
      const isLoader = document.querySelector('.loader');
      const inputDisabled = document.querySelector('.rcw-new-message');
      setTimeout(() => {
        if(!(isLoader && isLoader.classList.contains('active'))) {
          toggleMsgLoader();
        }
        if(!(inputDisabled && inputDisabled.classList.contains('rcw-message-disable'))) {
          toggleInputDisabled();
        }
      });
      let predQueryString = `?q=${searchQuery}&chatStatus=${totalSearchQuestion > 0 ? 'ongoing' : 'new'}`;

      const formData = new FormData();
      formData.append('q', searchQuery);
      formData.append('chatStatus', totalSearchQuestion > 0 ? 'ongoing' : 'new');

      let activeProfileText = null;
      setActiveProfile(prevState => {
        activeProfileText = prevState;
        return prevState;
      });

      if(activeProfileText) {
        predQueryString += `&profile=${activeProfileText}`;
      }
      const localUserId = localStorage.getItem('uid');
      if(localUserId) {
        predQueryString += `&userId=${localUserId}`;
        formData.append('userId', encodeURIComponent(localUserId));
      }
      if (process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
        if(Array.isArray(files) && files.length) {
          files.forEach((file) => {
            formData.append('files', file);
          });
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI_TEST}`, predQueryString, 'POST', searchQuery, 'Gemini Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT_TEST}`, predQueryString, 'POST', searchQuery, 'OpenAI Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE_TEST}`, predQueryString, 'POST', searchQuery, 'Anthropic Base-Model', formData);
        } else {
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI_TEST}`, predQueryString, 'GET', searchQuery, 'Gemini Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT_TEST}`, predQueryString, 'GET', searchQuery, 'OpenAI Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE_TEST}`, predQueryString, 'GET', searchQuery, 'Anthropic Base-Model');
        }
      } else {
        if(Array.isArray(files) && files.length) {
          files.forEach((file) => {
            formData.append('files', file);
          });
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, predQueryString, 'POST', searchQuery, 'Gemini Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, predQueryString, 'POST', searchQuery, 'OpenAI Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, predQueryString, 'POST', searchQuery, 'Anthropic Base-Model', formData);
        } else {
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, predQueryString, 'GET', searchQuery, 'Gemini Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, predQueryString, 'GET', searchQuery, 'OpenAI Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, predQueryString, 'GET', searchQuery, 'Anthropic Base-Model');
        }
      }
    }
    if (type === 'save_chat') {
      const data = {
        q: {
          title: currentQuestion !== '' ? currentQuestion : searchQueryText,
          a1: defaultData,
          a2: defaultData1,
          a3: defaultData2,
          order: 3
        },
        attachFiles: chatFilePreviews
      };

      const storedChats = JSON.parse(localStorage.getItem('defaultChats') as string);
      const defaultStoreData = defaultChat && defaultChat.data ? defaultChat.data.map((question: any) => {
        const questionCloneData = { ...question };
        const questionObj = {
          q: questionCloneData,
          attachFiles: questionCloneData.attachFiles
        };
        delete questionCloneData.attachFiles;
        return questionObj;
      }) : [];

      spinner(true, 0);
      disableDraftBtns(true);

      if(!defaultChat) {
        localStorage.setItem('defaultChats', JSON.stringify({
          mainTitle: searchQueryText,
          data: [data]
        }));
      } else {
        localStorage.setItem('defaultChats', JSON.stringify({
          mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : searchQueryText,
          data: [...defaultStoreData, data]
        }));
      }

      const response = savedChatId ? await saveChats({
        type: 'update',
        id: savedChatId,
        record: {
          mainTitle: storedChats && storedChats.mainTitle ? storedChats.mainTitle : ((defaultChat && defaultChat.mainTitle) ? defaultChat[0].mainTitle : searchQueryText),
          chatData: [...defaultStoreData, data]
        }
      }) : await saveChats({
        type: 'create',
        record: {
          mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : searchQueryText,
          chatData: [...defaultStoreData, data]
        }
      });
      disableDraftBtns(false);
      spinner(false, 0);
      if (response && response.data) {
        if(!savedChatId) {
          setSavedChatId(response.data.id);
        }
        toast.success('Data Saved Successfully.', { position: 'top-right' });
      }
    }
  };

  const handleToggle = (event: any) => {
    if (event) {
      setpopup(true);
    } else {
      setpopup(false);
      // set chat in localhost to display again when chat widget re-open
      setLocalhostChatData('tempChatData');
      if(!!searchQueryText) {
        dispatch(updateGlobalFlags({ lastQuestion: searchQueryText }));
        localStorage.setItem('lastQuestion', searchQueryText);
      }
    }
  };

  const questionWithFile = (attachFiles: any) => {
    const data: any = [];
    typeof attachFiles === 'object' && Object.values(attachFiles).forEach((item: any) => {
      data.push(item);
    });

    return (
      <>
        {
          data && data.map((file: any) => {
            return imageTypes.includes(file.type) ? (
              <div className="preview-img mb-1">
                <img src={file.preview} alt={file.name} width="200" />
              </div>
            ) : (
              <div className="preview-file mb-1">
                <FileCopy color="primary" />
                <div>
                  <p>{ file.name }</p>
                  <p>{ file.type }</p>
                </div>
              </div>
            );
          })
        }
      </>
    );
  };

  const handleFilePreview = () => {
    // Get all elements with the class "rcw-client"
    const elements = document.getElementsByClassName('rcw-client');

    // Check if any elements were found
    if (elements.length > 0) {
      // Get the last element in the collection
      const lastElement = elements[elements.length - 1];
      const mydata: any[] = Object.values(filePreviews);
      // Merge dom of question and attach files for file preview in chat area
      lastElement.innerHTML = renderToStaticMarkup(questionWithFile(mydata)) + lastElement.innerHTML;
    } else {
      // tslint:disable-next-line:no-console
      console.log('No elements found with the class \'rcw-client\'');
    }
  };

  const searchInBot = (text: any, activeProfileData: any = null) => {
    try {
      disableDraftBtns(true);
      setDisabledSavedChatBtn(true);
      // toggleInputDisabled();
      setTotalDraftLoad(1);
      // setSearchQueryText(text);
      handleQuickButtonClickFn('other_draft', text);
    } catch (error) {
      toast.error('Unable to load data');
      // tslint:disable-next-line:no-console
      console.log('chat gpt error', error);
    } finally {
      setFiles([]);
    }
  };

  const handleNewUserMessage = async (newMessage: any) => {
    // tslint:disable-next-line:no-console
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
    // const res = await axios.post('http://localhost:4000/chat', {prompt: newMessage});

    // tslint:disable-next-line:no-empty
    setTimeout(() => {
      handleFilePreview();
    });

    if(searchQueryText && defaultData) {
      setCurrentQuestion(newMessage);
      const data = {
        title: currentQuestion !== '' ? currentQuestion : searchQueryText,
        a1: defaultData,
        a2: defaultData1,
        a3: defaultData2,
        attachFiles: currentQuestion !== '' ? chatFilePreviews : [],
        order: 3
      };

      let storedChats = null;
      if(localStorage.getItem('tempChatData')) {
        storedChats = JSON.parse(localStorage.getItem('tempChatData') as string) || { data: [] };
      } else {
        storedChats = JSON.parse(localStorage.getItem('defaultChats') as string) || { data: [] };
      }

      setDefaultChat({
        mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : ((storedChats && storedChats.mainTitle && storedChats.data.length === 1) ? storedChats.mainTitle : searchQueryText),
        data: defaultChat && defaultChat.data ? [...defaultChat.data, data] : [data]
      });
    }

    if(favorites.length) {
      setFavorites([...favorites, {id: '', question: newMessage, isFavorite: false}]);
      setLibraryFavorites([...favorites, {id: '', question: newMessage, isFavorite: false}]);
    } else {
      setFavorites([{id: '', question: searchQueryText, isFavorite: false}, {id: '', question: newMessage, isFavorite: false}]);
      setLibraryFavorites([{id: '', question: searchQueryText, isFavorite: false}, {id: '', question: newMessage, isFavorite: false}]);
    }

    setDefaultData('');
    setDefaultData1('');
    setDefaultData2('');

    setChatFilePreviews(filePreviews);
    setFilePreviews([]);
    searchInBot(newMessage, activeProfile);

  };

  // set chat data in chatbot which are stored in localhost
  const setMainChatData = (storedChats: any) => {
    if(defaultData1 && defaultData2) {
      setQuickButtons(renderQuickBtn());
      if(isExxonMobileEmail(context)) {
        disableQuickButton(1);
      }
    }

    if(storedChats && storedChats.mainTitle) {
      setDefaultChat({...defaultChat, mainTitle: storedChats.mainTitle});
    }

    storedChats && storedChats.data && storedChats.data.forEach((answerData: any, index: number) => {

      // Set file preview in chat
      setTimeout(() => {
          addUserMessage(answerData['title']);
          const elements = document.getElementsByClassName('rcw-client');
          if(elements.length) {
            const lastElement = elements[elements.length - 1];
            // Merge dom of question and attach files for file preview in chat area
            lastElement.innerHTML = renderToStaticMarkup(questionWithFile(answerData.attachFiles)) + lastElement.innerHTML;
          }
          const copyAnswerData = {
            ...answerData
          };

          delete copyAnswerData.title;
          delete copyAnswerData.order;
          delete copyAnswerData.attachFiles;

          Object.values(copyAnswerData).forEach((answer: any, i: number) => {
            if(answer !== '') {
              const answerParts = answer.split('\n\n');
              const data = {
                tag: answerParts[0],
                text: '',
                message: answerParts.slice(1, answerParts.length).join('\n\n')
              };
              renderCustomComponent(customResponseComponent, data);
            }
          });
        }, index * 500);
    });
  };

  const handleOpenSavedChats = async (status: boolean) => {
    dropMessages();
    setOpenSavedChat(status);
    const storedChats = JSON.parse(localStorage.getItem('defaultChats') as string) || { data: [] };

    if(status) {
      setLocalhostChatData('defaultChats', storedChats);
    } else {
      setMainChatData(storedChats);
    }
  };

  const handleProfile = (status: boolean) => {
    setShowProfile(status);
  };

  const closepopup = () => {
    setpopup(false);
  };

  const onRouteChange = () => {
    routeChange();
    setpopup(false);
  };

  const chatUi = () => {
    setIsChatFullScreen(f => !f);
  };

  const handleToggleProfile = (profileTitle: string) => {
    setActiveProfile(profileTitle);
  };

  const handleCopyToChat = (title: string) => {
    const rcwChatInput: any = document.querySelector('.rcw-input');
    if (rcwChatInput) {
      rcwChatInput.textContent = title;
    }
    setTimeout(() => {
      adjustInputHeight();
    });
  };

  const onFavoriteClick = (index: number) => {
    favorites[index].isFavorite = !favorites[index].isFavorite;
  };

  return (
    <>
      <SavePromptsModal
        favorites={favorites}
        showQuestionsPopup={showQuestionsPopup}
        disableDraftBtns={disableDraftBtns}
        onFavoriteClick={onFavoriteClick}
        setFavorites={setFavorites}
        setShowQuestionsPopup={setShowQuestionsPopup}
      />
      <LibraryQuestionsListModal
        context={context}
        showLibraryQuestionsPopup={showLibraryQuestionsPopup}
        setShowLibraryQuestionsPopup={setShowLibraryQuestionsPopup}
        libraryFavorites={libraryFavorites}
        setLibraryFavorites={setLibraryFavorites}
      />
      { isChatFullScreen && openSavedChat &&
        <SavedChats
          activeSavedChatId={savedChatId}
          activeProfile={activeProfile}
          closeSavedChat={handleOpenSavedChats}
          handleProfile={handleProfile}
          filePreviews={filePreviews}
          setFilePreviews={setFilePreviews}
          files={files}
          setFiles={setFiles}
          setShowSavedPrompts={setShowSavedPrompts}
          setShowSaveLibrary={setShowSaveLibrary}
        />}
      { isChatFullScreen && showProfile && <Profile closeProfile={handleProfile} onToggleProfile={handleToggleProfile} /> }
      { isChatFullScreen && showSavedPrompts && <SavedPrompts context={context} closeSavedPrompt={setShowSavedPrompts} onCopyText={handleCopyToChat} /> }
      { isChatFullScreen && showSaveLibrary && <SavedLibrary context={context} libraryType={1} closeSavedLibrary={setShowSaveLibrary} onCopyText={handleCopyToChat} /> }
      { isChatFullScreen && showPrivateLibrary && <SavedLibrary context={context} libraryType={2} closeSavedLibrary={setPrivateLibrary} onCopyText={handleCopyToChat} /> }
      { !openSavedChat && <Container ref={main} >
        <ChatBox message={currentLoaderMessage}>

          <div style={{
            visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch ? 'visible' : 'hidden'
          }}>
            <Widget
              launcherOpenImg={EnviroAiTriangle}
              handleQuickButtonClicked={handleQuickButtonClickFn}
              showCloseButton={false}
              fullScreenMode={isChatFullScreen}
              handleToggle={handleToggle}
              handleNewUserMessage={handleNewUserMessage}
              title={
                <TitleContainer>
                  { isChatFullScreen &&
                      <button className="btn btn-outline-light btn-sm" onClick={() => handleOpenSavedChats(true)} disabled={disabledSavedChatBtn}>
                        <ChatIcon /> Saved Chats
                      </button>
                    }
                  { isChatFullScreen &&
                      <button className="btn btn-outline-light ml-2" onClick={() => handleProfile(true)}>
                        <PersonIcon /> Profile
                      </button>
                    }
                  { isChatFullScreen &&
                      <button className="btn btn-outline-light ml-2" onClick={() => setShowSavedPrompts(true)}>
                        <ClearAllIcon />Saved Prompts
                      </button>
                    }
                  { isChatFullScreen &&
                      <button
                      id="library-btn"
                      className="btn btn-outline-light ml-2"
                      onClick={() => {setShowSaveLibrary(true);}}
                    >
                      <LibraryBooksIcon /> Saved Library
                    </button>
                  }
                   { isChatFullScreen &&
                      <button
                      id="library-btn"
                      className="btn btn-outline-light ml-2"
                      onClick={() => {setPrivateLibrary(true);}}
                    >
                      <LibraryBooksIcon /> Private Library
                    </button>
                  }
                  <div className={`title-head ${isChatFullScreen ? 'title-head-fullscreen' : ''}`}>
                    <img src={HeaderEnviroAILogo} alt="EnviroAI Logo" />
                  </div>
                  {
                    isChatFullScreen &&
                    (<div className="environmental">
                      <p>Environmentally<sup> Trained</sup> Generative AI</p>
                    </div>)
                  }
                  <div className="icon-container">
                  <FiMaximize2 className="max-btn" style={{ visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch && !isChatFullScreen ? 'visible' : 'hidden', zIndex: 998 }} onClick={chatUi} />
                    <FiMinimize2 className="max-btn" style={{ visibility: isChatFullScreen ? 'visible' : 'hidden', zIndex: 998 }} onClick={chatUi} />
                    <button className="close-Chat" style={{ visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch ? 'visible' : 'hidden' }} onClick={WidgetClose}>X</button>
                  </div>
                </TitleContainer>
              }
              subtitle=""
              emojis={false}
              sendButtonAlt="Send"
            >
            </Widget>
          </div>
        </ChatBox>
        <IFrameContainer style={{
          visibility: searchState.isSearch && !isLoading && popup && !isDirectCustomSearch ? 'visible' : 'hidden', zIndex: 994
        }}>
          <Container maxWidth="sm" className="title-container">
            <h4 className="web-title"> EnviroWeb </h4>
          </Container>
          <button className="close-iframe" onClick={closepopup}>X</button>
          <FiMaximize2 className="maximize-btn" onClick={onRouteChange} />
          <div style={{ height: '89%' }}>
            <iframe title="iframe" width="100%" className="iframe-title" src={`/search?q=${frameSearchQueryText}&type=${CUSTOM_SEARCH_TYPE.INTERNET}&directsearch=yes&hideSearchBar=true`} height="100%"></iframe>
          </div>
        </IFrameContainer>
      </Container>}
    </>
  );
};

export default MainChatBot;
