import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { IJob } from '../../../store/aiearth/types';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: 600,
    },
    custAccrodion: {
      boxShadow: 'unset',
      borderBottom: '1px solid #e7e7e7',
    },
    custAccrodionChild: {
      boxShadow: 'unset',
    },
  })
);
const Accordions = styled(Accordion)`
  & .MuiAccordionDetails-root {
    display: block;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
    color: #005fbc;
  }
  & .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  & .MuiTypography-body1 {
    font-size: 14px;
    margin-left: 20px;
  }
`;
const SoilTitle = styled.div`
  font-weight: 600;
  color: #000;
`;
const ItemArea = styled.div`
  & .active {
    background-color: #005fbc;
  }
`;

const SoilSummary: React.FunctionComponent<any> = () => {
  const classes = useStyles();

  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;
  const soilSelect: any = jobState.soilSelect;

  useEffect(() => {
    if (
      typeof soilSelect['Map Unit Key'] !== 'undefined' &&
      jobState.esadataFindings.map_findings_summary_soils
    ) {
      const elements: any = document.getElementById(soilSelect['Map Unit Key']);
      elements.scrollIntoView();
    }
  }, [soilSelect, jobState.esadataFindings.map_findings_summary_soils]);

  return (
    <div className={classes.root}>
      {jobState.esadataFindings.map_findings_summary_soils &&
        jobState.esadataFindings.map_findings_summary_soils.map(
          (src: any, keys: number) => {
            const items = src['Map Unit Composition'];

            return (
              <ItemArea key={keys}>
                <Accordions square className={classes.custAccrodion}>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    expandIcon={<ExpandMoreIcon />}
                    className={
                      typeof soilSelect['Map Unit Key'] !== 'undefined' &&
                      soilSelect['Map Unit Key'] === src['Map Unit Key']
                        ? 'active'
                        : ''
                    }
                    id={src['Map Unit Key']}
                  >
                    <SoilTitle>{src['Map Unit Title']}</SoilTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {items &&
                      items.length > 0 &&
                      items.map((item: any, index: number) => (
                        <Accordions
                          square
                          key={index}
                          className={classes.custAccrodionChild}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${Math.floor(
                              Math.random() * 10000000
                            )}a-content`}
                            id={`panel${Math.floor(
                              Math.random() * 10000000
                            )}a-header`}
                          >
                            <Typography className={classes.heading}>
                              Map Unit Composition -{' '}
                              {item['Map Unit Composition']}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <strong>Map Unit Composition: </strong>
                              {item['Map Unit Composition']}
                            </Typography>
                            <Typography>
                              <strong>Geomorphic Setting: </strong>
                              {item['Geomorphic Setting']}
                            </Typography>
                            <Typography>
                              <strong>Soil Series: </strong>
                              {item['Soil Series']}
                            </Typography>
                            <Typography>
                              <strong>Family: </strong>
                              {item['Family']}
                            </Typography>
                            <Typography>
                              <strong>Drainge: </strong>
                              {item['Drainge']}
                            </Typography>
                            <Typography>
                              <strong>Greatgroup: </strong>
                              {item['Greatgroup']}
                            </Typography>
                            <Typography>
                              <strong>Hydrologic Group: </strong>
                              {item['Hydrologic Group']}
                            </Typography>
                            <Typography>
                              <strong>Order: </strong>
                              {item['Order']}
                            </Typography>
                          </AccordionDetails>
                        </Accordions>
                      ))}
                  </AccordionDetails>
                </Accordions>
              </ItemArea>
            );
          }
        )}
    </div>
  );
};
export default SoilSummary;
