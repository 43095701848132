import React, { useState } from 'react';
import styled from 'styled-components';

interface IContent {
  details: any;
}

const Content = styled.div``;

const SnippetDetails: React.FC<IContent> = (props) => {
  const [reaMore, setReadMore] = useState(true);
  const smallText = () => {
    if (props.details.length <= 150) {
      return {
        __html: props.details,
      };
    }
    return {
      __html: props.details.substring(0, 150) + '...',
    };
  };
  const showMore = () => {
    return {
      __html: props.details,
    };
  };

  return (
    <>
      <Content dangerouslySetInnerHTML={reaMore ? smallText() : showMore()} />
      {reaMore ? (
        <span onClick={() => setReadMore(false)}>More</span>
      ) : (
        <span onClick={() => setReadMore(true)}>Less</span>
      )}
    </>
  );
};
export default SnippetDetails;
