import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const MainSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
`;

const AppSpinner = () => (
  <MainSpinnerContainer>
    <Spinner variant="primary" animation="border">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </MainSpinnerContainer>
);

export default AppSpinner;
