import React, { useState, useEffect } from 'react';
import { search, customSearch } from '../../../services/firebase';
import styled from 'styled-components';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { IElasticResult, ISearchRecord, ICustomSearchRecord, ICustomSearchResult } from '../../../types/search';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import SearchResultTable from '../../search/SearchResultTable';
import SearchPagination from '../SearchPagination';
import AppSpinner from '../../../components/AppSpinner';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import GoogleCustomSearch from '../../search/GoogleCustomSearch';

const TransitionPopup = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const DialogCust = styled(Dialog)`
  z-index: 9999 !important;
  & table thead tr {
    top: -20px !important;
  }

  .quote-main-container {
    .message-bar {
      display: none;
    }
  }
`;
const PageLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  min-height: 250px;
  max-height: 600px;
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      zIndex: 9999,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: '16px !important',
    border: '0px',
    borderRadius: '4px',
  },
}))(MuiDialogContent);

interface IModalParam {
  open: boolean;
  setOpen: (open: boolean) => void;
  queryText: string;
  context: any;
  setShowPopover: (show: boolean) => void;
  type: string;
  containerRef: HTMLDivElement;
}

const TabResultsModal: React.FC<IModalParam> = ({
  open,
  setOpen,
  queryText,
  context,
  setShowPopover,
  type,
  containerRef,
}) => {
  const [isLoad, setLoad] = useState(false);
  const [searchResult, setSearchResult] = useState<IElasticResult<ISearchRecord> | null>(null);
  const [customSearchResult, setCustomSearchResult] = useState<ICustomSearchResult<ICustomSearchRecord> | null>(null);
  const [sort, setSort] = useState('');

  const handleClose = () => {
    setOpen(false);
    setShowPopover(true);
  };

  const handleSortOrderChange = (e: any) => {
    const value = (e && e.target && e.target.value) || '';
    setSort(value);
    googleCustomSearch(1, value);
  };

  const doTextSearch = (startFrom = 0) => {
    return search({
      query: {
        from: startFrom,
        text: queryText,
        source_type: type,
      },
    })
      .then((result: any) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        setSearchResult(data);
      })
      .finally(() => setLoad(false));
  };

  const googleCustomSearch = (from: number = 1, sortVal: string = '') => {
    return customSearch({
      type,
      query: queryText,
      from,
      sort: sortVal,
    })
      .then((result) => {
        return setCustomSearchResult(result.data);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (open && type && type !== 'INTERNET') {
      setLoad(true);
      doTextSearch();
    }
    if (open && type === 'INTERNET') {
      setLoad(true);
      googleCustomSearch();
    }
    // eslint-disable-next-line
  }, [open, type]);

  return (
    <div>
      <DialogCust
        onClose={handleClose}
        TransitionComponent={TransitionPopup}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'lg'}
        container={() => containerRef}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {queryText}
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: '600px', minHeight: '250px', maxHeight: '600px' }}>
          {isLoad && (
            <PageLoader>
              <AppSpinner />
            </PageLoader>
          )}
          {searchResult && type && type !== 'INTERNET' && (
            <>
              {searchResult.records.length > 0 ? (
                <>
                  <SearchResultTable records={searchResult.records} context={context} queryText={queryText} />
                  <SearchPagination
                    isDisabled={false}
                    isFirstPage={(searchResult && searchResult.from === 0) || false}
                    isLastPage={
                      (searchResult && searchResult.from + searchResult.size >= searchResult.total.value) || false
                    }
                    totalRecords={(searchResult && searchResult.total.value) || 0}
                    onPreviousClick={() => {
                      if (!searchResult) {
                        return;
                      }
                      const currentFrom = searchResult.from;
                      const currentSize = searchResult.size;
                      setLoad(true);
                      return doTextSearch(currentFrom - currentSize);
                    }}
                    onNextClick={() => {
                      if (!searchResult) {
                        return;
                      }
                      const currentFrom = searchResult.from;
                      const currentSize = searchResult.size;
                      setLoad(true);
                      return doTextSearch(currentFrom + currentSize);
                    }}
                  />
                </>
              ) : (
                <h3 style={{ textAlign: 'center' }}>No results found.</h3>
              )}
            </>
          )}
          {customSearchResult && type === 'INTERNET' && (
            <GoogleCustomSearch
              result={customSearchResult}
              onNextClick={() => {
                if (!customSearchResult || !customSearchResult.next) {
                  return;
                }
                return googleCustomSearch(customSearchResult.next.startIndex);
              }}
              onPreviousClick={() => {
                if (!customSearchResult || !customSearchResult.previous) {
                  return;
                }
                return googleCustomSearch(customSearchResult.previous.startIndex);
              }}
              handleSortOrderChange={handleSortOrderChange}
              isLoading={isLoad}
              sort={sort}
            />
          )}
        </DialogContent>
      </DialogCust>
    </div>
  );
};

export default TabResultsModal;
