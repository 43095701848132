import React, { useEffect } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import Paginations from './Pagination';
import styled from 'styled-components';
import { OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      '&.MuiTableCell-head': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      '& .MuiTableSortLabel-root:hover': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-root:focus': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: '#fff',
      },
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const CustTableCell = styled(TableCell)`
  em {
    font-style: normal;
    font-weight: 700;
  }
`;

const TableCellFont = styled.div`
  font-size: 20px;
`;

interface INotificationContainerProps {
  notifications: any[];
  getSubjectTrack: any;
  showItems: number;
  isLoad: boolean;
  orderDate: any;
  setLoad: any;
  setOrderDate: any;
  setOrderKeyword: any;
  activeOrder: string;
  setActiveOrder: any;
  orderKeyword: any;
  rows: any;
  setShowFrom: any;
  showFrom: any;
  getSubscriptionList: any;
}

const NotificationContainer: React.FC<
  INotificationContainerProps & { context: IAuthContext }
> = (props) => {
  const {
    getSubjectTrack,
    showItems,
    isLoad,
    orderDate,
    setLoad,
    setOrderDate,
    setOrderKeyword,
    orderKeyword,
    rows,
    setShowFrom,
    showFrom,
    getSubscriptionList,
    activeOrder,
    setActiveOrder,
  } = props;
  const classes = useStyles();

  const onPreviousClick = async () => {
    setShowFrom(showFrom - showItems);
    getSubjectTrack(showFrom - showItems);
  };
  const onNextClick = async () => {
    setShowFrom(showFrom + showItems);
    getSubjectTrack(showFrom + showItems);
  };
  useEffect(() => {
    if (isLoad) {
      getSubjectTrack();
      getSubscriptionList();
    }
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label=" table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TableSortLabel
                  active={activeOrder === 'date' ? true : false}
                  direction={orderDate}
                  onClick={() => {
                    setOrderDate(orderDate === 'asc' ? 'desc' : 'asc');
                    setOrderKeyword('asc');
                    setLoad(true);
                    setActiveOrder('date');
                  }}
                >
                  Date/Time Generated
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell style={{ width: '13%' }}>
                <TableSortLabel
                  active={activeOrder === 'keyword' ? true : false}
                  direction={orderKeyword}
                  onClick={() => {
                    setOrderKeyword(orderKeyword === 'asc' ? 'desc' : 'asc');
                    setOrderDate('desc');
                    setLoad(true);
                    setActiveOrder('keyword');
                  }}
                >
                  Words/Subjects Tracked
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Snippet</TableCellFont>
              </StyledTableCell>
              <StyledTableCell style={{ width: '14%' }}>
                Entity Name
              </StyledTableCell>
              <StyledTableCell align="center">Documents</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.data &&
              rows.data.length > 0 &&
              rows.data.map((row: any, index: number) => (
                <TableRow key={row.document_id + index}>
                  <TableCell component="th" scope="row" align="center">
                    {row.document_date}
                  </TableCell>
                  <TableCell>{row.keyword}</TableCell>
                  <CustTableCell
                    dangerouslySetInnerHTML={{
                      /*eslint no-control-regex: 0*/
                      __html: row.document_snippet.replace(/[^\x00-\x7F]/g, ''),
                    }}
                    style={{ wordBreak: 'break-word' }}
                  />

                  <TableCell>{row.document_entity_name}</TableCell>
                  <TableCell>
                  <OverlayTrigger
                        placement="bottom"
                        key={index}
                        overlay={<ReactTooltip effect="solid" />}
                      >
                    <a
                    data-tip="Click to download"
                      href={row.document_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.document_source}
                    </a>
                    </OverlayTrigger>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginations
        isDisabled={false}
        isFirstPage={(rows && rows.records_num_from === 0) || false}
        isLastPage={
          (rows &&
            rows.records_num_from + rows.records_num_show >=
              rows.total_records) ||
          false
        }
        totalRecords={(rows && rows.total_records) || 0}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
    </>
  );
};
export default withAuthContext(NotificationContainer);
