import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Button, Form, FormControl, FormGroup, InputGroup, Spinner } from 'react-bootstrap';
import { Lock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AuthContext, { IAuthContext } from '../context/auth/auth-context';
import { projectId, db, updateUser } from '../services/firebase';

const SignupFormGroup = styled(FormGroup)`
  margin-bottom: 2rem;
`;

const LoginTitle = styled.h3`
  color: rgb(44, 25, 14);
  text-align: center;
  font-weight: 500;
  padding: 10px;
  font-size: 20px;
  text-transform: uppercase;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-bottom: 2rem;

  &&, &:active {
    color: rgb(44, 25, 14) !important;
    background-color: rgb(221, 197, 179) !important;
    border-color: rgb(221, 197, 179) !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
`;

const SignupFormContainer = styled.div`
  width: 300px;
`;

const SignupForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [getMessage, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [signupError, setSignupError] = useState('');
  const [isSignupSuccess, setSignupSuccess] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);

  const updateUserInfo = async (user: any): Promise<any> => {
    await axios.post(
      'https://us-central1-' + projectId + '.cloudfunctions.net/api/updateUserInfo',
      // 'http://localhost:5000/ceres-platform-test/us-central1/api/updateUserInfo',
      user,
      {
        headers: {
          Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
        },
      }
    );
  };
  const getUserCorporateDomain = async (userEmail: string): Promise<any> => {
    return await axios.post(
      'https://us-central1-' + projectId + '.cloudfunctions.net/api/getUserCorporateDomain',
      // 'http://localhost:5000/ceres-platform-test/us-central1/api/getUserCorporateDomain',
      { email: userEmail },
      {
        headers: {
          Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
        },
      }
    );
  };

  const updateTier = async (userCred: any, membershipTier: string): Promise<any> => {
    return await db
      .collection('users')
      .doc(userCred.user.uid)
      .update({
        tier: membershipTier,
      })
      .then(async () => {
        await updateUser({
          uid: userCred.user.uid,
          emailVerified: true,
        });
        return true;
      })
      .catch(async (err) => {
        await updateTier(userCred, membershipTier);
      });
  };

  return (
    <AuthContext.Consumer>
      {(context: IAuthContext) => {
        return (
          <SignupFormContainer>
            <Alert show={isSignupSuccess} variant="primary">
              <Alert.Heading>Ready to go!</Alert.Heading>
              <p>{getMessage}</p>
              <hr />
              <Link to="/login">Continue to login</Link>
            </Alert>
            {!isSignupSuccess && (
              <Form
                validated={isFormValidated}
                onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
                  setIsLoading(true);
                  e.preventDefault();
                  const form = e.currentTarget;
                  if (form.checkValidity()) {
                    const getTierInfo: any = await getUserCorporateDomain(email);
                    const membershipTier =
                      getTierInfo && getTierInfo.data.length > 0 && getTierInfo.data[0].membership_tier
                        ? getTierInfo.data[0].membership_tier.toUpperCase()
                        : 'FREE';

                    return context
                      .createUserWithEmailAndPassword(email, password)
                      .then(async (userCred: any) => {
                        if (userCred.user && userCred.user.emailVerified === false) {
                          membershipTier === 'FREE' &&
                            (await userCred.user
                              .sendEmailVerification({
                                url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT || '',
                              })
                              .catch((err: any) => setSignupError(err.message)));

                          userCred.user.uid &&
                            membershipTier !== 'FREE' &&
                            (await updateTier(userCred, membershipTier));

                          await updateUserInfo({
                            email: userCred.user.email,
                            displayName: userCred.user.displayName,
                            emailVerified: false,
                            creationTime: new Date(),
                            tier: membershipTier,
                            phoneNumber: userCred.user.phoneNumber,
                            uid: userCred.user.uid,
                          });
                        }
                      })
                      .then(() => {
                        setSignupSuccess(true);
                      })
                      .catch((err) => {
                        setSignupError(err.message);
                      })
                      .finally(() => {
                        context.signOut();
                        setMessage(
                          `Your account has been created successfully. ${
                            membershipTier === 'FREE' ? 'Please verify the email address before trying to login' : ''
                          }`
                        );
                        setIsLoading(false);
                      });
                  }
                  setIsLoading(false);
                  return setIsFormValidated(true);
                }}
              >
                <Alert show={!!signupError} dismissible variant="danger" onClose={() => setSignupError('')}>
                  <Alert.Heading>Error</Alert.Heading>
                  <p>{signupError}</p>
                </Alert>
                <SignupFormGroup>
                  <LoginTitle>Sign Up</LoginTitle>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <Mail />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      onChange={(e: any) => {
                        setIsFormValidated(false);
                        setEmail(e.target.value);
                      }}
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                    />
                  </InputGroup>
                </SignupFormGroup>
                <SignupFormGroup>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <Lock />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      onChange={(e: any) => {
                        setIsFormValidated(false);
                        setPassword(e.target.value);
                      }}
                      placeholder="Password"
                      type="password"
                      minLength={6}
                      required
                      name="password"
                    />
                  </InputGroup>
                </SignupFormGroup>
                <SignupFormGroup>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <Lock />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      onChange={(e: any) => {
                        setIsFormValidated(false);
                        if (password !== e.target.value) {
                          e.target.setCustomValidity('Password does not match');
                        } else {
                          e.target.setCustomValidity('');
                        }
                      }}
                      placeholder="Confirm Password"
                      type="password"
                      minLength={6}
                      required
                      name="confirmPassword"
                    />
                  </InputGroup>
                </SignupFormGroup>
                <FullWidthButton color="primary" type="submit" disabled={isLoading}>
                  {!isLoading && 'Sign Up'}
                  {isLoading && <Spinner animation="border" variant="light" size="sm" />}
                </FullWidthButton>
              </Form>
            )}
          </SignupFormContainer>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default SignupForm;
