import * as R from 'ramda';

import { IElasticResult, ISearchRecord } from '../../types/search';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner, Tab, Tabs } from 'react-bootstrap';

import { CeresLogger } from '../../logger';
import { IAuthContext } from '../../context/auth/auth-context';
import { Scrollbars } from 'react-custom-scrollbars';
import { TIERS } from '../../types/tiers';
import TreeView from './TreeViewData';
import axios from 'axios';
import { projectId } from '../../services/firebase';
import { searchAIEarth } from '../../services/firebase';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../../context/auth/AuthConsumer';
interface IRecordMarkerProps {
  record: ISearchRecord;
  context: IAuthContext;
  doStorageDownload: any;
  getStorageRef: any;
  downloadTriggers: any;
  setDownloadTriggers: any;
  showProgramQueryPanel: any;
  searchTerm: string;
}

const EntityRecordListContainer = styled.div`
  // height: 160px;
  // overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;

const OLContainer = styled.ol`
  padding-inline-start: 10px !important;
  font-size: 0.6rem;
  padding-top: 7px;
  margin-bottom: 0px;
`;

const AddressText = styled.span`
  font-size: 0.6rem;
`;

const CustomTabs = styled(Tabs)`
  border-bottom: 1px solid #005fbc !important;
`;

const SpaceEvenly = styled.div`
  display: flex;
  justify-content: space-between;
`;

const getTCEQRecordUrl = (entityNumber: string) =>
  `https://www15.tceq.texas.gov/crpub/index.cfm?fuseaction=regent.validateRE&re_ref_num_txt=${entityNumber}`;

const getEPARecordUrl = (entityNumber: string) =>
  `https://ofmpub.epa.gov/frs_public2/fii_map_master.fii_retrieve?fac_search=facility_uin&fac_value=${entityNumber}&fac_search_type=Equal+To&postal_code=&location_address=&add_search_type=B&city_name=&county_name=&state_code=&epa_region_code=&cong_dist=&legis_dist=&huc_code=&fed_agency=&TribalLand=0&selectTribe=noselect&sic_type=Equal+to&sic_code_to=&naic_type=Equal+to&naic_to=&org_name=&duns_num=&contact_name=&prog_search=&int_search=&search_type=&search_type=all&all_programs=YES&sysname=&page_no=1&output_sql_switch=TRUE&report=1&database_type=FII&tribal_ind=&last_facility=&univ_search=&fac_search_term=&tribetype=&triballand=&selecttribe=&tribedistance1=`;

const fetchRecordsByRN = (
  rnNumber: string,
  searchTerm: string,
  searchType: string
) => {
  return searchAIEarth({
    query: {
      from: 0,
      size: 50,
      sortByReleaseDate: true,
      text: searchTerm,
      type: searchType,
      terms: {
        regulated_entity_number: rnNumber,
      },
    },
  });
};

const EPALink = styled.span`
  color: #005fbc;
  cursor: pointer;
`;
const DownloadHyperlink = styled.a`
  color: #005fbc;
  cursor: pointer;
  &:hover {
    color: #ac5e08 !important;
    text-decoration: underline !important;
  }
`;

const RecordMarkerContainer = styled.div``;

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const RecordMarkerInfoWindow: React.FC<{
  routerProps: RouteComponentProps<any>;
  record: ISearchRecord;
  isLoading: boolean;
  relatedRecords: ISearchRecord[];
  authContext: IAuthContext;
  doStorageDownload: any;
  getStorageRef: any;
  downloadTriggers: any;
  setDownloadTriggers: any;
  details: any;
}> = (props) => {
  const authContext = props.authContext;
  const record = props.record;
  const documentsTabTitle = `Documents(${props.relatedRecords.length})`;

  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner animation="border" variant="primary" />
    </SpinnerContainer>
  );
  const getRecordTitle = (item: ISearchRecord) =>
    `${item.title} - ${item.seriesName} - ${item.releaseDate}`;
  const renderResult = () => {
    return (
      <EntityRecordListContainer>
        <AddressText>{record.address}</AddressText>
        <h6>
          <SpaceEvenly>
            {record.entitySource === 'TCEQ' && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getTCEQRecordUrl(record.entityNumber)}
              >
                {record.entityNumber}
              </a>
            )}
            {record.entitySource === 'EPA' && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getEPARecordUrl(record.entityNumber)}
              >
                {record.entityNumber}
              </a>
            )}
            {record.entitySource === 'TCEQ-EPA' && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getTCEQRecordUrl(record.entityNumber)}
              >
                {record.entityNumber}
              </a>
            )}
            {record.entitySource === 'LDEQ' && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getTCEQRecordUrl(record.entityNumber)}
              >
                {record.entityNumber}
              </a>
            )}
            {record.secondaryId && (
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={
                  <Popover id="popover">
                    <Popover.Title>EPA IDs</Popover.Title>
                    <Popover.Content>
                      {record.secondaryId.split(',').map((epaId: any) => {
                        if (epaId.startsWith('11') && epaId.length === 12) {
                          return (
                            <p>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getEPARecordUrl(epaId)}
                              >
                                {epaId}
                              </a>
                            </p>
                          );
                        }
                        return '';
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <EPALink>EPA IDs</EPALink>
              </OverlayTrigger>
            )}
          </SpaceEvenly>
        </h6>
        <CustomTabs defaultActiveKey="details" id="uncontrolled-tab-example">
          <Tab eventKey="details" title="Details">
            <TreeView details={props.details} />
          </Tab>
          <Tab eventKey="documents" title={documentsTabTitle}>
            <OLContainer>
              <Scrollbars
                style={{
                  height: 'calc(100vh - 305px)',
                }}
              >
                {R.compose(
                  R.map((relatedRecord: ISearchRecord) => {
                    return (
                      <li
                        style={{
                          color: relatedRecord.containsSearchTerm
                            ? 'green'
                            : 'none',
                        }}
                        key={relatedRecord.contentId}
                      >
                        {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
                          getRecordTitle(relatedRecord)
                        ) : (
                          <DownloadHyperlink
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                props.doStorageDownload(
                                  relatedRecord.entitySource
                                )
                              ) {
                                const storageRef = props.getStorageRef(
                                  relatedRecord.entitySource
                                );
                                if (R.isNil(storageRef)) {
                                  return toast.error(
                                    `Oops, could not find the file.`
                                  );
                                }
                                props.setDownloadTriggers(
                                  R.append(
                                    relatedRecord.contentId,
                                    props.downloadTriggers
                                  )
                                );
                                return storageRef
                                  .ref(relatedRecord.fileName)
                                  .getDownloadURL()
                                  .then((url: string | undefined) =>
                                    window.open(
                                      url,
                                      '_blank',
                                      'noopener,noreferrer'
                                    )
                                  )
                                  .catch((err: any) => toast.error(err.message))
                                  .finally(() =>
                                    props.setDownloadTriggers(
                                      R.without(
                                        [relatedRecord.contentId],
                                        props.downloadTriggers
                                      )
                                    )
                                  );
                              }
                              if (!relatedRecord.fileUrl) {
                                return toast.warn(
                                  <span>
                                    No associated file was found for the content
                                    id <b>{relatedRecord.contentId}</b>
                                  </span>
                                );
                              }
                              window.open(relatedRecord.fileUrl, '_blank');
                              // props.setRelatedDocument(relatedRecord);
                            }}
                          >
                            {getRecordTitle(relatedRecord)}
                          </DownloadHyperlink>
                        )}
                      </li>
                    );
                  }),
                  R.sort(
                    R.comparator(
                      (itemOne: ISearchRecord, itemTwo: ISearchRecord) =>
                        itemOne.releaseDate > itemTwo.releaseDate
                    )
                  )
                )(props.relatedRecords)}
                {props.relatedRecords.length < 1
                  ? 'No documents currently available.'
                  : ''}
              </Scrollbars>
            </OLContainer>
          </Tab>
        </CustomTabs>
      </EntityRecordListContainer>
    );
  };
  return (
    <Fragment>
      <h6>{record.entityName}</h6>
      {props.isLoading && renderSpinner()}
      {!props.isLoading && renderResult()}
    </Fragment>
  );
};

const ProgramQueryDocDrawer: React.FC<
  IRecordMarkerProps & RouteComponentProps<any>
> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [relatedRecords, setRelatedRecords] = useState<ISearchRecord[]>([]);
  const [details, setDetails] = useState();
  const entityNumber = props.record.entityNumber;
  const entitySource = props.record.entitySource;
  const authContext = props.context;

  useEffect(() => {
    async function programQueryDetails() {
      const requestBody = { RN: entityNumber };
      try {
        setIsLoading(true);
        axios
          .post(
            'https://us-central1-' +
              projectId +
              '.cloudfunctions.net/api/programQueryDetails',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
            requestBody,
            {
              headers: {
                Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
              },
            }
          )
          .then((response) => {
            setIsLoading(false);
            const detailsJson = response.data[0].get_prgm_json_for_entity;
            setDetails(detailsJson);
          })
          .catch((err) => {
            CeresLogger.error(err);
            setIsLoading(false);
          })
          .finally(() => setIsLoading(false));
      } catch (e) {
        CeresLogger.error(e);
      }
    }

    const ldeqDocuments = async (enNumber: string) => {
      const requestBody = { RN: enNumber };
      const data = await axios
        .post(
          'https://us-central1-' +
            projectId +
            '.cloudfunctions.net/api/ldeqDocuments',
          requestBody,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response: any) => {
          return response.data[0].get_doc_json_ldeq.result;
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
      return data;
    };

    async function fetchRecords() {
      let isSubscribed = true;
      if (entitySource === 'LDEQ') {
        const data: any = await ldeqDocuments(entityNumber);
        const records = (data && data.records) || [];

        records.map((rec: any) => {
          rec.containsSearchTerm = true;
          return rec;
        });
        setRelatedRecords(records);
        setIsLoading(false);
        return () => {
          isSubscribed = false;
        };
      } else {
        const containsSearchTerm = await fetchRecordsByRN(
          entityNumber,
          props.searchTerm,
          'must'
        );
        const data = containsSearchTerm.data as IElasticResult<ISearchRecord>;
        const records = (data && data.records) || [];
        records.map((rec) => {
          rec.containsSearchTerm = true;
          return rec;
        });
        isSubscribed && setRelatedRecords(records);
        setIsLoading(false);
        return () => {
          isSubscribed = false;
        };
      }
    }
    if (props.showProgramQueryPanel) {
      programQueryDetails();
      fetchRecords();
    }
    // eslint-disable-next-line
  }, [entityNumber, entitySource, props.showProgramQueryPanel]);
  return (
    <RecordMarkerContainer>
      {
        <RecordMarkerInfoWindow
          routerProps={props}
          details={details}
          authContext={authContext}
          record={props.record}
          isLoading={isLoading}
          relatedRecords={relatedRecords}
          doStorageDownload={props.doStorageDownload}
          getStorageRef={props.getStorageRef}
          downloadTriggers={props.downloadTriggers}
          setDownloadTriggers={props.setDownloadTriggers}
        />
      }
    </RecordMarkerContainer>
  );
};

export default withRouter(withAuthContext(ProgramQueryDocDrawer));
