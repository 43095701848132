import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Layers, Search, Image, Video, ShoppingBag, FileText, Speaker, MapPin } from 'react-feather';
import { default as Sliders } from 'react-slick';
import { Grid } from '@material-ui/core';
import mediaQuery from '../../util/media-query';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as GlobeSVG } from '../../assets/search/globe.svg';
import GoogleSearchMenuMoreFilters from './GoogleSearchMenuMoreFilters';

const SlidersI = styled(Sliders)<any>`
  width: ${(props) => props.width - 40}px;
  margin: auto;
  @media (max-width: 767px) {
    max-width: 95%;
    margin: auto;
  }
  & .slick-arrow:before {
    z-index: 11;
  }
  & .slick-arrow {
    height: 30px;
    width: 30px;
  }
`;
const ItemArea = styled.div`
  padding: 0 10px;
`;
const CustButton = styled(Button)`
  &.default-link {
    background-color: #7991af;
  }
  &.google-link {
    font-size: 14px;
    color: #5f6368;
    text-decoration: none;
    padding: 0.375rem 0.55rem;
  }
  &.active {
    color: #005fbc;
    font-weight: 600;
  }
  svg {
    margin-bottom: 3px;
  }
`;
const MenuArea = styled.div`
  display: flex;
  align-items: center;
  min-width: 750px;
  padding-bottom: 0;
  ${mediaQuery.phone`
    padding-bottom: 15px;
 `}
`;
const BackLink = styled.div`
  font-weight: 700;
  color: #336195;
  padding-left: 50px;
  cursor: pointer;
`;

const AllArea = styled.div`
  ${mediaQuery.phone`
    overflow-y: scroll;
 `}
`;

const searchTypes = [
  {
    label: 'All',
    value: CUSTOM_SEARCH_TYPE.ALL,
    icon: <Search size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Images',
    value: CUSTOM_SEARCH_TYPE.IMAGES,
    icon: <Image size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Videos',
    value: CUSTOM_SEARCH_TYPE.VIDEOS,
    icon: <Video size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'News',
    value: CUSTOM_SEARCH_TYPE.NEWS,
    icon: <Layers size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Map',
    value: CUSTOM_SEARCH_TYPE.MAP,
    icon: <MapPin size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Shopping',
    value: CUSTOM_SEARCH_TYPE.SHOPPING,
    icon: <ShoppingBag size={16} />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Environmental Results',
    value: CUSTOM_SEARCH_TYPE.INTERNET,
    icon: <GlobeSVG width="17px" height="17px" />,
    type: 'google',
    isSubMenu: false,
  },
  {
    label: 'Internet',
    value: CUSTOM_SEARCH_TYPE.INTERNET,
    icon: <img src="/icon/globe.png" alt="globe" width="20" />,
    type: 'environmental',
    isSubMenu: false,
  },
  {
    label: 'EPA',
    value: CUSTOM_SEARCH_TYPE.EPA,
    icon: <img src="/icon/EPA.png" alt="globe" width="24" />,
    type: 'environmental',
    isSubMenu: false,
  },
  {
    label: 'News',
    value: CUSTOM_SEARCH_TYPE.NEWS,
    icon: <img src="/icon/news.png" alt="news" width="22" />,
    type: 'environmental',
    isSubMenu: false,
  },
  {
    label: 'Social Media',
    value: CUSTOM_SEARCH_TYPE.SOCIAL,
    icon: <img src="/icon/social-media.png" alt="social" width="30" />,
    type: 'environmental',
    isSubMenu: false,
  },

  {
    label: 'Legal',
    value: CUSTOM_SEARCH_TYPE.LEGAL,
    icon: <img src="/icon/gavel.png" alt="gavel" width="30" />,
    type: 'environmental',
    isSubMenu: false,
  },
  {
    label: 'More Filters',
    value: CUSTOM_SEARCH_TYPE.TCEQ_ENFORCEMENT,
    icon: <img src="/icon/plus.png" alt="plus" width="22" />,
    type: 'environmental',
    isSubMenu: true,
    menus: [
      {
        label: 'Web Files',
        value: CUSTOM_SEARCH_TYPE.TCEQ_WEB_FILES,
        icon: <FileText size={16} />,
        type: 'environmental',
      },
      {
        label: 'Enforcement',
        value: CUSTOM_SEARCH_TYPE.TCEQ_ENFORCEMENT,
        icon: <Speaker size={16} />,
        type: 'environmental',
      },
    ],
  },
];

interface IProps {
  settings: any;
  width: number;
  active: string;
  isDisabled: boolean;
  isOnlyDocumentSearch: boolean;
  setSourceType: (type: string) => void;
  setSearchType: (type: string) => void;
  onTypeChange: (type: CUSTOM_SEARCH_TYPE, source: string) => void;
  clearFilter: any;
  searchType: string;
  customSearchType: string;
  setCustomSearchType: (src: string) => void;
  setQuickSerch: (type: string) => void;
}
interface ISearchItem {
  active: string;
  isDisabled: boolean;
  isOnlyDocumentSearch: boolean;
  setSourceType: (type: string) => void;
  setSearchType: (type: string) => void;
  onTypeChange: (type: CUSTOM_SEARCH_TYPE, source: string) => void;
  clearFilter: any;
  value: CUSTOM_SEARCH_TYPE;
  label: string;
  searchType: string;
  icon: any;
  setCustomSearchType: (src: string) => void;
}

export const SearchItem: React.FC<ISearchItem> = ({
  active,
  isDisabled,
  isOnlyDocumentSearch,
  setSourceType,
  clearFilter,
  onTypeChange,
  setSearchType,
  value,
  label,
  searchType,
  icon,
  setCustomSearchType,
}) => {
  return (
      <CustButton
      active={value.toUpperCase() === active.toUpperCase()}
      disabled={(isOnlyDocumentSearch && value !== CUSTOM_SEARCH_TYPE.DEFAULT) || isDisabled}
      variant={searchType === 'default' ? 'outline-primary' : 'link'}
      onClick={() => {
        setSourceType('');
        clearFilter().then(() => {
          setSearchType(value);
          onTypeChange(value, '');
          label === 'Environmental Results' && setCustomSearchType('environmental');
        });
      }}
      className={searchType === 'default' ? 'default-link' : 'google-link'}
    >
      {icon} {label}
    </CustButton>
  );
};

const GoogleSearchMenu: React.FC<IProps> = ({
  settings,
  width,
  active,
  isDisabled,
  isOnlyDocumentSearch,
  setSourceType,
  clearFilter,
  onTypeChange,
  setSearchType,
  searchType,
  customSearchType,
  setCustomSearchType,
  setQuickSerch,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      md={searchType === 'default' ? 6 : 12}
      lg={searchType === 'default' ? 6 : 12}
      spacing={3}
    >
      {searchType === 'default' ? (
        <SlidersI {...settings} width={width < 1000 ? width : width / 2}>
          {searchTypes.map((type) => {
            return (
              <ItemArea key={btoa(type.value)}>
                <SearchItem
                  active={active}
                  isDisabled={isDisabled}
                  isOnlyDocumentSearch={isOnlyDocumentSearch}
                  setSourceType={setSourceType}
                  setSearchType={setSearchType}
                  onTypeChange={onTypeChange}
                  clearFilter={clearFilter}
                  value={type.value}
                  label={type.label}
                  searchType={searchType}
                  icon=""
                  setCustomSearchType={setCustomSearchType}
                />
              </ItemArea>
            );
          })}
        </SlidersI>
      ) : (
        <AllArea>
          <MenuArea>
            {searchTypes
              .filter((src) => src.type === customSearchType)
              .map((type, index) => {
                return (
                  <div key={index}>
                    {!type.isSubMenu ? (
                      <SearchItem
                        active={active}
                        isDisabled={isDisabled}
                        isOnlyDocumentSearch={isOnlyDocumentSearch}
                        setSourceType={setSourceType}
                        setSearchType={setSearchType}
                        onTypeChange={onTypeChange}
                        clearFilter={clearFilter}
                        value={type.value}
                        label={type.label}
                        searchType={searchType}
                        icon={type.icon}
                        setCustomSearchType={setCustomSearchType}
                      />
                    ) : (
                      <GoogleSearchMenuMoreFilters
                        sunMenu={type}
                        setCustomSearchType={setCustomSearchType}
                        setSourceType={setSourceType}
                        setSearchType={setSearchType}
                        onTypeChange={onTypeChange}
                        clearFilter={clearFilter}
                      />
                    )}
                  </div>
                );
              })}
            <BackLink
              onClick={() => {
                setQuickSerch('');
                setSourceType('');
                setSearchType(CUSTOM_SEARCH_TYPE.DEFAULT);
                onTypeChange(CUSTOM_SEARCH_TYPE.DEFAULT, '');
              }}
            >
              TUNDRA PRO
            </BackLink>
          </MenuArea>
        </AllArea>
      )}
    </Grid>
  );
};
export default GoogleSearchMenu;
