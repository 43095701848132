const quotesObj: any = {
  1: {
    quote: `“We are on Earth to take care of life. We are on Earth to take care of each other.”`,
    quoteAuthor: `Xiye Bastida`,
  },
  2: {
    quote: `“Every day is Earth Day, and I vote we start investing in a secure climate future right now.”`,
    quoteAuthor: `Jackie Speier`,
  },
  3: {
    quote: `“To leave the world better than you found it, sometimes you have to pick up other people’s trash.”`,
    quoteAuthor: `Bill Nye`,
  },
  4: {
    quote: `"First thing in the morning you look after yourself, you brush your teeth and wash your face, don’t you? Well, the second thing you must do is to look after the planet."`,
    quoteAuthor: `Antoine De Saint-Exupery`,
  },
  5: {
    quote: `"The Earth is what we all have in common."`,
    quoteAuthor: `Wendell Berry`,
  },
  6: {
    quote: `“The Earth is a fine place and worth fighting for.”`,
    quoteAuthor: `Ernest Hemingway`,
  },
  7: {
    quote: `“Look deep into nature, and then you will understand everything better.”`,
    quoteAuthor: `Albert Einstein`,
  },
  8: {
    quote: `“An understanding of the natural world and what’s in it is a source of not only a great curiosity but great fulfillment.”`,
    quoteAuthor: `David Attenborough`,
  },
  9: {
    quote: `“And into the forest I go, to lose my mind and my soul.”`,
    quoteAuthor: `John Muir`,
  },
  10: {
    quote: `“The real you is loving, joyful and free. The real you is just like a flower, just like the wind, just like the ocean, just like the sun.”`,
    quoteAuthor: `Don Miguel Ruiz`,
  },
  11: {
    quote: `“We still do not know one thousandth of one percent of what nature has revealed to us."`,
    quoteAuthor: `Albert Einstein`,
  },
  12: {
    quote: `“Look at a tree, a flower, a plant. Let your awareness rest upon it. How still they are, how deeply rooted in Being. Allow nature to teach you stillness.”`,
    quoteAuthor: `Eckhart Tolle`,
  },
  13: {
    quote: `"For in the true nature of things, if we rightly consider, every green tree is far more glorious than if it were made of gold and silver."`,
    quoteAuthor: `Martin Luther King Jr`,
  },
  14: {
    quote: `“I firmly believe nature brings solace in all troubles.”`,
    quoteAuthor: `Anne Frank`,
  },
  15: {
    quote: `“There is no medicine you can take that has such a direct influence on your health as a walk in a beautiful forest.”`,
    quoteAuthor: `Dr. Qing Li`,
  },
  16: {
    quote: `"Nature is an infinite sphere of which the centre is everywhere and the circumference nowhere."`,
    quoteAuthor: `Blaise Pascal`,
  },
  17: {
    quote: `“I am the wilderness.”`,
    quoteAuthor: `Brene Brown`,
  },
  18: {
    quote: `“Preserve and cherish the pale blue dot, the only home we’ve ever known.”`,
    quoteAuthor: `Carl Sagan`,
  },
  19: {
    quote: `“If you truly love nature, you will find beauty everywhere.”`,
    quoteAuthor: `Vincent Van Gogh`,
  },
  20: {
    quote: `“In all things of nature there is something of the marvelous.”`,
    quoteAuthor: `Aristotle`,
  },
  21: {
    quote: `”Earth laughs in flowers.”`,
    quoteAuthor: `Ralph Waldo Emerson`,
  },
  22: {
    quote: `”When one tugs at a single thing in nature, he finds it attached to the rest of the world.”`,
    quoteAuthor: `John Muir`,
  },
  23: {
    quote: `”We do not inherit the earth from our ancestors; we borrow it from our children.”`,
    quoteAuthor: `Native American Proverb`,
  },
  24: {
    quote: `“Away, away, from men and towns, / To the wild wood and the downs, / To the silent wilderness, / Where the soul need not repress its music.”`,
    quoteAuthor: `Percy Bysshe Shelley`,
  },
25: {
    quote: `“And forget not that the earth delights to feel your bare feet and the winds long to play with your hair.”`,
    quoteAuthor: `Kahlil Gibran`,
  },
26: {
    quote: `”When one tugs at a single thing in nature, he finds it attached to the rest of the world.”`,
    quoteAuthor: `John Muir`,
  },
27: {
    quote: `”We do not inherit the earth from our ancestors; we borrow it from our children.”`,
    quoteAuthor: `Native American Proverb`,
  },
28: {
    quote: `“And forget not that the earth delights to feel your bare feet and the winds long to play with your hair.”`,
    quoteAuthor: `Kahlil Gibran`,
  },
29: {
    quote: `“Nature is painting for us, day after day, pictures of infinite beauty.”`,
    quoteAuthor: `John Ruskin`,
  },
30: {
    quote: `“The ultimate test of man's conscience may be his willingness to sacrifice something today for future generations whose words of thanks will not be heard.”`,
    quoteAuthor: `Gaylord Nelson`,
  },
31: {
    quote: `“I am a passenger on the spaceship Earth.”`,
    quoteAuthor: `R. Buckminster Fuller`,
  },
32: {
    quote: `“Be the change you wish to see in this world.”`,
    quoteAuthor: `Gandhi`,
  },
33: {
    quote: `“What is the use of a house if you haven’t got a tolerable planet to put it on?”`,
    quoteAuthor: `Henry David Thoreau`,
  },
34: {
    quote: `“Save the Earth, it’s our only source of chocolate!”`,
    quoteAuthor: `Unknown`,
  },
35: {
    quote: `“There are no passengers on Spaceship Earth. We are all crew.”`,
    quoteAuthor: `Marshall McLuhan`,
  },
36: {
    quote: `“The environment is where we all meet; where we all have a mutual interest; it is the one thing all of us share.”`,
    quoteAuthor: ` Lady Bird Johnson`,
  },
37: {
    quote: `“Those who contemplate the beauty of the Earth find reserves of strength that will endure as long as life lasts.”`,
    quoteAuthor: `Rachel Carson`,
  },
38: {
    quote: `“Nothing is more beautiful than the loveliness of the woods before sunrise.”`,
    quoteAuthor: `George Washington Carver`,
  },
39: {
    quote: `“To me a lush carpet of pine needles or spongy grass is more welcome than the most luxurious Persian rug.”`,
    quoteAuthor: `Helen Keller`,
  },
40: {
    quote: `“The wealth of the nation is its air, water, soil, forests, minerals, rivers, lakes, oceans, scenic beauty, wildlife habitats, and biodiversity.”`,
    quoteAuthor: `Gaylord Nelson`,
  },
41: {
    quote: `“The more clearly we can focus our attention on the wonders and realities of the universe about us, the less taste we shall have for destruction.”`,
    quoteAuthor: `Rachel Carson`,
  },
42: {
    quote: `“Humankind has not woven the web of life. We are but one thread within it. Whatever we do to the web, we do to ourselves. All things are bound together; all things connect.”`,
    quoteAuthor: `Chief Seattle`,
  },
43: {
    quote: `“Dear old world, You are very lovely, and I am glad to be alive in you.”`,
    quoteAuthor: `Lucy Maud Montgomery`,
  },
44: {
    quote: `“There is a pleasure in the pathless woods. There is a rapture on the lonely shore. There is society, where none intrudes by the deep sea, and music in its roar. I love not man the less, but nature more.”`,
    quoteAuthor: `Lord Byron`,
  },
45: {
    quote: `“This world is but a canvas to our imagination.”`,
    quoteAuthor: ` Henry David Thoreau`,
  },
46: {
    quote: `“Where flowers bloom, so does hope.”`,
    quoteAuthor: `Lady Bird Johnson`,
  },
47: {
    quote: `“By discovering nature, you discover yourself.”`,
    quoteAuthor: `Maxime Lagacé`,
  },
48: {
    quote: `“The antidote to exhaustion isn’t rest. It’s nature.”`,
    quoteAuthor: `Shikoba`,
  },
49: {
    quote: `“Time spent amongst trees is never wasted time.”`,
    quoteAuthor: ` Katrina Mayer`,
  },
50: {
    quote: `“Come forth into the light things, let nature be your teacher.”`,
    quoteAuthor: `William Wordsworth`,
  },
};
export default quotesObj;
