import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      '&.MuiTableCell-head': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      '& .MuiTableSortLabel-root:hover': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-root:focus': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: '#fff',
      },
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const TableCellFont = styled.div`
  font-size: 20px;
`;
const Bakbtn = styled.div`
margin-bottom: 10px;
`;

interface IContactListContainerProps {
  rows: any;
  hideContactListTable: any;
}

const ContactListContainer: React.FC<
  IContactListContainerProps & { context: IAuthContext }
> = (props) => {
  const {
    rows,
    hideContactListTable,
  } = props;
  const classes = useStyles();

  return (
    <>
    <Bakbtn>
      <Button onClick={hideContactListTable}>Back</Button>
      </Bakbtn>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label=" table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <TableCellFont>Contact</TableCellFont>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Title</TableCellFont>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Phone</TableCellFont>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Email</TableCellFont>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Address</TableCellFont>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.data &&
              rows.data.length > 0 &&
              rows.data.map((row: any, index: number) => (
                <TableRow key={row.Name + index}>
                  <TableCell component="th" scope="row" align="center">
                    {row.Name}
                  </TableCell>
                  <TableCell>{row.Title && row.Title.length ? row.Title.join(',') : 'N/A'}</TableCell>
                  <TableCell>{row.Phone && row.Phone.length ? row.Phone.join(',') : 'N/A'}</TableCell>
                  <TableCell>{row.Email && row.Email.length ? row.Email.join(',') : 'N/A'}</TableCell>
                  <TableCell>{row.Address && row.Address.length ? row.Address.join(',') : 'N/A'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default withAuthContext(ContactListContainer);
