export let anwr = {
  Q1: [
      'CHEMICAL & ALLIED PRODUCTS MERCHANT WHOLESALERS-4246',
      'CONSTRUCTION, TRANSPORTATION, MINING & FORESTRY MACHINERY & EQUIPMENT RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53241',
  ],
  Q2: [
      'GENERAL MEDICAL & SURGICAL HOSPITALS (if fuel tanks are present)-6221',
      'MARINAS-71393',
      'WASTE MANAGEMENT & REMEDIATION SERVICES-562',
  ],
  property_owner: 'a',
  property_name: 'a',
  property_address: 'a',
  property_history: 'a',
  name_ksm: 'a',
  ksm_title: 'Owner',
  cell_ksm: '123',
  email_ksm: 'mike@enviro.ai',
  Q3: 'No',
  Q4: 'Yes',
  Q4E: 'ABC',
  Q5: 'No',
  Q6: 'No',
  Q7: 'No',
  Q8: 'No',
  Q9: 'No',
  Q10: 'No',
  Q11: 'No',
  Q12: 'No',
  Q13: 'No',
  Q14: 'No',
  Q15: 'No',
  Q16: 'No',
  Q17: 'No',
  Q18: 'No',
  Q19: 'No',
};

export let EnvironmentalQuestionnaireJSON = {
  title: 'Environmental Questionnaire',
  description:
    'The questionnaire must be completed by the Key Site Manager who is a person identified by the owner with good knowledge of the uses and physical characteristics of the Subject Property. ',
  showQuestionNumbers: 'off',
  questions: [
    {
      type: 'panel',
      name: 'property_information',
      elements: [
        {
          type: 'text',
          name: 'property_owner',
          title: 'Current Legal Owner of the Subject Property:',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'property_name',
          title: 'Subject Property Name:',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'property_address',
          title: 'Subject Property Address:',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'property_history',
          title: 'Please describe the current and historical use of the subject property:',
          isRequired: true,
        },
      ],
      title: 'Subject Property Information:',
    },
    {
      type: 'panel',
      name: 'ksm_information',
      elements: [
        {
          type: 'text',
          name: 'name_ksm',
          title:
            'Name of the Key Site Manager who is either the property owner or authorized owner representative knowledgeble of the history and operations at the subject property',
          isRequired: true,
        },
        {
          type: 'radiogroup',
          name: 'ksm_title',
          title: 'Title of the Key Site Manager',
          isRequired: true,
          hasOther: true,
          colCount: 1,
          choices: ['Owner', 'President', 'Vice President', 'Manager'],
        },
        {
          type: 'text',
          name: 'cell_ksm',
          title: 'Key Site Manager cell number',
          inputType: 'tel',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'email_ksm',
          title: 'Key Site Manager Email',
          inputType: 'email',
          isRequired: true,
        },
      ],
      title: 'Key Site Manager Information',
    },

    {
      type: 'checkbox',
      name: 'Q1',
      title:
        'Q1: Do any of the past and/or present uses of the Subject Property involve environmentally sensitive industries? Please check all that apply.',
      isRequired: true,
      hasOther: true,
      colCount: 1,
      choices: [
        'NONE',
        'AIR TRANSPORTATION-481',
        'AUTOMOBILE & OTHER MOTOR VEHICLE MERCHANT WHOLESALERS (if service bays present)-42311',
        'AUTOMOTIVE REPAIR & MAINTENANCE-8111',
        'BEVERAGE & TOBACCO PRODUCT MANUFACTURING-312',
        'CHEMICAL & ALLIED PRODUCTS MERCHANT WHOLESALERS-4246',
        'CHEMICAL MANUFACTURING-325',
        'COMMERCIAL & INDUSTRIAL MACHINERY & EQUIPMENT REPAIR & MAINTENANCE-8113',
        'COMPUTER & ELECTRONIC PRODUCT MANUFACTURING (not required if assembly only)-334',
        'CONSTRUCTION, TRANSPORTATION, MINING & FORESTRY MACHINERY & EQUIPMENT RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53241',
        'DEATH CARE SERVICES-8122',
        'ELECTRICAL EQUIPMENT, APPLIANCE & COMPONENT MANUFACTURING (not required if assembly only)-335',
        'ELECTRONIC & PRECISION EQUIPMENT REPAIR & MAINTENANCE (not required if assembly only)-8112',
        'EXTERMINATING & PEST CONTROL-56171',
        'FABRICATED METAL PRODUCT MANUFACTURING-332',
        'FOOD MANUFACTURING (if underground fuel tanks present)-311',
        'FUEL DEALERS-45431',
        'FURNITURE & RELATED MANUFACTURING (if finishing occurs on site)-337',
        'GASOLINE STATIONS-447',
        'GENERAL MEDICAL & SURGICAL HOSPITALS (if fuel tanks are present)-6221',
        'GOLF COURSES & COUNTRY CLUBS-71391',
        'HEAVY & CIVIL ENGINEERING CONSTRUCTION-237',
        'LAUNDRY & DRY CLEANING SERVICES (if dry cleaning operations on-site)-8123',
        'LEATHER & ALLIED PRODUCT MANUFACTURING-316',
        'MACHINERY MANUFACTURING (not required if assembly only)-333',
        'MARINAS-71393',
        'METAL & MINERAL MERCHANT WHOLESALER-4235',
        'MINING (EXCEPT OIL & GAS)-212',
        'MISCELLANEOUS MANUFACTURING (only required if hazardous materials are involved)-339',
        'MOTOR VEHICLE AND PARTS DEALERS (if service bays present)-441',
        'MOTOR VEHICLE PARTS (USED) MERCHANT WHOLESALERS-42314',
        'NONMETALLIC MINERAL PRODUCTS MANUFACTURING-327',
        'OIL & GAS EXTRACTION-211',
        'OTHER COMMERCIAL & INDUSTRIAL MACHINERY & EQUIPMENT RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53249',
        'PAPER MANUFACTURING-322',
        'PETROLEUM & COAL PRODUCTS MANUFACTURING-324',
        'PETROLEUM & PETROLEUM PRODUCTS MERCHANT WHOLESALERS-4247',
        'PHOTOFINISHING LABORATORIES (except one hour)-812921',
        'PIPELINE TRANSPORTATION-486',
        'PLASTICS & RUBBER PRODUCTS MANUFACTURING-326',
        'PRIMARY METAL MANUFACTURING-331',
        'PRINTING & RELATED SUPPORT ACTIVITIES-323',
        'RAIL TRANSPORTATION-482',
        'RECYCLABLE MATERIAL MERCHANT WHOLESALER-42393',
        'RV (RECREATIONAL VEHICLES) PARKS & RECREATIONAL CAMPS (if fuel tanks are present or if vehicle repairs or maintenance is performed onsite)-7212',
        'SKIING FACILITIES-71392',
        'SUPPORT ACTIVITIES FOR MINING-213',
        'SUPPORT ACTIVITIES FOR TRANSPORTATION (if fuel tanks are present or if repairs or maintenance is performed on site)-488',
        'TESTING LABORATORIES-54138',
        'TEXTILE MILLS-313',
        'TEXTILE PRODUCT MILLS-314',
        'TRANSPORTATION EQUIPMENT MANUFACTURING-336',
        'TRUCK, UTILITY TRAILER, AND RV (RECREATIONAL VEHICLE) RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53212',
        'WASTE MANAGEMENT & REMEDIATION SERVICES-562',
        'WOOD PRODUCT MANUFACTURING (if finishing occurs on site)-321',
      ],
    },
    {
      type: 'checkbox',
      name: 'Q2',
      title:
        'Q2: Do any of the past and/or present uses of the Adjoining Properties involve environmentally sensitive industries? Please check all that apply.',
      isRequired: true,
      hasOther: true,
      colCount: 1,
      choices: [
        'NONE',
        'AIR TRANSPORTATION-481',
        'AUTOMOBILE & OTHER MOTOR VEHICLE MERCHANT WHOLESALERS (if service bays present)-42311',
        'AUTOMOTIVE REPAIR & MAINTENANCE-8111',
        'BEVERAGE & TOBACCO PRODUCT MANUFACTURING-312',
        'CHEMICAL & ALLIED PRODUCTS MERCHANT WHOLESALERS-4246',
        'CHEMICAL MANUFACTURING-325',
        'COMMERCIAL & INDUSTRIAL MACHINERY & EQUIPMENT REPAIR & MAINTENANCE-8113',
        'COMPUTER & ELECTRONIC PRODUCT MANUFACTURING (not required if assembly only)-334',
        'CONSTRUCTION, TRANSPORTATION, MINING & FORESTRY MACHINERY & EQUIPMENT RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53241',
        'DEATH CARE SERVICES-8122',
        'ELECTRICAL EQUIPMENT, APPLIANCE & COMPONENT MANUFACTURING (not required if assembly only)-335',
        'ELECTRONIC & PRECISION EQUIPMENT REPAIR & MAINTENANCE (not required if assembly only)-8112',
        'EXTERMINATING & PEST CONTROL-56171',
        'FABRICATED METAL PRODUCT MANUFACTURING-332',
        'FOOD MANUFACTURING (if underground fuel tanks present)-311',
        'FUEL DEALERS-45431',
        'FURNITURE & RELATED MANUFACTURING (if finishing occurs on site)-337',
        'GASOLINE STATIONS-447',
        'GENERAL MEDICAL & SURGICAL HOSPITALS (if fuel tanks are present)-6221',
        'GOLF COURSES & COUNTRY CLUBS-71391',
        'HEAVY & CIVIL ENGINEERING CONSTRUCTION-237',
        'LAUNDRY & DRY CLEANING SERVICES (if dry cleaning operations on-site)-8123',
        'LEATHER & ALLIED PRODUCT MANUFACTURING-316',
        'MACHINERY MANUFACTURING (not required if assembly only)-333',
        'MARINAS-71393',
        'METAL & MINERAL MERCHANT WHOLESALER-4235',
        'MINING (EXCEPT OIL & GAS)-212',
        'MISCELLANEOUS MANUFACTURING (only required if hazardous materials are involved)-339',
        'MOTOR VEHICLE AND PARTS DEALERS (if service bays present)-441',
        'MOTOR VEHICLE PARTS (USED) MERCHANT WHOLESALERS-42314',
        'NONMETALLIC MINERAL PRODUCTS MANUFACTURING-327',
        'OIL & GAS EXTRACTION-211',
        'OTHER COMMERCIAL & INDUSTRIAL MACHINERY & EQUIPMENT RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53249',
        'PAPER MANUFACTURING-322',
        'PETROLEUM & COAL PRODUCTS MANUFACTURING-324',
        'PETROLEUM & PETROLEUM PRODUCTS MERCHANT WHOLESALERS-4247',
        'PHOTOFINISHING LABORATORIES (except one hour)-812921',
        'PIPELINE TRANSPORTATION-486',
        'PLASTICS & RUBBER PRODUCTS MANUFACTURING-326',
        'PRIMARY METAL MANUFACTURING-331',
        'PRINTING & RELATED SUPPORT ACTIVITIES-323',
        'RAIL TRANSPORTATION-482',
        'RECYCLABLE MATERIAL MERCHANT WHOLESALER-42393',
        'RV (RECREATIONAL VEHICLES) PARKS & RECREATIONAL CAMPS (if fuel tanks are present or if vehicle repairs or maintenance is performed onsite)-7212',
        'SKIING FACILITIES-71392',
        'SUPPORT ACTIVITIES FOR MINING-213',
        'SUPPORT ACTIVITIES FOR TRANSPORTATION (if fuel tanks are present or if repairs or maintenance is performed on site)-488',
        'TESTING LABORATORIES-54138',
        'TEXTILE MILLS-313',
        'TEXTILE PRODUCT MILLS-314',
        'TRANSPORTATION EQUIPMENT MANUFACTURING-336',
        'TRUCK, UTILITY TRAILER, AND RV (RECREATIONAL VEHICLE) RENTAL & LEASING (if repairs, maintenance or vehicle washing are performed onsite)-53212',
        'WASTE MANAGEMENT & REMEDIATION SERVICES-562',
        'WOOD PRODUCT MANUFACTURING (if finishing occurs on site)-321',
      ],
    },
    {
      type: 'radiogroup',
      name: 'Q3',
      title:
        'Q3: Are there currently or do you have any prior knowledge that there have been any damaged or discarded automotive or industrial batteries, pesticides, paints, or other chemicals in individual containers of greater than 5 gallons (19 L) in volume or 50 gallons (190L) in the aggregate, stored on or used at the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q3E',
      title: 'Please Explain',
      visibleIf: '{Q3}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q4',
      title:
        'Q4: Are there currently or do you have any prior knowledge that there have been any industrial drums (typically 55 gal (208 L)) or sacks of chemicals located on the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q4E',
      title: 'Please Explain',
      visibleIf: '{Q4}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q5',
      title:
        'Q5: Did you observe evidence or do you have any prior knowledge that fill dirt has been brought onto the Subject Property that originated from a contaminated site or is of an unknown origin?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q5E',
      title: 'Please Explain',
      visibleIf: '{Q5}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q6',
      title:
        'Q6: Are there currently or do you have any prior knowledge, or did you observe that there have been any pits, ponds, or lagoons located on the Subject Property in connection with waste treatment or waste disposal?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q6E',
      title: 'Please Explain',
      visibleIf: '{Q6}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q7',
      title:
        'Q7: Is there currently or do you have any prior knowledge that there has been stained soil on the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q7E',
      title: 'Please Explain',
      visibleIf: '{Q7}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q8',
      title:
        'Q8: Are there currently or do you have any prior knowledge or did you observe that there has been any registered or unregistered storage tanks (above or underground) located on the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q8E',
      title: 'Please Explain',
      visibleIf: '{Q8}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q9',
      title:
        'Q9: Did you observe evidence or do you have any prior knowledge that there have been previously, any vent pipes, fill pipes, or access ways indicating a fill pipe protruding from the ground on the property or adjacent to any structure located on the property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q9E',
      title: 'Please Explain',
      visibleIf: '{Q9}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q10',
      title:
        'Q10: Is there currently or do you have any prior knowledge or did you observe that there has been evidence of leaks, spills, or staining by substances other than water, or foul odors, associated with any flooring, drains, walls, ceilings, or exposed grounds on the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q10E',
      title: 'Please Explain',
      visibleIf: '{Q10}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q11',
      title:
        'Q11: Is the Subject Property served currently or historically by a private well or non public water system?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q11E',
      title: 'Please Explain',
      visibleIf: '{Q11}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q12',
      title:
        'Q12: Is there currently or do you have any prior knowledge or did you observe that there has been evidence of a septic system?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q12E',
      title: 'Please Explain',
      visibleIf: '{Q12}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q13',
      title:
        'Q13: Does the owner or occupant of the Subject Property have any knowledge of environmental liens or governmental notification relating to past or recurrent violations of environmental laws or any past, threatened, or pending lawsuits, or administrative proceedings concerning a release or threatened release of any hazardous substance or petroleum products involving the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q13E',
      title: 'Please Explain',
      visibleIf: '{Q13}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q14',
      title:
        'Q14: Have you been informed of current or past existence of violations, hazardous substances, or petroleum products with respect to the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q14E',
      title: 'Please Explain',
      visibleIf: '{Q14}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q15',
      title:
        'Q15: Do you know of any past, threatened, or pending lawsuits or administrative proceedings concerning a release or threatened release of any hazardous substance or petroleum products involving the property by any owner or occupant of the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q15E',
      title: 'Please Explain',
      visibleIf: '{Q15}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q16',
      title:
        'Q16: Does the discharge from the Subject Property of waste water (not including sanitary waste or storm water) onto or adjacent to the Subject Property and/or into a storm water or sanitary waste system?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q16E',
      title: 'Please Explain',
      visibleIf: '{Q16}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q17',
      title:
        'Q17: Did you observe evidence or do you have any prior knowledge that any hazardous substances or petroleum products, unidentified waste materials, tires, automotive or industrial batteries or any other waste materials have been dumped above grade, buried, and/or burned on the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q17E',
      title: 'Please Explain',
      visibleIf: '{Q17}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q18',
      title:
        'Q18: Is there a transformer, capacitor, or any hydraulic equipment for which there are any records, indicating the presence of PCBs?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q18E',
      title: 'Please Explain',
      visibleIf: '{Q18}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q19',
      title: 'Q19: Do you have knowledge of any Environmental Site Assessment of the Subject Property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q19E',
      title: 'Please Explain',
      visibleIf: '{Q19}=\'Yes\'',
      isRequired: true,
    },
  ],
};

export let UserQuestionnaireJSON = {
  title: 'User Questionnaire',
  description:
    'The User Questionnaire must be completed by the User the party seeking to use Practice E1527 to complete an environmental site assessment of the property. A User may include, without limitation, a potential purchaser of the property, a potential tenant of the property, an owner of the property, a lender, or a property manager. When the User of the Phase 1 Environmental Site Assessment is required to help the environmental professional identify recognized environmental conditions at the property, a \'User Questionnaire\' is completed by the User to help gather information that may identify recognized environmental conditions at the property. Please answer the questions below to the best of your knowledge. We encourage you to complete and return the questionnaire as soon as possible, which will allow us to reflect the fact that the User Questionnaire was completed when we issue our report. Failure to complete the User Questionnaire can result in a finding of a Data Gap. The second part of this survey questions (Q7-Q20) are related to documentation. The E1527-13 Standard requires that the User will ensure that the consultant is made aware that any of these materials exist for a site, and if so, that these documents be provided for the consultant\'s review. Please indicate whether any of these documents are available and that the environmental professional will either receive copies or be provided an opportunity to review the relevant materials.',
  showQuestionNumbers: 'off',
  questions: [
    {
      type: 'panel',
      name: 'property_information',
      elements: [
        {
          type: 'text',
          name: 'property_name',
          title: 'Subject Property Name:',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'property_address',
          title: 'Subject Property Address:',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'property_history',
          title: 'Please describe the current and historical use of the subject property:',
          isRequired: true,
        },
      ],
      title: 'Subject Property Information:',
    },
    {
      type: 'panel',
      name: 'ksm_information',
      elements: [
        {
          type: 'text',
          name: 'name_user',
          title:
            'The party (Entity or Person) seeking to use Practice E1527 to complete an environmental site assessment of the property.',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'user_rep',
          title:
            'The authorized representative of the User. The person completing this \'User Questionnaire\' affirms that they are authorized by the User, and the questionnaire has been completed to the best of their knowledge. ',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'cell_ksm',
          title: 'User cell number',
          inputType: 'tel',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'email_ksm',
          title: 'User Email',
          inputType: 'email',
          isRequired: true,
        },
      ],
      title: 'User Information',
    },

    {
      type: 'radiogroup',
      name: 'Q1',
      title:
        'Q1: Are you aware of any environmental cleanup liens against the property that are filed or recorded under federal, tribal state or local law?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q1E',
      title: 'Please Explain',
      visibleIf: '{Q1}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q2',
      title:
        'Q2: Are you aware of any Activity and Use Limitations (AULs), such as engineering controls, land use restrictions or institutional controls that are in place at the site or have been filed or recorded in a registry under federal, tribal, state or local law?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q2E',
      title: 'Please Explain',
      visibleIf: '{Q2}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q3',
      title:
        'Q3: As the user of this ESA do you have any specialized knowledge or experience related to the property or nearby properties? For example, are you involved in the same line of business as the current or former occupants of the property or an adjoining property so that you would have specialized knowledge of the chemicals and processes used by this type of business?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q3E',
      title: 'Please Explain',
      visibleIf: '{Q3}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q4',
      title:
        'Q4: Does the purchase price/loan amount for this property reasonably reflect the fair market value of the property? If you conclude that there is a difference, have you considered whether the lower purchase price is because contamination is known or believed to be present at the property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q4E',
      title: 'Please Explain',
      visibleIf: '{Q4}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q5',
      title:
        'Q5: Are you aware of commonly known or reasonably ascertainable information about the property that would help the environmental professional to identify conditions indicative of releases or threatened releases? For example, as User: (a.) Do you know the past uses of the property? (b.) Do you know of specific chemicals that are present of once were present at the property? (c.) Do you know of spills or other chemical releases that have taken place at the property? (d.) Do you know of any environmental cleanups that have taken place at the property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q5E',
      title: 'Please Explain',
      visibleIf: '{Q5}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q6',
      title:
        'Q6: As the User of this ESA, based on your knowledge and experience related to the property are there any obvious indicators that point to the presence or likely presence of contamination at the property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q6E',
      title: 'Please Explain',
      visibleIf: '{Q6}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q7',
      title: 'Q7: Are you aware of any previous environmental site assessment reports?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q7E',
      title: 'Please Explain',
      visibleIf: '{Q7}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q8',
      title: 'Q8: Are you aware of any environmental compliance audit reports?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q8E',
      title: 'Please Explain',
      visibleIf: '{Q8}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q9',
      title:
        'Q9: Are you aware of any environmental permits (including but not limited to solid waste disposal permits, hazardous waste disposal permits, wastewater permits, NPDES permits, underground injection permits?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q9E',
      title: 'Please Explain',
      visibleIf: '{Q9}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q10',
      title: 'Q10: Are you aware of any registrations for underground and aboveground storage tanks?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q10E',
      title: 'Please Explain',
      visibleIf: '{Q10}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q11',
      title: 'Q11: Are you aware of any registrations for underground injection systems?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q11E',
      title: 'Please Explain',
      visibleIf: '{Q11}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q12',
      title: 'Q12: Are you aware of any Material Safety Data Sheets (MSDS)?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q12E',
      title: 'Please Explain',
      visibleIf: '{Q12}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q13',
      title: 'Q13: Are you aware of a Community Right-to-Know Plan?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q13E',
      title: 'Please Explain',
      visibleIf: '{Q13}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q14',
      title:
        'Q14: Are you aware of any safety plans; preparedness and prevention plans; spill prevention, countermeasure, and control plans; etc.?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q14E',
      title: 'Please Explain',
      visibleIf: '{Q14}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q15',
      title:
        'Q15: Are you aware of any reports regarding hydrogeologic conditions on the property or surrounding area?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q15E',
      title: 'Please Explain',
      visibleIf: '{Q15}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q16',
      title:
        'Q16: Are you aware of any notices or other correspondence from any government agency relating to past or current violations of environmental laws with respect to the property or relating to environmental liens encumbering the property?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q16E',
      title: 'Please Explain',
      visibleIf: '{Q16}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q17',
      title: 'Q17: Are you aware of any hazardous waste generator notices or reports?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q17E',
      title: 'Please Explain',
      visibleIf: '{Q17}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q18',
      title: 'Q18: Are you aware of any geotechnical studies?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q18E',
      title: 'Please Explain',
      visibleIf: '{Q18}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q19',
      title: 'Q19: Are you aware of any risk assessments?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q19E',
      title: 'Please Explain',
      visibleIf: '{Q19}=\'Yes\'',
      isRequired: true,
    },
    {
      type: 'radiogroup',
      name: 'Q20',
      title: 'Q20: Are you aware of any Activity and Use Limitations (AULs)?',
      isRequired: true,
      choices: ['Yes', 'No'],
      colCount: 0,
    },
    {
      type: 'text',
      name: 'Q20E',
      title: 'Please Explain',
      visibleIf: '{Q20}=\'Yes\'',
      isRequired: true,
    },
  ],
};

const defaultSurveyCSS = {};
const defaultSurveyDATA = {};

const defaultSurveyJSON = EnvironmentalQuestionnaireJSON;

const defaultSurveyConfig = {
  defaultSurveyJSON,
  defaultSurveyCSS,
  defaultSurveyDATA
};

export default defaultSurveyConfig;
