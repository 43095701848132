import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner
} from 'react-bootstrap';
import { AlertTriangle, Plus, Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IProjectContext } from '../../context/project/project-context';
import withProjectContext from '../../context/project/ProjectConsumer';
import { IJobType, IProject } from '../../context/project/types';
import ProjectSwitcherLineItem from './ProjectSwitcherLineItem';

const ProjectSwitcherHeader = styled(Modal.Header)`
  && {
    padding: 2rem 1.5rem;
    flex-direction: column;
  }
`;

const ProjectSwitcherTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100%;
`;
const NewProjectButton = styled(Button)`
  && {
    padding: 0.375rem 2rem;
  }
`;

const ProjectSwitcherFooter = styled(Modal.Footer)`
  && {
    margin-top: 100px;
    border: none;
    justify-content: center;
    span {
      margin-left: 10px;
      vertical-align: middle;
      line-height: 24px;
      height: 24px;
      display: inline-block;
    }
  }
`;

const ProjectSwitcherBody = styled(Modal.Body)`
  && {
    display: flex;
    min-height: 350px;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
`;

const SearchButton = styled(Button)`
  && {
    line-height: 0;
    width: 80px;
  }
`;

const SearchBarInputContainer = styled.div`
  margin-top: 35px;
  min-width: 100%;
`;

const LoadingSpinner = styled(Spinner)`
  && {
    align-self: center;
  }
`;

const ErrorContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    color: #6c757d;
    color: var(--secondary);
  }
  span {
    margin-top: 10px;
  }
`;

interface IProjectSwitcherState {
  projects: IProject[];
  isLoading: boolean;
  error: string;
}

interface IProjectSwitcherProps {
  show: boolean;
  handleClose: () => void;
  onNewProjectClick: () => void;
}

const ProjectSwitcher: React.FC<
  IProjectSwitcherProps & { context: IProjectContext }
> = props => {
  const context = props.context;
  const [state, setState] = useState<IProjectSwitcherState>({
    error: '',
    isLoading: true,
    projects: []
  });
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (!props.show) {
      return;
    }
    const fetchRecentProjects = () => {
      setState({
        error: '',
        isLoading: true,
        projects: []
      });
      return context
        .getRecentProjects()
        .then(result => {
          setState({ projects: result, isLoading: false, error: '' });
        })
        .catch(err => {
          return setState({
            error: err.message,
            isLoading: false,
            projects: []
          });
        });
    };
    fetchRecentProjects();
  }, [props, context]); // Added props to the useEffect deps, to trigger fetch everytime the modal opens.
  return (
    <Modal
      dialogClassName="ceres--project-switcher"
      size="lg"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <ProjectSwitcherHeader>
        <ProjectSwitcherTitleContainer>
          <Modal.Title>Select a project</Modal.Title>
          <NewProjectButton variant="primary" onClick={props.onNewProjectClick}>
            New Project
          </NewProjectButton>
        </ProjectSwitcherTitleContainer>
        <SearchBarInputContainer>
          <Form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              setState({
                ...state,
                isLoading: true
              });
              return context
                .searchProject(searchText)
                .then(result => {
                  return setState({
                    error: '',
                    isLoading: false,
                    projects: result
                  });
                })
                .catch(err => {
                  return setState({
                    error: err.message,
                    isLoading: false,
                    projects: []
                  });
                });
            }}
          >
            <InputGroup>
              <FormControl
                onChange={(e: any) => setSearchText(e.target.value)}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                required
              />
              <InputGroup.Append>
                <SearchButton type="submit" variant="outline-secondary">
                  <Search />
                </SearchButton>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </SearchBarInputContainer>
      </ProjectSwitcherHeader>
      <ProjectSwitcherBody>
        {state.isLoading && (
          <LoadingSpinner variant="primary" animation="border" />
        )}
        {!state.isLoading &&
          state.projects.length > 0 &&
          state.projects.map((project, index) => {
            return (
              <ProjectSwitcherLineItem
                onJobTypeSelect={(jobType: IJobType) => {
                  context.setActiveProjectAndJobType(project, jobType);
                  toast.success(
                    `${project.name} - ${jobType.type} is now active`
                  );
                  return props.handleClose();
                }}
                key={project.id}
                projectName={project.name}
                projectId={project.id}
                jobTypes={project.jobTypes}
              />
            );
          })}
        {!state.isLoading && state.projects.length <= 0 && (
          <ErrorContainer>
            <AlertTriangle />
            <span>{state.error || `No projects to show.`}</span>
          </ErrorContainer>
        )}
      </ProjectSwitcherBody>
      <ProjectSwitcherFooter>
        <Link to="/projects">
          <Plus />
          <span>Show All</span>
        </Link>
      </ProjectSwitcherFooter>
    </Modal>
  );
};

export default withProjectContext(ProjectSwitcher);
