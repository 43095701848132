import React, {useState} from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Spinner
} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Search, MapPin, BarChart2, FileText, Bell, Cloud } from 'react-feather';
import withAuthContext from '../../context/auth/AuthConsumer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAuthContext } from '../../context/auth/auth-context';
import { CeresStripePayments } from '../../services/stripe';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TIERS } from '../../types/tiers';
import { toast } from 'react-toastify';
import mediaQuery from '../../util/media-query';

const StorerWrapper = styled.div`
  margin: 30px 15px;
  width: 100%;
`;
const StoreList = styled.div`
  border: 8px solid #bbbbbb;
  min-height: calc(85vh - 120px);
  background-color: var(--primary);
  padding: 40px;
  ${mediaQuery.tablet`
    padding: 15px;
  `}
`;
const BtnContainer = styled.div`
  text-align: center;
  padding: 10%;
  ${mediaQuery.tablet`
    padding: 0 0 40px;
  `}
`;
const ButtonLink = styled(Link)`
  text-transform: uppercase;
  font-size: 20px;
  border: 6px solid #bbbbbb;
  padding: 10px 10px;
  background-color: #fff;
  font-weight: 500;
  color: #495057 !important;
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  && span {
    margin-left: 15px;
    vertical-align: text-top;
  }
  &:hover {
    text-decoration: none;
  }
`;
const PlainText = styled.p`
  font-size: 22px;
  color: #e2dede;
  margin-top: 15px;
  line-height: 26px;
`;
// const AlertBar = styled.div`
//   display: block;
//   background-color: #747aec;
//   color: #fff;
//   padding: 6px 15px;
//   font-size: 20px;
//   text-align: center;
//   margin: 40px auto 0;
//   width: 80%;
// `;
// const AlertLink = styled(Link)`
//   border: 1px solid #fff;
//   padding: 4px 20px;
//   display: inline-block;
//   text-align: center;
//   border-radius: 4px;
//   cursor: pointer;
//   margin-left: 20px;
//   color: #fff;
//   &:hover {
//     text-decoration: none;
//     color: #fff;
//   }
// `;
const StorePage = styled.div`
  border: 8px solid var(--primary);
  min-height: calc(85vh - 120px);
  background-color: #7e7e7e;
  padding: 40px;
`;
const ExamplePage = styled.div`

`;
const GifPage = styled.div`
  margin-bottom: 30px;
`;
const ExampleNote = styled.h3`
  font-size: 28px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 0;
`;
const STable = styled(Table)`
  border: 2px solid #202020;
`;
const TH = styled.th`
  border: 1px solid #202020 !important;
  border-bottom: 1px solid #202020 !important;
  background-color: var(--primary);
  color: #202020;
  text-align: center;
  vertical-align: middle !important;
  font-size: 20px;
  line-height: 20px;
`;
const TD = styled.td`
  border: 1px solid #202020 !important;
  background-color: var(--primary);
  vertical-align: middle !important;
  a{
    color: #0000FF;
    text-decoration: underline;
    font-size: 20px;
  }
  a:hover{
    color: #0000FF;
    text-decoration: underline;
  }
`;
const TableHeading = styled.h2`
  font-weight: 700;
  color: var(--primary);
  font-size: 40px;
  padding: 40px 0 20px;
  text-align: center;
`;
const PurchaseNow = styled(Button)`
  color: #0000FF !important;
  text-decoration: underline;
  font-size: 20px;

  background-color: transparent;
  border: 0;
  &:hover{
    background-color: transparent;
    border: 0;
    text-decoration: underline;
  }
`;
const Cols = styled(Col)`
  display: flex;
  justify-content: center;
  align-self: center;
`;
const PlatinumAccess = styled.div`
  text-align: center;
  background-color: #747aec;
  padding: 20px 15px;
  && h2 {
    color: #fff;
    font-size: 40px;
  }
  && h4 {
    font-size: 30px;
  }
  && p {
    color: #fff;
    font-size: 12px;
  }
  && a{
    color: #fff;
  }
`;
const PlatinumTable = styled.div`
  max-width: 500px;
  && td {
    text-align: center;
    color: #fff;
    background-color: #585858;
  }
`;
const Tul =styled.ul`

`;
const Tli =styled.li`

`;

const Store: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = props => {
  const [isBasicLoading, setBasicLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const authContext = props.context;
  const warnAndRedirectToLogin = () => {
    toast.warn(`Please login to purchase any subscription.`);
    return props.history.push('/login');
  };
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  const GifPlayer = require('react-gif-player');
  const storeLink = 'https://storage.googleapis.com/ceres-maps/ceres_store/';
  return (
    <StorerWrapper>
      {((props.history.location.pathname === '/store') || props.history.location.pathname === '/store/') && (
      <StoreList>
        <Row>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/search"><Search size={34} /> <span>Search</span></ButtonLink>
              <PlainText>It’s “google” for environmental</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/ai-earth"><MapPin size={34} /> <span>Ai Earth</span></ButtonLink>
              <PlainText>Mapping of environmental data <br/>and compliance information</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/benchmarking"><BarChart2 size={34} /> <span>Benchmarking</span></ButtonLink>
              <PlainText>Benchmarks company <br/>environmental performance</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/validator"><FileText size={34} /> <span>Validator</span></ButtonLink>
              <PlainText>Reviews Phase 1 Environmental <br/>Assessment Reports</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/generator">
                <img src="/icon/generator.png" alt="Generator" width="40" />
                <span>Generator</span>
              </ButtonLink>
              <PlainText>Identifies potential emissions <br/>reduction opportunities</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/notifications"><Bell size={34} /> <span>Notifications</span></ButtonLink>
              <PlainText>Notifications for new agency filings</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/ai-air"><Cloud size={34} /> <span>AI Air</span></ButtonLink>
              <PlainText>Utilizes AI for map analyses of air pollution and sources</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/intelligent-emis">
                <img src="/icon/intelligent-emis.png" alt="EMIS" width="50" />
                <span>Intelligent EMIS</span>
              </ButtonLink>
              <PlainText>Environmental Management Information System utilizing AI and external data</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/suggester" style={{paddingLeft: '30px'}}>
                <img
                  src="/icon/suggester.png"
                  alt="Suggester"
                  width="40"
                />
                <span>“Suggester” for Corrective Actions</span>
              </ButtonLink>
              <PlainText>Suggests corrective actions for violations and deviations</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/social">
                <img src="/icon/social.png" alt="Social" width="50" />
                <span>EnviroAI Social</span>
              </ButtonLink>
              <PlainText>Environmental social-media tracker</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/drone-app">
                <img src="/icon/drone-app.png" alt="Drone App" width="70" />
                <span>EnviroAI Drone App</span>
              </ButtonLink>
              <PlainText>Automated drone with artificial intelligence data analysis</PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/satellite-app">
                <img src="/icon/satellite-app.png" alt="Satellite App" width="40" />
                <span>EnviroAI Satellite App</span>
              </ButtonLink>
              <PlainText>
                Schedule satellite flight/image with AI object detection and environmental data overlay
              </PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/action-tracker" style={{padding: '10px'}}>
                <img src="/icon/action-tracker.png" alt="Satellite App" width="220" />
                <span></span>
              </ButtonLink>
              <PlainText>
                Tracks the status of corrective actions for violations, deviations, disclosures, consent decrees
              </PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/atmosfir" style={{padding: '10px'}}>
              <img src="/icon/atmosfir.jpg" alt="Atmosfir" width="60" />
                <span>Fenceline Open-Path FTIR System </span>
              </ButtonLink>
              <PlainText>
                Detects fenceline pollution levels in the ppb range for multiple pollutants using open-path FTIR
              </PlainText>
            </BtnContainer>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <BtnContainer>
              <ButtonLink to="/store/optical-gas" style={{paddingLeft: '20px'}}>
                <img src="/icon/camera.jpg" alt="Camera" width="45" />
                <span>Optical Gas Imaging System</span>
              </ButtonLink>
              <PlainText>
                Fixed camera system (pan-tilt-zoom) detects pollution using thermal imaging and AI object detection
              </PlainText>
            </BtnContainer>
          </Col>
        </Row>
      </StoreList>
      )}
      {(props.history.location.pathname === '/store/search') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'search.gif'}
                  still={storeLink + 'search.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
              <ExampleNote>
                ---“Search more environmental data and get faster, more relevant search results
                . . . without the advertisements.”
              </ExampleNote>
            </Col>
            <Col md={6}>
            <TableHeading>
              Prices start at $17.95 per month
            </TableHeading>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <TD className="text-center">
                      <h4>TCEQ Search Engine</h4>
                    </TD>
                    <TD>Uses artificial intelligence for more relevant search results of TCEQ online
                      files and adjusted user interface</TD>
                    <TD className="text-center"><h4>$17.95</h4></TD>
                    <TD className="text-center">
                      <PurchaseNow
                        onClick={() => {
                          if (!authContext.isAuthenticated) {
                            return warnAndRedirectToLogin();
                          }
                          if (authContext.isTier(TIERS.BASIC) && !authContext.isLubrizol()) {
                            return toast.warn(`You are already a BASIC subscriber`);
                          }
                          setBasicLoading(true);
                          CeresStripePayments.doCheckout(
                            'BASIC20200812',
                            authContext.getUserEmail()
                          )
                            .then(
                              ({ error }: { error: any }) =>
                                error && toast.error(error.message)
                            )
                            .catch((error: { message: string }) =>
                              toast.error(error.message)
                            )
                            .finally(() => setBasicLoading(false));
                        }}
                      >
                        {!isBasicLoading && (
                          <span>
                            Purchase now
                          </span>
                        )}
                        {isBasicLoading && (
                          <Spinner variant="light" animation="border" size="sm" />
                        )}
                      </PurchaseNow>
                    </TD>
                  </tr>
                  {/* <tr>
                    <TD className="text-center">
                      <h4>TCEQ Search Engine</h4>
                    </TD>
                    <TD>Uses artificial intelligence for more relevant search results of TCEQ online
                      files and adjusted user interface</TD>
                    <TD className="text-center"><h5>$179</h5></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr> */}
                  <tr>
                    <TD className="text-center">
                      <h4>EnviroAI Search Engine</h4>
                    </TD>
                    <TD>TCEQ Search and Internet Search with additional TCEQ data--
                      plus EPA data, social media, legal, news & other data
                      categories and advanced filtering capabilities.</TD>
                    <TD className="text-center"><h5>$595</h5></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Col>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/ai-earth') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'ai-earth.gif'}
                  still={storeLink + 'ai-earth.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>AI Earth</h4>
                    </TD>
                    <TD>Map-based geospatial data and interactive regulatory/environmental information interface.</TD>
                    <TD className="text-center"><h4>$1,875</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/benchmarking') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'benchmarking.gif'}
                  still={storeLink + 'benchmarking.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Benchmarking</h4>
                    </TD>
                    <TD>Benchmarks various environmental metrics for companies and facilities.</TD>
                    <TD className="text-center"><h4>$125</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/validator') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'validator.gif'}
                  still={storeLink + 'validator.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Validator</h4>
                    </TD>
                    <TD>
                      Evaluates Phase 1 environmental reports and whether the reports meet certain ASTM standards.
                    </TD>
                    <TD className="text-center"><h4>$375</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/generator') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'generator.gif'}
                  still={storeLink + 'generator.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Generator</h4>
                    </TD>
                    <TD>Identifies potential emissions reduction and credit opportunities.</TD>
                    <TD className="text-center"><h4>$350</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/notifications') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'notifications.gif'}
                  still={storeLink + 'notifications.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Notifications</h4>
                    </TD>
                    <TD>Notifications of TCEQ filings based on company or key words.</TD>
                    <TD className="text-center"><h4>$125</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/ai-air') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'ai-air.gif'}
                  still={storeLink + 'ai-air.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>AI Air</h4>
                    </TD>
                    <TD>Utilizes AI for map analyses focusing on facilities that emit air pollutants.</TD>
                    <TD className="text-center"><h4>$1,495</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/intelligent-emis') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={storeLink + 'intelligent-emis-01.jpg'}
                      alt="intelligent-emis"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={storeLink + 'intelligent-emis-02.jpg'}
                      alt="intelligent-emis"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={storeLink + 'intelligent-emis-03.jpg'}
                      alt="intelligent-emis"
                    />
                  </Carousel.Item>
                </Carousel>
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Intelligent EMIS</h4>
                    </TD>
                    <TD>Environmental Management Information System that utilizes AI and connects directly with
                      the external data power in EnviroAI.</TD>
                    <TD className="text-center"><h4>$2,500*</h4>Prices start at this amount (based on the size and
                    complexity of the facility). Customized set-up fee also applies.</TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/suggester') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage></ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Suggester</h4>
                    </TD>
                    <TD>Type in an environmental violation, deviation or problem . . . and “suggester” provides you
                      a list of corrective actions that other companies have employed.</TD>
                    <TD className="text-center"><h5>$250 per search or $950 per month for unlimited search.</h5></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/social') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={storeLink + 'enviroAI_social_01.jpg'}
                      alt="EnviroAI Social"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={storeLink + 'enviroAI_social_02.jpg'}
                      alt="EnviroAI Social"
                    />
                  </Carousel.Item>
                </Carousel>
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>EnviroAI Social</h4>
                    </TD>
                    <TD>Environmental social-media tracker.</TD>
                    <TD className="text-center"><h4>$35</h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/drone-app') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <GifPage>
                    <GifPlayer
                      gif={storeLink + 'drone_01.gif'}
                      still={storeLink + 'drone_01.jpg'}
                      autoplay={true}
                      width="100%"
                    />
                  </GifPage>
                </Col>
                <Col md={6}>
                  <GifPage>
                    <GifPlayer
                      gif={storeLink + 'drone_02.gif'}
                      still={storeLink + 'drone_02.jpg'}
                      autoplay={true}
                      width="100%"
                    />
                  </GifPage>
                </Col>
                <Col md={6}>
                  <GifPage>
                    <GifPlayer
                      gif={storeLink + 'drone_03.gif'}
                      still={storeLink + 'drone_03.jpg'}
                      autoplay={true}
                      width="100%"
                    />
                  </GifPage>
                </Col>
                <Col md={6}>
                  <GifPage>
                    <GifPlayer
                      gif={storeLink + 'drone_04.gif'}
                      still={storeLink + 'drone_04.jpg'}
                      autoplay={true}
                      width="100%"
                    />
                  </GifPage>
                </Col>
              </Row>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>EnviroAI Drone App</h4>
                    </TD>
                    <TD>Automated drone and automated docking station with artificial intelligence data analysis:
                      <Tul>
                        <Tli>ENVIRONMENTALCOMPLIANCE ASSISTANCE </Tli>
                        <Tli>ENVIRONMENTAL INSPECTIONS</Tli>
                        <Tli>ENVIRONMENTAL MONITORING</Tli>
                        <Tli>ENVIRONMENTAL RESPONSE</Tli>
                      </Tul>
                    </TD>
                    <TD className="text-center"><h4>$10,825*</h4>Prices start at this amount. Hardware set-up fee
                    also applies.</TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/satellite-app') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <Row>
                  <Col md={4}>
                    <GifPage>
                      <GifPlayer
                        gif={storeLink + 'satellite.gif'}
                        still={storeLink + 'satellite.jpg'}
                        autoplay={true}
                        width="100%"
                      />
                    </GifPage>
                  </Col>
                  <Col md={8}>
                    <GifPage>
                      <GifPlayer
                        gif={storeLink + 'satellite_2.gif'}
                        still={storeLink + 'satellite_2.jpg'}
                        autoplay={true}
                        width="100%"
                      />
                    </GifPage>
                  </Col>
                </Row>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>EnviroAI Satellite App</h4>
                    </TD>
                    <TD>Schedule a satellite flight/ image.  Images processed with AI to identify certain environmental
                      objects.  Environmental regulatory and data overlays also provided.</TD>
                    <TD className="text-center"><h4>$255*</h4>Prices start at this amount.</TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/action-tracker') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage></ExamplePage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Corrective Action Tracker</h4>
                    </TD>
                    <TD>Tracks:
                      <Tul>
                        <Tli>Title V Deviations </Tli>
                        <Tli>Audit Disclosures </Tli>
                        <Tli>Consent Decrees </Tli>
                        <Tli>MACT Deviations </Tli>
                        <Tli>Agreed Orders </Tli>
                        <Tli>NOVs/NOEs and Investigative Actions</Tli>
                      </Tul>
                    </TD>
                    <TD className="text-center"><h4>$1,450 </h4></TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/atmosfir') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <GifPage>
                <GifPlayer
                  gif={storeLink + 'atmosfir.gif'}
                  still={storeLink + 'atmosfir.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </GifPage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '20%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '15%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '15%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <a
                        href="https://www.atmosfir.net/d-fenceline1"
                        target="_blank"
                        rel="noopener noreferrer">
                        The D-fencelineTM System
                      </a>
                    </TD>
                    <TD>Advanced, High Technology Real Time Fenceline Air Monitoring System for continuosly measuring
                      and identifying emissions 24/7 with unprecedented detection limits (ppb) along the fence line of
                      industrial facilities. The technology is based on Open Path FTIR and Official US EPA method TO-16.
                      The system can simultaneously detect multiple emitted materials including BTEX, 1,3-Butadiene,
                      Ethylene Oxide, VOCs and more.</TD>
                    <TD className="text-center"><h4>$49,000* </h4>Prices start at this amount.</TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/optical-gas') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <GifPage>
                <GifPlayer
                  gif={storeLink + 'optical-gas.gif'}
                  still={storeLink + 'optical-gas.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </GifPage>
            </Col>
            <Cols md={6}>
              <STable striped bordered hover responsive >
                <thead>
                  <tr>
                    <TH style={{width: '25%'}}>Products</TH>
                    <TH>Description</TH>
                    <TH style={{width: '20%'}}>Price <br/><small>(per user per month)</small></TH>
                    <TH style={{width: '20%'}}></TH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TD className="text-center">
                      <h4>Optical Gas Imaging System</h4>
                    </TD>
                    <TD>
                      24/7 emissions monitoring using fixed infrared camera installation (pan-tilt-zoom);
                       400+ Hydrocarbons, VOC Gases & CO2;
                       artificial intelligence object detection to recognize emissions anomalies.
                    </TD>
                    <TD className="text-center"><h4>$25,250* </h4>Prices start at this amount.</TD>
                    <TD className="text-center">
                      <a href="mailto:validator@enviro.ai">Contact Us</a>
                    </TD>
                  </tr>
                </tbody>
              </STable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {(props.history.location.pathname === '/store/platinum-access') && (
        <StorePage>
          <Row>
            <Col md={6}>
              <ExamplePage>
                <GifPlayer
                  gif={storeLink + 'platinum.gif'}
                  still={storeLink + 'platinum.jpg'}
                  autoplay={true}
                  width="100%"
                />
              </ExamplePage>
            </Col>
            <Cols md={6}>
              <PlatinumTable>
                <STable striped bordered hover >
                  <thead>
                    <tr>
                      <PlatinumAccess>
                        <h2>Enterprise Platinum Subscription</h2>
                        <h4>$7500 per month</h4>
                        <p>(all features, multiple users, custom development)</p>
                        <a href="mailto:validator@enviro.ai">Contact Us</a>
                      </PlatinumAccess>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Company-wide/organizational-wide access (multi-user)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Access to all features
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Access to new features/products as added
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Customized feature requests
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Directed product development
                      </td>
                    </tr>
                  </tbody>
                </STable>
              </PlatinumTable>
            </Cols>
          </Row>
        </StorePage>
      )}
      {/* <AlertBar>
        1 week trial available
        <AlertLink to="/trial-request" className="btn-deafult">here</AlertLink>
      </AlertBar> */}
    </StorerWrapper>
  );
};
export default withRouter(withAuthContext(Store));
