import React from 'react';
import ProjectContext from './project-context';

const withProjectContext = (
  Component: React.ComponentClass<any> | React.FunctionComponent<any>
) => {
  return (props: any) => (
    <ProjectContext.Consumer>
      {context => <Component {...props} context={context} />}
    </ProjectContext.Consumer>
  );
};

export default withProjectContext;
