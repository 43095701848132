import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import withAuthContext from './context/auth/AuthConsumer';
import { IAuthContext } from './context/auth/auth-context';
import { TIERS } from './types/tiers';
import { CeresLogger } from './logger';
import * as R from 'ramda';

const ProtectedRoute: React.FunctionComponent<
  RouteProps & { tiers: TIERS[]; context: IAuthContext }
> = routeProps => {
  const { component, tiers = [], context, ...rest } = routeProps;
  // const locationState = (rest.location && rest.location.state) || {};
  const Component: any = component;
  return (
    <Route
      {...rest}
      render={props => {
        if (!context.isAuthenticated) {
          CeresLogger.info({
            message: `No authenticated context found, redirecting to login page`
          });
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          );
        }
        if (tiers.length <= 0) {
          return <Component {...props} />;
        }
        // Check if the configured route's tier list matches with logged in user's tier
        if (context.isLubrizol() || R.any((tierItem: TIERS) => context.isTier(tierItem), tiers)) {
          return <Component {...props} />;
        }
        CeresLogger.info({
          message: `Redirecting user back to login screen, since the user tier does not have permission`,
          payload: {
            tiers,
            userTier: context.getUserTier(),
            userId: context.getUserId()
          }
        });
        // Old implementation.
        // Uncomment below if something goes wrong.
        // if (
        //   (!!userId || !!locationState.userId) &&
        //   tiers.filter(c => context.isTier(c)).length > 0
        // ) {
        //   return <Component {...props} />;
        // }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default withAuthContext(ProtectedRoute);
