import * as R from 'ramda';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import FormControl from '@material-ui/core/FormControl';
import { IJob } from '../../store/aiearth/types';
import ListItemText from '@material-ui/core/ListItemText';
import MUIButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import Select from '@material-ui/core/Select';
import { Table } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { updateJob } from '../../store/aiearth/action';

const AdjoiningPropForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  & * {
    margin-left: 2px;
  }
`;

const JobButton = styled(MUIButton)`
  margin: 0.2rem !important;
`;

const CustomFormControl = styled(FormControl)`
  width: 100%;
    border-radius: 4px;
`;

const JobsAdjoiningProperties: React.FC<any> = (props) => {
  const jobAdjoiningPropsInitial = {
    id: null,
    name: '',
    address: '',
    label: '',
    elevation: '',
    relativeGradient: '',
    currentUse: '',
    geojson: {},
  };

  const [jobAdjoiningProps, setJobAdjoiningProps] = useState(
    jobAdjoiningPropsInitial
  );
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;

  // useEffect(() => {
  //   if (!fetchFlag) {
  //     fetchJobs();
  //     setFetchFlag(true);
  //   }
  //   dispatch(
  //     updateJob({
  //       ...jobState,
  //       ...{ jobJson },
  //     })
  //   );
  //   // eslint-disable-next-line
  // }, [jobJson]);

  return (
    <>
      <AdjoiningPropForm>
        <Row>
          <TextField
            label="Name"
            autoFocus
            id="name"
            variant="outlined"
            size="small"
            value={jobAdjoiningProps.name}
            onChange={(e: any) => {
              setJobAdjoiningProps({
                ...jobAdjoiningProps,
                name: e.target.value,
              });
            }}
          />
          <TextField
            id="label"
            label="Label"
            variant="outlined"
            size="small"
            value={jobAdjoiningProps.label}
            onChange={(e: any) => {
              setJobAdjoiningProps({
                ...jobAdjoiningProps,
                label: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            label="Address"
            id="address"
            variant="outlined"
            fullWidth
            size="small"
            value={jobAdjoiningProps.address}
            onChange={(e: any) => {
              setJobAdjoiningProps({
                ...jobAdjoiningProps,
                address: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            id="elevation"
            label="Elevation"
            variant="outlined"
            size="small"
            value={jobAdjoiningProps.elevation}
            onChange={(e: any) => {
              setJobAdjoiningProps({
                ...jobAdjoiningProps,
                elevation: e.target.value,
              });
            }}
          />
          <TextField
            id="currentUse"
            label="Current Use"
            variant="outlined"
            size="small"
            value={jobAdjoiningProps.currentUse}
            onChange={(e: any) => {
              setJobAdjoiningProps({
                ...jobAdjoiningProps,
                currentUse: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <CustomFormControl size="small" variant="outlined">
            <Select
              value={jobAdjoiningProps.relativeGradient}
              onChange={(e: any) => {
                setJobAdjoiningProps({
                  ...jobAdjoiningProps,
                  relativeGradient: e.target.value,
                });
              }}
            >
              {[
                'Not Determined',
                'Up-Gradient',
                'Down-Gradient',
                'Cross-Gradient',
              ].map((layer) => (
                <MenuItem key={layer} value={layer}>
                  <ListItemText primary={layer} />
                </MenuItem>
              ))}
            </Select>
          </CustomFormControl>
        </Row>
        <Row>
          <JobButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => {
              const adjoiningPropertiesArray: any =
                jobState.adjoiningProperties;
              if (jobAdjoiningProps && jobAdjoiningProps.id) {
                const index = R.findIndex(R.propEq('id', jobAdjoiningProps.id))(
                  adjoiningPropertiesArray
                );
                adjoiningPropertiesArray[index] = jobAdjoiningProps;
              } else {
                adjoiningPropertiesArray.push({
                  id: adjoiningPropertiesArray.length + 1,
                  name: jobAdjoiningProps.name,
                  address: jobAdjoiningProps.address,
                  label: jobAdjoiningProps.label,
                  elevation: jobAdjoiningProps.elevation,
                  relativeGradient: jobAdjoiningProps.relativeGradient,
                  currentUse: jobAdjoiningProps.currentUse,
                  geojson: jobState.adjoiningPropJson.geometry,
                });
              }
              dispatch(
                updateJob({
                  ...jobState,
                  ...{
                    adjoiningProperties: adjoiningPropertiesArray,
                    jobCreate: 'create',
                    jobAction: true,
                  },
                })
              );
              setJobAdjoiningProps(jobAdjoiningPropsInitial);
            }}
          >
            {jobAdjoiningProps.id ? 'Save' : 'Add'}
          </JobButton>
          <JobButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditLocationIcon />}
            onClick={() => {
              dispatch(
                updateJob({
                  ...jobState,
                  ...{ jobCreate: 'createAdjoiningProp', jobAction: true },
                })
              );
            }}
          >
            Select Parcel
          </JobButton>
        </Row>
        <Table bordered hover>
          <tbody>
            {jobState.adjoiningProperties &&
              jobState.adjoiningProperties.map((property: any, index: any) => {
                return (
                  <tr key={'property' + index}>
                    <td>{property.name}</td>
                    <td>{property.address}</td>
                    <td>
                      <JobButton
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setJobAdjoiningProps(property);
                        }}
                      >
                        Edit
                      </JobButton>
                      <JobButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </JobButton>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </AdjoiningPropForm>
    </>
  );
};

export default JobsAdjoiningProperties;
