import { css } from 'styled-components';

const sizes: any = {
  desktop: 992,
  phone: 576,
  tablet: 768
};

// Iterate through the sizes and create a media template
const mediaQuery: any = Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css.apply(null, args)}
    }
  `;
  return acc;
}, {});

export default mediaQuery;
