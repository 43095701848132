import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FileText } from 'react-feather';

const FooterContainer = styled.div`
  z-index: 101;
  position: fixed;
  width: 100vw;
  bottom: 0;
  background: #fff;
  height: 30px;
  max-height: 40px;
`;

const FooterLink = styled.div`
  display: block;
  position: fixed;
  bottom: 5px;
  right: 45%;
  z-index: 9999;
  text-align: center;
  margin: 0 auto;
  padding-top: 2px;
  a {
    text-decoration: none !important;
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    && {
      text-align: left;
    }
  }
`;

const HelpLink = styled.div`
  display: block;
  position: fixed;
  bottom: 5px;
  right: 20px;
  z-index: 9999;
  font-size: 15px;
  font-weight: 600;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterLink>
        <Link to="/termsandconditions">
          <FileText size="15" /> Terms and Conditions
        </Link>
      </FooterLink>
      <HelpLink data-tour="ceres-second-step">
        <Link to="/tutorial">Need help?</Link>
      </HelpLink>
    </FooterContainer>
  );
};

export default Footer;
