import React from 'react';
import { ChevronRight, Grid } from 'react-feather';
import styled from 'styled-components';
import { IJobType } from '../../context/project/types';

const LineItemContainer = styled.div`
  display: flex;
  flex: 1;
  color: var(--dark);
  flex-direction: column;
`;

const ProjectTitle = styled.div`
  background: #fff;
  background: var(--light);
  display: flex;
  padding: 1rem 2rem;
  span {
    margin-left: 30px;
  }
`;

const JobItem = styled.div`
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  background: #fff;
  transition: all 0.3s ease;
  padding: 1rem 35px 1rem 5.5rem;
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 24px rgba(32, 43, 54, 0.25);
    z-index: 10;
  }
`;

interface IProjectSwitcherLineItem {
  projectName: string;
  projectId: string;
  jobTypes: IJobType[];
  onJobTypeSelect: (jobType: IJobType) => void;
}

const ProjectSwitcherLineItem: React.FC<IProjectSwitcherLineItem> = props => {
  return (
    <LineItemContainer>
      <ProjectTitle>
        <Grid />
        <span>{props.projectName}</span>
      </ProjectTitle>
      {props.jobTypes.map(jobType => {
        return (
          <JobItem
            key={jobType.id}
            onClick={() => props.onJobTypeSelect(jobType)}
          >
            <span>{jobType.type}</span>
            <ChevronRight />
          </JobItem>
        );
      })}
    </LineItemContainer>
  );
};

export default ProjectSwitcherLineItem;
