import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IJob } from '../../store/aiearth/types';
import { projectId } from '../../services/firebase';
import { CeresLogger } from '../../logger';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Spinner,
  Button,
  Table,
  Tab,
  Tabs,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import styled from 'styled-components';
import { ChevronLeft } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TreeView from './TreeViewData';
import FormControl from '@material-ui/core/FormControl';
import DataTable from 'react-data-table-component';
import { searchAIEarth } from '../../services/firebase';
import { IElasticResult, ISearchRecord } from '../../types/search';
import { Scrollbars } from 'react-custom-scrollbars';
import { IAuthContext } from '../../context/auth/auth-context';
import { TIERS } from '../../types/tiers';
import { toast } from 'react-toastify';
import { updateJob } from '../../store/aiearth/action';
import SoilSummary from './findings/SoilSummary';
import DatabaseDescription from './findings/DatabaseDescription';

interface IMapsFinding {
  gMap: any;
  gMaps: any;
  doStorageDownload: any;
  getStorageRef: any;
  setDownloadTriggers: any;
  downloadTriggers: any;
  context: IAuthContext;
  selectedEntityNumber: any;
}
const ButtonContainer: any = styled.div`
  display: flex;
  padding: 6px 10px;
  background-color: #6c757d;
  flex-direction: row-reverse;
`;
const PreviewButtonContainer = styled(ButtonContainer)`
  background-color: #ea5647;
  padding: 4px 10px;
`;
const FilterFindings = styled.div`
  flex: 1;
`;
const CustomFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 4px;
`;
const RNDataContainer: any = styled.div`
  padding: 5px 0px;
  height: calc(100vh - 170px);
  overflow: auto;
  background-color: #f1f1f1;
  margin-right: 5px;
`;
const JobTasksContainer = styled(RNDataContainer)`
  padding: 5px 0px 0px;
  height: fit-content;
  overflow: auto;
  background-color: #f1f1f1;
  margin-right: 0;
  border-left: 1px solid #eee;
  max-height: 55vh;
`;
const JobDetailsContainer = styled(RNDataContainer)`
  padding: 10px;
  height: calc(100vh - 170px);
  overflow: auto;
  max-height: 55vh;
  background-color: #f1f1f1;
  & h6 {
    color: #005fbc;
  }
`;
const StyledDataTable = styled(DataTable)`
  & .rdt_Table {
    max-height: 52vh;
  }
  & .rdt_TableCell {
    font-size: 10px;
    padding: 3px;
    width: 9rem;
    text-overflow: ellipsis;
  }
  & .rdt_TableCol {
  }
  & .rdt_TableBody {
    overflow-y: scroll;
  }
  & .rdt_TableRow,
  .rdt_TableHeadRow {
    min-height: 36px;
  }
  & .rdt_TableRow,
  .rdt_TableCol {
    padding: 8px 10px;
    text-transform: uppercase;
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;
const TableAstm = styled(Table)`
  width: 704px;
`;
const TD = styled.td`
  font-size: 12px;
`;
const TH = styled.th`
  font-size: 12px;
  text-transform: uppercase;
`;
const CustomTabs = styled(Tabs)`
  border-bottom: 1px solid #005fbc !important;
`;
const OLContainer = styled.ol`
  padding-inline-start: 10px !important;
  font-size: 0.6rem;
  padding-top: 7px;
  margin-bottom: 0px;
`;
const DownloadHyperlink = styled.a`
  color: #005fbc;
  cursor: pointer;
  &:hover {
    color: #ac5e08 !important;
    text-decoration: underline !important;
  }
`;
const AddressText = styled.span`
  font-size: 0.6rem;
`;
const EnameText = styled.h6`
  color: #212529 !important;
`;
const SpaceEvenly = styled.div`
  display: flex;
  justify-content: space-between;
`;
const EPALink = styled.span`
  color: #005fbc;
  cursor: pointer;
`;

const fetchRecordsByRN = (
  rnNumber: string,
  searchTerm: string,
  searchType: string
) => {
  return searchAIEarth({
    query: {
      from: 0,
      size: 50,
      sortByReleaseDate: true,
      text: searchTerm,
      type: searchType,
      terms: {
        regulated_entity_number: rnNumber,
      },
    },
  });
};

const RecordTable: React.FC<{
  columns: any;
  data: any;
  onFindingClick?: any;
  findingsRowStyles?: any;
}> = (props) => {
  return (
    <StyledDataTable
      columns={props.columns}
      data={props.data}
      noHeader={true}
      fixedHeaderScrollHeight={'calc(100vh - 240px)'}
      fixedHeader={true}
      pointerOnHover
      striped
      sortServer={true}
      onRowClicked={props.onFindingClick || undefined}
      highlightOnHover
      conditionalRowStyles={props.findingsRowStyles}
    />
  );
};

const MyTundraMapsFinding: React.FC<IMapsFinding> = (props) => {
  const authContext = props.context;
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;
  const [getAutoScroll, setAutoScroll] = useState<any>(false);
  const [entityNumber, setEntityNumber] = useState<any>();
  const [getEntity, setEntity] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [entityDetails, setEntityDetails] = useState();
  const [orphansFindings, setOrphansFindings] = useState<any>([]);
  const [relatedRecords, setRelatedRecords] = useState<ISearchRecord[]>([]);
  const documentsTabTitle = `Documents(${relatedRecords.length})`;

  const onFindingClick = (row: any) => {
    setAutoScroll(false);
    getZoomPoint(row.geom);
    dispatch(
      updateJob({
        ...jobState,
        ...{
          jobInit: false,
          jobFeatureTab: true,
          mapFindingTab: true,
          jobFeature: row,
        },
      })
    );
  };
  const onOrphansClick = (row: any) => {
    setOrphansFindings(row);
    dispatch(
      updateJob({
        ...jobState,
        ...{
          jobFeatureTab: true,
        },
      })
    );
  };

  const propertiesCol = [
    {
      name: 'ID',
      selector: 'map_id',
      width: '40px',
    },
    {
      name: 'Vec',
      selector: 'vec_status',
      width: '80px',
    },
    {
      name: 'Rec',
      selector: 'rec_status',
      width: '80px',
    },
    {
      name: 'Entity Number',
      selector: 'entity_number',
      width: '120px',
    },
    {
      name: 'Entity Name',
      selector: 'entity_name',
      width: '120px',
    },
    {
      name: 'Address',
      selector: 'address',
      width: '150px',
    },
    {
      name: 'City',
      selector: 'city',
      width: '80px',
    },
    {
      name: 'State',
      selector: 'state',
      width: '50px',
    },
    {
      name: 'Zip',
      selector: 'zip_code',
      width: '60px',
    },
    {
      name: 'Programs',
      selector: 'programs',
      width: '70px',
    },
    {
      name: 'GW',
      selector: 'gw_flow',
      width: '70px',
    },
    {
      name: 'Elevation',
      selector: 'elevation',
      width: '70px',
    },
    {
      name: 'Distance',
      selector: 'distance_feet',
      width: '70px',
    },
  ];
  const orphansCol = [
    {
      name: 'Entity Name',
      selector: 'entity_name',
    },
    {
      name: 'Entity Number',
      selector: 'entity_number',
    },
    {
      name: 'Entity Source',
      selector: 'entity_source',
    },
    {
      name: 'Database',
      selector: 'programs',
    },
    {
      name: 'Address',
      selector: 'address',
    },
    {
      name: 'City',
      selector: 'city',
    },
    {
      name: 'Zip',
      selector: 'zip_code',
    },
    {
      name: 'Country',
      selector: 'county',
    },
    {
      name: 'Symbol',
      selector: 'program_symbol',
    },
    {
      name: 'State',
      selector: 'state',
    },
    {
      name: 'Secondary ID',
      selector: 'secondary_id',
    },
    {
      name: 'Street Name',
      selector: 'street_name',
    },
  ];
  const adjoiningCol = [
    {
      name: 'Address',
      selector: 'address',
    },
    {
      name: 'City',
      selector: 'city',
    },
    {
      name: 'County',
      selector: 'county',
    },
    {
      name: 'Direction',
      selector: 'direction',
    },
    {
      name: 'Distance',
      selector: 'distance_feet',
    },
    {
      name: 'Elevation',
      selector: 'elevation_feet',
    },
    {
      name: 'GW',
      selector: 'gw_flow',
    },
    {
      name: 'Legal',
      selector: 'legal',
    },
    {
      name: 'Mail Address',
      selector: 'mail_addr',
    },
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Owner',
      selector: 'owner',
    },
    {
      name: 'State',
      selector: 'state',
    },
  ];

  useEffect(() => {
    async function getMapsFindingSummary() {
      dispatch(
        updateJob({
          ...jobState,
          ...{
            searchFinding: false,
          },
        })
      );
      try {
        axios
          .get(
            `https://us-central1-ceres-platform-test.cloudfunctions.net/getMapsFindingSummaryPhase1?v_rn_number=${props.selectedEntityNumber}`
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
          )
          .then((response) => {
            const detailsJson = response.data[0].esadata_findings;
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  esadataFindings: detailsJson,
                  searchFinding: false,
                },
              })
            );
          })
          .catch((err) => {
            CeresLogger.error(err);
          });
      } catch (e) {
        CeresLogger.error(e);
      }
    }
    getMapsFindingSummary();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobState.mapFindingsTypes,
  ]);

  useEffect(() => {
    async function programQueryDetails() {
      const requestBody = { RN: entityNumber };
      try {
        setIsLoading(true);
        axios
          .post(
            'https://us-central1-' +
              projectId +
              '.cloudfunctions.net/api/programQueryDetails',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
            requestBody,
            {
              headers: {
                Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
              },
            }
          )
          .then((response) => {
            setIsLoading(false);
            const detailsJson = response.data[0].get_prgm_json_for_entity;
            setEntityDetails(detailsJson);
          })
          .catch((err) => {
            CeresLogger.error(err);
            setIsLoading(false);
          })
          .finally(() => setIsLoading(false));
      } catch (e) {
        CeresLogger.error(e);
      }
    }

    async function fetchRecords() {
      let isSubscribed = true;
      const containsSearchTerm = await fetchRecordsByRN(
        entityNumber,
        '',
        'must'
      );
      const data = containsSearchTerm.data as IElasticResult<ISearchRecord>;
      const records = (data && data.records) || [];
      records.map((rec) => {
        rec.containsSearchTerm = true;
        return rec;
      });
      isSubscribed && setRelatedRecords(records);
      setIsLoading(false);
      return () => {
        isSubscribed = false;
      };
    }
    if (entityNumber) {
      programQueryDetails();
      fetchRecords();
    }
  }, [entityNumber, setIsLoading]);

  const getZoomPoint = (location: string) => {
    location = location.replace('POINT(', '');
    location = location.replace(')', '');
    const latLng = location.split(' ');
    const lat = latLng[1];
    const lng = latLng[0];
    if (lat && lng) {
      const latlng = new props.gMaps.LatLng(lat, lng);
      props.gMap.panTo(latlng);
      props.gMap.setZoom(18);
    }
  };

  const addjoinFindingsRowStyles = [
    {
      when: (row: any) => {
        if (row.ogc_fid === jobState.jobFeature.ogc_fid) {
          setEntity(row);
          if (getAutoScroll) {
            const index = jobState.mapFindings.findIndex(
              (x: any) => x.ogc_fid === jobState.jobFeature.ogc_fid
            );
            const list: any = document.getElementById('row-' + index);
            if (list) {
              list.scrollIntoView();
            }
          }
          return true;
        } else {
          return false;
        }
      },
      style: {
        // tslint:disable-next-line: object-literal-key-quotes
        backgroundColor: '#005FBCeb !important',
        // tslint:disable-next-line: object-literal-key-quotes
        color: 'white !important',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const findingsRowStyles = [
    {
      when: (row: any) => {
        if (row.entity_number === jobState.jobFeature.entity_number) {
          setEntityNumber(row.entity_number);
          setEntity(row);
          if (getAutoScroll) {
            const index = jobState.mapFindings.findIndex(
              (x: any) => x.entity_number === jobState.jobFeature.entity_number
            );
            const list: any = document.getElementById('row-' + index);
            if (list) {
              list.scrollIntoView();
            }
          }
          return true;
        } else {
          return false;
        }
      },
      style: {
        // tslint:disable-next-line: object-literal-key-quotes
        backgroundColor: '#005FBCeb !important',
        // tslint:disable-next-line: object-literal-key-quotes
        color: 'white !important',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];
  const orphansRowStyles = [
    {
      when: (row: any) => {
        if (row.entity_number === orphansFindings.entity_number) {
          setEntityNumber(row.entity_number);
          setEntity(row);
          if (getAutoScroll) {
            const index = orphansFindings.findIndex(
              (x: any) => x.entity_number === orphansFindings.entity_number
            );
            const list: any = document.getElementById('row-' + index);
            if (list) {
              list.scrollIntoView();
            }
          }
          return true;
        } else {
          return false;
        }
      },
      style: {
        // tslint:disable-next-line: object-literal-key-quotes
        backgroundColor: '#005FBCeb !important',
        // tslint:disable-next-line: object-literal-key-quotes
        color: 'white !important',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const getRecordTitle = (item: ISearchRecord) =>
    `${item.title} - ${item.seriesName} - ${item.releaseDate}`;

  const getTCEQRecordUrl = (eNumber: string) =>
    `https://www15.tceq.texas.gov/crpub/index.cfm?fuseaction=regent.validateRE&re_ref_num_txt=${eNumber}`;

  const getEPARecordUrl = (eNumber: string) =>
    `https://ofmpub.epa.gov/frs_public2/fii_map_master.fii_retrieve?fac_search=facility_uin&fac_value=${eNumber}&fac_search_type=Equal+To&postal_code=&location_address=&add_search_type=B&city_name=&county_name=&state_code=&epa_region_code=&cong_dist=&legis_dist=&huc_code=&fed_agency=&TribalLand=0&selectTribe=noselect&sic_type=Equal+to&sic_code_to=&naic_type=Equal+to&naic_to=&org_name=&duns_num=&contact_name=&prog_search=&int_search=&search_type=&search_type=all&all_programs=YES&sysname=&page_no=1&output_sql_switch=TRUE&report=1&database_type=FII&tribal_ind=&last_facility=&univ_search=&fac_search_term=&tribetype=&triballand=&selecttribe=&tribedistance1=`;

  return (
    <>
      {jobState.mapFindingTab && !jobState.jobInit && (
        <Col lg={3}>
          <PreviewButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                dispatch(
                  updateJob({
                    ...jobState,
                    ...{
                      jobInit: true,
                      jobFeatureTab: false,
                      mapFindingTab: false,
                    },
                  })
                );
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
            <FilterFindings>
              <CustomFormControl size="small" variant="outlined">
                <Autocomplete
                  options={[
                    'Job Map Entities',
                    'ASTM Summary',
                    'VEC Summary',
                    'Oil-Gas Summary',
                    'Orphans Summary',
                    'Adjoining Properties Summary',
                    'Database Description',
                    'Soils Summary',
                  ]}
                  value={jobState.mapFindingsTypes}
                  getOptionLabel={(option: any) => option}
                  getOptionSelected={(option: string, value: string) =>
                    option === value
                  }
                  onChange={(event: any, newValue: any) => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          jobFeatureTab: false,
                          mapFindingsTypes: newValue,
                        },
                      })
                    );
                  }}
                  style={{ width: 210 }}
                  disableClearable
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Findings"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </CustomFormControl>
            </FilterFindings>
          </PreviewButtonContainer>
          <JobTasksContainer>
            {jobState.mapFindingsTypes === 'Job Map Entities' &&
              jobState.esaData &&
              Object.keys(jobState.esaData).length > 0 &&
              typeof jobState.esaData.regulated_entities_point.features !==
                'undefined' &&
              typeof jobState.esaData.regulated_entities_point.features[0]
                .geometry !== 'undefined' &&
              Object.keys(jobState.esaData.regulated_entities_point.features)
                .length > 0 &&
              jobState.esaData.regulated_entities_point && (
                <RecordTable
                  columns={propertiesCol}
                  data={jobState.mapFindings}
                  onFindingClick={onFindingClick}
                  findingsRowStyles={findingsRowStyles}
                />
              )}
            {jobState.esadataFindings !== null &&
              jobState.mapFindingsTypes === 'ASTM Summary' && (
                <div className="table-responsive">
                  <TableAstm bordered hover>
                    <thead>
                      <tr>
                        <TH>Database</TH>
                        <TH>DIST(MI)</TH>
                        <TH>TP</TH>
                        <TH>{'> tp < 1/8'}</TH>
                        <TH>{'1/8 - < 1/4'}</TH>
                        <TH>{'1/4 - < 1/2'}</TH>
                        <TH>1/2 - 1</TH>
                        <TH>{'> 1'}</TH>
                        <TH>Total</TH>
                      </tr>
                    </thead>
                    <tbody>
                      {jobState.esadataFindings !== null &&
                        jobState.esadataFindings.map_findings_summary_astm
                          .length > 0 && (
                          <>
                            {jobState.esadataFindings.map_findings_summary_astm.map(
                              (astm: any, key: any) => (
                                <>
                                  <tr key={`${key}${astm.group}`}>
                                    <TD colSpan={9}>{astm.group}</TD>
                                  </tr>
                                  {astm.summary.length > 0 &&
                                    astm.summary.map(
                                      (summary: any, keys: any) => (
                                        <tr key={`${keys}${summary['prgm']}`}>
                                          <TD>{summary['prgm']}</TD>
                                          <TD>{summary['dist(mi)']}</TD>
                                          <TD>{summary['tp']}</TD>
                                          <TD>{summary['> tp < 1/8']}</TD>
                                          <TD>{summary['1/8 - < 1/4']}</TD>
                                          <TD>{summary['1/4 - < 1/2']}</TD>
                                          <TD>{summary['1/2 - 1']}</TD>
                                          <TD>{summary['> 1']}</TD>
                                          <TD>{summary['Total']}</TD>
                                        </tr>
                                      )
                                    )}
                                </>
                              )
                            )}
                          </>
                        )}
                    </tbody>
                  </TableAstm>
                </div>
              )}
            {jobState.esadataFindings !== null &&
              jobState.mapFindingsTypes === 'VEC Summary' && (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <TH>Database</TH>
                      <TH>TP</TH>
                      <TH>Crossgradient</TH>
                      <TH>Downgradient</TH>
                      <TH>Upgradient</TH>
                      <TH>Total</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {jobState.esadataFindings !== null &&
                      typeof jobState.esadataFindings
                        .map_findings_summary_vec !== 'undefined' &&
                      jobState.esadataFindings.map_findings_summary_vec.length >
                        0 && (
                        <>
                          {jobState.esadataFindings.map_findings_summary_vec.map(
                            (vec: any) => (
                              <>
                                <tr>
                                  <TD colSpan={6}>{vec.group}</TD>
                                </tr>
                                {vec.summary.length > 0 &&
                                  vec.summary.map((summary: any) => (
                                    <tr>
                                      <TD>{summary['prgm']}</TD>
                                      <TD>{summary['tp']}</TD>
                                      <TD>{summary['crossgradient']}</TD>
                                      <TD>{summary['downgradient']}</TD>
                                      <TD>{summary['upgradient']}</TD>
                                      <TD>{summary['Total']}</TD>
                                    </tr>
                                  ))}
                              </>
                            )
                          )}
                        </>
                      )}
                  </tbody>
                </Table>
              )}
            {jobState.esadataFindings !== null &&
              jobState.mapFindingsTypes === 'Oil-Gas Summary' && (
                <TableAstm bordered hover>
                  <thead>
                    <tr>
                      <TH>Database</TH>
                      <TH>TP</TH>
                      <TH>{'1/4 - < 1/2'}</TH>
                      <TH>{'1/8 - < 1/4'}</TH>
                      <TH>{'> 1/2'}</TH>
                      <TH>{'> tp < 1/8'}</TH>
                      <TH>Total</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {jobState.esadataFindings !== null &&
                      typeof jobState.esadataFindings
                        .map_findings_summary_oil_gas !== 'undefined' &&
                      Object.keys(
                        jobState.esadataFindings.map_findings_summary_oil_gas
                      ).length > 0 && (
                        <>
                          <tr>
                            <TD colSpan={7}>
                              {
                                jobState.esadataFindings
                                  .map_findings_summary_oil_gas.group
                              }
                            </TD>
                          </tr>
                          {jobState.esadataFindings.map_findings_summary_oil_gas
                            .summary.length > 0 &&
                            jobState.esadataFindings.map_findings_summary_oil_gas.summary.map(
                              (summary: any) => (
                                <tr>
                                  <TD>{summary['prgm']}</TD>
                                  <TD>{summary['tp']}</TD>
                                  <TD>{summary['1/4 - < 1/2']}</TD>
                                  <TD>{summary['1/8 - < 1/4']}</TD>
                                  <TD>{summary['> 1/2']}</TD>
                                  <TD>{summary['> tp < 1/8']}</TD>
                                  <TD>{summary['Total']}</TD>
                                </tr>
                              )
                            )}
                        </>
                      )}
                  </tbody>
                </TableAstm>
              )}

            {jobState.esadataFindings !== null &&
              jobState.mapFindingsTypes === 'Database Description' && (
                <DatabaseDescription />
              )}

            {jobState.esadataFindings !== null &&
              jobState.mapFindingsTypes === 'Soils Summary' && <SoilSummary />}

            {jobState.mapFindingsTypes === 'Orphans Summary' &&
              jobState.esadataFindings !== null &&
              typeof jobState.esadataFindings.orphans !== 'undefined' &&
              jobState.esadataFindings.orphans !== null &&
              Object.keys(jobState.esadataFindings.orphans).length > 0 && (
                <StyledDataTable
                  columns={orphansCol}
                  data={jobState.esadataFindings.orphans}
                  noHeader={true}
                  fixedHeaderScrollHeight={'calc(100vh - 240px)'}
                  fixedHeader={true}
                  pointerOnHover
                  striped
                  sortServer={true}
                  onRowClicked={onOrphansClick || undefined}
                  highlightOnHover
                  conditionalRowStyles={orphansRowStyles}
                />
              )}
            {jobState.mapFindingsTypes === 'adjoining_properties_boundaries' &&
              jobState.esaData &&
              Object.keys(jobState.esaData).length > 0 &&
              typeof jobState.esaData.adjoining_properties_boundaries
                .features !== 'undefined' &&
              typeof jobState.esaData.adjoining_properties_boundaries
                .features[0].geometry !== 'undefined' &&
              Object.keys(
                jobState.esaData.adjoining_properties_boundaries.features
              ).length > 0 &&
              jobState.esaData.adjoining_properties_boundaries && (
                <RecordTable
                  columns={adjoiningCol}
                  data={jobState.adjoiningPropertiesBoundaries}
                  findingsRowStyles={addjoinFindingsRowStyles}
                />
              )}
          </JobTasksContainer>
        </Col>
      )}
      {jobState.jobFeatureTab && (
        <Col lg={3}>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                dispatch(
                  updateJob({
                    ...jobState,
                    ...{
                      jobFeatureTab: false,
                    },
                  })
                );
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <JobDetailsContainer>
            {isLoading ? (
              <SpinnerContainer>
                <Spinner animation="border" variant="primary" />
              </SpinnerContainer>
            ) : (
              <>
                <EnameText>{getEntity.entity_name}</EnameText>
                <AddressText>{getEntity.address}</AddressText>
                <h6>
                  <SpaceEvenly>
                    {getEntity.entity_source === 'TCEQ' && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getTCEQRecordUrl(entityNumber)}
                      >
                        {entityNumber}
                      </a>
                    )}
                    {getEntity.entity_source === 'EPA' && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getEPARecordUrl(entityNumber)}
                      >
                        {entityNumber}
                      </a>
                    )}
                    {getEntity.entity_source === 'TCEQ-EPA' && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getTCEQRecordUrl(entityNumber)}
                      >
                        {entityNumber}
                      </a>
                    )}
                    {getEntity.secondary_id && (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="bottom"
                        overlay={
                          <Popover id="popover">
                            <Popover.Title>EPA IDs</Popover.Title>
                            <Popover.Content>
                              {getEntity.secondary_id
                                .split(',')
                                .map((epaId: any) => {
                                  if (
                                    epaId.startsWith('11') &&
                                    epaId.length === 12
                                  ) {
                                    return (
                                      <p>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={getEPARecordUrl(epaId)}
                                        >
                                          {epaId}
                                        </a>
                                      </p>
                                    );
                                  }
                                  return '';
                                })}
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <EPALink>EPA IDs</EPALink>
                      </OverlayTrigger>
                    )}
                  </SpaceEvenly>
                </h6>
                <CustomTabs
                  defaultActiveKey="details"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="details" title="Details">
                    <TreeView details={entityDetails} />
                  </Tab>
                  <Tab eventKey="documents" title={documentsTabTitle}>
                    <OLContainer>
                      <Scrollbars
                        style={{
                          height: 'calc(100vh - 305px)',
                        }}
                      >
                        {R.compose(
                          R.map((relatedRecord: ISearchRecord) => {
                            return (
                              <li
                                style={{
                                  color: relatedRecord.containsSearchTerm
                                    ? 'green'
                                    : 'none',
                                }}
                                key={relatedRecord.contentId}
                              >
                                {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
                                  getRecordTitle(relatedRecord)
                                ) : (
                                  <DownloadHyperlink
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        props.doStorageDownload(
                                          relatedRecord.entitySource
                                        )
                                      ) {
                                        const storageRef = props.getStorageRef(
                                          relatedRecord.entitySource
                                        );
                                        if (R.isNil(storageRef)) {
                                          return toast.error(
                                            `Oops, could not find the file.`
                                          );
                                        }
                                        props.setDownloadTriggers(
                                          R.append(
                                            relatedRecord.contentId,
                                            props.downloadTriggers
                                          )
                                        );
                                        return storageRef
                                          .ref(relatedRecord.fileName)
                                          .getDownloadURL()
                                          .then((url: string | undefined) =>
                                            window.open(
                                              url,
                                              '_blank',
                                              'noopener,noreferrer'
                                            )
                                          )
                                          .catch((err: any) =>
                                            toast.error(err.message)
                                          )
                                          .finally(() =>
                                            props.setDownloadTriggers(
                                              R.without(
                                                [relatedRecord.contentId],
                                                props.downloadTriggers
                                              )
                                            )
                                          );
                                      }
                                      if (!relatedRecord.fileUrl) {
                                        return toast.warn(
                                          <span>
                                            No associated file was found for the
                                            content id{' '}
                                            <b>{relatedRecord.contentId}</b>
                                          </span>
                                        );
                                      }
                                      window.open(
                                        relatedRecord.fileUrl,
                                        '_blank'
                                      );
                                      // props.setRelatedDocument(relatedRecord);
                                    }}
                                  >
                                    {getRecordTitle(relatedRecord)}
                                  </DownloadHyperlink>
                                )}
                              </li>
                            );
                          }),
                          R.sort(
                            R.comparator(
                              (
                                itemOne: ISearchRecord,
                                itemTwo: ISearchRecord
                              ) => itemOne.releaseDate > itemTwo.releaseDate
                            )
                          )
                        )(relatedRecords)}
                        {relatedRecords.length < 1
                          ? 'No documents currently available.'
                          : ''}
                      </Scrollbars>
                    </OLContainer>
                  </Tab>
                </CustomTabs>
              </>
            )}
          </JobDetailsContainer>
        </Col>
      )}
    </>
  );
};

export default MyTundraMapsFinding;
