import * as R from 'ramda';

import GoogleMapReact, { ChangeEventValue, MapOptions, Maps } from 'google-map-react';
import { OverlayTrigger } from 'react-bootstrap';
import React, { useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import CeresBotLogo from '../../../assets/ceres-robo-map.png';
import { ISearchRecord } from '../../../types/search';
import RecordMarker from './RecordMarker';
import RecordNavigation from './RecordNavigation';
import { dummyRecord } from '../../../types/search';
import { renderEPNInfo } from '../../ai-earth-components/map/GoogleMapEPNInfo';
import { IAuthContext } from '../../../context/auth/auth-context';
import styled from 'styled-components';
import { Maximize, Minimize } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

// tslint:disable-next-line:no-var-requires
const googleMapReactUtil = require('google-map-react/utils');

interface IAiEarthMapProps {
  records: ISearchRecord[];
  docSearchEPNResult: any;
  context: IAuthContext;
}

interface IMapBoundingBox {
  nw: {
    lat: number;
    lng: number;
  };
  se: {
    lat: number;
    lng: number;
  };
}

const MAP_NAVIGATION_ELEMENT_ID = 'ceres-map-navigation';

// TODO: Remove this crap.
const isIOSOrSafari = () =>
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  (navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i));

const MainMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-bottom: 0;
  margin-top: 1em;
  height: 500px;
  height: calc(100vh - 110px);
`;

const InnerMapContainer = styled.div<{ status: boolean }>`
  display: flex;
  height: ${(props) => (props.status ? '100vh' : '500px')};
  min-height: 700px;
  flex: 1;
  position: relative;
`;

const FullScreenBtn = styled.button`
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 0px;
  z-index: 99;
`;

const MapHeader = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  // TODO: Honestly, gotta remove this shit sooner or later.
  margin: ${() => (isIOSOrSafari() ? 4 : 0)}em 0em 1em 0em;
`;

const MarkerPinGeneric = styled.div`
  width: 7rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #ff0000b3;
  cursor: pointer;
  z-index: 10;
  color: #e9ecef;
  padding: 2px 0 2px 6px;
`;

const MarkerPinStandard = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #005fbc;
  background: var(--primary);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  z-index: 10;
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #fff;
    background: var(--light);
    position: absolute;
    border-radius: 50%;
  }
`;

const getBoundingBox = (uniqueEntityRecords: ISearchRecord[]): IMapBoundingBox => {
  const locations: Array<{ lat: number; lon: number }> = R.map(
    (record: ISearchRecord) => record.location,
    uniqueEntityRecords
  );

  if (R.isEmpty(locations)) {
    return {
      nw: {
        lat: 29.0,
        lng: -95.0,
      },
      se: {
        lat: 29.5,
        lng: -95.5,
      },
    };
  }

  const [latitudes, longitudes] = R.compose<
    Array<{ lat: number; lon: number }>,
    Array<Array<[string, number]>>,
    Array<[string, number]>,
    {
      [key: string]: Array<[string, number]>;
    },
    [number[], number[]]
  >(
    (groupedData: { [key: string]: Array<[string, number]> }): [number[], number[]] => {
      const groupedLats = groupedData.lat || [];
      const groupedLongs = groupedData.lon || [];
      const getLocValue = R.map((item: [string, number]) => R.last(item) as number);
      return [getLocValue(groupedLats), getLocValue(groupedLongs)];
    },
    R.groupBy(([locKey, _]) => locKey),
    R.reduce((acc, item) => [...acc, ...item], [] as Array<[string, number]>),
    R.map(R.toPairs)
  )(locations);

  // const latitudes = R.flatten(R.map(R.toPairs, locations));

  return {
    nw: {
      lat: Math.max(...latitudes),
      lng: Math.min(...longitudes),
    },
    se: {
      lat: Math.min(...latitudes),
      lng: Math.max(...longitudes),
    },
  };
};

const getMapOptions = (map: Maps): MapOptions => ({
  gestureHandling: 'greedy',
  scaleControl: true,
  mapTypeControl: true,
  streetViewControl: true,
  fullscreenControl: false,
  mapTypeControlOptions: {
    position: map.ControlPosition.TOP_LEFT,
    mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
    zoomControlOptions: {
      position: map.ControlPosition.RIGHT_BOTTOM,
      style: map.ZoomControlStyle.SMALL,
    },
  },
});

/**
 * hook, that's triggered when the google map is loaded.
 * @param param0.map - Current Map instance.
 * @param param0.maps - google maps object.
 */
const onMapLoaded = ({ map, maps }: { map: any; maps: any }) => {
  // Set silver style for the map.
  // const styledMapSilver = new maps.StyledMapType(silverMapStyle, {
  //   name: 'Gray Map',
  // });
  // // map.mapTypes.set('gray_map', styledMapSilver);
  map.setMapTypeId('roadmap');

  // Load ceres logo.
  const logoElement = document.createElement('img');
  logoElement.src = CeresBotLogo;
  logoElement.className = 'ceres-map-logo';
  map.controls[maps.ControlPosition.LEFT_BOTTOM] = [logoElement];

  // Setup navigation div.
  const navigationElement = document.createElement('div');
  navigationElement.className = 'ceres-map-navigation-main';
  navigationElement.id = MAP_NAVIGATION_ELEMENT_ID;
  map.controls[maps.ControlPosition.LEFT_CENTER] = [navigationElement];
};

const getUniqueSearchEntityRecords = (records: ISearchRecord[]) => {
  const isValidLatLong = (lat: number, lng: number) => {
    return lat !== 0 && lng !== 0;
  };
  return R.compose<ISearchRecord[], ISearchRecord[], ISearchRecord[], ISearchRecord[]>(
    R.uniqBy(R.prop('entityNumber')),
    R.filter(
      (record: ISearchRecord) =>
        !!record &&
        record.location &&
        typeof record.location.lat !== 'undefined' &&
        isValidLatLong(record.location.lat, record.location.lon)
    ),
    R.filter((record: ISearchRecord) => !!record && !!record.entityNumber)
  )(records);
};

/**
 * Renders the marker pins for each search record
 * @param records - Search Records from the search result.
 */
const renderRecordMarkers = (
  records: ISearchRecord[],
  activeMarkerRecord: ISearchRecord | null,
  onMarkerClick: (record: ISearchRecord | null) => void
) => {
  return R.map((record: ISearchRecord) => {
    return (
      <RecordMarker
        onInfoWindowClose={() => onMarkerClick(null)}
        key={record.contentId}
        onMarkerClick={() => onMarkerClick(record)}
        lat={record.location.lat}
        lng={record.location.lon}
        isActive={R.equals(activeMarkerRecord && activeMarkerRecord.entityNumber, record.entityNumber)}
        record={record}
      />
    );
  }, records);
};

const EPNPin: React.FC<any> = (props) => {
  const [show, setShow] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [selected, setSelected] = useState<string>('0');

  if (props.marker === 'STANDARD') {
    return (
      <OverlayTrigger
        // key={props.record.contentId}
        trigger="hover"
        placement="bottom"
        delay={{ show: 200, hide: 1200 }}
        overlay={
          <ReactTooltip effect="solid" html={true} />
        }
      >
        <MarkerPinStandard
          data-tip={
            ReactDOMServer.renderToString(
              <div>
                <strong>{props.name}</strong>
              </div>
            )
          }
        ></MarkerPinStandard>
      </OverlayTrigger>
    );
  } else {
    return (
      <div>
        <OverlayTrigger
          // key={props.record.contentId}
          trigger="click"
          placement="bottom"
          rootClose={show}
          container={props.containerRef.current}
          overlay={
            props.details &&
            renderEPNInfo(
              props.details,
              props.entityName,
              props.entity,
              props.openInfoModal,
              props.setInfoModal,
              props.openTabResultsModal,
              props.setTabResultsModal,
              props.openTabResultsType,
              props.setTabResultsType,
              props.setTrackbotTabModal,
              props.tabKeyword,
              props.setTabKeyword,
              setShow,
              modalTitle,
              setModalTitle,
              selected,
              setSelected,
              props.context,
              props.containerRef.current
            )
          }
        >
          <MarkerPinGeneric>{props.name}</MarkerPinGeneric>
        </OverlayTrigger>
      </div>
    );
  }
};

const AiEarthMap: React.FC<IAiEarthMapProps> = (props) => {
  const context = props.context;
  const filterImptyLocation = (entPoint: any) => {
    let jsonArray: any = '';
    if (Object.keys(entPoint).length > 0) {
      for (const val of entPoint) {
        if (val.location) {
          if (jsonArray) {
            jsonArray = jsonArray.concat([val]);
          } else {
            jsonArray = [val];
          }
        }
      }
    }
    return jsonArray;
  };

  const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;
  const allRecords = filterImptyLocation(props.records);
  const containerRef: any = useRef<HTMLDivElement>(null);
  const uniqueEntityRecords = getUniqueSearchEntityRecords(allRecords);
  const [activeMarkerRecord, setActiveMarkerRecord] = useState<ISearchRecord | null>(null);
  const [gMap, setGMap] = useState();
  const [gMaps, setGMaps] = useState();
  const [showNavigation, setShowNavigation] = useState(false);
  const [openInfoModal, setInfoModal] = useState(false);
  const [openTabResultsModal, setTabResultsModal] = useState(false);
  const [openTabResultsType, setTabResultsType] = useState('');
  const [tabKeyword, setTabKeyword] = useState('');

  const getCenterWithOffset = (lat: number, lng: number, offsetX: number, offsetY: number) => {
    if (!gMap || !gMaps) {
      return;
    }
    const currentMapProjection = gMap.getProjection();
    const point = currentMapProjection.fromLatLngToPoint(new gMaps.LatLng(lat, lng));
    // point.x = point.x + offsetX;
    // point.y = point.y + offsetY;
    const newCenterLatLng = currentMapProjection.fromPointToLatLng(point);
    return { lat: newCenterLatLng.lat(), lng: newCenterLatLng.lng() };
  };

  const resetMapToInitial = () => {
    const { zoom } = googleMapReactUtil.fitBounds(getBoundingBox(uniqueEntityRecords), {
      width: 1172,
      height: 235,
    });
    setActiveMarkerRecord(null);
    setZoomLevel(zoom);
  };

  const { center: defaultCenter, zoom: defaultZoom } = googleMapReactUtil.fitBounds(
    getBoundingBox(uniqueEntityRecords),
    {
      width: 1172,
      height: 235,
    }
  );
  const [zoomLevel, setZoomLevel] = useState<number>(defaultZoom);
  const center = activeMarkerRecord
    ? getCenterWithOffset(activeMarkerRecord.location.lat, activeMarkerRecord.location.lon, -0.1, -0.15)
    : defaultCenter;

  const record: ISearchRecord = activeMarkerRecord || dummyRecord;
  const handle = useFullScreenHandle();

  return (
    <MainMapContainer>
      <MapHeader>
        <h2>Map of Document Locations</h2>
      </MapHeader>
      <FullScreen handle={handle}>
        <InnerMapContainer status={handle.active} ref={containerRef}>
          {handle.active ? (
            <FullScreenBtn onClick={handle.exit}>
              <Minimize />
            </FullScreenBtn>
          ) : (
            <FullScreenBtn onClick={handle.enter}>
              <Maximize />
            </FullScreenBtn>
          )}

          {showNavigation && (
            <RecordNavigation
              record={record}
              mapNavigationElementId={MAP_NAVIGATION_ELEMENT_ID}
              records={uniqueEntityRecords}
              onNavigationItemClick={(searchRecord: ISearchRecord) => {
                setActiveMarkerRecord(searchRecord);
                gMap.panTo(getCenterWithOffset(searchRecord.location.lat, searchRecord.location.lon, -0.1, -0.15));
                gMap.setMapTypeId('satellite');
                setZoomLevel(18);
                !handle.active && handle.enter();
              }}
            />
          )}
          <GoogleMapReact
            onChange={(value: ChangeEventValue) => {
              setZoomLevel(value.zoom);
            }}
            bootstrapURLKeys={{
              key: GOOGLE_MAP_KEY,
              libraries: ['places', 'drawing'],
              v: 'beta',
            }}
            onClick={() => {
              // resetMapToInitial();
            }}
            onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => {
              onMapLoaded({ map, maps });
              setGMap(map);
              setGMaps(maps);
              document.addEventListener('DOMNodeInserted', (event) => {
                const target = event.target;
                if (R.isNil(target)) {
                  return;
                }
                const element: HTMLElement = target as HTMLElement;
                if (element && element.id === MAP_NAVIGATION_ELEMENT_ID) {
                  setShowNavigation(true);
                }
              });
            }}
            options={getMapOptions}
            defaultCenter={defaultCenter}
            center={center}
            defaultZoom={defaultZoom}
            zoom={zoomLevel}
          >
            {renderRecordMarkers(uniqueEntityRecords, activeMarkerRecord, (searchRecord: ISearchRecord | null) => {
              if (R.isNil(searchRecord)) {
                return resetMapToInitial();
              }
              setActiveMarkerRecord(searchRecord);
              setZoomLevel(18);
            })}
            {props.docSearchEPNResult &&
              gMap &&
              gMap.getZoom() >= 17 &&
              props.docSearchEPNResult.map((epn: any, index: any) => {
                const detailsObj =
                  epn.details &&
                  typeof Object.keys(epn.details)[0] !== 'undefined' &&
                  epn.details[Object.keys(epn.details)[0]];

                return (
                  <EPNPin
                    key={epn.location.lat + index}
                    marker={epn.marker}
                    details={epn.details}
                    name={epn.name}
                    entityName={detailsObj && detailsObj.agency_doc_regulated_entity_number}
                    entity={activeMarkerRecord && activeMarkerRecord.entityName}
                    lat={epn.location.lat}
                    lng={epn.location.lon}
                    openInfoModal={openInfoModal}
                    setInfoModal={setInfoModal}
                    openTabResultsModal={openTabResultsModal}
                    setTabResultsModal={setTabResultsModal}
                    openTabResultsType={openTabResultsType}
                    setTabResultsType={setTabResultsType}
                    tabKeyword={tabKeyword}
                    setTabKeyword={setTabKeyword}
                    context={context}
                    containerRef={containerRef}
                  />
                );
              })}
          </GoogleMapReact>
        </InnerMapContainer>
      </FullScreen>
    </MainMapContainer>
  );
};

export default AiEarthMap;
