import React, { Fragment, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Tab, Tabs } from 'react-bootstrap';
import { ISearchRecord, IElasticResult } from '../../../types/search';
import { searchAIEarth } from '../../../services/firebase';
import { Spinner } from 'react-bootstrap';
import * as R from 'ramda';
import withAuthContext from '../../../context/auth/AuthConsumer';
import { IAuthContext } from '../../../context/auth/auth-context';
import { TIERS } from '../../../types/tiers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import UserContext from '../../../context/user/user-context';
import AirAiSummary from './AirAiSummary';
import axios from 'axios';
import { projectId } from '../../../services/firebase';
import { CeresLogger } from '../../../logger';
import AirEventTable from './AirEventTable';
import TreeView from '../TreeViewData';
import { Col } from 'react-bootstrap';
import AirDeviationTable from './AirDeviationTable';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

interface IRecordMarkerProps {
  lat: number;
  lng: number;
  isActive: boolean;
  showDocPanel: any;
  showPanel: any;
  setShowDocPanel: any;
  record: ISearchRecord;
  onMarkerClick: (record: any) => void;
  onInfoWindowClose: () => void;
  context: IAuthContext;
  isMap: boolean;
  currentQueryText: string;
  setRelatedDocument: any;
  setAirEventTableOpen: any;
  airEventTableOpen: any;
  setStreetView: any;
  activeTabDrawer: any;

}
// const RecordMarkerInfoWindowContainer = styled.div`
//   position: absolute;
//   bottom: 30px;
//   left: -250px;
//   width: 500px;
//   background: #fff;
//   box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
//   padding: 10px;
//   font-size: 14px;
//   z-index: 100;
// `;

const EntityRecordListContainer = styled.div`
  // height: 160px;
  // overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;

const SampleFileHyperlink = styled.a`
  float: right;
  color: #005fbc !important;
  margin-right: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
`;

const CustomTabs = styled(Tabs)`
  border-bottom: 1px solid #005fbc !important;
  font-size: 14px !important;
`;

const OLContainer = styled.ol`
  padding-inline-start: 20px !important;
  font-size: 0.6rem;
`;

interface IProps {
  isActive?: string;
}

const MarkerPin = styled.div<IProps>`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #005fbc;
  background: var(--primary);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  z-index: 10;
  background: ${(props) => props.isActive};
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #fff;
    background: var(--light);
    position: absolute;
    border-radius: 50%;
  }
`;

const getUrl = (entityNumber: string) => {
  if(!entityNumber) {
    return '#';
  }

  const href = entityNumber.toLowerCase();
  if(href.includes('rn')) {
    return `https://www15.tceq.texas.gov/crpub/index.cfm?fuseaction=regent.validateRE&re_ref_num_txt=${entityNumber}`;
  }

  return 'https://www.deq.louisiana.gov/';
};

const fetchRecordsByRN = (rnNumber: string, searchTerm: string, searchType: string) => {
  return searchAIEarth({
    query: {
      from: 0,
      size: 50,
      text: searchTerm,
      type: searchType,
      terms: {
        regulated_entity_number: rnNumber,
      },
    },
  });
};

// const InfoWindowCloseContainer = styled.div`
//   position: absolute;
//   right: 10px;
//   cursor: pointer;
// `;

const DownloadHyperlink = styled.a`
  color: #005fbc;
  cursor: pointer;
  &:hover {
    color: #ac5e08 !important;
    text-decoration: underline !important;
  }
`;
const IconPegman = styled.img`
  width: 30px;
  cursor: pointer;
  margin: 5px 0;
`;

const RecordMarkerContainer = styled.div``;

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const RecordMarkerInfoWindow: React.FC<{
  routerProps: RouteComponentProps<any>;
  record: ISearchRecord;
  isLoading: boolean;
  activeTabDrawer: any;
  details: any;
  relatedRecords: ISearchRecord[];
  onInfoWindowClose: () => void;
  authContext: IAuthContext;
  setShowDocPanel: any;
  setRelatedDocument: any;
  airAiResults: any;
  totalEvents: any;
  deviations: any;
  setDeviations: any;
}> = (props) => {
  const documentsTabTitle = `Documents(${props.relatedRecords.length})`;
  const authContext = props.authContext;
  const record = props.record;
  const searchContext = useContext(UserContext);
  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner animation="border" variant="primary" />
    </SpinnerContainer>
  );

  const [selectedContentId, setSelectedContentId] = useState<any>();

  const getRecordTitle = (item: ISearchRecord) => `${item.title} - ${item.seriesName} - ${item.releaseDate}`;
  const renderResult = () => {
    return (
      <EntityRecordListContainer>
        <SampleFileHyperlink
          onClick={(e) => {
            e.stopPropagation();
            const ceresSearch = searchContext;
            localStorage.setItem('RnNumber', record.entityNumber);
            localStorage.setItem('searchTerm', ceresSearch.searchTerm);
            window.open('/', '_blank');
          }}
        >
          Search Entity Docs
        </SampleFileHyperlink>
        <h6>
          {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() && !record.entityNumber.toLowerCase().includes('rn') && !record.entityNumber.toLowerCase().includes('ldeq') ? (
            record.entityNumber
          ) : (
            <a target="_blank" rel="noopener noreferrer" href={getUrl(record.entityNumber)}>
              {record.entityNumber}
            </a>
          )}
        </h6>
        <CustomTabs defaultActiveKey={props.activeTabDrawer} id="uncontrolled-tab-example">
          <Tab eventKey="documents" title={documentsTabTitle}>
          <OLContainer>
          {R.compose(
            R.map((relatedRecord: ISearchRecord) => {
              return (
                <li
                  style={{
                    color: relatedRecord.containsSearchTerm ? 'green' : 'none', fontWeight: `${relatedRecord.contentId}_${relatedRecord.containsSearchTerm}` === selectedContentId ? 'bold' : 'normal'
                  }}
                  key={relatedRecord.contentId}
                >
                  {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
                    getRecordTitle(relatedRecord)
                  ) : (
                    <DownloadHyperlink
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.setShowDocPanel(true);
                        props.setRelatedDocument(relatedRecord);
                        setSelectedContentId(`${relatedRecord.contentId}_${relatedRecord.containsSearchTerm}`);
                        // if (doStorageDownload(relatedRecord.entitySource)) {
                        //   const storageRef = getStorageRef(
                        //     relatedRecord.entitySource
                        //   );
                        //   if (R.isNil(storageRef)) {
                        //     return toast.error(
                        //       `Oops, could not find the file.`
                        //     );
                        //   }
                        //   return storageRef
                        //     .ref(relatedRecord.fileName)
                        //     .getDownloadURL()
                        //     .then(url =>
                        //       window.open(url, '_blank', 'noopener,noreferrer')
                        //     )
                        //     .catch(err => toast.error(err.message));
                        // }
                        // if (!relatedRecord.fileUrl) {
                        //   return toast.warn(
                        //     <span>
                        //       No associated file was found for the content id{' '}
                        //       <b>{relatedRecord.contentId}</b>
                        //     </span>
                        //   );
                        // }
                        // window.open(relatedRecord.fileUrl, '_blank');
                      }}
                    >
                      {getRecordTitle(relatedRecord)}
                    </DownloadHyperlink>
                  )}
                </li>
              );
            }),
            R.sort(
              R.comparator(
                (itemOne: ISearchRecord, itemTwo: ISearchRecord) => itemOne.releaseDate > itemTwo.releaseDate
              )
            )
          )(props.relatedRecords)}
        </OLContainer>
          </Tab>
          <Tab eventKey="permits" title={`Permits(${props.details ? Object.keys(props.details).length : ''})`}>
            <TreeView details={props.details} />
          </Tab>
          {props.airAiResults && props.airAiResults.length > 0 && (
            <Tab
              eventKey="emissionEvents"
              title={`Emission Events(${props.totalEvents ? props.airAiResults.length : ''})`}
            >
              {props.totalEvents && (
                <>
                  <Col lg={10} sm={12} style={{ fontSize: '14px' }}>
                    Total events: {props.airAiResults.length} (since 2004) <br />
                    Total released: {props.totalEvents ? props.totalEvents.toFixed(2) : ''} lbs
                  </Col>
                  <AirEventTable events={props.airAiResults} />
                </>
              )}
            </Tab>
          )}
          {props.deviations && props.deviations.length > 0 && (
            <Tab eventKey="" title={`Deviations(${props.deviations ? Object.keys(props.deviations).length : ''})`}>
              <AirDeviationTable events={props.deviations} />
            </Tab>
          )}
        </CustomTabs>
      </EntityRecordListContainer>
    );
  };
  return (
    // <RecordMarkerInfoWindowContainer>
    //   <InfoWindowCloseContainer onClick={() => props.onInfoWindowClose()}>
    //     <X />
    //   </InfoWindowCloseContainer>
    <Fragment>
      <h6>{record.entityName}</h6>
      {props.isLoading && renderSpinner()}
      {!props.isLoading && renderResult()}
    </Fragment>
    // </RecordMarkerInfoWindowContainer>
  );
};

const RecordMarker: React.FC<IRecordMarkerProps & RouteComponentProps<any>> = (props) => {
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [relatedRecords, setRelatedRecords] = useState<ISearchRecord[]>([]);
  const entityNumber = props.record.entityNumber;
  const authContext = props.context;
  const { isMap } = props;
  const [airAiResults, setAirAiResults] = useState();
  const [deviations, setDeviations] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const numIncidents = airAiResults ? airAiResults.length : 0;

  async function programQueryDetails() {
    const requestBody = { RN: entityNumber };
    try {
      setIsLoading(true);
      axios
        .post(
          'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryDetails',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
          requestBody,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const detailsJson = response.data[0].get_prgm_json_for_entity;
          setDetails(detailsJson);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  }

  const fetchAirAIRNStats = (value: any) => {
    try {
      axios
        .post(
          'https://us-central1-' + projectId + '.cloudfunctions.net/api/fetchAirAIRNStats',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/fetchAirAIRNStats',
          { RN: value },
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const emissionEventsJson =
              typeof response.data[0].get_emission_events !== 'undefined' ? response.data[0].get_emission_events : [];
            setAirAiResults(emissionEventsJson);

            const total = emissionEventsJson.reduce((acc: number, item: any) => acc + item.amount, 0);
            setTotalAmount(total);
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const fetchDeviationRecords = () => {
    const requestBody = { RN: entityNumber };
    try {
      axios
        .post(
          'https://us-central1-' + projectId + '.cloudfunctions.net/api/fetchDeviationRecords',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/fetchDeviationRecords',
          requestBody,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const deviationsJson = response.data[0].aai_get_deviations_tab_json;
          setDeviations(deviationsJson);
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  useEffect(() => {
    async function fetchRecords() {
      let isSubscribed = true;
      const containsSearchTerm = await fetchRecordsByRN(entityNumber, props.currentQueryText, 'must');
      const data = containsSearchTerm.data as IElasticResult<ISearchRecord>;
      let relatedRecordsData;
      if (data.records.length < 50) {
        const containsSearchTermFalse = await fetchRecordsByRN(entityNumber, props.currentQueryText, 'must_not');
        const containsSearchTermFalseData = containsSearchTermFalse.data as IElasticResult<ISearchRecord>;
        relatedRecordsData = (containsSearchTermFalseData && containsSearchTermFalseData.records) || [];
      }
      let records = (data && data.records) || [];
      records.map((rec) => {
        rec.containsSearchTerm = true;
        return rec;
      });
      if (relatedRecordsData) {
        relatedRecordsData.map((rec) => {
          rec.containsSearchTerm = false;
          return rec;
        });
      }
      records = relatedRecordsData ? records.concat(relatedRecordsData) : records;
      isSubscribed && setRelatedRecords(records);
      setIsLoading(false);
      return () => {
        isSubscribed = false;
      };
    }
    if (props.showPanel) {
      fetchRecords();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityNumber, props.currentQueryText, props.showPanel, totalAmount, props.match.path]);

  useEffect(() => {
    if (props.showPanel) {
      programQueryDetails();
      fetchAirAIRNStats(entityNumber);
      fetchDeviationRecords();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityNumber]);
  return (
    <RecordMarkerContainer>
      {isMap && (
        <OverlayTrigger
          key={props.record.contentId}
          trigger="hover"
          placement="bottom"
          delay={{ show: 200, hide: 1200 }}
          overlay={
            <ReactTooltip effect="solid" html={true} />
          }
        >
          <MarkerPin
            data-tip={
              ReactDOMServer.renderToString(
                <div>
                   <strong>{props.record.entityNumber}</strong>
              <br />
              {props.record.entityName}
              <br />
              {/* Program Symbol:{props.record.programSymbol} */}
              <IconPegman
                src="/icon/pegman.jpg"
                onClick={() => {
                  props.setStreetView(props.record.location);
                }}
              />
                </div>
              )
            }
            isActive={props.isActive ? '#336195' : '#005FBC'}
            onClick={(e: any) => {
              props.onMarkerClick(props.record);
              e.stopPropagation();
            }}
          />
        </OverlayTrigger>
      )}
      {props.isActive && !isMap && (
        <>
          <AirAiSummary
            isActive={props.isActive}
            path={props.match.path}
            totalAmount={totalAmount}
            numIncidents={numIncidents}
            airEventTableOpen={props.airEventTableOpen}
            setAirEventTableOpen={props.setAirEventTableOpen}
          />
          {props.airEventTableOpen && <AirEventTable events={airAiResults} />}
          {!props.airEventTableOpen && (
            <RecordMarkerInfoWindow
              routerProps={props}
              authContext={authContext}
              details={details}
              onInfoWindowClose={props.onInfoWindowClose}
              record={props.record}
              isLoading={isLoading}
              relatedRecords={relatedRecords}
              setShowDocPanel={props.setShowDocPanel}
              setRelatedDocument={props.setRelatedDocument}
              activeTabDrawer={props.activeTabDrawer}
              airAiResults={airAiResults}
              totalEvents={totalAmount}
              deviations={deviations}
              setDeviations={setDeviations}
            />
          )}
        </>
      )}
    </RecordMarkerContainer>
  );
};

export default withRouter(withAuthContext(RecordMarker));
