import * as R from 'ramda';

import { Button, OverlayTrigger, Spinner } from 'react-bootstrap';

import { ISearchRecord } from '../../../types/search';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
// import { Rating } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

interface IRecordNavigationProps {
  records: ISearchRecord[];
  record: ISearchRecord;
  onNavigationItemClick: (record: ISearchRecord, e: any) => void;
  // mapNavigationElementId: string;
  SearchForm: React.StatelessComponent;
  SearchPagination: any; // React.StatelessComponent;
  onPreviousClick: () => void;
  onNextClick: () => void;
  favoritesFn?: (record: any) => void;
  checkIsFav?: (contentId: any) => boolean;
  result: any;
  showRecordsLoader: boolean;
}

const RecordNavigationContainer = styled.div`
  background: rgba(108, 117, 125, 0.5);
  padding: 8px;
  padding-bottom: 0;
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .scroll-content > div:first-child {
      margin-bottom: 1.5rem !important;
  }
`;
const FavIconBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  background-color: rgb(0, 95, 188);
  margin-bottom: 10px;
`;

const RecordNavigationButtonsContainer = styled.div`
  margin-top: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const NavigationItemPortal: React.FC<{
//   mapNavigationElementId: string;
// }> = props => {
//   const mapNavigationElement = document.getElementById(
//     props.mapNavigationElementId
//   );
//   if (R.isNil(mapNavigationElement)) {
//     return <></>;
//   }
//   return ReactDOM.createPortal(props.children, mapNavigationElement);
// };

const RecordNavigation: React.FC<IRecordNavigationProps> = (props) => {
  const { SearchForm, SearchPagination, onPreviousClick, onNextClick, result, showRecordsLoader } = props;
  // const { SearchForm, SearchPagination } = props;
  // console.log("SearchPagination", SearchPagination);
  const totalRecords = (result && result.total) || 0;

  const onFavoriteClick = (record: ISearchRecord, isFav?: any) => {
    if(props.favoritesFn) {
      if(isFav) {
        props.favoritesFn({
          type: 'remove',
          bodyData: {
            contentId: record.contentId,
          },
        });
      } else {
        props.favoritesFn({
          type: 'create',
          bodyData: record,
        });
      }
    }
  };

  return (
    // <NavigationItemPortal mapNavigationElementId={props.mapNavigationElementId}>
    <RecordNavigationContainer>
      <SearchForm />
      <RecordNavigationButtonsContainer>
        <Scrollbars className="scroll-content" style={{ height: 'calc(100vh - 210px)' }}>
          {R.map((record: ISearchRecord) => {
            return (
              <OverlayTrigger
                key={record.contentId}
                trigger="hover"
                placement="bottom"
                overlay={
                  <ReactTooltip effect="solid" html={true} />
                }
              >
                <FavIconBtn>
                <Button
                  data-tip={
                    ReactDOMServer.renderToString(
                      <div>
                       <strong>{record.entityNumber}</strong>
                    <br />
                    {record.cityName}
                      </div>
                    )
                  }
                  style={{
                    backgroundColor: props.record.entityNumber === record.entityNumber ? '#005FBC' : '#005FBC', border:'none'
                  }}
                  onClick={(e: any) => {
                    props.onNavigationItemClick(record, e);
                  }}
                  size="sm"
                  block={true}
                >
                  {record.entityName || record.entityNumber}
                </Button>
                {props.checkIsFav && props.checkIsFav(record.contentId) ?
                <OverlayTrigger
                overlay={<ReactTooltip place="right" effect="solid" />}
                  >
                    <IconButton onClick={() =>
                  onFavoriteClick(
                    record,
                    true
                  )} aria-label="fav-icon" component="span" data-tip="Remove from Favorites">
                  <StarIcon style={{color: '#faaf00'}} />
                </IconButton>
              </OverlayTrigger>
               :
               <OverlayTrigger
                  overlay={<ReactTooltip place="right" effect="solid" />}
                    >
                 <IconButton onClick={() =>
                  onFavoriteClick(
                    record,
                    false
                  )} aria-label="fav-icon" component="span" data-tip="Add to Favorites">
                  <StarBorderIcon style={{color: '#faaf00'}} />
                </IconButton>
              </OverlayTrigger>
                 }
                {/* <Rating
                    onClick={() =>
                      onFavoriteClick(
                        record,
                        props.checkIsFav && props.checkIsFav(record.contentId)
                      )
                    }
                    defaultValue={
                      props.checkIsFav && props.checkIsFav(record.contentId)
                        ? 1
                        : 0
                    }
                    max={1}
                  /> */}
                </FavIconBtn>
              </OverlayTrigger>
            );
          }, props.records)}
          {showRecordsLoader &&
          <SpinnerContainer>
            <Spinner
              as="span"
              variant="primary"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </SpinnerContainer>}
          {totalRecords ? (
            <SearchPagination
              isDisabled={false}
              isFirstPage={(result && result.from === 0) || false}
              isLastPage={(result && result.from + result.size >= result.total) || false}
              totalRecords={totalRecords.value}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          ) : null}
        </Scrollbars>
      </RecordNavigationButtonsContainer>
    </RecordNavigationContainer>
    // </NavigationItemPortal>
  );
};

export default RecordNavigation;

//     isDisabled={false}
//     isFirstPage={(props.result && props.result.from === 0) || false}
//     isLastPage={
//       (props.result &&
//         props.result.from + props.result.size >= props.result.total) ||
//       false
//     }
//     totalRecords={(props.result && props.result.total) || 0}
//     onPreviousClick={props.onPreviousClick}
//     onNextClick={props.onNextClick}
