import * as R from 'ramda';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IJob } from '../../store/aiearth/types';
import MUIButton from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Table } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { updateJob } from '../../store/aiearth/action';
import { v4 as uuidv4 } from 'uuid';

const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  & * {
    margin-left: 2px;
  }
`;

const JobButton = styled(MUIButton)`
  margin: 0.2rem !important;
`;

const JobsIdentifyManagers: React.FC<any> = (props) => {
  const jobsIdentifyManagersInitial = {
    id: null,
    name: '',
    email: '',
    phone: '',
  };

  const [jobIdentifyManagers, setJobIdentifyManagers] = useState(
    jobsIdentifyManagersInitial
  );
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;

  return (
    <>
      <CustomForm>
        <Row>
          <TextField
            label="Name"
            autoFocus
            id="name"
            fullWidth
            variant="outlined"
            size="small"
            value={jobIdentifyManagers.name}
            onChange={(e: any) => {
              setJobIdentifyManagers({
                ...jobIdentifyManagers,
                name: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            size="small"
            value={jobIdentifyManagers.email}
            onChange={(e: any) => {
              setJobIdentifyManagers({
                ...jobIdentifyManagers,
                email: e.target.value,
              });
            }}
          />
           </Row>
           <Row>
          <TextField
            label="Phone"
            id="phone"
            variant="outlined"
            fullWidth
            size="small"
            value={jobIdentifyManagers.phone}
            onChange={(e: any) => {
              setJobIdentifyManagers({
                ...jobIdentifyManagers,
                phone: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <JobButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => {
              const managersArray: any = jobState.activeJob.keySiteManagers;
              if (jobIdentifyManagers && jobIdentifyManagers.id) {
                const index = R.findIndex(
                  R.propEq('id', jobIdentifyManagers.id)
                )(managersArray);
                managersArray[index] = jobIdentifyManagers;
              } else {
                managersArray.push({
                  id: uuidv4(),
                  name: jobIdentifyManagers.name,
                  email: jobIdentifyManagers.email,
                  phone: jobIdentifyManagers.phone,
                });
              }
              const activeJobObj = jobState.activeJob;
              activeJobObj.keySiteManagers = managersArray;
              dispatch(
                updateJob({
                  ...jobState,
                  ...{
                    activeJob: activeJobObj,
                  },
                })
              );
              setJobIdentifyManagers(jobsIdentifyManagersInitial);
            }}
          >
            {jobIdentifyManagers.id ? 'Save' : 'Add'}
          </JobButton>
        </Row>
        <Table bordered hover>
          <tbody>
            {jobState.activeJob.keySiteManagers.length > 0 &&
              jobState.activeJob.keySiteManagers.map(
                (property: any, index: any) => {
                  return (
                    <tr key={'Managers' + index}>
                      <td>{property.name}</td>
                      <td>{property.email}</td>
                      <td>
                        <JobButton
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setJobIdentifyManagers(property);
                          }}
                        />
                        <JobButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            const ManagersArray: any =
                              jobState.activeJob.keySiteManagers;
                            const deleteIndex = R.findIndex(
                              R.propEq('id', property.id)
                            )(ManagersArray);
                            ManagersArray.splice(deleteIndex, 1);
                            const activeJobManagersObj = jobState.activeJob;
                            activeJobManagersObj.keySiteManagers = ManagersArray;
                            dispatch(
                              updateJob({
                                ...jobState,
                                ...{
                                  activeJob: activeJobManagersObj,
                                },
                              })
                            );
                          }}
                        />
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
      </CustomForm>
    </>
  );
};

export default JobsIdentifyManagers;
