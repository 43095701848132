import React from 'react';

export const useContainerDimensions = (myRef: any) => {
  const getDimensions = () => ({
    width: myRef.current ? myRef.current.offsetWidth : '180px',
    height: myRef.current ? myRef.current.offsetHeight : '180px',
  });

  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  return dimensions;
};
