import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import styled from 'styled-components';

interface IHomeTourProps {
  showTour: boolean;
  onCloseTour: () => void;
  onGotItClick: () => void;
}

const GotItButton = styled.div`
  background: #005fbc;
  background: var(--primary);
  color: #fff;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
`;

const HomeTour: React.FC<IHomeTourProps> = (props: IHomeTourProps) => {
  const [step, setStep] = useState(0);

  const onEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      if (step === 2) {
        props.onGotItClick();
        props.onCloseTour();
      } else {
        setStep(step + 1);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEnterPress, false);
    return () => {
      document.removeEventListener('keydown', onEnterPress, false);
    };
  });

  return (
    <Tour
      steps={[
        {
          selector: '[data-tour="ceres-first-step"]',
          content: () => {
            return (
              <div>
                <div>
                  Welcome to CERES! Type any search term into this text box.
                </div>
                <div>
                  For example, you could type "net heating value" or "leaking
                  petroleum storage tank".
                </div>
                <div>
                  Then press <b>Enter</b> or click the search button
                </div>
              </div>
            );
          },
          position: 'right',
        },
        {
          selector: '[data-tour="ceres-second-step"]',
          content: () => {
            return (
              <div>
                <div>
                  You can click <b>"Need help?"</b> at any time if you feel
                  unsure about what to do.
                </div>
              </div>
            );
          },
          position: 'top',
        },
        {
          selector: '[data-tour="ceres-third-step"]',
          content: () => {
            return (
              <div>
                Click on this menu to access more features or upgrade your
                account.
              </div>
            );
          },
          position: 'top',
        },
      ]}
      lastStepNextButton={
        <GotItButton
          onClick={() => {
            props.onGotItClick();
            props.onCloseTour();
          }}
        >
          Got it.
        </GotItButton>
      }
      accentColor="#005FBC"
      badgeContent={(currentPage, totalPage) =>
        `${currentPage} of ${totalPage}`
      }
      isOpen={props.showTour}
      onRequestClose={() => props.onCloseTour()}
      goToStep={step}
    />
  );
};

export default HomeTour;
