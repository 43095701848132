import * as firebase from 'firebase/app';
import React from 'react';
import { auth, ceresApp } from '../../services/firebase';
import { TIERS } from '../../types/tiers';
import { ICeresUser } from '../../interfaces/user';

export const authInitialState = {
  createUserWithEmailAndPassword: (email: string, password: string) =>
    auth.createUserWithEmailAndPassword(email, password),
  isAuthenticated: false,
  isInitialized: false,
  claims: null,
  isTier: (tier: TIERS) => tier === authInitialState.getUserTier(),
  isLubrizol: () => false,
  getUserTier: () => TIERS.FREE,
  getUserEmail: () => '',
  getUserName: () => '',
  getUserPhone: () => '',
  getClientName: () => '',
  getClientAddress: () => '',
  getUserDisplayName: () => '',
  getUserId: () => '',
  sendPasswordResetEmail: (email: string) => auth.sendPasswordResetEmail(email),
  signInWithEmailAndPassword: (email: string, password: string) => auth.signInWithEmailAndPassword(email, password),
  signInWithGoogle: () => ceresApp.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()),
  signInWithSSO: () => ceresApp.auth().signInWithPopup(new firebase.auth.SAMLAuthProvider('saml.saml')),
  signOut: () => auth.signOut(),
  user: null,
  getCompleteUserInfo: () => null,
};

const AuthContext = React.createContext<IAuthContext>(authInitialState);
export interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: firebase.User | null;
  claims: any;
  signInWithGoogle: () => Promise<firebase.auth.UserCredential>;
  signInWithSSO: () => Promise<firebase.auth.UserCredential>;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  isTier: (tier: TIERS) => boolean;
  isLubrizol: () => boolean;
  getUserTier: () => TIERS;
  getUserEmail: () => string;
  getUserName: () => string;
  getUserPhone: () => string;
  getClientName: () => string;
  getClientAddress: () => string;
  getUserDisplayName: () => string;
  getUserId: () => string;
  getCompleteUserInfo: () => ICeresUser | null;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
}

export default AuthContext;
