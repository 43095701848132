import React from 'react';
import * as R from 'ramda';
import { OverlayTrigger, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ISearchRecord } from '../../../types/search';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

interface IRecordNavigationProps {
  records: ISearchRecord[];
  record: ISearchRecord;
  onNavigationItemClick: (record: ISearchRecord) => void;
  mapNavigationElementId: string;
}

const RecordNavigationContainer = styled.div`
  background: rgba(108, 117, 125, 0.5);
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
`;

const NavigationItemPortal: React.FC<{
  mapNavigationElementId: string;
}> = (props) => {
  const mapNavigationElement = document.getElementById(props.mapNavigationElementId);
  if (R.isNil(mapNavigationElement)) {
    return <></>;
  }
  return ReactDOM.createPortal(props.children, mapNavigationElement);
};

const RecordNavigation: React.FC<IRecordNavigationProps> = (props) => {
  return (
    <NavigationItemPortal mapNavigationElementId={props.mapNavigationElementId}>
      <RecordNavigationContainer>
        {R.map((record: ISearchRecord) => {
          return (
            <OverlayTrigger
              key={record.contentId}
              trigger="hover"
              placement="bottom"
              overlay={
                <ReactTooltip effect="solid" html={true} />
              }
            >
              <Button
                data-tip={
                  ReactDOMServer.renderToString(
                    <div>
                      <strong>{record.entityNumber}</strong>
                  <br />
                  {record.cityName}
                    </div>
                  )
                }
                style={{
                  backgroundColor: props.record.entityNumber === record.entityNumber ? '#336195' : '#005FBC',
                }}
                onClick={() => props.onNavigationItemClick(record)}
                size="sm"
                block={true}
              >
                {record.entityName}
              </Button>
            </OverlayTrigger>
          );
        }, props.records)}
      </RecordNavigationContainer>
    </NavigationItemPortal>
  );
};

export default RecordNavigation;
