import React from 'react';
import AuthContext, { IAuthContext } from './auth-context';

const withAuthContext = (
  Component: React.ComponentClass<any> | React.FunctionComponent<any>
) => {
  return (props: any) => (
    <AuthContext.Consumer>
      {(context: IAuthContext) => <Component {...props} context={context} />}
    </AuthContext.Consumer>
  );
};

export default withAuthContext;
