import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import AnnualAmissionChart from './AnnualAmissionChart';

interface IEmissions {
  result: any;
  entity: any;
}
const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
`;
const TableContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 40px;
`;
const TD = styled.td`
  font-size: 16px;
  text-align: center;
`;
const TH = styled.th`
  font-size: 16px;
  text-align: center;
`;
const TableHeading = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
`;
const Rows = styled(Row)`
  border: 1px solid #000;
  margin: 0 !important;
`;
const Cols = styled(Col)`
  border: 1px solid #000;
`;
const AirEmissions: React.FC<IEmissions> = (props) => {
  const statsResult = props.result.Graphs;
  const tableData = props.result.Tables.facility_pollutant;
  const entity = props.entity;

  const co2eOptions = {
    color: '#286ab3',
    title: statsResult.co2e_graph.title,
    max_y_axis: statsResult.co2e_graph.max_y_axis,
    fData: statsResult.co2e_graph.plot_lines.ghg_plot_line,
    imData: statsResult.co2e_graph.plot_lines.industry_median_ghg_plot_line,
    labels: statsResult.co2e_graph.labels
  };
  const vocOptions = {
    color: '#286ab3',
    title: statsResult.voc_graph.title,
    max_y_axis: statsResult.voc_graph.max_y_axis,
    fData: statsResult.voc_graph.plot_lines.voc_plot_line,
    imData: statsResult.voc_graph.plot_lines.industry_median_voc_plot_line,
    labels: statsResult.voc_graph.labels
  };
  const so2Options = {
    color: '#286ab3',
    title: statsResult.so2_graph.title,
    max_y_axis: statsResult.so2_graph.max_y_axis,
    fData: statsResult.so2_graph.plot_lines.so2_plot_line,
    imData: statsResult.so2_graph.plot_lines.industry_median_so2_plot_line,
    labels: statsResult.so2_graph.labels
  };
  const pm25Options = {
    color: '#286ab3',
    title: statsResult.pm25_graph.title,
    max_y_axis: statsResult.pm25_graph.max_y_axis,
    fData: statsResult.pm25_graph.plot_lines.pm25_plot_line,
    imData: statsResult.pm25_graph.plot_lines.industry_median_pm25_plot_line,
    labels: statsResult.pm25_graph.labels
  };
  const pm10Options = {
    color: '#286ab3',
    title: statsResult.pm10_graph.title,
    max_y_axis: statsResult.pm10_graph.max_y_axis,
    fData: statsResult.pm10_graph.plot_lines.pm10_plot_line,
    imData: statsResult.pm10_graph.plot_lines.industry_median_pm10_plot_line,
    labels: statsResult.pm10_graph.labels
  };
  const pbOptions = {
    color: '#286ab3',
    title: statsResult.pb_graph.title,
    max_y_axis: statsResult.pb_graph.max_y_axis,
    fData: statsResult.pb_graph.plot_lines.pb_plot_line,
    imData: statsResult.pb_graph.plot_lines.industry_median_pb_plot_line,
    labels: statsResult.pb_graph.labels
  };
  const coOptions = {
    color: '#286ab3',
    title: statsResult.co_graph.title,
    max_y_axis: statsResult.co_graph.max_y_axis,
    fData: statsResult.co_graph.plot_lines.co_plot_line,
    imData: statsResult.co_graph.plot_lines.industry_median_co_plot_line,
    labels: statsResult.co_graph.labels
  };
  const noxOptions = {
    color: '#286ab3',
    title: statsResult.nox_graph.title,
    max_y_axis: statsResult.nox_graph.max_y_axis,
    fData: statsResult.nox_graph.plot_lines.nox_plot_line,
    imData: statsResult.nox_graph.plot_lines.industry_median_nox_plot_line,
    labels: statsResult.nox_graph.labels
  };
  return (
    <ResultContainer>
      <TableContainer>
        <Row>
            <Col md={12}>
              <TableHeading>
                <h4>Facility Pollutant Ranking - Annual Emissions</h4>
              </TableHeading>
              <Table bordered hover>
                <thead>
                  <tr>
                    <TH>Facility Rank</TH>
                    <TH>Pollutant</TH>
                    <TH>Amount(tons/year)</TH>
                  </tr>
                </thead>
                <tbody>
                {tableData.data.map((ele: any, key: string) => {
                  return (
                    <tr key={'Air' + key} className={ele.amount ? '' : 'hide'}>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>{ele.rank}</b>
                        ) : (
                            ele.rank
                          )}
                      </TD>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>{ele.pollutant}</b>
                        ) : (
                            ele.pollutant
                          )}
                      </TD>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>
                            {ele.amount.toLocaleString(
                              navigator.language,
                              { minimumFractionDigits: 0 }
                            )}
                          </b>
                        ) : (
                            ele.amount.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0
                            })
                          )}
                      </TD>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
            </Col>
        </Row>
      </TableContainer>
      <Rows>
        <Cols md={6}>
          <AnnualAmissionChart options={noxOptions} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={vocOptions} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={so2Options} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={coOptions} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={pm25Options} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={pm10Options} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={pbOptions} />
        </Cols>
        <Cols md={6}>
          <AnnualAmissionChart options={co2eOptions} />
        </Cols>
      </Rows>
    </ResultContainer>
  );
};

export default AirEmissions;
