import React from 'react';
import styled from 'styled-components';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

import NotificationPage from '../../../pages/Notification';

const TransitionPopup = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const DialogCust = styled(Dialog)`
  z-index: 9999 !important;
  & table thead tr {
    top: -20px !important;
  }
`;

const MotifyArea = styled.div`
  & .sub-track {
    margin: 0 !important;
  }
  & .track-area {
    box-shadow: unset !important;
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      zIndex: 9999,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: '16px !important',
    border: '0px',
    borderRadius: '4px',
  },
}))(MuiDialogContent);

interface IModalParam {
  open: boolean;
  setOpen: (open: boolean) => void;
  context: any;
}

const TrackbotTabModal: React.FC<IModalParam> = ({
  open,
  setOpen,
  context,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogCust
        onClose={handleClose}
        TransitionComponent={TransitionPopup}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'lg'}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          test
        </DialogTitle>
        <DialogContent
          dividers
          style={{ minWidth: '600px', minHeight: '250px', maxHeight: '600px' }}
        >
          <MotifyArea>
            <NotificationPage context={context} />
          </MotifyArea>
        </DialogContent>
      </DialogCust>
    </div>
  );
};

export default TrackbotTabModal;
