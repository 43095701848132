import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Upload } from 'react-feather';
import { Spinner } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import PaperContainer from '../components/PaperContainer';
import { CeresLogger } from '../logger';
import { validatorStorage, addJob } from '../services/firebase';
import showToastMessage from '../util/showToastMessage';

export const isExxonMobileEmail = (authContext: IAuthContext): boolean => {
  return !!(
    authContext &&
    authContext.claims &&
    authContext.claims.email &&
    authContext.claims.email.includes('@exxonmobil.com')
  ) && Boolean(process.env.REACT_APP_ENVIROCHAT_DISABLE_EXXON);
};

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important
`;

const ValidatorWrapper = styled.div`
  width: 100%;
`;

const ValidatorContainer = styled.div`
  margin: 4em 5em;
`;

const ValidatorText = styled.div`
  margin: 2em 5em;
  padding-bottom: 5%;

  .note {
    color: red;
    text-align: center;
    font-weight: bold;
  }
`;

const FileDropText = styled.p`
  font-size: 20px;
  padding-top: 3%;
  text-align: center;
`;

const FileDropIcon = styled.div`
  color: #005fbc;
  color: var(--primary);
  text-align: center;
  padding-top: 3%;
  padding-bottom: 5%;
`;

const ValidatorPage: React.FC<{ context: IAuthContext }> = ({ context: authContext }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!authContext.user || !authContext.isAuthenticated) {
        return;
      }
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      setIsLoading(true);
      try {
        setIsLoading(true);
        const timeStamp = +new Date();
        await validatorStorage
          .ref()
          .child(timeStamp + '#' + file.name)
          .put(file);
        const email = authContext.getUserEmail();
        await addJob({
          email,
          file_path: timeStamp + '#' + file.name,
          file_name: '',
        });
        toast.success(`File uploaded successfully. We'll email ${email} when validation is complete.`);
      } catch (e) {
        CeresLogger.error(e);
        toast.error(`Failed to upload file for validation. Please contact the administrator if issue persists.`);
      } finally {
        setIsLoading(false);
      }
    },
    [authContext]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleClick = (event: any) => {
    if (isExxonMobileEmail(authContext)) {
      event.preventDefault(); // Prevent the default click action
      showToastMessage({
        type: 'warn',
        title: 'Access Denied',
        description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
      });
    }
  };

  return (
    <ValidatorWrapper>
      <ValidatorContainer>
        <h3>Phase 1 Validator from CERES</h3>
        <PaperContainer {...getRootProps()}>
          <input {...getInputProps()} disabled={isExxonMobileEmail(authContext)} />
          <FileDropText>
            {isDragActive ? 'Drop ' : 'Drag and Drop your Phase 1 pdf '}
            here, for validation.
          </FileDropText>
          <FileDropIcon onClick={handleClick}>
            {isLoading ? <Spinner variant="primary" animation="border" /> :
              isExxonMobileEmail(authContext) ? <>
                <div data-tip="This feature is disabled for ExxonMobil. Please contact the Administrator." data-for="exxon">
                  <Upload size="50" color="gray" />
                </div>
                <StyledTooltip id="exxon" place="bottom" effect="solid"/>
              </> : <Upload size="50" />
            }
          </FileDropIcon>
          <ValidatorText>
            { isExxonMobileEmail(authContext) && <p className="note">*This feature is disabled for ExxonMobil. Please contact the Administrator.</p>}
            <p>
              The Phase 1 Validator service from CERES improves the efficiency and comprehension of processing phase one
              environmental reports for bankers, real estate agents, commercial real estate developers and prospective
              buyers.{' '}
            </p>
            <p>
              Environmental site assessment reports, known simply as “Phase 1 Reports”, can be complex and seemingly
              cryptic. For many roles who routinely receive these reports, there is a shortlist of essential facts to
              extract from a large amount of data. The Phase 1 Validator from CERES brings these key points to the
              forefront, allowing for faster decisions and comprehension of environmental reports.
            </p>
          </ValidatorText>
        </PaperContainer>
      </ValidatorContainer>
    </ValidatorWrapper>
  );
};

export default withAuthContext(ValidatorPage);
