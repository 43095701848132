import React, { useState } from 'react';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TourDetails from './TourDetails';

const ReportWrapper = styled.div`
  width: 98%;
  margin: 0px auto;
`;
const CustomFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 4px;
`;
const CustomButton = styled.button`
  background-color: transparent;
  border: 0;
  color: var(--blue);
  padding: 0;
  &:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Report: React.FC<{ context: IAuthContext }> = () => {
  const [tourReport, setTourReport] = useState('');
  const [isTourOpen, setTourOpen] = useState(false);

  const storeLink = 'https://storage.googleapis.com/ceres-maps/ceres_store/';

  return (
    <ReportWrapper>
      <h3 className="text-center mt-5 mb-5">Report Tour</h3>
      <Form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
        }}
      >
        <TourDetails
          showTour={isTourOpen}
          onGotItClick={() => {
            localStorage.setItem('walkthrough', 'false');
          }}
          onCloseTour={() => {
            setTourOpen(false);
          }}
        />
        <Form.Group controlId="validationCustom02">
          <Row className="justify-content-center">
            <Col md={5}>
              <CustomFormControl size="small" variant="outlined">
                <Autocomplete
                  options={[
                    '',
                    'H078.9  Crosby & Overton - Long Beach, CA  USA [2021]',
                    'H128.18  Kinsbursky Brothers - Anaheim, CA  USA [2021]',
                    'H472.7  Stericycle - Vernon, CA  USA [2020]',
                  ]}
                  value={tourReport}
                  getOptionLabel={(option: any) => option}
                  getOptionSelected={(option: string, value: string) =>
                    option === value
                  }
                  fullWidth
                  onChange={async (event: any, newValue: any) => {
                    setTourReport(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Please select a report"
                      variant="outlined"
                    />
                  )}
                />
              </CustomFormControl>
              {tourReport && (
                <Row className="mt-5">
                  <Col>
                    <a
                      href={storeLink + tourReport + '.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Click to download this report
                    </a>
                  </Col>
                  <Col>
                    <CustomButton onClick={() => setTourOpen(true)}>
                      Click to take a tour of this report
                    </CustomButton>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </ReportWrapper>
  );
};

export default withAuthContext(Report);
