import React, { useEffect, useState } from 'react';
import PaperContainer from '../../components/PaperContainer';
import styled from 'styled-components';
import { Check, X } from 'react-feather';
import { db } from '../../services/firebase';
import withAuthContext from '../../context/auth/AuthConsumer';
import { IAuthContext } from '../../context/auth/auth-context';
import { CeresLogger } from '../../logger';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import * as R from 'ramda';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface ICheckoutResultProps {
  checkoutSessionId: string;
  isSuccess: boolean;
}

const CheckoutResultContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 100%;
  display: flex;
`;

const CheckoutResultSpinnerContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const CheckoutResultHero = styled(PaperContainer)`
  min-height: 500px;
  min-width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CheckoutResultText = styled.div`
  margin: 2em 0;
`;

const CheckoutResultIcon = styled.div<{ success: boolean }>`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  svg {
    width: 70px;
    height: 70px;
    color: ${props => (props.success ? 'var(--primary)' : 'var(--danger)')};
  }
`;

const renderCheckoutSessionStatus = (
  isSuccess: boolean,
  authContext: IAuthContext,
  transactionStatus: string
) => {
  return R.cond<string, any>([
    [
      R.equals('INCOMPLETE'),
      R.always(
        <CheckoutResultIcon success={false}>
          <X />
          <CheckoutResultText>
            <span>
              Transaction is under review, we will update you soon on your
              transaction.
            </span>
          </CheckoutResultText>
          <Link to="/">Go home</Link>
        </CheckoutResultIcon>
      )
    ],
    [
      R.equals('COMPLETE'),
      R.always(
        <CheckoutResultIcon success={isSuccess}>
          <Check />
          <CheckoutResultText>
            {isSuccess ? (
              <span>
                Success! your plan has changed to{' '}
                <b>{authContext.getUserTier()}</b>
              </span>
            ) : (
              `Oops, that din't go well, we are looking into your transaction`
            )}
          </CheckoutResultText>
          <Link to="/">Go home</Link>
        </CheckoutResultIcon>
      )
    ],
    [
      R.T,
      R.always(
        <CheckoutResultIcon success={false}>
          <X />
          <CheckoutResultText>
            <span>
              The transaction status is unknown, do not worry, we will look into
              your issue.
            </span>
          </CheckoutResultText>
          <Link to="/">Go home</Link>
        </CheckoutResultIcon>
      )
    ]
  ])(transactionStatus);
};

const CheckoutResult: React.FC<
  ICheckoutResultProps & RouteComponentProps<any> & { context: IAuthContext }
> = props => {
  const authContext = props.context;
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  useEffect(() => {
    setIsLoading(true);
    const loadCheckoutSessionDetails = async () => {
      const checkoutSessionSnapshot = await db
        .collection('users')
        .doc(authContext.getUserId())
        .collection('transactions')
        .where('stripeCheckoutSessionId', '==', props.checkoutSessionId)
        .limit(1)
        .get();
      if (checkoutSessionSnapshot.size <= 0) {
        const errorMessage = `No transaction was found.`;
        toast.error(errorMessage);
        CeresLogger.error({
          message: errorMessage,
          payload: {
            checkoutSessionId: props.checkoutSessionId,
            userId: authContext.getUserId()
          }
        });
        return props.history.push('/');
      }
      const checkoutSession = R.head(checkoutSessionSnapshot.docs).data();
      setTransactionStatus(checkoutSession.status);
      setIsLoading(false);
    };
    loadCheckoutSessionDetails();
  }, [props.history, props.checkoutSessionId, authContext]);

  return (
    <CheckoutResultContainer>
      <CheckoutResultHero>
        {!isLoading &&
          renderCheckoutSessionStatus(
            props.isSuccess,
            authContext,
            transactionStatus
          )}
        {isLoading && (
          <CheckoutResultSpinnerContainer>
            <Spinner variant="primary" animation="border" />
          </CheckoutResultSpinnerContainer>
        )}
      </CheckoutResultHero>
    </CheckoutResultContainer>
  );
};

export default withAuthContext(CheckoutResult);
