export interface IElasticResult<T> {
  from: number;
  size: number;
  total: any;
  records: T[];
}

export interface ISearchRecord {
  incident_url?: string;
  cityName: string;
  state: string;
  streetName: string;
  contentId: string;
  entityName: string;
  entityNumber: string;
  secondaryId: string;
  fileName: string;
  hitContent: string[];
  releaseDate: string;
  fromReleaseDate: string;
  toReleaseDate: string;
  seriesId: string;
  seriesName: string;
  title: string;
  fileUrl: string;
  relevanceScore: number;
  fileType: string;
  entitySource: string;
  programSymbol: string;
  address: string;
  containsSearchTerm: boolean;
  location: {
    lat: number;
    lon: number;
    geomz: string;
    geom_json: any;
  };
}

export interface ICustomSearchPagination {
  count: number;
  startIndex: number;
}

export interface ICustomSearchResult<T> {
  next: ICustomSearchPagination | null;
  previous: ICustomSearchPagination | null;
  size: number;
  records: T[];
}

export interface ICustomSearchRecord {
  title: string;
  formattedTitle: string;
  formattedUrl: string;
  displayLink: string;
  url: string;
  formattedDescription: string;
  description: string;
  thumbnail: [
    {
      src: string;
      width: number;
      height: number;
    }
  ];
  image: [
    {
      src: string;
      width: number;
      height: number;
    }
  ];
  images?: {
    contextLink: string;
    thumbnailLink: string;
    height: number;
    width: number;
    thumbnailHeight: number;
    thumbnailWidth: number;
  };
  videoobject?: string;
}

export const dummyRecord = {
  cityName: '',
  state: '',
  streetName: '',
  contentId: '',
  entityName: '',
  entityNumber: '',
  secondaryId: '',
  fileName: '',
  hitContent: [],
  releaseDate: '',
  fromReleaseDate: '',
  toReleaseDate: '',
  seriesId: '',
  seriesName: '',
  title: '',
  fileUrl: '',
  relevanceScore: 0,
  programSymbol: '',
  fileType: '',
  entitySource: '',
  address: '',
  containsSearchTerm: false,
  location: {
    lat: 0,
    lon: 0,
    geomz: '',
    geom_json: '',
  },
};
