import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const LoadingButton: React.FC<{ isLoading: boolean; searchBtn?: React.MutableRefObject<any> }> = (props) => (
  <Button
    color="primary"
    type="submit"
    style={{ padding: '5px' }}
    ref={props.searchBtn}
    name="submitSearch"
    disabled={props.isLoading}
  >
    {!props.isLoading && props.children}
    {props.isLoading && <Spinner animation="border" variant="light" size="sm" />}
  </Button>
);

export default LoadingButton;
