import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(13),
      marginRight: '15px',
      fontWeight: 'bold',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'bold',
    },
    tableItem: {
      padding: '8px 5px !important',
      fontWeight: 'normal',
    },
  })
);

const EmptyData = styled.p`
  text-align: center;
`;

const EmissionDetailsControls: React.FC<any> = ({ finsInfo }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={classes.root}>
      {finsInfo.controls &&
        finsInfo.controls.length > 0 &&
        finsInfo.controls.map((controlInfo: any, index: number) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography className={classes.heading} title="ID">
                {controlInfo.ID}
              </Typography>
              <Typography className={classes.heading} title="Name">
                {controlInfo.Name}
              </Typography>
              <Typography className={classes.secondaryHeading} title="Type">
                {controlInfo.Type}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {controlInfo.controls_detail ? (
                <Table
                  striped
                  bordered
                  hover
                  key={index}
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0',
                  }}
                >
                  <thead>
                    {controlInfo.controls_detail &&
                      controlInfo.controls_detail.length > 0 && (
                        <tr>
                          {Object.entries(controlInfo.controls_detail[0]).map(
                            ([key], value: number) => (
                              <th key={value} className={classes.tableItem}>
                                {key}
                              </th>
                            )
                          )}
                        </tr>
                      )}
                  </thead>
                  <tbody>
                    {controlInfo.controls_detail &&
                      controlInfo.controls_detail.length > 0 &&
                      controlInfo.controls_detail.map(
                        (details: any, keys: number) => (
                          <tr key={keys}>
                            {Object.entries(controlInfo.controls_detail[0]).map(
                              ([key], value: number) => (
                                <td key={value} className={classes.tableItem}>
                                  {details[key]}
                                </td>
                              )
                            )}
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              ) : (
                <EmptyData>No Controls reported.</EmptyData>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      {!finsInfo.controls && <EmptyData>No Controls reported.</EmptyData>}
    </div>
  );
};
export default EmissionDetailsControls;
