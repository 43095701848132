import * as CryptoJS from 'crypto-js';
import * as R from 'ramda';

import { Button, OverlayTrigger } from 'react-bootstrap';
import { IAIEarth, IAir, IMap } from '../../store/aiearth/types';
import { IElasticResult, ISearchRecord, dummyRecord } from '../../types/search';
import React, { Fragment, useEffect, useState } from 'react';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { iv, key as secKey } from '../../services/firebase';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { updateAIEarth, updateAir } from '../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CeresLogger } from '../../logger';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { IAuthContext } from '../../context/auth/auth-context';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Parser from 'rss-parser';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Scrollbars } from 'react-custom-scrollbars';
import SearchIcon from '@material-ui/icons/Search';
import SearchPagination from './SearchPagination';
import Switch from '@material-ui/core/Switch';
import { Table } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { VariableSizeList } from 'react-window';
import { applyFilterToQuery } from '../../pages/ai-earth/index';
import axios from 'axios';
import { projectId } from '../../services/firebase';
import { search } from '../../services/firebase';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import withAuthContext from '../../context/auth/AuthConsumer';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

interface IAiEarthTab {
  getAirLists: () => void;
  boundingBox: any;
  setBoundingBox: any;
  coords: any;
  setActiveTabDrawer: any;
  ProgramQuery: React.StatelessComponent;
  setSearchTerm: any;
  searchTerm: string;
}
const TypographyCustom = styled.span`
  font-size: 8px;
  font-weight: 600;
`;

const AirContainer = styled.div`
  margin: 5px;
  .MuiFormGroup-root {
    margin: 15px 0 5px !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 12px !important;
  }
  .MuiRadio-root {
    padding: 2px !important;
  }
  .MuiTypography-body1 {
    font-size: 14px !important;
  }
`;
const BoundingBox = styled.p`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
  color: #6c757d;
  margin: 5px 15px 10px 15px;
`;

const PopupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const RecordsContainer = styled.div`
  padding-right: 10px;
`;

const SearchResultContainer = styled.div`
  padding: 10px 5px;
`;
const Dialogs = styled(Dialog)`
  z-index: 9999 !important;
`;
const PopoverContainer = styled.div`
  border: 1px solid #36a2eb;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
`;

const TweetContainer = styled.div`
  padding: 0px 12px;
`;

const EmissionTR = styled.tr`
  background-color: #8080805e;
  color: black;
  font-weight: 600;
`;

const CenterTD = styled.td`
  vertical-align: middle !important;
  text-align: center;
`;

const FixedTD = styled.td`
  width: 8em;
`;

const NewsHeader = styled.h4`
  background-color: #339ca8;
  padding: 10px 15px 12px;
  color: white;
  text-align: center;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const Transition: any = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="right" timeout={1000} mountOnEnter unmountOnExit ref={ref} {...props} />;
});

const LISTBOX_PADDING = 8;

function renderRow(renderRowProps: any) {
  const { data, index, style } = renderRowProps;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((OuterElementprops: any, ref: any) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...OuterElementprops} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<any>(null);
  React.useEffect(() => {
    if (ref != null && ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const SelectComponent: any = React.forwardRef(function ListboxComponent(props: any, ref: any) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: any) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

SelectComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  dialog: {
    maxWidth: '50vw',
  },
  listbox: {
    // tslint:disable-next-line: object-literal-key-quotes
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const AIEarthAirTab: React.FC<
  IAiEarthTab & {
    context: IAuthContext;
  }
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ProgramQuery, searchTerm, setSearchTerm } = props;
  const store: any = useSelector<any>((state): any => state);
  const aiEarthState: IAIEarth = store.aiearth;
  const aiEarthMapState: IMap = store.map;
  const aiAirState: IAir = store.air;
  const [airSearch, setAirSearch] = useState('keyword');
  const [latestEmissionEventsPopover, setLatestEmissionEventsPopover] = useState(false);
  const [showPopups, setShowPopups] = useState(localStorage.getItem('airPopups') === 'yes' ? true : false);
  const [twitterPopover, setTwitterPopover] = useState(false);
  const [texasNewsRSSPopover, setTexasNewsRSSPopover] = useState(false);
  const [latestEventsPopover, setLatestEventsPopover] = useState(false);
  const [emissionsPopover, setEmissionsPopover] = useState(false);
  const [aqiPopover, setAQIPopover] = useState(false);
  const [twitterTweets, setTwitterTweets] = useState<any>();
  const [texasNewsRSS, setTexasNewsRSS] = useState<any>();
  const [latestEmissionEvents, setLatestEmissionEvents] = useState<any>();
  const [latestRNEmissionEvents, setLatestRNEmissionEvents] = useState<any>();
  const [airQualityIndex, setAirQualityIndex] = useState<any>();
  const [airEmissions, setAirEmissions] = useState<any>();
  const [searchRecords, setSearchRecords] = useState<any>({});
  const [getSelectFacility, setSelectFacility] = useState<string>('');

  useEffect(() => {
    props.getAirLists();
    if (!latestEmissionEvents) {
      getInitialPopups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doTextSearch = (queryText: string = '', from = 0) => {
    dispatch(
      updateAIEarth({
        ...aiEarthState,
        ...{ isLoading: true },
      })
    );
    return search(applyFilterToQuery({ query: { from, text: queryText, size: 10 } }, { category: 'Air' }))
      .then((result: any) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        const epnArray: any[] = [];
        const filteredRecords = R.uniqBy(R.prop('entityNumber'), data.records)
          .filter((ele: ISearchRecord) => {
            return ele.entityNumber !== null;
          })
          .map((ele: ISearchRecord) => {
            if (ele.location.geomz) {
              const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
                iv,
                mode: CryptoJS.mode.CBC,
              }).toString(CryptoJS.enc.Utf8);
              const latLon = decryptedData.split(',');
              ele.location.lon = parseFloat(latLon[0]);
              ele.location.lat = parseFloat(latLon[1]);
            }
            if (ele.location.geom_json) {
              for (const epnKey in ele.location.geom_json) {
                if (ele.location.geom_json.hasOwnProperty(epnKey)) {
                  for (const epnInnerKey in ele.location.geom_json[epnKey]) {
                    if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
                      const element = ele.location.geom_json[epnKey][epnInnerKey];
                      const epnRecord: any = {};
                      if (element.geom) {
                        const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                          iv,
                          mode: CryptoJS.mode.CBC,
                        }).toString(CryptoJS.enc.Utf8);
                        const latLon = decryptedData.split(',');
                        epnRecord.location = Object.assign({});
                        epnRecord.location.lon = parseFloat(latLon[0]);
                        epnRecord.location.lat = parseFloat(latLon[1]);
                        epnRecord.marker = element.marker;
                        epnRecord.name = element.name;
                        epnRecord.type = element.type;
                        epnRecord.details = element.details;
                        if (ele.entityNumber) {
                          epnRecord.entityNumber = ele.entityNumber;
                          epnArray.push(epnRecord);
                        }
                      }
                    }
                  }
                }
              }
            }
            return ele;
          });
        const searchData: any = data;
        searchData.records = filteredRecords;
        searchData.epnData = epnArray;
        setSearchRecords(searchData);
        if (filteredRecords.length > 0) {
          dispatch(
            updateAir({
              ...aiAirState,
              ...{ activeRecord: filteredRecords[0] },
            })
          );
          dispatch(
            updateAIEarth({
              ...aiEarthState,
              ...{ mainDrawer: 'air', data: searchData, isLoading: false },
            })
          );
          if (filteredRecords[0].location.lat && filteredRecords[0].location.lon) {
            aiEarthMapState.gMap.setZoom(16);
            aiEarthMapState.gMap.setMapTypeId('satellite');
            getPopupsData({
              entityNumber: filteredRecords[0].entityNumber,
              latitude: filteredRecords[0].location.lat,
              longitude: filteredRecords[0].location.lon,
            });
          } else {
            aiEarthMapState.gMap.setZoom(6);
            aiEarthMapState.gMap.setMapTypeId('satellite');
            getPopupsData({
              entityNumber: filteredRecords[0].entityNumber,
              latitude: 29.0,
              longitude: -95.0,
            });
          }
        }
      })
      .catch(() => {
        dispatch(
          updateAIEarth({
            ...aiEarthState,
            ...{ isLoading: false },
          })
        );
      });
  };

  const doProgramSearch = (entityNumber: any, from = 0) => {
    const requestBody: any = {};
    dispatch(
      updateAIEarth({
        ...aiEarthState,
        ...{ isLoading: true },
      })
    );
    requestBody.entity_ids = entityNumber;

    if (props.boundingBox) {
      requestBody.bounding_box = props.coords.boundingBox;
    }
    if (from !== 0) {
      requestBody.offset = from;
    }

    try {
      axios
        .post(
          'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQuery',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQuery',
          requestBody,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const resultRecords = response.data[0].query_programs.data;
          const programQueryRecords: ISearchRecord[] = [];
          const epnArray: any[] = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < resultRecords.length; i++) {
            const tempRecord: ISearchRecord = Object.assign({}, dummyRecord);
            tempRecord.contentId = resultRecords[i].id;
            tempRecord.entityName = resultRecords[i].nm;
            tempRecord.entityNumber = resultRecords[i].en;
            tempRecord.entitySource = resultRecords[i].es;
            tempRecord.secondaryId = resultRecords[i].si;
            tempRecord.address = resultRecords[i].ad;
            if (!!resultRecords[i].gm) {
              const decryptedData = CryptoJS.AES.decrypt(resultRecords[i].gm, secKey, {
                iv,
                mode: CryptoJS.mode.CBC,
              }).toString(CryptoJS.enc.Utf8);
              const latLon = decryptedData.split(',');
              tempRecord.location = Object.assign({});
              tempRecord.location.lon = parseFloat(latLon[0]);
              tempRecord.location.lat = parseFloat(latLon[1]);
            }
            if (resultRecords[i].geom_json) {
              for (const key in resultRecords[i].geom_json) {
                if (resultRecords[i].geom_json.hasOwnProperty(key)) {
                  for (const innerKey in resultRecords[i].geom_json[key]) {
                    if (resultRecords[i].geom_json[key].hasOwnProperty(innerKey)) {
                      const element = resultRecords[i].geom_json[key][innerKey];
                      const epnRecord: any = {};
                      if (element.geom) {
                        const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                          iv,
                          mode: CryptoJS.mode.CBC,
                        }).toString(CryptoJS.enc.Utf8);
                        const latLon = decryptedData.split(',');
                        epnRecord.location = Object.assign({});
                        epnRecord.location.lon = parseFloat(latLon[0]);
                        epnRecord.location.lat = parseFloat(latLon[1]);
                        epnRecord.marker = element.marker;
                        epnRecord.name = element.name;
                        epnRecord.type = element.type;
                        epnRecord.details = element.details;
                        epnRecord.entityNumber = element.entityNumber;
                        epnArray.push(epnRecord);
                      }
                    }
                  }
                }
              }
            }
            programQueryRecords.push(tempRecord);
          }
          const programQueryMapRecords = programQueryRecords.filter((rec: any) => {
            return rec.location != null;
          });
          const data = {
            records: programQueryMapRecords,
            total: programQueryMapRecords.length,
            epnData: epnArray,
          };
          setSearchRecords(data);
          if (programQueryMapRecords.length > 0) {
            dispatch(
              updateAir({
                ...aiAirState,
                ...{ activeRecord: programQueryMapRecords[0] },
              })
            );
            dispatch(
              updateAIEarth({
                ...aiEarthState,
                ...{ mainDrawer: 'air', data, isLoading: false },
              })
            );
            aiEarthMapState.gMap.setZoom(16);
            aiEarthMapState.gMap.setMapTypeId('satellite');
            getPopupsData({
              entityNumber: programQueryMapRecords[0].entityNumber,
              latitude: programQueryMapRecords[0].location.lat,
              longitude: programQueryMapRecords[0].location.lon,
            });
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getAQIData = async (airQualityData: any) => {
    const requestBody: any = {};
    requestBody.latitude = airQualityData.latitude;
    requestBody.longitude = airQualityData.longitude;
    requestBody.distance = 25;
    requestBody.date = '2020-09-24';
    requestBody.type = 'latLong';
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const aqiResponse = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/getAirQualityIndex',
        requestBody,
        {
          headers,
        }
      );
      return aqiResponse.data;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getAirEmissions = async (airQualityData: any) => {
    const requestBody: any = {};
    requestBody.RN = airQualityData.entityNumber;
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const airEmissionResponse = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/airEmissionPopup',
        requestBody,
        {
          headers,
        }
      );
      const airEmissionData = airEmissionResponse.data;
      return airEmissionData;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getTwitterTweets = async (twitterSearchTerm: string) => {
    const requestBody: any = {};
    requestBody.searchTerm = twitterSearchTerm;
    requestBody.count = 10;
    requestBody.resultType = 'mixed';
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const twitterTweetsResp = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/getTwitterTweets',
        // 'http://localhost:5000/ceres-platform-test/us-central1/api/getTwitterTweets',
        requestBody,
        {
          headers,
        }
      );
      const twitterTweetsData = twitterTweetsResp.data;
      return twitterTweetsData;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getRNLatestEmissionEvents = async (airQualityData: any) => {
    const requestBody: any = {};
    requestBody.RN = airQualityData.entityNumber;
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const latestEmissionEventsResponse = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/getRNLatestEvents',
        requestBody,
        {
          headers,
        }
      );
      let latestEmissionEventsData = null;
      if (latestEmissionEventsResponse.data && latestEmissionEventsResponse.data.length > 0) {
        latestEmissionEventsData =
          typeof latestEmissionEventsResponse.data[0].emission_events_top10_json !== 'undefined'
            ? latestEmissionEventsResponse.data[0].emission_events_top10_json
            : [];
      }
      return latestEmissionEventsData;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getPopupsData = async (airQualityData: any) => {
    if (showPopups) {
      const [aqiResult, airEmissionData, latestRNEmissionEventsData] = await Promise.all([
        getAQIData(airQualityData),
        getAirEmissions(airQualityData),
        getRNLatestEmissionEvents(airQualityData),
      ]);

      let x = 0;
      const popupInterval = setInterval(() => {
        if (++x === 4) {
          window.clearInterval(popupInterval);
          setLatestEmissionEventsPopover(false);
        }
        if (x === 1) {
          if (airEmissionData.length > 0) {
            setAirEmissions(airEmissionData[0].aai_emission_popup);
            setEmissionsPopover(true);
          }
        } else if (x === 2) {
          setEmissionsPopover(false);
          if (aqiResult && aqiResult.length > 0) {
            setAirQualityIndex(aqiResult);
            setAQIPopover(true);
          }
        } else if (x === 3) {
          setAQIPopover(false);
          if (latestRNEmissionEventsData && latestRNEmissionEventsData.length > 0) {
            setLatestRNEmissionEvents(latestRNEmissionEventsData);
            setLatestEmissionEventsPopover(true);
          }
        }
      }, 3000);
    }
  };

  const getInitialPopups = async () => {
    if (showPopups) {
      const [latestEmissionEventsData, rssNewsResponse, twitterTweetsData] = await Promise.all([
        getLatestEmissionEvents(),
        getTexasEnvNews(),
        getTwitterTweets(
          'texas+environment OR texas+pollution OR texas+petroleum OR texas+refinery OR texas+contamination'
        ),
      ]);
      let x = 0;
      const popupInterval = setInterval(() => {
        if (++x === 4) {
          window.clearInterval(popupInterval);
          setTwitterPopover(false);
        }
        if (x === 1) {
          if (latestEmissionEventsData && latestEmissionEventsData.length > 0) {
            setLatestEmissionEvents(latestEmissionEventsData);
            setLatestEventsPopover(true);
          }
        } else if (x === 2) {
          setLatestEventsPopover(false);
          if (rssNewsResponse != null) {
            const parser = new Parser();
            parser.parseString(rssNewsResponse, (error, feed: any) => {
              if (feed && feed.items.length > 0) {
                setTexasNewsRSS(feed.items);
                setTexasNewsRSSPopover(true);
              }
            });
          }
        } else if (x === 3) {
          setTexasNewsRSSPopover(false);
          if (twitterTweetsData != null) {
            if (twitterTweetsData.statuses && twitterTweetsData.statuses.length > 0) {
              setTwitterTweets(twitterTweetsData.statuses);
              setTwitterPopover(true);
            }
          }
        }
      }, 3000);
    }
  };

  const getLatestEmissionEvents = async () => {
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const latestEmissionEventsResponse = await axios.get(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/airLatestEvents',
        {
          headers,
        }
      );
      let latestEmissionEventsData = null;
      if (latestEmissionEventsResponse.data) {
        latestEmissionEventsData = latestEmissionEventsResponse.data[0].jsonlistb;
      }
      return latestEmissionEventsData;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getTexasEnvNews = async () => {
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      const airTexasLatestNewsResponse = await axios.get(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/airTexasLatestNews',
        {
          headers,
        }
      );
      return airTexasLatestNewsResponse.data;
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key?: string) => {
    setAnchorEl(null);
    if (key === 'LatestTexasEmissionEvents') {
      setLatestEventsPopover(true);
    }
    if (key === 'TexasEnvironmentalSocialMedia') {
      setTwitterPopover(true);
    }
    if (key === 'TexasEnvironmentalNews') {
      setTexasNewsRSSPopover(true);
    }
    if (key === 'EmissionsProfileFacility') {
      setEmissionsPopover(true);
    }
    if (key === 'AirQualityFacility') {
      setAQIPopover(true);
    }
    if (key === 'LatestEmissionsFacility') {
      setLatestEmissionEventsPopover(true);
    }
  };

  const CustomSwitch = withStyles({
    switchBase: {
      // tslint:disable-next-line: object-literal-key-quotes
      color: '#005FBCc7',
      '&$checked': {
        color: '#005FBC',
      },
      '&$checked + $track': {
        backgroundColor: '#005FBC',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const totalRecords = searchRecords.total;
  return (
    <Fragment>
      <AirContainer>
        <PopupContainer>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={showPopups}
                onChange={() => {
                  setShowPopups(!showPopups);
                  localStorage.setItem('airPopups', showPopups ? 'no' : 'yes');
                }}
              />
            }
            label="Show Popups"
          />
          <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <AnnouncementIcon />
          </Button>
        </PopupContainer>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          keepMounted
          open={open}
          onClose={() => {
            handleClose();
          }}
          TransitionComponent={Fade}
        >
          {latestEmissionEvents && (
            <MenuItem
              onClick={() => {
                handleClose('LatestTexasEmissionEvents');
              }}
            >
              Latest Texas Emission Events
            </MenuItem>
          )}
          {texasNewsRSS && (
            <MenuItem
              onClick={() => {
                handleClose('TexasEnvironmentalNews');
              }}
            >
              Texas Environmental News
            </MenuItem>
          )}
          {twitterTweets && (
            <MenuItem
              onClick={() => {
                handleClose('TexasEnvironmentalSocialMedia');
              }}
            >
              Social Media
            </MenuItem>
          )}
          {airEmissions && (
            <MenuItem
              onClick={() => {
                handleClose('EmissionsProfileFacility');
              }}
            >
              Emissions Profile for the Facility
            </MenuItem>
          )}
          {airQualityIndex && (
            <MenuItem
              onClick={() => {
                handleClose('AirQualityFacility');
              }}
            >
              Air Quality for the Facility
            </MenuItem>
          )}
          {latestRNEmissionEvents && (
            <MenuItem
              onClick={() => {
                handleClose('LatestEmissionsFacility');
              }}
            >
              Latest Emissions for the Facility
            </MenuItem>
          )}
        </Menu>
        <FormControl component="fieldset">
          <FormLabel component="legend">Air Search</FormLabel>
          <RadioGroup
            row
            name="airSearch"
            value={airSearch}
            onChange={(e) => {
              setAirSearch(e.target.value);
              setSearchRecords({});
              setSearchTerm('');
              setLatestRNEmissionEvents(null);
              setAirQualityIndex(null);
              setAirEmissions(null);
              dispatch(
                updateAIEarth({
                  ...aiEarthState,
                  ...{
                    mainDrawer: '',
                    subDrawer: '',
                    data: null,
                  },
                })
              );
              if (aiEarthState.mainDrawer !== '') {
                aiEarthMapState.gMap.setZoom(5);
              }
              aiEarthMapState.gMap.setMapTypeId('roadmap');
            }}
          >
            <FormControlLabel
              value="facility"
              control={<Radio size="small" />}
              label="Facility"
              onChange={(e: any) => {
                props.setBoundingBox(false);
              }}
            />
            <FormControlLabel
              value="keyword"
              control={<Radio size="small" />}
              label="Keyword"
              onChange={(e: any) => {
                props.setBoundingBox(false);
              }}
            />
            <FormControlLabel
              value="boundingBox"
              control={<Radio size="small" />}
              label="Bounding Box"
              name="boundingBox"
              checked={props.boundingBox}
              onChange={(e: any) => {
                props.setBoundingBox(true);
                aiEarthMapState.gMap.panTo({ lat: 31, lng: -100 });
              }}
            />
          </RadioGroup>
        </FormControl>
        {airSearch === 'facility' && (
          <Autocomplete
            size="small"
            value={getSelectFacility}
            disabled={aiEarthState.isLoading}
            ListboxComponent={SelectComponent}
            onChange={(e: any, value: any) => {
              if (value) {
                const rnNumber = value.split('-')[2];
                doProgramSearch(rnNumber);
                props.setActiveTabDrawer('permits');
              }
            }}
            options={aiEarthState.airListOptions ? aiEarthState.airListOptions : []}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Facilities" />}
            renderOption={(option: any) => <TypographyCustom>{option}</TypographyCustom>}
          />
        )}
        {airSearch === 'keyword' && (
          <>
            <TextField
              variant="outlined"
              label="Keyword"
              value={searchTerm}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        doTextSearch(searchTerm);
                        props.setActiveTabDrawer('permits');
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled={aiEarthState.isLoading}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  doTextSearch(searchTerm);
                  props.setActiveTabDrawer('permits');
                }
              }}
            />
          </>
        )}
        {props.boundingBox && (
          <>
            <BoundingBox>Air Facilities intersecting the bounding box</BoundingBox>
            <ProgramQuery />
          </>
        )}
        {aiEarthState.isLoading && <LinearProgress />}
        {(airSearch === 'keyword' || airSearch === 'facility') && (
          <SearchResultContainer>
            <Scrollbars style={{ height: 'calc(100vh - 340px)' }}>
              {searchRecords.records && (
                <RecordsContainer>
                  {R.compose<any[], any[], any[]>(
                    R.map((record: ISearchRecord) => {
                      return (
                        <OverlayTrigger
                          key={record.contentId}
                          trigger="hover"
                          placement="bottom"
                          overlay={
                            <ReactTooltip effect="solid" html={true} />
                          }
                        >
                          <Button
                            data-tip={
                              ReactDOMServer.renderToString(
                                <div>
                                  <strong>{record.entityNumber}</strong>
                              <br />
                              {record.cityName}
                                </div>
                              )
                            }
                            style={{
                              backgroundColor:
                                aiAirState.activeRecord.entityNumber === record.entityNumber ? '#336195' : '#005FBC',
                            }}
                            onClick={(e: any) => {
                              dispatch(
                                updateAir({
                                  ...aiAirState,
                                  ...{ activeRecord: record },
                                })
                              );
                              dispatch(
                                updateAIEarth({
                                  ...aiEarthState,
                                  ...{ mainDrawer: 'air' },
                                })
                              );

                              if (record.location && typeof record.location.lat !== 'undefined') {
                                aiEarthMapState.gMap.setZoom(16);
                                getPopupsData({
                                  entityNumber: record.entityNumber,
                                  latitude: record.location.lat,
                                  longitude: record.location.lon,
                                });
                                aiEarthMapState.gMap.setMapTypeId('satellite');
                              } else {
                                aiEarthMapState.gMap.setZoom(6);
                                getPopupsData({
                                  entityNumber: record.entityNumber,
                                  latitude: 29.0,
                                  longitude: -95.0,
                                });
                                aiEarthMapState.gMap.setMapTypeId('satellite');
                              }
                            }}
                            size="sm"
                            block={true}
                          >
                            {record.entityName || record.entityNumber}
                          </Button>
                        </OverlayTrigger>
                      );
                    }),
                    R.filter((record: any) => {
                      return record.entityName || record.entityNumber;
                    })
                  )(searchRecords.records)}
                  {totalRecords && (
                    <SearchPagination
                      isDisabled={false}
                      isFirstPage={(searchRecords && searchRecords.from === 0) || false}
                      isLastPage={(searchRecords && searchRecords.from + searchRecords.size >= totalRecords) || false}
                      totalRecords={totalRecords}
                      onPreviousClick={() => {
                        if (!searchRecords) {
                          return;
                        }
                        const currentFrom = searchRecords.from;
                        const currentSize = searchRecords.size;
                        return doTextSearch(searchTerm, currentFrom - currentSize);
                      }}
                      onNextClick={() => {
                        if (!searchRecords) {
                          return;
                        }
                        const currentFrom = searchRecords.from;
                        const currentSize = searchRecords.size;
                        return doTextSearch(searchTerm, currentFrom + currentSize);
                      }}
                    />
                  )}
                </RecordsContainer>
              )}
            </Scrollbars>
          </SearchResultContainer>
        )}
        <Dialogs
          TransitionComponent={Transition}
          onClose={() => {
            setEmissionsPopover(false);
          }}
          open={emissionsPopover}
        >
          <PopoverContainer>
            <NewsHeader>Emissions Profile</NewsHeader>
            <Table bordered>
              <tbody>
                {airEmissions && (
                  <>
                    <EmissionTR>
                      <CenterTD>Current Emissions Level</CenterTD>
                      <td>
                        {airEmissions.current_hourly_emissions_criteria_pounds}{' '}
                        {airEmissions.current_hourly_emissions_criteria_pounds !== '-'
                          ? 'lbs/hr(criteria pollutants)'
                          : ''}
                      </td>
                      <FixedTD>
                        {airEmissions.current_emissions_rank !== '-' ? 'Rank #' : ''}
                        {airEmissions.current_emissions_rank}
                      </FixedTD>
                    </EmissionTR>
                    <EmissionTR>
                      <CenterTD rowSpan={2}>Annual Emissions</CenterTD>
                      <td>
                        {airEmissions.annual_emissions_criteria_rank !== '-' && (
                          <Link to={'/benchmarking?rn=' + aiAirState.activeRecord.entityNumber + '&event=2'}>
                            {airEmissions.annual_emissions_criteria_rank}
                            {' tons (criteria pollutants)'}
                          </Link>
                        )}
                        {airEmissions.annual_emissions_criteria_rank === '-' && '-'}
                      </td>
                      <FixedTD>
                        {airEmissions.current_emissions_rank !== '-' ? 'Rank #' : ''}
                        {airEmissions.current_emissions_rank}
                      </FixedTD>
                    </EmissionTR>
                    <EmissionTR>
                      <td>
                        {airEmissions.annual_emissions_co2e_tons !== '-' && (
                          <Link to={'/benchmarking?rn=' + aiAirState.activeRecord.entityNumber + '&event=2'}>
                            {airEmissions.annual_emissions_co2e_tons}
                            {' tons (coe2)'}
                          </Link>
                        )}
                        {airEmissions.annual_emissions_co2e_tons === '-' && '-'}
                      </td>
                      <FixedTD>
                        {airEmissions.annual_emissions_co2e_rank !== '-' ? 'Rank #' : ''}
                        {airEmissions.annual_emissions_co2e_rank}
                      </FixedTD>
                    </EmissionTR>
                    <EmissionTR>
                      <CenterTD>Unauthorized Emissions since 2004</CenterTD>
                      <td>
                        {airEmissions.unauthorized_emissions_tons !== '-' && (
                          <Link to={'/benchmarking?rn=' + aiAirState.activeRecord.entityNumber + '&event=1'}>
                            {airEmissions.unauthorized_emissions_tons}
                            {' tons'}
                          </Link>
                        )}
                        {airEmissions.unauthorized_emissions_tons === '-' && '-'}
                      </td>
                      <FixedTD>
                        {airEmissions.unauthorized_emissions_rank !== '-' ? 'Rank #' : ''}
                        {airEmissions.unauthorized_emissions_rank}
                      </FixedTD>
                    </EmissionTR>
                    <EmissionTR>
                      <CenterTD>Uncontrolled / Undercontrolled Emissions (NOx and VOC)</CenterTD>
                      <td>
                        {airEmissions.uncontrolled_emissions_tons !== '-' && (
                          <Link to={'/generator?rn=' + aiAirState.activeRecord.entityNumber}>
                            {airEmissions.uncontrolled_emissions_tons}
                            {' tons'}
                          </Link>
                        )}
                        {airEmissions.uncontrolled_emissions_tons === '-' && '-'}
                      </td>
                      <FixedTD>
                        {airEmissions.uncontrolled_emissions_rank !== '-' ? 'Rank #' : ''}
                        {airEmissions.uncontrolled_emissions_rank}
                      </FixedTD>
                    </EmissionTR>
                  </>
                )}
              </tbody>
            </Table>
          </PopoverContainer>
        </Dialogs>
        <Dialogs
          TransitionComponent={Transition}
          onClose={() => {
            setAQIPopover(false);
          }}
          open={aqiPopover}
        >
          <PopoverContainer>
            <NewsHeader>
              <a
                href={
                  airQualityIndex &&
                  'https://www.airnow.gov/?city=' +
                    airQualityIndex[0].ReportingArea +
                    '&state=' +
                    airQualityIndex[0].StateCode +
                    '&country=USA'
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: 'pointer' }}
              >
                Today's Air Quality for this Facility
              </a>
            </NewsHeader>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>AQI</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {airQualityIndex && (
                  <>
                    {airQualityIndex[0] && (
                      <tr>
                        <td>{airQualityIndex[0].ParameterName}</td>
                        <td>{airQualityIndex[0].AQI}</td>
                        <td>{airQualityIndex[0].Category.Name}</td>
                      </tr>
                    )}
                    {airQualityIndex[1] && (
                      <tr>
                        <td>{airQualityIndex[1].ParameterName}</td>
                        <td>{airQualityIndex[1].AQI}</td>
                        <td>{airQualityIndex[1].Category.Name}</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </PopoverContainer>
        </Dialogs>
        <Dialogs
          TransitionComponent={Transition}
          onClose={() => {
            setLatestEmissionEventsPopover(false);
          }}
          open={latestEmissionEventsPopover}
        >
          <PopoverContainer>
            <NewsHeader
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                if (aiAirState.activeRecord.entityNumber) {
                  doProgramSearch(aiAirState.activeRecord.entityNumber);
                  setAirSearch('facility');
                  setSelectFacility(aiAirState.activeRecord.entityName + '-' + aiAirState.activeRecord.entityNumber);
                  props.setActiveTabDrawer('emissionEvents');
                  setLatestEmissionEventsPopover(false);
                }
              }}
            >
              Latest Emission Events for this Facility
            </NewsHeader>
            <Table bordered hover>
              <tbody>
                {latestRNEmissionEvents &&
                  latestRNEmissionEvents.map((event: any) => {
                    return (
                      <>
                        <tr>
                          <td>{event.date}</td>
                          <td>{event.amount} lbs</td>
                          <td>{event.duration}</td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </PopoverContainer>
        </Dialogs>
        <Dialogs
          classes={{
            paper: classes.dialog,
          }}
          TransitionComponent={Transition}
          onClose={() => {
            setLatestEventsPopover(false);
          }}
          open={latestEventsPopover}
        >
          <PopoverContainer>
            <NewsHeader>The Latest Texas Emission Events</NewsHeader>
            <Table bordered hover>
              <tbody>
                {latestEmissionEvents &&
                  latestEmissionEvents.map((event: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a
                            href="#/"
                            onClick={() => {
                              doProgramSearch(event.rn_number);
                              setAirSearch('facility');
                              setSelectFacility(event.rn_name + '-' + event.customer_name + '-' + event.rn_number);
                              props.setActiveTabDrawer('emissionEvents');
                              setLatestEventsPopover(false);
                            }}
                            title={event.rn_name}
                          >
                            {event.rn_name}
                          </a>
                        </td>
                        <td>{event.amount} lbs</td>
                        <td>{event.date}</td>
                        <td>{event.duration}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </PopoverContainer>
        </Dialogs>
        <Dialogs
          classes={{
            paper: classes.dialog,
          }}
          TransitionComponent={Transition}
          onClose={() => {
            setTexasNewsRSSPopover(false);
          }}
          open={texasNewsRSSPopover}
        >
          <PopoverContainer>
            <NewsHeader>
              <a href="http://www.texasenvironmentalnews.com" target="_blank" rel="noopener noreferrer">
                Texas Environmental News
              </a>
            </NewsHeader>
            <Divider />
            {texasNewsRSS &&
              texasNewsRSS.map((news: any, index: number) => {
                return (
                  <div key={index}>
                    <h6>
                      <a href={news.link} rel="noopener noreferrer" target="_blank">
                        {news.title}
                      </a>
                    </h6>
                    <p>
                      {news.contentSnippet.split('[...]')[0].split('…Read Full Article Source')[0]}
                      <a href={news.link} rel="noopener noreferrer" target="_blank">
                        ...Read More
                      </a>
                    </p>
                    <Divider />
                  </div>
                );
              })}
          </PopoverContainer>
        </Dialogs>
        <Dialogs
          TransitionComponent={Transition}
          onClose={() => {
            setTwitterPopover(false);
          }}
          open={twitterPopover}
        >
          <PopoverContainer>
            <NewsHeader>Texas Environmental Social Media</NewsHeader>
            <Divider />
            <Scrollbars
              style={{
                height: 'calc(100vh - 200px)',
              }}
            >
              <TweetContainer>
                {twitterTweets &&
                  twitterTweets.map((tweet: any, index: number) => {
                    return (
                      <div key={index}>
                        <h6>
                          <a
                            href={`https://twitter.com/i/web/status/${tweet.id_str}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: tweet.text }}
                          ></a>
                        </h6>
                        <TwitterTweetEmbed tweetId={tweet.id_str} />
                        <Divider />
                      </div>
                    );
                  })}
              </TweetContainer>
            </Scrollbars>
          </PopoverContainer>
        </Dialogs>
      </AirContainer>
    </Fragment>
  );
};

export default withAuthContext(AIEarthAirTab);
