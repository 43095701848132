import React, { useEffect, useState } from 'react';
import { db } from '../../services/firebase';
import NotificationContext, {
  INotificationContext,
  notificationInitialState
} from './notifications-context';

interface INotificationProviderProps {
  uid: string;
}

const NotificationProvider: React.FC<INotificationProviderProps> = props => {
  const [state, setState] = useState<INotificationContext>(
    notificationInitialState
  );

  useEffect(() => {
    if (!props.uid) {
      return;
    }
    const unsubscribeNotifications = db
      .collection('users')
      .doc(props.uid)
      .collection('notifications')
      .limit(100)
      .onSnapshot(snapshot => {
        const docs = snapshot.docs;
        setState({
          notifications: docs
            .map(c => ({
              ...c.data(),
              id: c.id
            }))
            .filter((c: any) => c.subscriptionId)
        });
      });
    return () => unsubscribeNotifications();
  }, [props.uid]);

  return (
    <NotificationContext.Provider value={state}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
