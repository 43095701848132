import React from 'react';
import styled from 'styled-components';
import { ICustomSearchRecord } from '../../../types/search';

const ImageListItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 3%;
  row-gap: 25px;
`;
const ItemArea = styled.div`
  width: 30.5%;
`;
const ItemAreaImg = styled.a`
  height: 170px;
  display: flex;
  overflow: hidden;
  justify-content: center;
`;
const ItemAreaLink = styled.a`
  color: #666666;
  font-size: 12px;
  margin-top: 10px;
  display: block;
  & p {
    margin: 0;
  }
`;

interface IProps {
  records: ICustomSearchRecord[];
}

const ImageItem: React.FC<IProps> = ({ records }) => {
  return (
    <ImageListItem>
      {records.map((record, index) => (
        <ItemArea key={index}>
          <ItemAreaImg href={record.images && record.images.contextLink} rel="noopener noreferrer">
            <img src={record.images && record.images.thumbnailLink} alt={record.title} />
          </ItemAreaImg>
          <ItemAreaLink href={record.images && record.images.contextLink} rel="noopener noreferrer">
            <p>{record.title}</p>
            <p>{record.displayLink}</p>
          </ItemAreaLink>
        </ItemArea>
      ))}
    </ImageListItem>
  );
};
export default ImageItem;
