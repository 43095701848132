import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import PaperContainer from '../../components/PaperContainer';
import { Spinner } from 'react-bootstrap';
import { fetchValidatorUsage } from '../../services/firebase';
import ReactTooltip from 'react-tooltip';
import { Copy } from 'react-feather';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const NotificationPageContainer = styled(PaperContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  min-height: calc(100vh - 80px);
`;

const UserPanelHeader = styled.h3`
  padding: 0.75em 1em;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AccessCodeContainer = styled.div`
  width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const renderLoadingSpinner = () => (
  <SpinnerContainer>
    <Spinner variant="primary" animation="border" />
  </SpinnerContainer>
);

const TotalUsersDiv = styled.h5`
  margin: 0 1.2em 1em;
`;

const ValidatorUsage: React.FC = () => {
  const [currentUsageList, setUsageList] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const copyToClipboard = (token: string) => {
    navigator.clipboard.writeText(token);
  };

  const userList = [...currentUsageList];
  const columns = [
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'File Name',
      selector: 'file',
      sortable: true,
    },
    {
      name: 'File Submitted At',
      selector: 'submittedAt',
      sortable: true,
    },
    {
      name: 'Usages left',
      selector: 'usageLeft',
      sortable: true,
    },
    {
      name: 'Access Code',
      selector: 'lastSignInTime',
      cell: (row: any) => <AccessCodeContainer>{row.token}</AccessCodeContainer>,
    },
    {
      name: '..',
      selector: 'lastSignInTime',
      cell: (row: any) => (
        <Fragment>
          {document.queryCommandSupported('copy') && (
            <div>
              <ReactTooltip data-delay-hide={2000} globalEventOff="click" />
              <span
                data-tip="click to copy Access Code"
                onClick={() => {
                  copyToClipboard(row.token);
                }}
              >
                <Copy />
              </span>
            </div>
          )}
        </Fragment>
      ),
    },
  ];
  const Datatable = () => <DataTable columns={columns} data={userList} pagination />;

  const loadValidatorUsage = async () => {
    setIsLoading(true);
    const { data } = await fetchValidatorUsage({});
    setUsageList(data.validatorUsageLog);
    setIsLoading(false);
  };
  useEffect(() => {
    loadValidatorUsage();
    return;
  }, []);

  return (
    <NotificationPageContainer>
      <UserPanelHeader>Validator Usage</UserPanelHeader>
      {isLoading && renderLoadingSpinner()}
      {!isLoading && <TotalUsersDiv>Total Usages: {currentUsageList.length || 0}</TotalUsersDiv>}
      {!isLoading && (
        // <Table responsive>
        //   <thead>
        //     <tr>
        //       <th>Email</th>
        //       <th>File Name</th>
        //       <th>File Submitted At</th>
        //       <th>Usages left</th>
        //       <th>Access Code</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {R.map((userRecord: any) => {
        //       return (
        //         <tr key={userRecord.id}>
        //           <td>{userRecord.email}</td>
        //           <td>{userRecord.file}</td>
        //           <td>{userRecord.submittedAt}</td>
        //           <td>{userRecord.usageLeft}</td>
        //           <td><AccessCodeContainer>{userRecord.token}</AccessCodeContainer></td>
        //           <td>
        //             {
        //               document.queryCommandSupported('copy') &&
        //               <div><ReactTooltip data-delay-hide={2000} globalEventOff="click" />
        //                 <span data-tip="click to copy Access Code" onClick={() => {
        //                   copyToClipboard(userRecord.token);
        //                 }}>
        //                   <Copy /></span>
        //               </div>
        //             }
        //           </td>
        //         </tr>
        //       );
        //     }, currentUsageList)}
        //   </tbody>
        // </Table>
        <Datatable />
      )}
    </NotificationPageContainer>
  );
};

export default ValidatorUsage;
