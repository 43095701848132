import * as R from 'ramda';
/* tslint:disable:no-console */
export interface ICeresLoggerPayload {
  message: string;
  payload?: {
    [key: string]: any;
  };
}
/**
 * A simple console logger configured via an environment variable
 * mark the env variable FE_CONSOLE_LOGGING true or false to enable or disable
 * by default its disabled.
 */
export class CeresLogger {
  public static error(payload: ICeresLoggerPayload) {
    if (!this.isConsoleLogEnabled) {
      return;
    }
    console.error(payload.message);
    console.dir(payload);
    return;
  }
  public static debug(payload: ICeresLoggerPayload) {
    if (!this.isConsoleLogEnabled && !this.isDebugLogEnabled) {
      return;
    }
    console.log(payload.message);
    console.dir(payload.payload);
    return;
  }
  public static warn(payload: ICeresLoggerPayload) {
    if (!this.isConsoleLogEnabled) {
      return;
    }
    console.warn(payload.message);
    console.dir(payload);
    return;
  }
  public static info(payload: ICeresLoggerPayload) {
    if (!this.isConsoleLogEnabled) {
      return;
    }
    console.log(payload.message);
    !!payload.payload && console.log(payload.payload);
    return;
  }
  private static isConsoleLogEnabled: boolean = R.equals(
    process.env.REACT_APP_FE_CONSOLE_LOGGING,
    'true'
  );
  private static isDebugLogEnabled: boolean = R.equals(
    process.env.REACT_APP_FE_DEBUG_CONSOLE_LOGGING,
    'true'
  );
}
