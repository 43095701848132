import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Row, Col,Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoadingButton from '../../components/LoadingButton';
import ContactContainer from '../../components/contacts';
import { projectId } from '../../services/firebase';
import ContactListContainer from '../../components/contacts/contactList';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import { CeresLogger } from '../../logger';
import { Search, Users } from 'react-feather';

const NotificationPageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;
const SubscribeRNFormContainer = styled.div`
  margin: 4em 5em;
  form {
    margin-top: 20px;
  }
`;
const TrackingList = styled.div`
  margin-top: 20px;
`;
const HeadingDiv = styled.div`
  color:#ffff;
 background-color:#1b70ed;
 border:3px solid #a6a6a6;
 border-top-right-radius:20%;
 padding: 20px 20px 40px;
 & h3 {
  text-align: center;
 }
 & Table {
  background-color: white;
  color: black;
  text-align: right;
  margin-top: 30px;
 }
`;
const MainDiv = styled.div`
text-align: center;
& h3{
  font-size: 50px;
  font-weight: 400;
}
& h6{
  font-size: 30px;
  color: #0066ff;
  font-weight: 400;
}
`;
const ContactDiv = styled.div`
text-align: centre;
& h3{
  font-size: 40px;
  font-weight: 700;
}
& h6{
  font-size: 30px;
  color: #0066ff;
  font-weight: 600;
}`;

const SearchBarInputContainer = styled.form`
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
`;
// const SubscriptionListLink = styled.a`
//   margin-top: 10px;
//   display: inline-block;
// `;
// const SubscriptionTypeGroup = styled(Form.Group)`
//   display: flex;
//   .input-group-text {
//     padding: 0.375rem 0;
//     border: none;
//     background: none;
//     display: inline;
//     margin-right: 5px;
//   }
// `;
// const SubscriptionTypeRadioItem = styled.div`
//   margin-left: 10px;
// `;
// const SubscriptionTypeText = styled.span``;
// const TrackbotRobo = styled.div`
//   border: 8px solid #999;
//   border-radius: 30px;
//   padding: 10px 20px;
//   width: 180px;
//   height: 192px;
//   margin: auto;
//   & h4 {
//     text-align: center;
//     font-size: 22px;
//     font-weight: 700;
//   }
//   & img {
//     width: 100%;
//   }
// `;
// const Trackbot = styled.div`
//   h3 {
//     color: #005fbc;
//     text-align: center;
//     font-size: 40px;
//     font-weight: 700;
//   }
// `;
const CustRow = styled(Row)`
  align-items: center;


  }
`;
// const ContentHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 15px;
// `;
// const FilterArea = styled.div`
//   max-width: 500px;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   & > span {
//     font-weight: 600;
//   }
// `;
const PeopleFinderPage: React.FC<{ context: IAuthContext }> = (props) => {
  const [peopleFinderSearch, setPeopleFinderSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderLocation, setOrderLocation] = useState<any>('asc');
  const [orderCompany, setOrderCompany] = useState<any>('desc');
  const [activeOrder, setActiveOrder] = useState<any>('company');
  const [isLoad, setLoad] = useState(false);
  const [showFrom, setShowFrom] = useState(0);
  const [peopleList, setPeopleList] = useState<any>([]);
  const [selectedContactList, setSelectedContactList] = useState<any>([]);
  const [showContactList, setShowContactList] = useState<boolean>(false);
  const [contactCount, setContactCount] = useState<any>(0);
  const showItems = 20;

  const getContactTotalCount = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      };
      const data = {
        action: 'total'
      };
      const totalContactsResp = await axios.post('https://us-central1-' + projectId + '.cloudfunctions.net/TCEQGetContacts',data, config);
      if(totalContactsResp && totalContactsResp.data && totalContactsResp.data.total_contacts) {
      setContactCount(totalContactsResp.data.total_contacts);
    }
    } catch (error) {
      toast.error(`Failed to load contact count.`);
    }
  };

  useEffect(() => {
    getContactTotalCount();
  }, []);

  const getPeopleList = async (fromPage = 0) => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      };
      const data = {
        action: 'search',
        search_type: 'all',
        keyword: peopleFinderSearch,
        records_num_start: fromPage,
        records_num_show: showItems,
        sorting: activeOrder === 'company' ? 'company-' + orderCompany : ''
      };
      await axios
        .post('https://us-central1-' + projectId + '.cloudfunctions.net/TCEQGetContacts', data, config)
        .then((response: any) => {
            if(response.data && response.data) {
              setPeopleList({
                // total_records: response.data.total_records,
                // data: response.data.contacts
                ...response.data
              });
            }
        })
        .catch((error) => {
            CeresLogger.error(error);
            toast.error(`Failed to load contact list.`);
        })
        .finally(() => setIsLoading(false));

    } catch (error) {
      toast.error(`Failed to load contact list.`);
    }
  };

  const onContactListReq = (row: any) => {
    setSelectedContactList({
        data: row.contacts,
        ...row
    });
    setShowContactList(true);
  };

  return (
    <NotificationPageContainer className="track-area">
      <SubscribeRNFormContainer className="sub-track">
        {showContactList ?
            <CustRow>
            <Col md={2}>
              <div>
                <Users size={250} />
              </div>
           </Col>
            <Col md={10}>
              <ContactDiv>
              <h3>{selectedContactList.entity_name}</h3>
              <h6>{selectedContactList.city}</h6>
              </ContactDiv>
            </Col>
          </CustRow>
        :
        <CustRow>
        <Col md={3}>
          <HeadingDiv>
            <h3>EnviroAI Factoids</h3>
            <h5># of contacts in EnviroAI’s system =</h5>
            <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Total Email/Content ID</th>
          </tr>
      </thead>
      <tbody>
        <tr>
          <td>{contactCount}</td>
          </tr>
          </tbody>
    </Table>
          </HeadingDiv>
       </Col>
        <Col md={9}>
          <MainDiv>
          <h3>People Finder</h3>
          <h6>from Environmental Agency Files</h6>
          </MainDiv>
          <SearchBarInputContainer
            noValidate
            // validated={isFormValidated}
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              const form = e.currentTarget as any;
              if (form && !form.checkValidity()) {
                e.stopPropagation();
                // setFormValidation(true);
                return;
              }
              // saveSubjectTrack();
              setShowFrom(0);
              getPeopleList(0);
            }}
          >
            <InputGroup>
              <Form.Control
                required
                value={peopleFinderSearch}
                onChange={(e: any) => setPeopleFinderSearch(e.target.value)}
                placeholder="Search Company, Facility, Location, Person"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              {/* <Form.Control.Feedback type="invalid">
                Please enter required information for subscription.
              </Form.Control.Feedback> */}
              <InputGroup.Append>
            <LoadingButton isLoading={isLoading}>
              <Search />
            </LoadingButton>
            </InputGroup.Append>
            </InputGroup>
          </SearchBarInputContainer>
            {/* </Form.Group>
          </Form> */}
        </Col>
      </CustRow>
        }
        <TrackingList>
              {showContactList ?
              <ContactListContainer
                rows={selectedContactList}
                hideContactListTable={() => setShowContactList(false)}
              />
              :
              <ContactContainer
                getPeopleList={getPeopleList}
                showItems={showItems}
                isLoad={isLoad}
                orderCompany={orderCompany}
                setLoad={setLoad}
                setOrderCompany={setOrderCompany}
                setOrderLocation={setOrderLocation}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                orderLocation={orderLocation}
                rows={peopleList}
                onContactListReq={onContactListReq}
                showFrom={showFrom}
                setShowFrom={setShowFrom}
              />
              }
        </TrackingList>
      </SubscribeRNFormContainer>
    </NotificationPageContainer>
  );
};

export default withAuthContext(PeopleFinderPage);
