import React, { useState } from 'react';
import UserContext, { IUserContext, userInitialState } from './user-context';

const UserProvider: React.FC = props => {
  const [state, setState] = useState<IUserContext>(userInitialState);

  const updateValues = (values: IUserContext) => {
    setState(values);
  };
  return (
    <UserContext.Provider value={{
      ...state, updateValues
    }}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
