import React, { useState } from 'react';
import styled from 'styled-components';
import { db } from '../services/firebase';
import { Container, Row, Col, Badge, Modal, Form, Spinner, Button } from 'react-bootstrap';
import withAuthContext from '../context/auth/AuthConsumer';
import { IAuthContext } from '../context/auth/auth-context';
import Footer from '../components/Footer';
import { Edit } from 'react-feather';
import { toast } from 'react-toastify';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { TIERS } from '../types/tiers';
import * as R from 'ramda';

const PageWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  max-width: 800px;
  margin: auto;
`;

const TierBadge = styled(Badge)``;

const PageTitle = styled.h1`
  display: block;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0 60px;
`;

const Paragraph = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  margin: 8px 0;
`;
const EditUserPanelBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const EditUserPanelItem = styled.div`
  margin-bottom: 30px;
`;
const UpdateProfile = styled.div`
  margin-bottom: 15px;
  text-align: right;
  & button {
    padding-bottom: 8px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: 0,
    },
  })
);

const Account: React.FC<{
  context: IAuthContext;
}> = (props) => {
  const classes = useStyles();
  const authContext = props.context;
  const [isLoading, setIsLoading] = useState(false);
  const [editUserNameModal, setEditUserNameModal] = useState<boolean>(false);
  const userinfo: any = authContext.getCompleteUserInfo();
  const [userName, setUserName] = useState(
    authContext.getUserName() ? authContext.getUserName() : authContext.getUserDisplayName()
  );
  const [userPhone, setUserPhone] = useState(authContext.getUserPhone());
  const [clientName, setClientName] = useState(authContext.getClientName());
  const [clientAddress, setClientAddress] = useState(authContext.getClientAddress());

  const creationTime = () => {
    if (authContext && authContext.user) {
      return authContext.user.metadata.creationTime;
    }
  };
  const renderTierBadge = (tier: TIERS, BadgeComp = TierBadge) => {
    return R.cond<any, any>([
      [
        R.equals(TIERS.DAYPASS),
        () => (
          <BadgeComp pill variant="primary">
            DAYPASS
          </BadgeComp>
        ),
      ],
      [
        R.equals(TIERS.PLATINUM),
        () => (
          <BadgeComp pill variant="primary">
            PLATINUM
          </BadgeComp>
        ),
      ],
      [
        R.equals(TIERS.BASIC),
        () => (
          <BadgeComp pill variant="info">
            BASIC
          </BadgeComp>
        ),
      ],
      [
        R.equals(TIERS.FREE),
        () => (
          <BadgeComp pill variant="success">
            FREE
          </BadgeComp>
        ),
      ],
      [
        R.equals(TIERS.SUPER_ADMIN),
        () => (
          <BadgeComp pill variant="primary">
            Super Admin
          </BadgeComp>
        ),
      ],
      [
        R.T,
        () => (
          <BadgeComp pill variant="danger">
            N/A
          </BadgeComp>
        ),
      ],
    ])(tier);
  };

  const updateUserNameInContext = async (name: any) => {
    authContext.getUserName = () => name;
  };

  return (
    <>
      <PageWrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>Account Information</PageTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <UpdateProfile>
                <Button variant="primary" onClick={() => setEditUserNameModal(true)} className={classes.margin}>
                  <Edit size={18} /> &nbsp; Update Profile
                </Button>
              </UpdateProfile>

              <Divider />
              <Paragraph>
                <span>
                  User Name: <b>{userName}</b>
                </span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>
                  Email Address: <b>{authContext.getUserEmail()}</b>
                </span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>
                  User Phone: <b>{userPhone}</b>
                </span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>
                  Client Name: <b>{clientName}</b>
                </span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>
                  Client Address: <b>{clientAddress}</b>
                </span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>Account Created: {creationTime()}</span>
              </Paragraph>
              <Divider />
              <Paragraph>
                <span>Membership Tier: {renderTierBadge(authContext.getUserTier())}</span>
              </Paragraph>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col>
              <Paragraph>
                <span>
                  Click <a href="/">here</a> to return to CERES
                </span>
              </Paragraph>
              <Divider />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
      <Footer />

      <Modal
        dialogClassName="ceres--subscription-list"
        show={editUserNameModal}
        onHide={() => setEditUserNameModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <EditUserPanelBody>
          <Form>
            <EditUserPanelItem>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  onChange={(e: any) => setUserName(e.target.value)}
                  placeholder="Enter name"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={userPhone}
                  onChange={(e: any) => setUserPhone(e.target.value)}
                  placeholder="Enter phone number"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  value={clientName}
                  onChange={(e: any) => setClientName(e.target.value)}
                  placeholder="Enter client name"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Client Address</Form.Label>
                <Form.Control
                  type="text"
                  value={clientAddress}
                  onChange={(e: any) => setClientAddress(e.target.value)}
                  placeholder="Enter client address"
                />
              </Form.Group>
            </EditUserPanelItem>
          </Form>
        </EditUserPanelBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditUserNameModal(false)} disabled={isLoading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setIsLoading(true);
              db.collection('users')
                .doc(userinfo.uid)
                .update({
                  name: userName,
                  phone: userPhone,
                  clientname: clientName,
                  address: clientAddress,
                })
                .then(() => {
                  toast.success(`Saved user information successfully`);
                  updateUserNameInContext(userName);
                })
                .catch(() => {
                  toast.error(`Error while saving user information`);
                })
                .finally(() => {
                  setIsLoading(false);
                  setEditUserNameModal(false);
                });
            }}
          >
            {isLoading ? <Spinner size="sm" variant="light" animation="border" /> : 'Save changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withAuthContext(Account);
