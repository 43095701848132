import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { FileText } from 'react-feather';
import { Scrollbars } from 'react-custom-scrollbars';

interface IAirDeviationTable {
  events: any[];
}
const TD = styled.td`
  font-size: 11px;
  text-align: center;
  padding: 3px !important;
`;
const TH = styled.th`
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
  padding: 3px !important;
  vertical-align: middle !important;
  background: #e8e8e8;
  position: sticky;
  top: -1px;
  z-index: 10;
`;

const AirDeviationTable: React.FC<IAirDeviationTable> = props => {
  const { events } = props;
  if (!events) {
    return null;
  }
  return (
    <Scrollbars
      style={{
        height: 'calc(100vh - 285px)',
      }}
    >
      <Table bordered className="pt-2">
        <thead>
          <tr>
            <TH>Date <br/>Began</TH>
            <TH>Duration <br />Minutes</TH>
            <TH>Cause</TH>
            <TH>Corrective Action</TH>
            <TH>Unit</TH>
            <TH>Doc <br/>Link</TH>
          </tr>
        </thead>
        <tbody>
        {events.map((event, key) => (
            <tr key={key}>
              <TD>{event['deviation_period_start_date']}</TD>
              <TD>{event['duration_minutes']}</TD>
              <TD>{event['cause_of_deviation']}</TD>
              <TD>{event['corrective_action_take']}</TD>
              <TD>{event['unit_id']}</TD>
              <TD><a href={event['content_file_url']} download><FileText /></a></TD>
            </tr>
        ))}
        </tbody>
      </Table>
    </Scrollbars>
  );
};

export default AirDeviationTable;
