import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { IJob } from '../../store/aiearth/types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MUIButton from '@material-ui/core/Button';
import Moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import { activeJobInitial as jobEditAddressInitial } from '../../store/aiearth/reducer';
import styled from 'styled-components';
import { updateJob } from '../../store/aiearth/action';

const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  & * {
    margin-left: 2px;
  }
`;

const JobButton = styled(MUIButton)`
  margin: 0.2rem !important;
`;

const CustomFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 4px;
`;

const Flex1 = styled.div`
  display: flex;
  flex: 1;
`;

const Flex2 = styled.div`
  display: flex;
  flex: 2;
`;

const JobsEditAddress: React.FC<any> = (props) => {
  const [jobEditAddress, setJobEditAddress] = useState(jobEditAddressInitial);
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;
  useEffect(() => {
    if (jobState.activeJob) {
      setJobEditAddress({ ...jobState.activeJob });
    }
  }, [jobState.activeJob]);

  return (
    <>
      <CustomForm>
        <Row>
          <TextField
            label="Job Name"
            autoFocus
            id="jobName"
            variant="outlined"
            size="small"
            value={jobEditAddress.name}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                name: e.target.value,
              });
            }}
          />
          <TextField
            id="facilityName"
            label="Facility Name"
            variant="outlined"
            size="small"
            value={jobEditAddress.facilityName}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                facilityName: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            label="Address"
            id="address"
            variant="outlined"
            fullWidth
            size="small"
            value={jobEditAddress.address}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                address: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <Flex2>
            <TextField
              id="city"
              label="City"
              variant="outlined"
              size="small"
              fullWidth
              value={jobEditAddress.city}
              onChange={(e: any) => {
                setJobEditAddress({
                  ...jobEditAddress,
                  city: e.target.value,
                });
              }}
            />
          </Flex2>
          <Flex1>
            <CustomFormControl size="small" variant="outlined">
              <Autocomplete
                options={['TX']}
                getOptionLabel={(option: any) => option}
                getOptionSelected={(option: string, value: string) =>
                  option === value
                }
                id="state"
                value={jobEditAddress.state}
                onChange={(event: any, newValue: any) => {
                  setJobEditAddress({ ...jobEditAddress, state: newValue });
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </CustomFormControl>
          </Flex1>
        </Row>
        <Row>
          <TextField
            id="zip"
            label="Zip"
            variant="outlined"
            size="small"
            value={jobEditAddress.zip}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                zip: e.target.value,
              });
            }}
          />
          <CustomFormControl size="small" variant="outlined">
            <Autocomplete
              options={['ASTM Phase 1']}
              getOptionLabel={(option: any) => option}
              getOptionSelected={(option: string, value: string) =>
                option === value
              }
              id="job-type"
              value={jobEditAddress.jobType}
              onChange={(event: any, newValue: any) => {
                setJobEditAddress({ ...jobEditAddress, jobType: newValue });
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Choose Job Type"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </CustomFormControl>
        </Row>
        <Row>
          <CustomFormControl size="small" variant="outlined">
              <Autocomplete
                options={[
                  'NOT_DETERMINED',
                  'N',
                  'NNE',
                  'NE',
                  'ENE',
                  'E',
                  'ESE',
                  'SE',
                  'SSE',
                  'S',
                  'SSW',
                  'SW',
                  'WSW',
                  'W',
                  'WNW',
                  'NW',
                  'NNW'
                ]}
                id="gw-type"
                getOptionLabel={(option: any) => option}
                getOptionSelected={(option: string, value: string) =>
                  option === value
                }
                value={jobEditAddress.gwGradient}
                onChange={(event: any, newValue: any) => {
                  setJobEditAddress({ ...jobEditAddress, gwGradient: newValue });
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Choose Groundwater Gradient"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </CustomFormControl>
        </Row>
        <Row>
          <Flex2>
            <CustomFormControl size="small" variant="outlined">
              <Autocomplete
                options={[
                  'Active',
                  'Closed',
                  'Cancelled',
                  'Proposal',
                  'Proposal Not Awarded',
                ]}
                getOptionLabel={(option: any) => option}
                getOptionSelected={(option: string, value: string) =>
                  option === value
                }
                fullWidth
                id="job-status"
                value={jobEditAddress.status}
                onChange={(event: any, newValue: any) => {
                  const jobEditAddressObj = jobEditAddress;
                  if (
                    ['Closed', 'Cancelled', 'Proposal Not Awarded'].includes(
                      newValue
                    )
                  ) {
                    jobEditAddressObj.jobActualCompleteDate = new Date();
                  }
                  setJobEditAddress({ ...jobEditAddress, status: newValue });
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Job Status"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </CustomFormControl>
          </Flex2>
          <Flex1>
            <TextField
              id="numberOfDays"
              label="Number of Days"
              variant="outlined"
              size="small"
              type="number"
              value={jobEditAddress.numberOfDays}
              onChange={(e: any) => {
                const noOfDays = e.target.value;
                setJobEditAddress({
                  ...jobEditAddress,
                  numberOfDays: noOfDays,
                  jobCompleteDate: Moment(jobEditAddress.jobStartDate)
                    .add(noOfDays ? noOfDays : 0, 'days')
                    .toDate(),
                });
              }}
            />
          </Flex1>
        </Row>
        <Row>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            format="MM/DD/YYYY"
            variant="inline"
            views={['date']}
            inputVariant="outlined"
            size="small"
            fullWidth
            label="Start Date"
            value={jobEditAddress.jobStartDate}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                jobStartDate: e,
                jobCompleteDate: Moment(e)
                  .add(jobEditAddress.numberOfDays, 'days')
                  .toDate(),
                jobActualCompleteDate: Moment(e)
                  .add(jobEditAddress.numberOfDays, 'days')
                  .toDate(),
              });
            }}
          />
        </Row>
        <Row>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            format="MM/DD/YYYY"
            variant="inline"
            views={['date']}
            inputVariant="outlined"
            size="small"
            fullWidth
            label="Scheduled Complete Date"
            value={jobEditAddress.jobCompleteDate}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                jobCompleteDate: e,
                numberOfDays:
                  Moment(e).diff(jobEditAddress.jobStartDate, 'days') + 1,
              });
            }}
          />
        </Row>
        <Row>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            format="MM/DD/YYYY"
            variant="inline"
            views={['date']}
            inputVariant="outlined"
            size="small"
            fullWidth
            label="Actual Complete Date"
            value={jobEditAddress.jobActualCompleteDate}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                jobActualCompleteDate: e,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            id="jobNotes"
            label="Job Notes"
            fullWidth
            variant="outlined"
            size="small"
            multiline
            value={jobEditAddress.jobNotes}
            onChange={(e: any) => {
              setJobEditAddress({
                ...jobEditAddress,
                jobNotes: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <JobButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => {
              dispatch(
                updateJob({
                  ...jobState,
                  ...{
                    activeJob: jobEditAddress,
                    panelType: 'tasks',
                  },
                })
              );
            }}
          >
            Update
          </JobButton>
        </Row>
      </CustomForm>
    </>
  );
};

export default JobsEditAddress;
