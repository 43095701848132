import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

interface IAnnualEmission {
  options: any;
}

const ChartContainer = styled.div`
  padding: 0 15px 15px
`;
const ChartTitle = styled.div`
  font-size: 22px;
  background-color: #f7f7f7;
  padding: 6px 20px;
  color: #a9a9a9;
  text-align: center;
  margin-bottom: 10px;
`;

let data = {};
let dataOptions = {};

const AnnualAmissionChart: React.FC<IAnnualEmission> = (props) => {
  const fData = props.options.fData;
  const imData = props.options.imData;
  const eventsLabels = props.options.labels;
  const title = props.options.title;
  const options = props.options;
  if (fData) {
    const fPlotLine = fData.map((ele: any) => {
      return ele.tons;
    });
    const imPlotLine = imData.map((ele: any) => {
      return ele.tons;
    });
    data = {
      labels: eventsLabels,
      datasets: [
        {
          label: 'Industry Median ' + title,
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(244,134,12,0.1)',
          borderColor: '#A9A9A9',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#A9A9A9',
          pointBackgroundColor: '#fff',
          borderWidth: 2,
          pointBorderWidth: 6,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: '#A9A9A9',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: imPlotLine
        },
        {
          label: 'Facility ' + title,
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(133,133,133,0.1)',
          borderColor: options.color,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: options.color,
          pointBackgroundColor: '#fff',
          borderWidth: 4,
          pointBorderWidth: 8,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: options.color,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: fPlotLine
        }
      ]
    };
    dataOptions = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (value: any, text: any) => {
            const xLabel = text.datasets[value.datasetIndex].label;
            const yLabel = numberFormat(value.yLabel);
            return xLabel + ': ' + yLabel;
          }
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value: any) => {
                return numberFormat(value);
              },
              beginAtZero: true,
              maxTicksLimit: 8,
              minTicksLimit: 8,
            },
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              fontColor: '#000',
              fontSize: '14',
            }
          }
        ]
      },
    };
  }
  const numberFormat = (val: number): string => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <ChartContainer>
      <ChartTitle>
        {title}
      </ChartTitle>
      <Line data={data} options={dataOptions} />
    </ChartContainer>
  );
};

export default AnnualAmissionChart;
