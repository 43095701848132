import { Filter, Search } from 'react-feather';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import React, { useContext, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { IAuthContext } from '../../context/auth/auth-context';
import LoadingButton from '../LoadingButton';
import { TIERS } from '../../types/tiers';
import UserContext from '../../context/user/user-context';
import mediaQuery from '../../util/media-query';
import styled from 'styled-components';
import withAuthContext from '../../context/auth/AuthConsumer';
import ReactTooltip from 'react-tooltip';

const SearchFormContainer = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0.5em;
  ${mediaQuery.phone`
    flex-direction: column;
    margin: 1em;
    padding: 0;
  `}
  ${mediaQuery.tablet`
    flex-direction: column;
    margin: 1em;
    padding: 0;
  `}
`;

const SearchFormInputContainer = styled.div`
  button.btn.btn-primary {
    line-height: 20px;
  }
`;

const FilterButton = styled.div<{ isApplied: boolean }>`
  ${props =>
    props.isApplied &&
    `svg {
      fill: #fff;
  }`}
  cursor: pointer;
  color: var(--white);
  margin-left: 5px;
  ${mediaQuery.phone`margin-top: 1em; margin-left: 0;`}
  ${mediaQuery.tablet`margin-top: 1em; margin-left: 0;`}
`;

interface ISearchForm {
  onSearchSubmit: (queryText: string) => void;
  isLoading: boolean;
  inputRef?: React.MutableRefObject<any>;
  onFilterClick: () => void;
  isFilterApplied: any;
  currentQueryText: string;
  isChemPlant?: any;
}
const SearchForm: React.FC<ISearchForm & { context: IAuthContext }> = props => {
  const authContext = props.context;
  const inputReference = React.createRef<any>();
  const searchContext = useContext(UserContext);
  const filtersObj = Object.assign({}, props.isFilterApplied);
  if (filtersObj) {
    Object.keys(filtersObj).forEach(k => {
      (filtersObj[k] === null ||
        filtersObj[k] === undefined ||
        filtersObj[k] === '') &&
        delete filtersObj[k];
    });
  }
  const filterLabels = {
    rnNumber: 'Entity Number',
    cityName: 'City Name',
    category: 'Category',
    entityName: 'Entity Name',
    zipCode: 'Zip Code',
    fromReleaseDate: 'From Release Date',
    toReleaseDate: 'To Release Date'
  };

  const filterList = Object.keys(filtersObj).map((val: string) => {
    return filterLabels[val as keyof typeof filterLabels];
  });

  const setSearchTerm = () => {
    if (inputReference && inputReference.current) {
      inputReference.current.value = props.currentQueryText;
    }
  };
  useEffect(() => {
    setSearchTerm();
  });
  const focusEid = () => {
    const d = document;
    setTimeout(() => {
      if (d != null && (d.getElementById('doc-query') !== null)) {
        // @ts-ignore
        d.getElementById('doc-query').focus();
      }
    }, 500);
  };
  focusEid();

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const ceresSearch = searchContext;
    ceresSearch.searchTerm = inputReference.current.value;
    searchContext.updateValues(ceresSearch);
    props.onSearchSubmit(inputReference.current.value);
  };

  return (
    <>
      <SearchFormContainer
        onSubmit={(e: React.FormEvent) => onSubmitHandler(e)}
      >
        <SearchFormInputContainer>
          <InputGroup>
            <FormControl
              ref={inputReference}
              id="doc-query"
              // onChange={(e: any) => setQueryText(e.target.value)}
              placeholder={props.isChemPlant === true ? 'Facility Name, Facility ID' : 'flares, net heating'}
              aria-label="Search"
              aria-describedby="basic-addon2"
              disabled={props.isLoading || false}
              onKeyDown={(e: any) => {
                if(e.key === 'Enter') {
                  onSubmitHandler(e);
                }
              }}
            />
            <InputGroup.Append>
              <LoadingButton isLoading={props.isLoading || false}>
                <Search />
              </LoadingButton>
            </InputGroup.Append>
          </InputGroup>
        </SearchFormInputContainer>
        {authContext.isAuthenticated &&
          (authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) ||
            authContext.isTier(TIERS.SUPER_ADMIN)) && (
              <OverlayTrigger
                trigger="hover"
                placement="right"
                overlay={<ReactTooltip effect="solid" html={true} />}
              >
                <FilterButton
                  data-tip={
                    ReactDOMServer.renderToString(
                      <div>
                        <strong>
                          {!!props.isFilterApplied ? 'Applied Filters' : 'No Filters Applied'}
                        </strong>
                        <br />
                        {filtersObj ? filterList.toString() : ''}
                        <br />
                      </div>
                    )
                  }
                  isApplied={!!props.isFilterApplied}
                  onClick={() => props.onFilterClick()}
                >
                  <Filter />
                </FilterButton>
              </OverlayTrigger>
          )}
      </SearchFormContainer>
    </>
    );
};

export default withAuthContext(SearchForm);
