export enum CUSTOM_SEARCH_TYPE {
  DEFAULT = 'default',
  ALL = 'ALL',
  IMAGES = 'IMAGES',
  VIDEOS = 'VIDEOS',
  LDEQ_DOC = 'LDEQ_DOC',
  IEPA_DOC = 'IEPA_DOC',
  SCIENTIFIC = 'SCIENTIFIC',
  TCEQ_ENFORCEMENT = 'TCEQ_ENFORCEMENT',
  TCEQ_WEB_FILES = 'TCEQ_WEB_FILES',
  EPA = 'EPA',
  LEGAL = 'LEGAL',
  NEWS = 'NEWS',
  SHOPPING = 'SHOPPING',
  INTERNET = 'INTERNET',
  SOCIAL = 'SOCIAL',
  MAP = 'MAP',
}
