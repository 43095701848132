import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ChevronLeft, X } from 'react-feather';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PaperContainer from '../../components/PaperContainer';
import { IProjectContext } from '../../context/project/project-context';
import withProjectContext from '../../context/project/ProjectConsumer';
import JobTypeWizardStep from './JobTypeWizardStep';
import NameWizardStep from './NameWizardStep';

const NewProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NewProjectHeader = styled.h3`
  margin: 1em;
`;
const WizardStepSection = styled(PaperContainer)`
  flex: 1;
  display: flex;
  margin: 1em;
  position: relative;
`;

const FinalSubmissionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  svg {
    color: var(--dark);
  }
`;
const BackButton = styled(CloseButton)`
  left: 25px;
  width: 24px;
`;

interface INewProject {
  errorMessage: string;
  projectName: string | null;
  selectedJobTypes: string[];
  isLoading: boolean;
  setupComplete: boolean;
  step: number;
}
const initialState = {
  errorMessage: '',
  isLoading: false,
  projectName: null,
  selectedJobTypes: [],
  setupComplete: false,
  step: 0
};

const NewProject: React.FC<{ context: IProjectContext }> = props => {
  const [state, setState] = useState<INewProject>(initialState);
  const context = props.context;
  useEffect(() => {
    if (state.step === 2 && state.isLoading) {
      context
        .createProject(state.projectName || '', state.selectedJobTypes)
        .then(() => {
          toast.success('Project created successfully!', {
            position: toast.POSITION.TOP_RIGHT
          });
          setState({
            ...initialState,
            setupComplete: true
          });
        })
        .catch(err => {
          setState({
            ...state,
            errorMessage: err.message,
            isLoading: false
          });
        });
    }
  }, [state, context]);
  if (state.setupComplete) {
    return <Redirect to="/" />;
  }
  return (
    <NewProjectContainer>
      <NewProjectHeader>Get started, with the project wizard</NewProjectHeader>
      <WizardStepSection>
        <CloseButton>
          <X />
        </CloseButton>
        {state.step > 0 && (
          <BackButton
            onClick={() =>
              !state.isLoading &&
              setState({
                ...state,
                step: state.step - 1
              })
            }
          >
            <ChevronLeft />
          </BackButton>
        )}
        {state.step <= 0 && (
          <NameWizardStep
            onComplete={(projectName: string) => {
              !state.isLoading &&
                setState({
                  ...state,
                  projectName,
                  step: state.step + 1
                });
            }}
            projectName={state.projectName}
          />
        )}
        {state.step === 1 && (
          <JobTypeWizardStep
            onComplete={(jobTypes: string[]) => {
              !state.isLoading &&
                setState({
                  ...state,
                  isLoading: true,
                  selectedJobTypes: jobTypes,
                  step: state.step + 1
                });
            }}
            selectedJobTypes={state.selectedJobTypes}
          />
        )}
        {state.step === 2 && (
          <FinalSubmissionContainer>
            {state.isLoading && (
              <Spinner animation="border" variant="primary" />
            )}
          </FinalSubmissionContainer>
        )}
      </WizardStepSection>
    </NewProjectContainer>
  );
};

export default withProjectContext(NewProject);
