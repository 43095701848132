import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SignupForm from '../components/SignupForm';
import VerticalCenter from '../components/VerticalCenter';
import EnviroAILogo from '../assets/enviroai_new.svg';
import mediaQuery from '../util/media-query';
import tundraSearch from '../assets/search/tundra_search.svg';

const Hero = styled<any>(VerticalCenter)`
  // padding: 80px;
  background-image: url(${tundraSearch});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  background-color: rgb(142, 187, 247);
  text-align: center;
  align-items: center;
  color: #000;
  & h1 {
    font-family: 'Lazy Dog';
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    color: inherit;
    margin-bottom: 10px;
  }
  & h3 {
    margin: 10px 100px;
    font-weight: 700;
  }
  & p {
    font-size: 18px;
    padding: 20px 59px;
    text-align: center;
    font-family: 'Open Sans';
  }
`;

const CeresBotHeroImage = styled.img`
  width: 100%;
  margin-top: 45px;
  max-width: 340px;
  @media only screen and (max-width: 1400px) {
    max-width: 45%;
  }
  ${mediaQuery.phone`
    width: auto;
    margin-top: 100px;
    max-width: 90%;
  `}
`;

const Main = styled<any>(VerticalCenter)`
  min-height: 100vh;
  background: #fff;
  align-items: center;

  a {
    color: rgb(44, 25, 14);
  }
`;

const Title = styled.h1`
  color: rgb(44, 25, 14);
  margin-bottom: 1em;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const SignupContainer = styled(Row)`
  flex: 1;
  overflow: auto;
  height: 100vh;
`;

const Signup: React.FC = () => (
  <SignupContainer noGutters>
    <Col lg={6} md={6}>
      <Hero>
        <CeresBotHeroImage src={EnviroAILogo} alt="EnviroAI" />
        <p className="mb-0 font-weight-bold">Digital Environmental Assistant</p>
        <p>
          Enviro Ai Pro utilizes big data, artificial intelligence, and the subject matter expertise training of
          experienced legal and consulting practitioners to deliver improved environmental professional performance.
        </p>
      </Hero>
    </Col>
    <Col lg={6} md={6}>
      <Main>
        <Title>EnviroAI</Title>
        <SignupForm />
        <Link to="/login">Already have an account?</Link>
      </Main>
    </Col>
  </SignupContainer>
);

export default Signup;
