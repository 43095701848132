import * as R from 'ramda';
import * as CryptoJS from 'crypto-js';

import { Alert, Button, Col, Spinner, Table } from 'react-bootstrap';
import {Button as MuiButton, Snackbar } from '@material-ui/core';
import { IAIEarth, IJob, IMap } from '../../store/aiearth/types';
import { ISearchRecord } from '../../types/search';
import { ChevronLeft } from 'react-feather';
import React, { useState, useEffect, useRef } from 'react';
import { ceresApp, buttonFavorite, iv, key as secKey } from '../../services/firebase';
import {
  updateJob,
  resetJob,
  resetAir,
  resetAIEarth,
} from '../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';
import { centroid, multiLineString, multiPolygon, polygon } from '@turf/turf';
import MyTundraMap from './map/MyTundraMap';
import { CeresLogger } from '../../logger';
import { IAuthContext } from '../../context/auth/auth-context';
import MyTundraRecordMarker from './map/MyTundraRecordMarker';
import { TIERS } from '../../types/tiers';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../../context/auth/AuthConsumer';
import ButtonOptions from './ButtonOptions';
import MyTundraFavoriteButtons from './MyTundraFavoriteButtons';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { projectId } from '../../services/firebase';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MyTundraTabResultsModal from './map/MyTundraTabResultsModal';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import MyTundraMapsFinding from './MyTundraMapsFinding';
import MuiAlert,{ AlertProps } from '@material-ui/lab/Alert';

interface IMyTundraContainer {
  showProgramQueryPanel: any;
  setShowProgramQueryPanel: any;
  programQueryEPNResult: any;
  boundingBox?: any;
  setBoundingBox?: any;
  showDocPanel: any;
  setShowDocPanel: any;
  showProgramQueryDocPanel: any;
  setShowProgramQueryDocPanel: any;
  activeMarkerRecord: any;
  setActiveMarkerRecord: any;
  onRectDrag?: any;
  doTextSearch: any;
  coords?: any;
  setProgramQueryEPNResult: any;
}

const MyTundraWrapper = styled.div `
`;

const ButtonContainer: any = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: #6c757d;
  flex-direction: row-reverse;
`;

const ModalContainer: any = styled.div `
  width: 22%;
  height: 67vh;
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
  color: #fff;
  top: 25%;
  border-left: hidden;
  left: 5%;
  // overflow-y: auto;
  padding: 0.5rem;
`;

const SearchContainer: any = styled.div `
  position: absolute;
  bottom: 75px;
  width: 100%;
  z-index: 1;

  .back-btn {
    min-width: 15%;
    border-radius: 50px;
    margin: 8px 48% 0;
    background-color: #5dc5c4 !important;
  }

  .search-box {
    width: 40%;
    margin: 0 35%;
    border: 2px solid #5DC5C4;
    border-radius: 10px;
    padding: 5px 10px;
    align-items: center;
    background-color: white;
    gap: 10px;

    .left-icon img {
      width: 55px;
    }
    .right-icon {
      width: 30px;
      margin: 0 auto;
    }
    input {
      border-width: 0;
      font-size: 20px;
      &:focus {
        border-width: 0 !important;
        box-shadow: unset;
      }
    }
  }

`;

const ButtonFav: any = styled.div`
  height: 60vh;
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #0042;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
  }
`;
const FavoritesContainer = styled(ModalContainer)`
  background-color: #0042;

  h2 {
    font-weight: bold;
  }

  .no-favorites {
    text-align: center;
    margin-top: 55%;
  }
`;

const OptionPanel: any = styled.div `
  overflow: auto;
  min-width: 34rem;
  max-width: 35rem;
  margin-left: 1rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6c757d;
    border-radius: 20px;
  }
`;

const FavoritesButton: any = styled.button`
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  border: solid 1px #fff;
  color: #fff;
  background-color: rgb(128 128 128 / 80%);
  width: 100%;
  padding: 2px;
`;

const RecordDetailsContainer = styled.div `
  height: 67vh;
  width: fit-content;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 5%;
  display: flex;

  .iframe-container {
    margin-left: 1rem;
    position: relative;
    background: #fff;

    .max-btn {
      cursor: pointer;
      position: absolute;
      font-size: 20px;
      top: 0.7rem;
      right: 3.25rem;
      color: #045fbc;
      border: none;
      background: none;
    }

    .close-iframe {
      position: absolute;
      font-size: 1.5rem;
      top: 0.25rem;
      right: 0.75rem;
      color: #005FBC;
      border: none;
      background: none;
    }

    &__expanded {
      position: fixed;
      top: 0% !important;
      right: 4%;
      height: 90vh !important;
      width: 72vw !important;

      iframe {
        width: 100% !important;
      }
    }
  }
`;

const MyTundraMapContainer = styled.div `
`;

const SpinnerContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
`;

const DocPreviewPanel = styled(OptionPanel) `
  max-width: 23rem;
  min-width: unset;
  height: 60vh;
  overflow: unset;
  position: relative;

  .MuiButton-root {
    min-width: unset;
    margin-left: 1rem;
    padding: 0.5rem;
  }

  .MuiSvgIcon-root {
    cursor: pointer;
  }
`;

const DocPreviewContainer = styled.div `
  padding: 5px 10px;
  background-color: #fff;
  height: calc(56vh - 20px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6c757d;
    border-radius: 20px;
  }
`;

const ContentTD = styled.p`
  && {
    padding: 0.75em;
  }
  em {
    font-style: normal;
    font-weight: 700;
  }
`;

const PopoverContainer = styled.div`
  border: 1px solid #36a2eb;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;

  .spinner-border {
    margin-top: -50%;
  }
`;

const NewsHeader = styled.h4`
  background-color: #339ca8;
  padding: 10px 15px 12px;
  color: white;
  text-align: center;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const EmissionTR = styled.tr`
  background-color: #8080805e;
  color: black;
  font-weight: 600;
`;

const CenterTD = styled.td`
  vertical-align: middle !important;
  text-align: center;
`;

const FixedTD = styled.td`
  width: 8em;
`;

const NavButtonsWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 1rem
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MyTundraContainer: React.FC<IMyTundraContainer & {
  context: IAuthContext;
}> = (props) => {
  const context = props.context;
  const dispatch = useDispatch();
  const [showNavigation, setShowNavigation] = useState(false);
  const [myFavList, setMyFavList] = useState<any[]>([]);
  const [gMap, setGMap] = useState<any>();
  const [gMaps, setGMaps] = useState<any>();
  // eslint-disable-next-line
  const [getStreetLocation, setStreetLocation] = useState<any>();
  const [rectangle, setRectangle] = useState<any>();
  const [relatedDocument, setRelatedDocument] = useState<ISearchRecord | null>(null);
  const [zoomLevel, setZoomLevel] = useState<any>();
  // eslint-disable-next-line
  const [getAutoScroll, setAutoScroll] = useState<any>(false);
  const store: any = useSelector<any>((state): any => state);
  const mapState: IMap = store.map;
  const [showRecordOptions, setShowRecordOptions] = useState<any>(false);
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const [iFrameUrl, setIframeUrl] = useState<string>('');
  const [iFrameExpanded, setIFrameExpanded] = useState<boolean>();
  const [favButtons, setFavButtons] = useState<any[]>([]);
  // eslint-disable-next-line
  const [optionsList, setOptionsList] = useState<any>([{
    buttonId: 1,
    name: 'Permits',
    type: 'modal',
    isDefault: true,
  }, {
    buttonId: 2,
    name: 'Agency Documents',
    type: 'modal',
    isDefault: true,
  }, {
    buttonId: 3,
    name: 'Emission Events',
    type: 'modal',
    isDefault: true,
  }, {
    buttonId: 4,
    name: 'Deviations',
    type: 'modal',
    isDefault: true,
  }]);
  const [selectedRecord, setSelectedRecord] = useState<any>('');
  const [selectedRecordOption, setSelectedRecordOption] = useState<string>('');
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');
  const [favoritesIsLoading, setFavoritesIsLoading] = useState<boolean>(false);
  const [showCustomizeButtons, setShowCustomizeButtons] = useState<boolean>(false);
  const [airEmissions, setAirEmissions] = useState<any>({});
  const [airEmissionsLoading, setAirEmissionsLoading] = useState<any>(false);
  const [airQualityLoading, setAirQualityLoading] = useState<any>(false);
  const [airQualityIndex, setAirQualityIndex] = useState<any>();
  // eslint-disable-next-line
  const [relatedRecordIndex, setRelatedRecordIndex] = useState<number>(0);
  const [complianceData, setComplianceData] = useState<any>({});
  const [mapType, setMapType] = useState<any>();
  const [firstZoom, setFirstZoom] = useState<boolean>(true);
  const jobState: IJob = store.job;
  const aiEarthState: IAIEarth = store.aiearth;
  const [copySnackbar, setcopySnackbar] = useState(true);
  const [IframeSpinner, setIframeSpinner] = useState<boolean>(true);
  const history = useHistory();
  const isBackButton = !!store.globalFlags.previousPage && store.globalFlags.previousPage === 'myenviroaiToFacility';

  const hideIframeSpinner = () => {
    setIframeSpinner(false);
  };
  const handleClose = () => {
    setcopySnackbar(false);
  };
  const AlertMessage = (text: AlertProps)=> {
    if(iFrameUrl.includes('ejscreen.epa.gov/mapper/')||iFrameUrl.includes('onemap.cdc.gov/portal/apps/sites/#/eji-explorer')) {
    navigator.clipboard.writeText(selectedRecord.physicalAddress);
    return <MuiAlert elevation={6} variant="filled" {...text} />;
  }
    return <MuiAlert style={{display:'none'}} />;
  };
  const isValidLatLong = (lat: number, lng: number) => {
    return lat !== 0 && lng !== 0 && lat !== null && lng !== null;
  };

  const getUserFavButtons = async ()=> {
    try {
      const resp = await buttonFavorite({
        actionType: 'getAll',
      });
      if(resp.data && resp.data.length) {
        const existingButtons = resp.data.map((elem: any) => {
          if(elem.buttonId === 5 && !elem.href.includes('/search')) {
            elem.href += '/search';
          }
          return elem;
        });
        const optList = [...optionsList, ...existingButtons];
        setOptionsList(optList);
        setFavButtons(optList);
      }
    } catch (error) {
      toast.error(`Error while fetching favorite button list.`);
    }
  };

  useEffect(() => {
    getUserFavButtons();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotLatLong = (data: any) => {
    const epnArray: any[] = [];
    data.map((ele: any) => {
      if (ele.location.geomz) {
        const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
          iv,
          mode: CryptoJS.mode.CBC,
        }).toString(CryptoJS.enc.Utf8);
        const latLon = decryptedData.split(',');
        ele.location.lon = parseFloat(latLon[0]);
        ele.location.lat = parseFloat(latLon[1]);
      }
      if (ele.location.geom_json) {
        for (const epnKey in ele.location.geom_json) {
          if (ele.location.geom_json.hasOwnProperty(epnKey)) {
            for (const epnInnerKey in ele.location.geom_json[epnKey]) {
              if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
                const element = ele.location.geom_json[epnKey][epnInnerKey];
                const epnRecord: any = {};
                if (element.geom) {
                  const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                    iv,
                    mode: CryptoJS.mode.CBC,
                  }).toString(CryptoJS.enc.Utf8);
                  const latLon = decryptedData.split(',');
                  epnRecord.location = Object.assign({});
                  epnRecord.location.lon = parseFloat(latLon[0]);
                  epnRecord.location.lat = parseFloat(latLon[1]);
                  epnRecord.marker = element.marker;
                  epnRecord.name = element.name;
                  epnRecord.type = element.type;
                  epnRecord.details = element.details;
                  if (ele.entityNumber) {
                    epnRecord.entityNumber = ele.entityNumber;
                    epnArray.push(epnRecord);
                  }
                }
              }
            }
          }
        }
      }
      return ele;
    });
    props.setProgramQueryEPNResult(epnArray);
  };

  const onNavigationItemClickHandle = (searchRecord: any, e: any, pass: boolean = false) => {
    toast.info('Zoom in to show more information.');
    if(mapType === 'jobs') {
      setMapType('');
      resetJobMap();
    }

    setIframeUrl('');

    if(searchRecord.entityName === selectedRecord.entityName && !pass) {
      setSelectedRecord(searchRecord);
      setShowRecordOptions(true);
      return;
    }

    if(!pass && (searchRecord.searchTerm && currentSearchTerm !== searchRecord.searchTerm)) {
      setCurrentSearchTerm(searchRecord.searchTerm);
    }

    setSelectedRecord(searchRecord);
    setShowRecordOptions(true);
    props.setActiveMarkerRecord(searchRecord);
    props.setShowDocPanel(false);
    props.setShowProgramQueryPanel(false);
    if (!isValidLatLong(searchRecord.location.lat, searchRecord.location.lon)) {
      toast.info(
        <span>
          AI Earth is not mapping this site because the original agency source data does not provide
          sufficient information for an accurate geocode.
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        }
      );
      return;
    }
    gMap.panTo(getCenterWithOffset(searchRecord.location.lat, searchRecord.location.lon, -0.1, -0.15));
    gMap.setMapTypeId('satellite');
    setZoomLevel(16);
  };

  const getCenterWithOffset = (lat: number, lng: number, offsetX: number, offsetY: number) => {
    if (!gMap || !gMaps) {
      return;
    }
    const currentMapProjection = gMap.getProjection();
    const point = currentMapProjection.fromLatLngToPoint(new gMaps.LatLng(lat, lng));
    // point.x = point.x + offsetX;
    // point.y = point.y + offsetY;
    const newCenterLatLng = currentMapProjection.fromPointToLatLng(point);
    return { lat: newCenterLatLng.lat(), lng: newCenterLatLng.lng() };
  };

  const setMapFindings = (entPoint: any) => {
    let jsonArray: any = '';
    if (Object.keys(entPoint).length > 0) {
      for (const val of entPoint) {
        if (jsonArray) {
          jsonArray = jsonArray.concat([val.properties]);
        } else {
          jsonArray = [val.properties];
        }
      }
    }
    return jsonArray;
  };

  const markerJson = (fetchData: any) => {
    const epnArray: any[] = [];
    for (const val of fetchData) {
      const epnRecord: any = {};
      epnRecord.location = Object.assign({});
      epnRecord.location.lon = val.geometry.coordinates[0];
      epnRecord.location.lat = val.geometry.coordinates[1];
      epnRecord.marker = 'STANDARD';
      epnRecord.name = val.properties.entity_name;
      epnRecord.type = 'ENTITY';
      epnRecord.details = val.properties;
      if (val.properties.entity_number) {
        epnRecord.entityNumber = val.properties.entity_number;
        epnArray.push(epnRecord);
      }
    }
    return epnArray;
  };

  const zoomFirstFavorite = () => {
    if(myFavList && myFavList.length > 0) {
      const firstRecord = myFavList[0];

      setSelectedRecord(firstRecord);
      props.setActiveMarkerRecord(firstRecord);
      props.setShowDocPanel(false);
      props.setShowProgramQueryPanel(false);
      if (!firstRecord.location.lat || !firstRecord.location.lon || !isValidLatLong(firstRecord.location.lat, firstRecord.location.lon)) {
        toast.info(
          <span>
            AI Earth is not mapping this site because the original agency source data does not provide
            sufficient information for an accurate geocode.
          </span>,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
          }
        );
        return;
      }
      gMap.panTo(getCenterWithOffset(firstRecord.location.lat, firstRecord.location.lon, -0.1, -0.15));
      gMap.setMapTypeId('satellite');
      setZoomLevel(16);
    }
  };

  const getFilterInfo = (fetchData: any) => {
    let jsonArray: any = '';
    for (const val of fetchData) {
      if (val.properties.vec_all === true) {
        if (jsonArray) {
          jsonArray = jsonArray.concat([val]);
        } else {
          jsonArray = [val];
        }
      }
    }
    return jsonArray;
  };

  const setSubjectPropertyEsaData = (subjectPropertyData: any, type: string = 'astm') => {
    if (subjectPropertyData) {
      if (mapState.subjectPropertyShowLayer) {
        mapState.subjectPropertyShowLayer.forEach((feature: any) => {
          mapState.subjectPropertyShowLayer.remove(feature);
        });
      }
      if (mapState.adjoiningPropertyLayer) {
        mapState.adjoiningPropertyLayer.forEach((feature: any) => {
          mapState.adjoiningPropertyLayer.remove(feature);
        });
      }
      if (mapState.waterWellsPointer) {
        mapState.waterWellsPointer.forEach((feature: any) => {
          mapState.waterWellsPointer.remove(feature);
        });
      }
      if (mapState.monitorWellsPointer) {
        mapState.monitorWellsPointer.forEach((feature: any) => {
          mapState.monitorWellsPointer.remove(feature);
        });
      }
      if (mapState.soilsPropertyLayer) {
        mapState.soilsPropertyLayer.forEach((feature: any) => {
          mapState.soilsPropertyLayer.remove(feature);
        });
      }
      const getType = (subjectPropJson: any) => {
        if (subjectPropJson.type === 'Polygon') {
          return polygon(subjectPropJson.coordinates);
        } else if (subjectPropJson.type === 'MultiPolygon') {
          return multiPolygon(subjectPropJson.coordinates);
        } else if (subjectPropJson.type === 'MultiLineString') {
          return multiLineString(subjectPropJson.coordinates);
        }
      };
      if (
        subjectPropertyData.subject_property ||
        subjectPropertyData.one_mile_buffer ||
        subjectPropertyData.half_mile_buffer ||
        subjectPropertyData.quarter_mile_buffer ||
        subjectPropertyData.regulated_entities_boundaries ||
        subjectPropertyData.regulated_entities_point
      ) {
        let polygonObj: any = {};
        const oneMileBuffer = subjectPropertyData.one_mile_buffer;
        if (
          oneMileBuffer &&
          typeof oneMileBuffer.features !== 'undefined' &&
          Object.keys(oneMileBuffer.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.one_mile_buffer);
        }
        if (
          subjectPropertyData.half_mile_buffer &&
          typeof subjectPropertyData.half_mile_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.half_mile_buffer.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.half_mile_buffer);
        }
        if (
          subjectPropertyData.quarter_mile_buffer &&
          typeof subjectPropertyData.quarter_mile_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.quarter_mile_buffer.features).length > 0 &&
          (type === 'astm' || type === 'topos' || type === 'oilgas' || type === 'water' || type === 'monitor')
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.quarter_mile_buffer);
        }
        if (
          subjectPropertyData.regulated_entities_boundaries &&
          typeof subjectPropertyData.regulated_entities_boundaries.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_boundaries.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_boundaries.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.regulated_entities_boundaries);
        }
        if (
          subjectPropertyData.regulated_entities_boundaries &&
          typeof subjectPropertyData.regulated_entities_boundaries.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_boundaries.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_boundaries.features).length > 0 &&
          type === 'vec'
        ) {
          const boundaryJson = getFilterInfo(subjectPropertyData.regulated_entities_boundaries.features);
          if (Object.keys(boundaryJson).length > 0) {
            const regulatedEntityBoundary = {
              type: 'FeatureCollection',
              features: boundaryJson,
            };
            mapState.subjectPropertyShowLayer.addGeoJson(regulatedEntityBoundary);
          }
        }
        if (
          subjectPropertyData.regulated_entities_point &&
          typeof subjectPropertyData.regulated_entities_point.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_point.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_point.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.regulated_entities_point);
          markerJson(subjectPropertyData.regulated_entities_point.features);
          jobState.mapFindings = setMapFindings(subjectPropertyData.regulated_entities_point.features);
          jobState.toolTipPoint = markerJson(subjectPropertyData.regulated_entities_point.features);
          dispatch(updateJob(jobState));
        }
        if (
          subjectPropertyData.regulated_entities_point &&
          typeof subjectPropertyData.regulated_entities_point.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_point.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_point.features).length > 0 &&
          type === 'vec'
        ) {
          const filterData = getFilterInfo(subjectPropertyData.regulated_entities_point.features);
          jobState.mapFindings = setMapFindings(filterData);
          jobState.toolTipPoint = markerJson(filterData);
          dispatch(updateJob(jobState));
          if (Object.keys(filterData).length > 0) {
            const regulatedEntityPoint = {
              type: 'FeatureCollection',
              features: filterData,
            };
            mapState.subjectPropertyShowLayer.addGeoJson(regulatedEntityPoint);
          }
        }
        if (
          subjectPropertyData.vec_entities_boundaries &&
          typeof subjectPropertyData.vec_entities_boundaries.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_entities_boundaries.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_entities_boundaries);
        }
        if (
          subjectPropertyData.vec_entities_point &&
          typeof subjectPropertyData.vec_entities_point.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_entities_point.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_entities_point);
        }
        if (
          subjectPropertyData.thirty_feet_buffer &&
          typeof subjectPropertyData.thirty_feet_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.thirty_feet_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.thirty_feet_buffer);
        }
        if (
          subjectPropertyData.vec_crossgradient_buffer &&
          typeof subjectPropertyData.vec_crossgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_crossgradient_buffer.features).length > 0 &&
          type === 'aerial'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_crossgradient_buffer);
        }
        if (
          subjectPropertyData.vec_downgradient_buffer &&
          typeof subjectPropertyData.vec_downgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_downgradient_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_downgradient_buffer);
        }
        if (
          subjectPropertyData.vec_upgradient_buffer &&
          typeof subjectPropertyData.vec_upgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_upgradient_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_upgradient_buffer);
        }
        if (
          subjectPropertyData.vec_angel &&
          typeof subjectPropertyData.vec_angel.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_angel.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_angel);
        }
        if (
          subjectPropertyData.contours &&
          typeof subjectPropertyData.contours.features !== 'undefined' &&
          subjectPropertyData.contours.features !== null &&
          Object.keys(subjectPropertyData.contours.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.contours);
        }
        if (
          subjectPropertyData.oil_gas_pipe_lines &&
          subjectPropertyData.oil_gas_pipe_lines.features &&
          type === 'oilgas'
        ) {
          mapState.pipeLinesJobMaps.addGeoJson(subjectPropertyData.oil_gas_pipe_lines);
        }
        if (subjectPropertyData.oil_gas_points && subjectPropertyData.oil_gas_points.features && type === 'oilgas') {
          mapState.oilGasJobMaps.addGeoJson(subjectPropertyData.oil_gas_points);
        }
        if (
          subjectPropertyData.adjoining_properties_boundaries &&
          subjectPropertyData.adjoining_properties_boundaries.features &&
          Object.keys(subjectPropertyData.adjoining_properties_boundaries.features).length > 1 &&
          type === 'adjoin'
        ) {
          mapState.adjoiningPropertiesBoundaries.addGeoJson(subjectPropertyData.adjoining_properties_boundaries);
          dispatch(
            updateJob({
              ...jobState,
              ...{
                adjoiningPropertiesBoundaries: setMapFindings(
                  subjectPropertyData.adjoining_properties_boundaries.features
                ),
              },
            })
          );
        }
        if (
          subjectPropertyData.water_wells &&
          typeof subjectPropertyData.water_wells.features !== 'undefined' &&
          subjectPropertyData.water_wells.features !== null &&
          Object.keys(subjectPropertyData.water_wells.features).length > 0 &&
          type === 'water'
        ) {
          mapState.waterWellsPointer.addGeoJson(subjectPropertyData.water_wells);
        }
        if (
          subjectPropertyData.soils &&
          typeof subjectPropertyData.soils.features !== 'undefined' &&
          subjectPropertyData.soils.features !== null &&
          Object.keys(subjectPropertyData.soils.features).length > 0 &&
          type === 'soils'
        ) {
          mapState.soilsPropertyLayer.addGeoJson(subjectPropertyData.soils);
        }

        if (
          subjectPropertyData.environmental_related_wells &&
          typeof subjectPropertyData.environmental_related_wells.features !== 'undefined' &&
          subjectPropertyData.environmental_related_wells.features !== null &&
          Object.keys(subjectPropertyData.environmental_related_wells.features).length > 0 &&
          type === 'monitor'
        ) {
          mapState.monitorWellsPointer.addGeoJson(subjectPropertyData.environmental_related_wells);
        }
        const subProperty = subjectPropertyData.subject_property;
        if (
          subProperty &&
          (type === 'astm' ||
            type === 'vec' ||
            type === 'topos' ||
            type === 'aerial' ||
            type === 'oilgas' ||
            type === 'adjoin' ||
            type === 'water' ||
            type === 'soils' ||
            type === 'monitor')
        ) {
          polygonObj = getType(subProperty.features[0].geometry);
          mapState.subjectPropertyShowLayer.addGeoJson(subProperty);
        }
        const centroidObj = centroid(polygonObj).geometry;
        if (centroidObj) {
          const latlng = new gMaps.LatLng(centroidObj.coordinates[1], centroidObj.coordinates[0]);
          gMap.panTo(latlng);
          if (
            type === 'vec' ||
            type === 'oilgas' ||
            type === 'adjoin' ||
            type === 'water' ||
            type === 'monitor' ||
            type === 'soils'
          ) {
            gMap.setZoom(16);
          } else {
            gMap.setZoom(15);
          }
        }
      } else {
        gMap.setZoom(5);
      }
    }
  };

  const resetJobMap = () => {
    if (mapState.mapOverLay) {
      mapState.mapOverLay.setMap(null);
      mapState.mapOverLay = null;
    }
    mapState.layers = [];
    mapState.execute = false;
    mapState.jobLayers = [];
    if (typeof gMap !== 'undefined') {
      gMap.setZoom(16);
      gMap.setMapTypeId('roadmap');
    }

    if (mapState.subjectPropertyShowLayer) {
      mapState.subjectPropertyShowLayer.forEach((feature: any) => {
        mapState.subjectPropertyShowLayer.remove(feature);
      });
    }
    if (mapState.adjoiningPropertyLayer) {
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
    }
    if (mapState.waterWellsPointer) {
      mapState.waterWellsPointer.forEach((feature: any) => {
        mapState.waterWellsPointer.remove(feature);
      });
    }
    if (mapState.monitorWellsPointer) {
      mapState.monitorWellsPointer.forEach((feature: any) => {
        mapState.monitorWellsPointer.remove(feature);
      });
    }
    if (mapState.soilsPropertyLayer) {
      mapState.soilsPropertyLayer.forEach((feature: any) => {
        mapState.soilsPropertyLayer.remove(feature);
      });
    }
    dispatch(resetJob());
    setZoomLevel(16);
    dispatch(resetAir());
    // when page redirect from myenviroai
    dispatch(resetAIEarth());
    props.setShowProgramQueryDocPanel(false);
  };

  const onMarkerClick = (searchRecord: ISearchRecord | null) => {
    if (R.isNil(searchRecord)) {
      // return; // resetMapToInitial(); //test
    }
    props.setActiveMarkerRecord(searchRecord);
    setZoomLevel(18);
  };

  const doStorageDownload = R.contains(R.__, ['tceq_novell']);

  const getStorageRef = R.cond<string, firebase.storage.Storage | null>([
    [R.equals('tceq_novell'), R.always(ceresApp.storage(`gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
    [R.T, R.always(null)],
  ]);

  const getIframeQueryParams = () => {
    if(selectedRecordOption === 'Permits' || iFrameUrl.includes('TCEQDeadlineReminder') || iFrameUrl.includes('tceq.liputra.com/texas-caer.php') || iFrameUrl.includes('NRC')
    || iFrameUrl.includes('EnforcementActions')) {
      return `?v_rn_number=${selectedRecord.entityNumber}`;
    } else if(iFrameUrl.includes('/search?type=SOCIAL')) {
      return `&q=${selectedRecord.entityName}&directsearch=yes&hideSearchBar=true`;
    }
    return `?rn=${selectedRecord.entityNumber}`;
  };

  const iframeEligibility = () => {
    if(iFrameUrl.includes('?skipIFrame=true')) {
      let url = iFrameUrl.replace('?skipIFrame=true', '');
      if(url.includes('www2.tceq.texas.gov/oce/ch/index.cfm')) {
        if(!complianceData.error) {
          url += `?fuseaction=main.viewdetails&rid=${complianceData.re_id}`;
        }
      }
      window.open(url, '_blank');
      return false;
    }
    return true;
  };

  const noIframeSpinner = () => {
    if(iFrameUrl.includes('/trackbot') || iFrameUrl.includes('/benchmarking') || iFrameUrl.includes('/sentiment') || iFrameUrl.includes('/search') ||
    iFrameUrl.includes('/generator') || iFrameUrl.includes('/corrective-tracker')) {
      setIframeSpinner(false);
      return false;
    }
    return true;
  };
  const getAirEmissions = async (airQualityData: any) => {
    const requestBody: any = {};
    requestBody.RN = airQualityData.entityNumber;
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      setAirEmissionsLoading(true);
      const airEmissionResponse = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/airEmissionPopup',
        requestBody,
        {
          headers,
        }
      );
      setAirEmissionsLoading(false);
      const airEmissionData = airEmissionResponse.data;
      setAirEmissions(airEmissionData[0].aai_emission_popup);
      if(!Object.keys(airEmissionData[0].aai_emission_popup).length) {
        toast.error(`No Emissions data for ${selectedRecord.entityNumber}`);
      }
    } catch (e) {
      setAirEmissionsLoading(false);
      CeresLogger.error(e);
    }
  };

  const getAQIData = async (airQualityData: any) => {
    const requestBody: any = {};
    requestBody.latitude = airQualityData.latitude;
    requestBody.longitude = airQualityData.longitude;
    requestBody.distance = 25;
    requestBody.date = '2020-09-24';
    requestBody.type = 'latLong';
    const headers = {
      Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
    };
    try {
      setAirQualityLoading(true);
      const aqiResponse = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/getAirQualityIndex',
        requestBody,
        {
          headers,
        }
      );
      setAirQualityLoading(false);
      const aqiResult = aqiResponse.data;
      setAirQualityIndex(aqiResult);

      if(!aqiResult.length) {
        toast.error(`No Air Quality data for ${selectedRecord.entityNumber}`);
      }
    } catch (e) {
      setAirQualityLoading(false);
      CeresLogger.error(e);
    }
  };

  const getAirEmissionsMemo = React.useCallback(() => getAirEmissions({
    entityNumber: selectedRecord.entityNumber,
    latitude: selectedRecord.location.lat,
    longitude: selectedRecord.location.lon,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [selectedRecord]);

  const getAQIDataMemo = React.useCallback(() => getAQIData({
    entityNumber: selectedRecord.entityNumber,
    latitude: selectedRecord.location.lat,
    longitude: selectedRecord.location.lon,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [selectedRecord]);

  useEffect(() => {
    if(!selectedRecord) {
      return;
    }
    if(selectedRecordOption === 'Emissions Profile') {
      getAirEmissionsMemo();
    } else if(selectedRecordOption === 'Current Air Quality Conditions') {
      getAQIDataMemo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecord, selectedRecordOption]);

  const getRecordOptionModal = () => {
    switch(selectedRecordOption) {
      case 'Emissions Profile':
        return (
           <OptionPanel>
            <ButtonContainer>
              <Button
                style={{ padding: '5px' }}
                onClick={() => {
                  setSelectedRecordOption('');
                }}
                size="sm"
              >
                <ChevronLeft />
              </Button>
            </ButtonContainer>
            <PopoverContainer>
              <NewsHeader>Emissions Profile</NewsHeader>
              {airEmissionsLoading ? <SpinnerContainer>
                  <Spinner
                    as="span"
                    variant="primary"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                </SpinnerContainer> :
                <Table bordered>
                  <tbody>
                    {Object.entries(airEmissions).length ? (
                      <>
                        <EmissionTR>
                          <CenterTD>Current Emissions Level</CenterTD>
                          <td>
                            {airEmissions.current_hourly_emissions_criteria_pounds}{' '}
                            {airEmissions.current_hourly_emissions_criteria_pounds !== '-'
                              ? 'lbs/hr(criteria pollutants)'
                              : ''}
                          </td>
                          <FixedTD>
                            {airEmissions.current_emissions_rank !== '-' ? 'Rank #' : ''}
                            {airEmissions.current_emissions_rank}
                          </FixedTD>
                        </EmissionTR>
                        <EmissionTR>
                          <CenterTD rowSpan={2}>Annual Emissions</CenterTD>
                          <td>
                            {airEmissions.annual_emissions_criteria_rank !== '-' && (
                              <Link to={'/benchmarking?rn=' + selectedRecord.entityNumber + '&event=2'}>
                                {airEmissions.annual_emissions_criteria_rank}
                                {' tons (criteria pollutants)'}
                              </Link>
                            )}
                            {airEmissions.annual_emissions_criteria_rank === '-' && '-'}
                          </td>
                          <FixedTD>
                            {airEmissions.current_emissions_rank !== '-' ? 'Rank #' : ''}
                            {airEmissions.current_emissions_rank}
                          </FixedTD>
                        </EmissionTR>
                        <EmissionTR>
                          <td>
                            {airEmissions.annual_emissions_co2e_tons !== '-' && (
                              <Link to={'/benchmarking?rn=' + selectedRecord.entityNumber + '&event=2'}>
                                {airEmissions.annual_emissions_co2e_tons}
                                {' tons (coe2)'}
                              </Link>
                            )}
                            {airEmissions.annual_emissions_co2e_tons === '-' && '-'}
                          </td>
                          <FixedTD>
                            {airEmissions.annual_emissions_co2e_rank !== '-' ? 'Rank #' : ''}
                            {airEmissions.annual_emissions_co2e_rank}
                          </FixedTD>
                        </EmissionTR>
                        <EmissionTR>
                          <CenterTD>Unauthorized Emissions since 2004</CenterTD>
                          <td>
                            {airEmissions.unauthorized_emissions_tons !== '-' && (
                              <Link to={'/benchmarking?rn=' + selectedRecord.entityNumber + '&event=1'}>
                                {airEmissions.unauthorized_emissions_tons}
                                {' tons'}
                              </Link>
                            )}
                            {airEmissions.unauthorized_emissions_tons === '-' && '-'}
                          </td>
                          <FixedTD>
                            {airEmissions.unauthorized_emissions_rank !== '-' ? 'Rank #' : ''}
                            {airEmissions.unauthorized_emissions_rank}
                          </FixedTD>
                        </EmissionTR>
                        <EmissionTR>
                          <CenterTD>Uncontrolled / Undercontrolled Emissions (NOx and VOC)</CenterTD>
                          <td>
                            {airEmissions.uncontrolled_emissions_tons !== '-' && (
                              <Link to={'/generator?rn=' + selectedRecord.entityNumber}>
                                {airEmissions.uncontrolled_emissions_tons}
                                {' tons'}
                              </Link>
                            )}
                            {airEmissions.uncontrolled_emissions_tons === '-' && '-'}
                          </td>
                          <FixedTD>
                            {airEmissions.uncontrolled_emissions_rank !== '-' ? 'Rank #' : ''}
                            {airEmissions.uncontrolled_emissions_rank}
                          </FixedTD>
                        </EmissionTR>
                      </>
                    ) : <p>No Data available</p>}
                  </tbody>
                </Table>
                }
            </PopoverContainer>
          </OptionPanel>
      );
      case 'Current Air Quality Conditions':
        return (
          <OptionPanel>
            <ButtonContainer>
              <Button
                style={{ padding: '5px' }}
                onClick={() => {
                  setSelectedRecordOption('');
                }}
                size="sm"
              >
                <ChevronLeft />
              </Button>
            </ButtonContainer>
            <PopoverContainer>
              <NewsHeader>
                <a
                  href={
                    airQualityIndex &&
                    'https://www.airnow.gov/?city=' +
                      airQualityIndex[0].ReportingArea +
                      '&state=' +
                      airQualityIndex[0].StateCode +
                      '&country=USA'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: 'pointer' }}
                >
                  Today's Air Quality for this Facility
                </a>
              </NewsHeader>
              {airQualityLoading ? <SpinnerContainer>
                  <Spinner
                    as="span"
                    variant="primary"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                </SpinnerContainer> :
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>AQI</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {airQualityIndex && (
                    <>
                      {airQualityIndex[0] && (
                        <tr>
                          <td>{airQualityIndex[0].ParameterName}</td>
                          <td>{airQualityIndex[0].AQI}</td>
                          <td>{airQualityIndex[0].Category.Name}</td>
                        </tr>
                      )}
                      {airQualityIndex[1] && (
                        <tr>
                          <td>{airQualityIndex[1].ParameterName}</td>
                          <td>{airQualityIndex[1].AQI}</td>
                          <td>{airQualityIndex[1].Category.Name}</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
               }
            </PopoverContainer>
          </OptionPanel>
        );
      case 'News & Internet Info About the Site':
        return (
          <OptionPanel>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                setSelectedRecordOption('');
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <PopoverContainer>
            <MyTundraTabResultsModal queryText={selectedRecord.entityName}/>
          </PopoverContainer>
        </OptionPanel>
        );
        default:
        return (
          <OptionPanel>
            <ButtonContainer>
              <Button
                style={{ padding: '5px' }}
                onClick={() => {
                  setSelectedRecordOption('');
                }}
                size="sm"
              >
                <ChevronLeft />
              </Button>
            </ButtonContainer>
            <MyTundraRecordMarker
              setRelatedDocument={setRelatedDocument}
              currentQueryText={selectedRecord.searchTerm}
              onInfoWindowClose={() => onMarkerClick(null)}
              key={selectedRecord.contentId}
              onMarkerClick={() => onMarkerClick(selectedRecord)}
              lat={selectedRecord.location.lat}
              lng={selectedRecord.location.lon}
              isActive={true}
              record={selectedRecord}
              isMap={false}
              showDocPanel={props.showDocPanel}
              setShowDocPanel={props.setShowDocPanel}
              selectedRecordOption={selectedRecordOption}
              setIframeUrl={setIframeUrl}
              relatedRecordIndex={relatedRecordIndex}
            />
          </OptionPanel>
          );
    }
  };

  const getFavoritesMapping = () => {
    if(myFavList.length) {
      return (myFavList.map((favorite, index) => {
        return (
          <FavoritesButton key={index} onClick={(e: any) => onNavigationItemClickHandle(favorite, e)}>
            {favorite.displayName || favorite.entityName || favorite.entityNumber}
          </FavoritesButton>);
      }));
    }

    return <div className="no-favorites">No Favorites Available</div>;
  };

  useEffect(() => {
  // favoritesFn({ type: 'getAll' });
    setMyFavList(aiEarthState.favorites);
    if(!aiEarthState.favoritesLoaded && aiEarthState.favorites.length <= 0) {
      setFavoritesIsLoading(true);
    } else {
      setFavoritesIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiEarthState.favorites]);

  useEffect(() => {
    if(myFavList.length > 0 && gMap && gMaps && firstZoom) {
      plotLatLong(myFavList);
      zoomFirstFavorite();
      setFirstZoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myFavList, gMap, gMaps]);

  useEffect(() => {
    if(selectedRecordOption === 'Agency Documents') {
      setRelatedRecordIndex(0);
    }
  }, [selectedRecordOption]);

  useEffect(() => {
    if(mapType !== 'jobs') {
      return;
    }

    const getEsaData = () => {
      try {
        jobState.activeJob.execute = false;
        dispatch(
          updateJob({
            ...jobState,
            ...{
              esaData: [],
              isLoadEsaData: false,
            },
          })
        );
        axios
          .get(`${process.env.REACT_APP_CERES_URL}/getEsaDataPhase1?v_rn_number=${selectedRecord ? selectedRecord.entityNumber : myFavList[0].entityNumber}`)
          .then((response) => {
            if (response.data) {
              const esajson = response.data[0];
              if (Object.keys(esajson.esadata).length > 0) {
                let jsonArray: any = '';
                const entPoint = esajson.esadata.regulated_entities_point.features;
                if (Object.keys(entPoint).length > 0) {
                  for (const val of entPoint) {
                    if (jsonArray) {
                      jsonArray = jsonArray.concat([val.properties]);
                    } else {
                      jsonArray = [val.properties];
                    }
                  }
                }
                dispatch(
                  updateJob({
                    ...jobState,
                    ...{
                      esaData: esajson.esadata,
                      mapFindings: jsonArray,
                      isLoadEsaData: true,
                      jobInit: true,
                    },
                  })
                );
              }
            }
          })
          .catch((err) => {
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  isLoadEsaData: true,
                  esaData: {},
                },
              })
            );
            CeresLogger.error(err);
            return toast.error(`Data is processing please try later.`);
          });
      } catch (e) {
        CeresLogger.error(e);
      }
    };

    getEsaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecord, mapType]);

  useEffect(() => {
    const getComplianceData = async () => {
      const response = await axios.get(`https://us-central1-ceres-platform-test.cloudfunctions.net/TCEQRNREID?v_rn=${selectedRecord.entityNumber}`);
      setComplianceData(response.data);
    };

    if(selectedRecord.entityNumber) {
      getTundraButtonVisibilityData();
    }
    getComplianceData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecord]);

  const getTundraButtonVisibilityData = async () => {
    const tundraButtonData = await axios.get(`https://us-central1-ceres-platform-test.cloudfunctions.net/myTundraButtons?v_entity_number=${selectedRecord.entityNumber}`);
    const tundraButtonArray = tundraButtonData.data;
    if(tundraButtonArray.length > 0 && favButtons.length > 0) {
      const buttonArray = favButtons.filter((el: any) => {
        const index = tundraButtonArray.findIndex((x: any) => x.button_name === el.name && x.button_status);
        return index >= 0;
      });
      setOptionsList(buttonArray);
    }
  };

  const useClickOutside = (handler: any) => {
    // tslint:disable-next-line:no-shadowed-variable
    const main: any = useRef(null);
    useEffect(() => {
      const maybeHandler = (event: any) => {
        if (main && main.current && !main.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener('mousedown', maybeHandler);

      return () => {
        document.removeEventListener('mousedown', maybeHandler);
      };
    });

    return main;
  };

  const main = useClickOutside(() => {
    setIframeUrl('');

  });

  const handleBackButton = (e: any) => {
    // flag use to clear chat data when user visit enviro page not from here
    localStorage.setItem('isBackToEnviroPage', 'true');
    history.replace('myenviroai');
  };

  return	(
    <MyTundraWrapper>
      {!showRecordOptions && (<FavoritesContainer>
          <h2>My Sites</h2>
          <ButtonFav>
          {
            favoritesIsLoading
            ? <SpinnerContainer>
                <Spinner
                  as="span"
                  variant="light"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </SpinnerContainer>
            : getFavoritesMapping()
          }
          </ButtonFav>
        </FavoritesContainer>
      )}
      {showRecordOptions && (
        <RecordDetailsContainer>
          <MyTundraFavoriteButtons
              selectedRecord={selectedRecord}
              setShowRecordOptions={setShowRecordOptions}
              setSelectedRecordOption={setSelectedRecordOption}
              setShowCustomizeButtons={setShowCustomizeButtons}
              setIframeUrl={setIframeUrl}
              optionsList={optionsList}
              setcopySnackbar={setcopySnackbar}
              setIframeSpinner={setIframeSpinner}
              setOptionsList={setOptionsList}
              mapType={mapType}
              setMapType={setMapType}
              resetMap={(e: any) => {resetJobMap(); onNavigationItemClickHandle(selectedRecord, e, true);}}
          />
          {
            selectedRecordOption !== '' && getRecordOptionModal()
          }
          { !!relatedDocument &&
            <DocPreviewPanel>
              <ButtonContainer>
                <Button
                  style={{ padding: '5px' }}
                  onClick={() => {
                    setRelatedDocument(null);
                  }}
                  size="sm"
                >
                  <ChevronLeft />
              </Button>
              </ButtonContainer>
              <DocPreviewContainer>
                <p>
                  Type: <b>{relatedDocument.title}</b>
                </p>
                <p>
                  Release Date: <b>{relatedDocument.releaseDate}</b>
                </p>
                <p>
                  Category: <b>{relatedDocument.seriesName}</b>
                </p>
                <p>
                  <Button
                    onClick={() => {
                      if (!context.isAuthenticated) {
                        return toast.warn(
                          <span>
                            Please <Alert.Link href="/login">login</Alert.Link> to download files.
                          </span>,
                          {
                            position: toast.POSITION.BOTTOM_CENTER,
                          }
                        );
                      }
                      if (context.isTier(TIERS.FREE) && !context.isLubrizol()) {
                        return toast.warn(<span>Please upgrade to BASIC or PLATINUM to download files.</span>, {
                          position: toast.POSITION.BOTTOM_CENTER,
                        });
                      }
                      if (doStorageDownload(relatedDocument.entitySource)) {
                        const storageRef = getStorageRef(relatedDocument.entitySource);
                        if (R.isNil(storageRef)) {
                          return toast.error(`Oops, could not find the file.`);
                        }
                        setDownloadTriggers(R.append(relatedDocument.contentId, downloadTriggers));
                        return storageRef
                          .ref(relatedDocument.fileName)
                          .getDownloadURL()
                          .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                          .catch((err) => toast.error(err.message))
                          .finally(() => setDownloadTriggers(R.without([relatedDocument.contentId], downloadTriggers)));
                      }
                      if (!relatedDocument.fileUrl) {
                        return toast.warn(
                          <span>
                            No associated file was found for the content id <b>{relatedDocument.contentId}</b>
                          </span>
                        );
                      }
                      window.open(relatedDocument.fileUrl, '_blank');
                    }}
                  >
                    Download
                  </Button>
                </p>
                <ContentTD
                  dangerouslySetInnerHTML={{
                    __html:
                      relatedDocument.hitContent.length > 0
                        ? relatedDocument.hitContent
                            // Remove non-ascii chars.
                            .map((str: string) =>
                              /*eslint no-control-regex: 0*/
                              str.replace(/[^\x00-\x7F]/g, '')
                            )
                            .join('<br />')
                        : '<h3>No Preview Available</h3>',
                  }}
                />
              </DocPreviewContainer>
              <NavButtonsWrapper>
                <MuiButton onClick={() => {
                      if(relatedRecordIndex !== 0) {
                        setRelatedRecordIndex(prev => --prev);
                      }
                    }}>
                  <ArrowBackIosIcon color={relatedRecordIndex ? 'primary' : 'disabled'} />
                </MuiButton>
                <MuiButton onClick={() => setRelatedRecordIndex(prev => ++prev)}>
                  <ArrowForwardIosIcon color="primary" />
                </MuiButton>
              </NavButtonsWrapper>
            </DocPreviewPanel>
          }
          {
            showCustomizeButtons && <ButtonOptions
              optionsList={optionsList}
              setOptionsList={setOptionsList}
              setShowCustomizeButtons={setShowCustomizeButtons}
            />
          }
          {
            iFrameUrl.length > 0 && iframeEligibility() && (<div  ref={main} className={`iframe-container ${iFrameExpanded ? 'iframe-container__expanded' : ''}`}>
              <FiMaximize2 className="max-btn" style={{visibility: !iFrameExpanded ? 'visible' : 'hidden'}} onClick={() => setIFrameExpanded(true)} />
              <FiMinimize2 className="max-btn" style={{visibility: iFrameExpanded ? 'visible' : 'hidden'}} onClick={() => setIFrameExpanded(false)} />
              <button className="close-iframe" onClick={() => setIframeUrl('')}>X</button>
              <Snackbar
               open ={copySnackbar}
              //  style={{top:'60px'}}
               anchorOrigin={{
                vertical:'top',
                horizontal:'right'
               }}
               autoHideDuration={10000}
               onClose={handleClose}>
                {
                <AlertMessage onClose={handleClose} severity="info">Site Address is copied to clipboard, you can paste in search bar</AlertMessage>
                }
                </Snackbar>
                {IframeSpinner && noIframeSpinner() ? (
                  <SpinnerContainer>
                <Spinner
                as="span"
                variant="primary"
                animation="border"
                role="status"
                aria-hidden="true"
                   />
              </SpinnerContainer>
                 ) : null}
              <iframe onLoad={hideIframeSpinner} src={iFrameUrl + getIframeQueryParams()} title="myTundraIframe" style={{height:'100%',
              width: `${selectedRecordOption === 'Permits' ? '39vw' : '55vw'}`,border:'none'}} name="myIframe" height="300px" width="100%" ></iframe>
            </div>)
          }
        {jobState.mapFindingTab && mapType === 'jobs' && (
          <MyTundraMapsFinding
            doStorageDownload={doStorageDownload}
            getStorageRef={getStorageRef}
            context={context}
            setDownloadTriggers={setDownloadTriggers}
            downloadTriggers={downloadTriggers}
            gMap={gMap}
            gMaps={gMaps}
            selectedEntityNumber={selectedRecord.entityNumber}
          />
        )}
        </RecordDetailsContainer>
      )}
      {/* { mapType === 'jobs' && getJobsMap()} */}
      {(!props.showProgramQueryPanel || jobState.jobInit || aiEarthState.mainDrawer !== '') && (
        <Col
          lg={
            (jobState.jobInit ||
            jobState.jobFeatureTab ||
            jobState.mapFindingTab ||
            aiEarthState.mainDrawer !== ''
              ? props.showDocPanel
                ? 4
                : 12
              : 12) + (aiEarthState.isCollapse ? 2 : 0)
          }
          sm={12}
        >
          <MyTundraMapContainer>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <MyTundraMap
                records={myFavList}
                programQueryEPNResult={props.programQueryEPNResult}
                showNavigation={showNavigation}
                setShowNavigation={setShowNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                boundingBox={props.boundingBox}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                activeMarkerRecord={props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                rectangle={rectangle}
                setRectangle={setRectangle}
                onRectDrag={props.onRectDrag}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setStreetLocation={setStreetLocation}
                setAutoScroll={setAutoScroll}
                setSubjectPropertyEsaData={setSubjectPropertyEsaData}
                resetJobMap={resetJobMap}
                context={context}
                mapType={mapType}
              />
            )}
          </MyTundraMapContainer>
        </Col>
      )}
      {props.showProgramQueryPanel && (
        <Col lg={props.showProgramQueryPanel ? (props.showProgramQueryDocPanel ? 4 : 7) : 0} sm={12}>
          <MyTundraMapContainer>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <MyTundraMap
                records={[]}
                programQueryEPNResult={props.programQueryEPNResult}
                showNavigation={showNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                boundingBox={props.boundingBox}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                setShowNavigation={setShowNavigation}
                activeMarkerRecord={props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                rectangle={rectangle}
                setRectangle={setRectangle}
                onRectDrag={props.onRectDrag}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setStreetLocation={setStreetLocation}
                setAutoScroll={setAutoScroll}
                resetJobMap={resetJobMap}
                context={context}
                mapType={mapType}
              />
            )}
          </MyTundraMapContainer>
        </Col>
      )}
      <SearchContainer>
        {/* <InputGroup className="search-box">
          <div className="input-group-append left-icon">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="tooltip">Enviro AI</Tooltip>}
            >
              <Button
                variant="link"
                className="px-0"
              >
                <img src={EnviroAiTriangleIcon} alt="Enviro Ai Logo" />
              </Button>
            </OverlayTrigger>
          </div>
          <input
            type="text"
            name="message"
            value={searchWord}
            className="form-control"
            placeholder="Not the correct facility or interested in a different facility? Search here..."
            onChange={(e: any) => setSearchWord(e.target.value)}
            autoFocus
          />
          <div className="input-group-append right-icon">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="tooltip">Send Message</Tooltip>}
            >
              <Button
                variant="link"
                className="px-0"
              >
                <Search />
              </Button>
            </OverlayTrigger>
          </div>
        </InputGroup> */}
        {isBackButton && <Button variant="primary" className="back-btn" size="lg" onClick={handleBackButton}>Back</Button>}
      </SearchContainer>
    </MyTundraWrapper>
  );
};

export default withAuthContext(MyTundraContainer);
