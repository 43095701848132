import React from 'react';
import { Pagination } from 'react-bootstrap';
import styled from 'styled-components';

const SearchPaginationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  .pagination {
    flex: 1 1 auto;
    justify-content: space-between;
  }
`;
const TotalRecordTextContainer = styled.div`
  margin: 1em 0;
  color: #6c757d;
  color: var(--secondary);
`;
interface IPaginationsProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  isFirstPage: boolean;
  isLastPage: boolean;
  totalRecords: number;
  isDisabled: boolean;
}

const Paginations: React.FC<IPaginationsProps> = (props) => {
  return (
    <SearchPaginationContainer>
      <TotalRecordTextContainer>
        About <b>{props.totalRecords}</b> records
      </TotalRecordTextContainer>
      <Pagination size="lg">
        <Pagination.First disabled={props.isFirstPage || props.isDisabled} onClick={props.onPreviousClick} />
        <Pagination.Last disabled={props.isLastPage || props.isDisabled} onClick={props.onNextClick} />
      </Pagination>
    </SearchPaginationContainer>
  );
};

export default Paginations;
