import { ISearchRecord } from '../../types/search';
import React, { useState } from 'react';

import MyTundraContainer from '../../components/ai-earth-components/MyTundraContainer';
import { IAuthContext } from '../../context/auth/auth-context';
import { RouteComponentProps } from 'react-router-dom';
import withAuthContext from '../../context/auth/AuthConsumer';

// interface interface ICeresHome {
//   tier: TIERS;
// }

const MyTundra: React.FC<
  RouteComponentProps<any> & {
  context: IAuthContext;
}> = (props) => {
  const [boundingBox, setBoundingBox] = useState();
  const [programQueryEPNResult, setProgramQueryEPNResult] = useState<any[]>();
  const [activeMarkerRecord, setActiveMarkerRecord] = useState<ISearchRecord | null>(null);
  const [showDocPanel, setShowDocPanel] = useState(false);
  const [showProgramQueryPanel, setShowProgramQueryPanel] = useState(false);
  const [showProgramQueryDocPanel, setShowProgramQueryDocPanel] = useState(false);
  const [coords, setCoords] = useState();

  return (
    <MyTundraContainer
      showProgramQueryPanel={showProgramQueryPanel}
      setShowProgramQueryPanel={setShowProgramQueryPanel}
      programQueryEPNResult={programQueryEPNResult}
      setProgramQueryEPNResult={setProgramQueryEPNResult}
      boundingBox={boundingBox}
      setBoundingBox={setBoundingBox}
      showDocPanel={showDocPanel}
      setShowDocPanel={setShowDocPanel}
      showProgramQueryDocPanel={showProgramQueryDocPanel}
      setShowProgramQueryDocPanel={setShowProgramQueryDocPanel}
      activeMarkerRecord={activeMarkerRecord}
      setActiveMarkerRecord={setActiveMarkerRecord}
      onRectDrag={(boxCoords: any) => {
        setCoords(boxCoords);
      }}
      coords={coords}
    />
  );
};

export default withAuthContext(MyTundra);
