import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { updateJob, updateMap } from '../../store/aiearth/action';
import { activeJobInitial as jobDataInitial } from '../../store/aiearth/reducer';
import { IJob, IMap } from '../../store/aiearth/types';
import { useDispatch, useSelector } from 'react-redux';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import { IElasticResult, ISearchRecord } from '../../types/search';
import SearchPagination from './SearchPagination';
import AiEarthMap from './map';
import { TIERS } from '../../types/tiers';
import { Row, Col, Card, Accordion, Spinner } from 'react-bootstrap';
import RecordNavigation from './map/RecordNavigation';
import * as R from 'ramda';
import RecordMarker from './map/RecordMarker';
import ProgramQueryDocDrawer from './ProgramQueryDocDrawer';
import { ChevronLeft } from 'react-feather';
import { toast } from 'react-toastify';
import { ceresApp } from '../../services/firebase';
import { Alert, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';

interface IAiAirContainer {
  result: IElasticResult<ISearchRecord> | null;
  programQueryResult: ISearchRecord[];
  programQueryResultCount: any;
  programQueryText: any;
  error?: any;
  showPanel: any;
  setShowPanel: any;
  showDocPanel: any;
  setShowDocPanel: any;
  isLoading: any;
  onPreviousClick: () => void;
  onNextClick: () => void;
  programQueryNextClick: () => void;
  programQueryPreviousClick: () => void;
  handleSort: () => void;
  SearchForm: React.StatelessComponent;
  ProgramQuery: React.StatelessComponent;
  currentQueryText: string;
  showProgramQueryPanel: any;
  setShowProgramQueryPanel: any;
  showProgramQueryDocPanel: any;
  setShowProgramQueryDocPanel: any;
  activeMarkerRecord: any;
  setActiveMarkerRecord: any;
  setAirEventTableOpen: any;
  airEventTableOpen: any;
  setAutoScroll: any;
}

// const TableContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   ${mediaQuery.phone`

//  `}
//   ${mediaQuery.tablet`

//   `}
// `;

// const MAP_NAVIGATION_ELEMENT_ID = 'ceres-map-navigation';
export const isValidLatLong = (lat: number, lng: number) => {
  return lat !== 0 && lng !== 0 && lat !== null && lng !== null;
};
const getUniqueSearchEntityRecords = (records: ISearchRecord[]) => {
  return R.compose<ISearchRecord[], ISearchRecord[], ISearchRecord[]>(
    R.uniqBy(R.prop('entityNumber')),
    R.filter((record: ISearchRecord) => !!record && !!record.entityNumber)
  )(records);
};
const RNDataContainer = styled.div`
  padding: 5px 10px;
  height: calc(100vh - 190px);
  overflow: auto;
  background-color: #f1f1f1;
`;

const DocPreviewContainer = styled(RNDataContainer)`
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: #b2cfea;
  flex-direction: row-reverse;
`;

const CustomButton = styled(Button)`
  padding: 5px !important;
  height: 37px;
  line-height: 0.1;
`;

const PreviewButtonContainer = styled(ButtonContainer)`
  background-color: #7030a0;
`;

const GridContainer = styled.div`
  padding: 5px;
  height: calc(100vh - 195px);
  /* overflow-x: scroll;
  & > * {
    overflow-x: initial;
  } */
`;

// const getPageNo = () => {
//   if (props.activeMarkerRecord) {
//     const index = programSearchData.findIndex(
//       (x: any) => x.entityNumber === props.activeMarkerRecord.entityNumber
//     );
//     const pageNo = (index + (10 - (index % 10))) / 10;
//     return pageNo;
//   } else {
//     return 1;
//   }
// };

const StyledAccordion = styled(Accordion)`
  height: calc(100vh - 205px);
  margin: 1px 5px;
`;
const StyledDataTable = styled(DataTable)`
  & .rdt_TableCell {
    font-size: 10px;
    padding: 3px;
  }
  /* & .rdt_TableBody {
    overflow-y: initial;
  } */
  & .rdt_TableRow,
  .rdt_TableHeadRow {
    min-height: 30px;
  }
`;

const ContentTD = styled.p`
  && {
    padding: 0.75em;
  }
  em {
    font-style: normal;
    font-weight: 700;
  }
`;

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const AiAirContainer: React.FC<
  IAiAirContainer & {
    context: IAuthContext;
  }
> = (props) => {
  const records = props.result ? props.result.records : [];
  const context = props.context;
  const { SearchForm } = props;
  const { ProgramQuery } = props;
  const dispatch = useDispatch();
  const [showNavigation, setShowNavigation] = useState(false);
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const [gMap, setGMap] = useState<any>();
  const [gMaps, setGMaps] = useState<any>();
  const [relatedDocument, setRelatedDocument] = useState<ISearchRecord | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>();
  const uniqueEntityRecords = getUniqueSearchEntityRecords(records);
  const store: any = useSelector<any>((state): any => state);
  const mapState: IMap = store.map;
  const jobState: IJob = store.job;

  const getCenterWithOffset = (lat: number, lng: number, offsetX: number, offsetY: number) => {
    if (!gMap || !gMaps) {
      return;
    }
    const currentMapProjection = gMap.getProjection();
    const point = currentMapProjection.fromLatLngToPoint(new gMaps.LatLng(lat, lng));
    // point.x = point.x + offsetX;
    // point.y = point.y + offsetY;
    const newCenterLatLng = currentMapProjection.fromPointToLatLng(point);
    return { lat: newCenterLatLng.lat(), lng: newCenterLatLng.lng() };
  };
  const onMarkerClick = (searchRecord: ISearchRecord | null) => {
    if (R.isNil(searchRecord)) {
      // return; // resetMapToInitial(); //test
    }
    props.setActiveMarkerRecord(searchRecord);
    setZoomLevel(18);
  };

  const resetJobMap = () => {
    if (mapState.mapOverLay) {
      mapState.mapOverLay.setMap(null);
      mapState.mapOverLay = null;
    }
    mapState.layers = [];
    mapState.execute = false;
    mapState.jobLayers = [];
    if (typeof gMap !== 'undefined') {
      gMap.setZoom(5);
    }
    dispatch(updateMap(mapState));

    if (mapState.subjectPropertyShowLayer) {
      mapState.subjectPropertyShowLayer.forEach((feature: any) => {
        mapState.subjectPropertyShowLayer.remove(feature);
      });
    }
    if (mapState.oilGasJobMaps) {
      mapState.oilGasJobMaps.forEach((feature: any) => {
        mapState.oilGasJobMaps.remove(feature);
      });
    }
    if (mapState.adjoiningPropertiesBoundaries) {
      mapState.adjoiningPropertiesBoundaries.forEach((feature: any) => {
        mapState.adjoiningPropertiesBoundaries.remove(feature);
      });
    }
    if (mapState.pipeLinesJobMaps) {
      mapState.pipeLinesJobMaps.forEach((feature: any) => {
        mapState.pipeLinesJobMaps.remove(feature);
      });
    }
    if (mapState.monitorWellsPointer) {
      mapState.monitorWellsPointer.forEach((feature: any) => {
        mapState.monitorWellsPointer.remove(feature);
      });
    }
    if (mapState.adjoiningPropertyLayer) {
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
    }
    if (mapState.waterWellsPointer) {
      mapState.waterWellsPointer.forEach((feature: any) => {
        mapState.waterWellsPointer.remove(feature);
      });
    }
    if (mapState.soilsPropertyLayer) {
      mapState.soilsPropertyLayer.forEach((feature: any) => {
        mapState.soilsPropertyLayer.remove(feature);
      });
    }
    dispatch(
      updateJob({
        ...jobState,
        ...{
          jobCreate: 'neutral',
          jobAction: true,
          jobInit: false,
          taskWizard: false,
          activeStep: 0,
          taskWizardStep: 0,
          adjoiningProperties: [],
          activeJob: jobDataInitial,
        },
      })
    );
  };

  const dummyRecord = {
    cityName: '',
    contentId: '',
    entityName: '',
    entityNumber: '',
    secondaryId: '',
    fileName: '',
    hitContent: [],
    releaseDate: '',
    fromReleaseDate: '',
    toReleaseDate: '',
    seriesId: '',
    seriesName: '',
    title: '',
    fileUrl: '',
    relevanceScore: 0,
    fileType: '',
    programSymbol: '',
    entitySource: '',
    address: '',
    containsSearchTerm: false,
    location: {
      lat: 0,
      lon: 0,
    },
  };
  const doStorageDownload = R.contains(R.__, ['tceq_novell']);

  const getStorageRef = R.cond<string, firebase.storage.Storage | null>([
    [R.equals('tceq_novell'), R.always(ceresApp.storage(`gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
    [R.T, R.always(null)],
  ]);

  let programSearchData: any = [];

  if (!!props.programQueryResult) {
    programSearchData = [...props.programQueryResult];
  }
  const columns = [
    {
      name: 'Entity Name',
      selector: 'entityName',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true,
    },
    {
      name: 'Entity Source',
      selector: 'entitySource',
      sortable: true,
      maxWidth: '50px',
    },
  ];

  const onRowClick = (row: any) => {
    if (row.location && row.location.lat) {
      gMap.panTo(getCenterWithOffset(row.location.lat, row.location.lon, -0.1, -0.15));
      setZoomLevel(18);
      gMap.setMapTypeId('satellite');
    }
    props.setActiveMarkerRecord(row);
    props.setShowProgramQueryDocPanel(true);
  };
  const conditionalRowStyles = [
    {
      when: (row: any) =>
        row.entityNumber === (props.activeMarkerRecord !== null ? props.activeMarkerRecord.entityNumber : ''),
      style: {
        // tslint:disable-next-line: object-literal-key-quotes
        backgroundColor: '#005FBCeb !important',
        // tslint:disable-next-line: object-literal-key-quotes
        color: 'white !important',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const record: ISearchRecord = props.activeMarkerRecord || dummyRecord;
  return (
    <Row noGutters style={{ height: 'calc(100vh - 130px)' }}>
      <Col lg={2} sm={12}>
        <StyledAccordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} style={{ cursor: 'pointer' }} eventKey="0">
              <b>Document Query</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <RecordNavigation
                // mapNavigationElementId={MAP_NAVIGATION_ELEMENT_ID}
                records={uniqueEntityRecords}
                record={record}
                showRecordsLoader={false}
                onNavigationItemClick={(searchRecord: ISearchRecord, e: any) => {
                  props.setActiveMarkerRecord(searchRecord);
                  props.setShowDocPanel(false);
                  props.setShowProgramQueryPanel(false);
                  props.setShowPanel(true);
                  if (!isValidLatLong(searchRecord.location.lat, searchRecord.location.lon)) {
                    toast.info(
                      <span>
                        AI Earth is not mapping this site because the original agency source data does not provide
                        sufficient information for an accurate geocode.
                      </span>,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 10000,
                      }
                    );
                    return;
                  }
                  gMap.panTo(getCenterWithOffset(searchRecord.location.lat, searchRecord.location.lon, -0.1, -0.15));
                  gMap.setMapTypeId('satellite');
                  setZoomLevel(18);
                }}
                SearchForm={SearchForm}
                SearchPagination={SearchPagination}
                onPreviousClick={props.onPreviousClick}
                onNextClick={props.onNextClick}
                result={props.result}
              />
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} style={{ cursor: 'pointer' }} eventKey="1">
              <b>Program Query</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Fragment>
                <Scrollbars
                  style={{
                    height: 'calc(100vh - 297px)',
                    background: 'rgba(108, 117, 125, 0.5)',
                  }}
                >
                  <ProgramQuery />
                </Scrollbars>
              </Fragment>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} style={{ cursor: 'pointer' }} eventKey="#">
              <b>Spatial Query</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Fragment>
                <Scrollbars
                  style={{
                    height: 'calc(100vh - 297px)',
                    background: 'rgba(108, 117, 125, 0.5)',
                  }}
                ></Scrollbars>
              </Fragment>
            </Accordion.Collapse>
          </Card>
        </StyledAccordion>
      </Col>
      {props.showPanel && (
        <Col lg={props.airEventTableOpen ? 6 : 3} sm={props.showPanel ? 12 : 0}>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowPanel(false);
                props.setShowDocPanel(false);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <RNDataContainer>
            {record.contentId && (
              <RecordMarker
                setRelatedDocument={setRelatedDocument}
                currentQueryText={props.currentQueryText}
                onInfoWindowClose={() => onMarkerClick(null)}
                key={record.contentId}
                onMarkerClick={() => onMarkerClick(record)}
                lat={record.location.lat}
                lng={record.location.lon}
                isActive={true}
                record={record}
                isMap={false}
                showDocPanel={props.showDocPanel}
                showPanel={props.showPanel}
                setShowDocPanel={props.setShowDocPanel}
                setAirEventTableOpen={props.setAirEventTableOpen}
                airEventTableOpen={props.airEventTableOpen}
              />
            )}
          </RNDataContainer>
        </Col>
      )}
      {props.showProgramQueryPanel && (
        <Col lg={props.showProgramQueryPanel ? 3 : 0}>
          <ButtonContainer>
            <CustomButton
              onClick={() => {
                props.setShowProgramQueryPanel(false);
              }}
              size="sm"
            >
              {props.isLoading ? <Spinner animation="border" variant="light" size="sm" /> : <ChevronLeft />}
            </CustomButton>
            {props.programQueryResultCount ? (
              <SearchPagination
                isDisabled={false}
                isFirstPage={(!!programSearchData && props.programQueryText.offset === 0) || false}
                isLastPage={
                  (programSearchData && props.programQueryText.offset + 50 >= props.programQueryResultCount) || false
                }
                totalRecords={props.programQueryResultCount}
                onPreviousClick={() => {
                  props.programQueryPreviousClick();
                }}
                onNextClick={() => {
                  props.programQueryNextClick();
                }}
              />
            ) : null}
          </ButtonContainer>
          <GridContainer>
            <StyledDataTable
              columns={columns}
              data={programSearchData}
              noHeader={true}
              fixedHeaderScrollHeight={'64vh'}
              fixedHeader={true}
              pointerOnHover
              striped
              onSort={props.handleSort}
              sortServer={true}
              progressPending={props.isLoading}
              onRowClicked={onRowClick || undefined}
              highlightOnHover
              conditionalRowStyles={conditionalRowStyles}
            />
          </GridContainer>
        </Col>
      )}
      {props.showProgramQueryDocPanel && (
        <Col lg={props.showProgramQueryDocPanel ? 3 : 0}>
          <PreviewButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowProgramQueryDocPanel(false);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </PreviewButtonContainer>
          <RNDataContainer>
            <ProgramQueryDocDrawer
              record={record}
              doStorageDownload={doStorageDownload}
              getStorageRef={getStorageRef}
              context={context}
              downloadTriggers={downloadTriggers}
              setDownloadTriggers={setDownloadTriggers}
              showProgramQueryPanel={props.showProgramQueryPanel}
            />
          </RNDataContainer>
        </Col>
      )}
      {props.showDocPanel && !!relatedDocument && !props.airEventTableOpen && (
        <Col lg={props.showDocPanel ? 3 : 0} sm={12}>
          <PreviewButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowDocPanel(false);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </PreviewButtonContainer>
          <DocPreviewContainer>
            <p>
              Type: <b>{relatedDocument.title}</b>
            </p>
            <p>
              Release Date: <b>{relatedDocument.releaseDate}</b>
            </p>
            <p>
              Category: <b>{relatedDocument.seriesName}</b>
            </p>
            <p>
              <Button
                onClick={() => {
                  if (!context.isAuthenticated) {
                    return toast.warn(
                      <span>
                        Please <Alert.Link href="/login">login</Alert.Link> to download files.
                      </span>,
                      {
                        position: toast.POSITION.BOTTOM_CENTER,
                      }
                    );
                  }
                  if (context.isTier(TIERS.FREE) && !context.isLubrizol()) {
                    return toast.warn(<span>Please upgrade to BASIC or PLATINUM to download files.</span>, {
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                  }
                  if (doStorageDownload(relatedDocument.entitySource)) {
                    const storageRef = getStorageRef(relatedDocument.entitySource);
                    if (R.isNil(storageRef)) {
                      return toast.error(`Oops, could not find the file.`);
                    }
                    setDownloadTriggers(R.append(relatedDocument.contentId, downloadTriggers));
                    return storageRef
                      .ref(relatedDocument.fileName)
                      .getDownloadURL()
                      .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                      .catch((err) => toast.error(err.message))
                      .finally(() => setDownloadTriggers(R.without([relatedDocument.contentId], downloadTriggers)));
                  }
                  if (!relatedDocument.fileUrl) {
                    return toast.warn(
                      <span>
                        No associated file was found for the content id <b>{relatedDocument.contentId}</b>
                      </span>
                    );
                  }
                  window.open(relatedDocument.fileUrl, '_blank');
                }}
              >
                Download
              </Button>
            </p>
            <ContentTD
              dangerouslySetInnerHTML={{
                __html:
                  relatedDocument.hitContent.length > 0
                    ? relatedDocument.hitContent
                        // Remove non-ascii chars.
                        .map((str: string) =>
                          /*eslint no-control-regex: 0*/
                          str.replace(/[^\x00-\x7F]/g, '')
                        )
                        .join('<br />')
                    : '<h3>No Preview Available</h3>',
              }}
            />
          </DocPreviewContainer>
        </Col>
      )}
      {!props.showProgramQueryPanel && (
        <Col lg={props.showPanel ? (props.showDocPanel || props.airEventTableOpen ? 4 : 7) : 10} sm={12}>
          <div>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <AiEarthMap
                records={records}
                showNavigation={showNavigation}
                setShowNavigation={setShowNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                activeMarkerRecord={props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                setActivePanel={props.setShowPanel}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setAutoScroll={props.setAutoScroll}
                resetJobMap={resetJobMap}
                context={context}
              />
            )}
          </div>
        </Col>
      )}
      {props.showProgramQueryPanel && (
        <Col
          lg={props.showProgramQueryPanel ? (props.showProgramQueryDocPanel || !props.airEventTableOpen ? 4 : 7) : 0}
          sm={12}
        >
          <div>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <AiEarthMap
                records={props.programQueryResult ? props.programQueryResult : []}
                showNavigation={showNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                setShowNavigation={setShowNavigation}
                activeMarkerRecord={props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                setActivePanel={props.setShowPanel}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setAutoScroll={props.setAutoScroll}
                resetJobMap={resetJobMap}
                context={context}
              />
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default withAuthContext(AiAirContainer);
