import React, { useState } from 'react';
import { Alert, Button, Col, Form, FormControl, FormGroup, InputGroup, Row, Spinner } from 'react-bootstrap';
import { ChevronLeft, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import VerticalCenter from '../components/VerticalCenter';
import AuthContext, { IAuthContext } from '../context/auth/auth-context';
import mediaQuery from '../util/media-query';
import EnviroAILogo from '../assets/enviroai_new.svg';
import tundraSearch from '../assets/search/tundra_search.svg';

const Main = styled<any>(VerticalCenter)`
  min-height: 100vh;
  background: #fff;
  align-items: center;

  a {
    color: rgb(44, 25, 14);
  }
`;

const Hero = styled<any>(VerticalCenter)`
  // padding: 80px;
  background-image: url(${tundraSearch});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  background-color: rgb(142, 187, 247);
  text-align: center;
  align-items: center;
  color: #000;
  & h1 {
    font-family: 'Lazy Dog';
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    color: inherit;
    margin-bottom: 10px;
  }
  & h3 {
    margin: 10px 100px;
    font-weight: 700;
  }
  & p {
    font-size: 18px;
    padding: 20px 59px;
    text-align: center;
    font-family: 'Open Sans';
  }
`;

const CeresBotHeroImage = styled.img`
  width: 100%;
  margin-top: 45px;
  max-width: 340px;
  @media only screen and (max-width: 1400px) {
    max-width: 45%;
  }
  ${mediaQuery.phone`
    width: auto;
    margin-top: 100px;
    max-width: 90%;
  `}
`;

const Title = styled.h1`
  color: #005fbc;
  color: var(--primary);
  margin-bottom: 1em;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ForgotPasswordButton = styled(Button)`
  width: 100%;
  &&, &:active {
    color: rgb(44, 25, 14) !important;
    background-color: rgb(221, 197, 179) !important;
    border-color: rgb(221, 197, 179) !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
`;

const ForgotPasswordFormContainer = styled.div`
  width: 300px;
  margin-bottom: 2rem;
`;
const ForgotPasswordFormGroup = styled(FormGroup)`
  margin-bottom: 2rem;
`;

const ForgotPasswordContainer = styled(Row)`
  flex: 1;
  overflow: auto;
  height: 100vh;
`;
const ForgotPasswordForm = styled(Form)``;

const AlertContainer = styled.div`
  margin-bottom: 2rem;
`;

const ForgotPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  return (
    <AuthContext.Consumer>
      {(context: IAuthContext) => {
        return (
          <ForgotPasswordContainer noGutters>
            <Col lg={6} md={6}>
              <Hero>
                <CeresBotHeroImage src={EnviroAILogo} alt="EnviroAI" />
                <p className="mb-0 font-weight-bold">Digital Environmental Assistant</p>
                <p>
                  Enviro Ai utilizes big data, artificial intelligence, and the subject matter expertise training of
                  experienced legal and consulting practitioners to deliver improved environmental professional performance.
                </p>
              </Hero>
            </Col>
            <Col lg={6} md={6}>
              <Main>
                <Title>Enviro Ai</Title>
                <ForgotPasswordFormContainer>
                  <AlertContainer>
                    <Alert show={showResetForm} variant="primary">
                      <Alert.Heading>Almost there!</Alert.Heading>
                      <p>A password reset link has been dispatched to your email.</p>
                    </Alert>
                    <Alert show={!!error} variant="danger">
                      <Alert.Heading>Failed to reset!</Alert.Heading>
                      <p>{error}</p>
                    </Alert>
                  </AlertContainer>
                  {!showResetForm && (
                    <ForgotPasswordForm
                      onSubmit={(e: React.FormEvent) => {
                        e.preventDefault();
                        setIsLoading(true);
                        setError('');
                        context
                          .sendPasswordResetEmail(email)
                          .then(() => setShowResetForm(true))
                          .catch((err) => setError(err.message))
                          .finally(() => setIsLoading(false));
                      }}
                    >
                      <ForgotPasswordFormGroup>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <Mail />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder="Email"
                            type="email"
                            name="email"
                            required
                            disabled={isLoading}
                          />
                        </InputGroup>
                      </ForgotPasswordFormGroup>
                      <ForgotPasswordButton type="submit" disabled={isLoading}>
                        {!isLoading && 'Send password reset link'}
                        {isLoading && <Spinner animation="border" variant="light" size="sm" />}
                      </ForgotPasswordButton>
                    </ForgotPasswordForm>
                  )}
                </ForgotPasswordFormContainer>
                <Link to="/login">
                  <ChevronLeft /> Back to login
                </Link>
              </Main>
            </Col>
          </ForgotPasswordContainer>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default ForgotPassword;
