import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import FocusLock from 'react-focus-lock';
import { IDatabase } from '../../store/aiearth/types';
import LoadingButton from '../LoadingButton';
import { Search } from 'react-feather';
import { initialStateDatabase } from '../../store/aiearth/reducer';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';
import { updateDatebase } from '../../store/aiearth/action';

// import { toast } from 'react-toastify';

interface IRecordNavigationProps {
  onSearchSubmit: (queryText: IDatabase) => void;
  isLoading: boolean;
  masters: any;
  boundingBox?: any;
  setBoundingBox?: any;
  getPageRoute?: any;
}

const RecordNavigationContainer = styled.div`
  padding: 8px;
  padding-bottom: 0;
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ProgramQueryContainer = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0.5em;
`;

const FormCheck = styled(Form.Check)`
  margin-right: 15px;
`;

const FilterItem = styled.div`
  margin-bottom: 10px;
`;

const CustomInputGroupText = styled(InputGroup.Text)`
  font-size: 0.7rem !important;
`;

const SourceType = styled(Select)`
  flex: 1 1 auto;
  font-size: 12px;
`;

const hasAnyFilterApplied = (filters: any) =>
  Object.keys(filters).filter((key: string) => !!filters[key]).length > 0;

const ProgramQuery: React.FC<IRecordNavigationProps> = (props) => {
  const store: any = useSelector<any>((storeState): any => storeState);
  const databaseState: IDatabase = store.database;
  const dispatch = useDispatch();
  const [hasAnyFilter, setHasAnyFilter] = useState(
    hasAnyFilterApplied(databaseState) ? true : false
  );
  const [focusdisabled, setFocusdisabled] = useState(true);
  const [filters, setFilters] = useState<any>(databaseState);

  const sourceOptions: any = {
    tx: [
      { value: 'epa', label: 'EPA' },
      { value: 'tceq', label: 'TCEQ' },
      { value: 'nrc', label: 'NRC' },
      { value: 'rrc', label: 'RRC' },
    ],
    la: [
      { value: 'epa', label: 'EPA' },
      { value: 'nrc', label: 'NRC' },
      { value: 'ldeq', label: 'LDEQ' },
    ],
  };
  const [source, setSource] = useState<any>(
    !!databaseState.source ? databaseState.source : null
  );
  const stateOptions = [
    { value: 'tx', label: 'TX' },
    { value: 'la', label: 'LA' },
  ];
  const [state, setState] = useState(
    !!databaseState.state ? databaseState.state : { value: 'tx', label: 'TX' }
  );
  const countyOptions = !!props.masters
    ? props.masters.counties[filters.state.label]
    : [];
  const [county, setCounty] = useState(
    !!databaseState.county ? databaseState.county : []
  );
  const programOptions = !!props.masters ? props.masters.programs : [];
  const [program, setProgram] = useState(
    !!databaseState.program ? databaseState.program : []
  );
  const animatedComponents = makeAnimated();
  const indicatorStyle = (base: any) => ({
    ...base,
    color: 'white',
    padding: '4px',
  });
  const styles = {
    clearIndicator: indicatorStyle,
    dropdownIndicator: indicatorStyle,
  };
  const IndicatorsContainer = (properties: any) => {
    return (
      <div style={{ background: '#005FBC' }}>
        <components.IndicatorsContainer {...properties} />
      </div>
    );
  };

  return (
    <FocusLock disabled={focusdisabled}>
      <ProgramQueryContainer
        onMouseLeave={() => {
          dispatch(updateDatebase(filters));
        }}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter') {
            const resetOffset = { ...filters, offset: 0 };
            dispatch(updateDatebase(resetOffset));
            props.onSearchSubmit(resetOffset);
          }
        }}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          const resetOffset = { ...filters, offset: 0 };
          dispatch(updateDatebase(resetOffset));
          props.onSearchSubmit(resetOffset);
        }}
      >
        <RecordNavigationContainer>
          {props.getPageRoute === '/ai-earth' && (
            <>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="rn-number-filter">
                      Entity IDs
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <FormControl
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...databaseState,
                        rnNumber: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                    id="pro-query"
                    value={filters.rnNumber}
                    aria-label="RN Number"
                    aria-describedby="rn-number-filter"
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="entity-name-filter">
                      Entity Names
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <FormControl
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...filters,
                        entityName: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                    value={filters.entityName}
                    aria-label="Entity Name"
                    aria-describedby="entity-name-filter"
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="street-name-filter">
                      Addresses
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <FormControl
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    onChange={(e: any) => {
                      const newFilter = { ...filters, street: e.target.value };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                    value={filters.street}
                    aria-label="Street"
                    aria-describedby="street-name-filter"
                    autoComplete="street"
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="city-name-filter">
                      Cities
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <FormControl
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...filters,
                        cityName: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                    value={filters.cityName}
                    aria-label="City Name"
                    aria-describedby="city-name-filter"
                    autoComplete="city"
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="zip-code-filter">
                      Zips
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <FormControl
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    onChange={(e: any) => {
                      const newFilter = { ...filters, zipCode: e.target.value };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                    value={filters.zipCode}
                    aria-label="Zip Code"
                    autoComplete="zipCode"
                    aria-describedby="zip-code-filter"
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="state-filter">
                      State
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <SourceType
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    value={state}
                    components={{ IndicatorsContainer, animatedComponents }}
                    closeMenuOnSelect={true}
                    styles={styles}
                    options={stateOptions}
                    onChange={(e: any) => {
                      const newFilter = { ...filters, state: e };
                      setFilters(newFilter);
                      setState(e);
                    }}
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="county-filter">
                      Counties
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <SourceType
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    value={county}
                    components={{ IndicatorsContainer, animatedComponents }}
                    closeMenuOnSelect={true}
                    isMulti
                    styles={styles}
                    options={countyOptions}
                    onChange={(e: any) => {
                      const newFilter = { ...filters, county: e };
                      setFilters(newFilter);
                      setCounty(e);
                    }}
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup>
                  <InputGroup.Prepend>
                    <CustomInputGroupText id="source-filter">
                      Source
                    </CustomInputGroupText>
                  </InputGroup.Prepend>
                  <SourceType
                    onFocus={() => {
                      setFocusdisabled(false);
                    }}
                    value={source}
                    components={{ IndicatorsContainer, animatedComponents }}
                    closeMenuOnSelect={true}
                    styles={styles}
                    options={sourceOptions[state.value]}
                    isClearable={true}
                    onChange={(e: any) => {
                      const newFilter = { ...filters, source: e };
                      setFilters(newFilter);
                      setSource(e);
                      setProgram([]);
                    }}
                  />
                </InputGroup>
              </FilterItem>
              {source && (
                <FilterItem>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <CustomInputGroupText id="program-filter">
                        Programs
                      </CustomInputGroupText>
                    </InputGroup.Prepend>
                    <SourceType
                      autoFocus
                      onFocus={() => {
                        setFocusdisabled(false);
                      }}
                      value={program}
                      components={{ IndicatorsContainer, animatedComponents }}
                      closeMenuOnSelect={true}
                      isMulti
                      styles={styles}
                      options={programOptions[source.label]}
                      onChange={(e: any) => {
                        const newFilter = { ...filters, program: e };
                        setFilters(newFilter);
                        setProgram(e);
                      }}
                    />
                  </InputGroup>
                </FilterItem>
              )}
              <FilterItem>
                <InputGroup
                  onFocus={() => {
                    setFocusdisabled(false);
                  }}
                >
                  <FormCheck
                    type="checkbox"
                    label="Bounding Box"
                    name="boundingBox"
                    checked={props.boundingBox}
                    onChange={(e: any) => {
                      if (!e.target.checked) {
                        const newFilter = { ...filters, boundingBox: '' };
                        setFilters(newFilter);
                      } else {
                        dispatch(updateDatebase(filters));
                      }
                      props.setBoundingBox(e.target.checked);
                    }}
                  />
                </InputGroup>
              </FilterItem>
              <FilterItem>
                <InputGroup
                  onFocus={() => {
                    setFocusdisabled(false);
                  }}
                >
                  <FormCheck
                    type="radio"
                    label="All"
                    name="mapOptions"
                    value="all"
                    checked={filters.mapOptions === 'all'}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...filters,
                        mapOptions: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                  />
                  <FormCheck
                    type="radio"
                    label="Mapped"
                    name="mapOptions"
                    value="mapped"
                    checked={filters.mapOptions === 'mapped'}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...filters,
                        mapOptions: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Unmapped"
                    name="mapOptions"
                    value="unmapped"
                    checked={filters.mapOptions === 'unmapped'}
                    onChange={(e: any) => {
                      const newFilter = {
                        ...filters,
                        mapOptions: e.target.value,
                      };
                      setFilters(newFilter);
                      setHasAnyFilter(hasAnyFilterApplied(newFilter));
                    }}
                  />
                </InputGroup>
              </FilterItem>
              <Button
                variant="secondary"
                style={{ marginRight: '15px' }}
                onClick={() => {
                  // toast.info('Cleared advanced search filters.');
                  dispatch(updateDatebase(initialStateDatabase));
                  setHasAnyFilter(false);
                  setSource(false);
                  setCounty(false);
                  props.setBoundingBox(false);
                }}
                onMouseDown={(e: any) => {
                  e.target.click();
                }}
              >
                Clear Filter
              </Button>
            </>
          )}
          {!!hasAnyFilter && (
            <LoadingButton isLoading={props.isLoading || false}>
              <Search />
            </LoadingButton>
          )}
        </RecordNavigationContainer>
      </ProgramQueryContainer>
    </FocusLock>
  );
};

export default ProgramQuery;
