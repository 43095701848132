import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// @ts-ignore
import Select from 'react-virtualized-select';
// @ts-ignore
import createFilterOptions from 'react-select-fast-filter-options';

const FilterArea = styled.div`
  width: 90%;
`;

const Filter: React.FC<{ subscriptionsList: any; setFilterBy: any; filterBy: any }> = (props) => {
  const { subscriptionsList, setFilterBy, filterBy } = props;
  const [subscriptions, setSubscriptions] = useState<any>();
  useEffect(() => {
    if (subscriptionsList && subscriptionsList.length > 0) {
      const list = subscriptionsList.map((src: any) => {
        return {
          label: src.keyword + ' - ' + src.document_source,
          value: src.keyword + '|||' + src.document_source,
        };
      });
      setSubscriptions(list);
    }
  }, [subscriptionsList, setSubscriptions]);

  return (
    <FilterArea>
      <Select
        value={filterBy}
        className="basic-single"
        filterOptions={createFilterOptions({ options: subscriptions })}
        name="events"
        options={subscriptions}
        onChange={(e: any) => {
          setFilterBy(e);
        }}
        placeholder="Words/Subjects Tracked"
      />
    </FilterArea>
  );
};
export default Filter;
