import { aiearthReducer, airReducer, databaseReducer, globalFlagsReducer, jobReducer, mapReducer, quickPromptsReducer, savedChatReducer, searchReducer } from './aiearth/reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';

import thunkMiddleware from 'redux-thunk';

const rootReducer = combineReducers({
  aiearth: aiearthReducer,
  job: jobReducer,
  map: mapReducer,
  database: databaseReducer,
  air: airReducer,
  search: searchReducer,
  savedChat: savedChatReducer,
  globalFlags: globalFlagsReducer,
  quickPrompts: quickPromptsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  const store = createStore(rootReducer, middleWareEnhancer);
  return store;
}
