import React from 'react';
import { Button, Form, FormControl, InputGroup, OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Filter, Search } from 'react-feather';
import styled from 'styled-components';
import mediaQuery from '../../util/media-query';
import LoadingButton from '../LoadingButton';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import { TIERS } from '../../types/tiers';
import { useSelector } from 'react-redux';
import { ISearch } from '../../store/aiearth/types';
import EnviroAiTriangleIcon from '../../assets/enviro.svg';

const SearchFormContainer = styled(Form)<{ issearch: string }>`
  max-width: ${(props) => (props.issearch ? '900px' : '770px')};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.issearch ? 'space-between' : 'center')};
  align-items: center;
  margin: ${(props) => (props.issearch ? '-20px 0 0 84px' : 'auto')};
  @media only screen and (max-width: 1000px) {
    margin: ${(props) => (props.issearch ? '15px 0 0' : 'auto')};
  }
  & .btn-primary {
    background-color: #fff;
    border: 0;
    ${(props) =>
      props.issearch ? 'border-bottom-right-radius: 40px !important;' : 'border-bottom-left-radius: 40px !important;'};
    ${(props) =>
      props.issearch ? 'border-top-right-radius: 40px !important;' : 'border-top-left-radius: 40px !important;'};

    padding: 5px 20px !important;
    width: 60px;
    & .text-light {
      color: #005fbc !important;
      width: 1.5rem;
      height: 1.5rem;
      border-width: 0.2em;
    }
    &:focus {
      box-shadow: 0 0 0 0 rgb(38 119 198 / 50%);
    }
    &:not(:disabled):not(.disabled):active {
      background-color: #fff;
      border-color: #fff;
    }
  }
  & .input-group {
    box-shadow: 0 0px 5px 1px rgb(0 0 0 / 50%);
    border-radius: 50px;
  }
  & .form-control {
    border: 0;
    ${(props) => (props.issearch ? 'border-bottom-left-radius: 40px;' : 'border-bottom-right-radius: 40px;')};
    ${(props) => (props.issearch ? 'border-top-left-radius: 40px;' : 'border-top-right-radius: 40px;')};
    padding-left: ${(props) => (props.issearch ? '20px' : '15px')};
    height: ${(props) => (props.issearch ? '44px' : '60px')};
    @media only screen and (max-width: 1300px) {
      height: 44px;
    }
    font-size: 16px;
    &:focus {
      box-shadow: 0 0 0 0 rgb(0 95 188 / 25%);
    }
    &:-internal-autofill-selected {
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: #333;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: #333;
    }
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: #333;
    }
  }
`;

const FilterButton = styled.div<{ isApplied: boolean }>`
  ${(props) =>
    props.isApplied &&
    `svg {
      fill: rgb(0, 95, 188);
  }`}
  cursor: pointer;
  color: var(--white);
  margin-left: 1em;
  ${mediaQuery.phone`margin-top: .1em; margin-left: 5px;`}
  ${mediaQuery.tablet`margin-top: .1em; margin-left: 5px;`}
`;

interface ISearchForm {
  onSearchSubmit: (queryText: string) => void;
  isLoading: boolean;
  inputRef?: React.MutableRefObject<any>;
  searchRef?: React.MutableRefObject<any>;
  onFilterClick: () => void;
  onEnviroFilterClick: () => void;
  isFilterApplied: boolean;
  queryText: string;
  setQueryText: (queryText: string) => void;
}

const googleSearch = (text: string) => {
  const arraSplit = text.split(' ');
  if (arraSplit[0] === '#g') {
    const searchText = text.replace('#g', '');
    window.location.href = `https://www.google.com/search${searchText ? `?q=${searchText}` : '/webhp'}`;
    return false;
  }
  return true;
};

const SearchForm: React.FC<ISearchForm & { context: IAuthContext }> = (props) => {
  const authContext = props.context;
  const store: any = useSelector<any>((state): any => state);
  const searchState: ISearch = store.search;
  const previousPage = store.globalFlags.previousPage;
  const isBackButton = previousPage && ((previousPage === 'myenviroaiToInternetResults' &&  searchState.type === 'ALL') || (previousPage === 'myenviroaiToAgency' && searchState.type === 'default'));

  return (
    <SearchFormContainer
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        if (props.inputRef) {
          if (googleSearch(props.inputRef.current.value)) {
            props.onSearchSubmit(props.inputRef.current.value);
          }
        } else {
          if (googleSearch(props.queryText)) {
            props.onSearchSubmit(props.queryText);
          }
        }
      }}
      issearch={searchState && searchState.isSearch ? 'yes' : ''}
    >
      <InputGroup>
        {!searchState.isSearch && (
          <InputGroup.Append>
            <LoadingButton searchBtn={props.searchRef} isLoading={props.isLoading || false}>
              <Search color="#005fbc" size={32} />
            </LoadingButton>
          </InputGroup.Append>
        )}

        <FormControl
          ref={props.inputRef}
          onChange={(e: any) => props.setQueryText(e.target.value)}
          value={props.queryText ? props.queryText : ''}
          placeholder="How can I help you?"
          name="inputRef"
          aria-label="Search"
          aria-describedby="basic-addon2"
          disabled={props.isLoading || false}
        />
        {searchState.isSearch && (
          <InputGroup.Append>
            <LoadingButton searchBtn={props.searchRef} isLoading={props.isLoading || false}>
              <Search color="#005fbc" size={20} />
            </LoadingButton>
          </InputGroup.Append>
        )}
      </InputGroup>

      {authContext.isAuthenticated &&
        searchState.type === 'default' &&
        (authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
          <OverlayTrigger
          overlay={<ReactTooltip place="bottom" effect="solid" />}
        >
         <FilterButton data-tip="Advanced Filter" isApplied={props.isFilterApplied} onClick={() => props.onFilterClick()}>
            <Filter color="#005fbc" />
          </FilterButton>
        </OverlayTrigger>
        )}

      {authContext.isAuthenticated && isBackButton &&
        (authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
          <OverlayTrigger
              overlay={<ReactTooltip place="bottom" effect="solid" />}
            >
              <Button
                data-tip="Enviro Filter"
                variant="link"
                onClick={() => props.onEnviroFilterClick()}
              >
                <img src={EnviroAiTriangleIcon} alt="Enviro Ai Logo" width="30" />
              </Button>
            </OverlayTrigger>
        )}
    </SearchFormContainer>
  );
};

export default withAuthContext(SearchForm);
