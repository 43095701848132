import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';

interface IBellOverlayProps {
  show: boolean;
  target: any;
  onHide: () => void;
}

const BellOverlay: React.FC<IBellOverlayProps> = props => {
  return (
    <Overlay
      rootClose={true}
      onHide={props.onHide}
      show={props.show}
      target={props.target}
      placement="bottom"
    >
      <Popover id="popover-contained" title="Notifications">
        Nothing to see here.
      </Popover>
    </Overlay>
  );
};

export default BellOverlay;
