import React from 'react';
import CheckoutResult from './CheckoutResult';
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps
} from 'react-router';
import * as qs from 'query-string';
import * as R from 'ramda';
import { toast } from 'react-toastify';

/**
 * Returns a checkout component renderer for a given success state
 * and checkout sessionId is picked from the query params
 * @param isSuccess - If its a success checkout result or not.
 */
const renderCheckoutResult = (isSuccess = false) => (
  props: RouteComponentProps<any>
) => {
  const locationQueryParamsString =
    (props.location && props.location.search) || '';
  const queryParams = qs.parse(locationQueryParamsString);
  const checkoutSessionId: string = R.cond([
    [R.is(Array), R.head],
    [R.T, R.identity]
  ])((queryParams && queryParams.i) || '');
  if (R.isEmpty(checkoutSessionId)) {
    toast.error(`Oops, something went wrong.`);
    return <Redirect to="/" />;
  }
  return (
    <CheckoutResult
      {...props}
      checkoutSessionId={checkoutSessionId}
      isSuccess={isSuccess}
    />
  );
};
const Checkout: React.FC = () => {
  return (
    <Switch>
      <Route path="/checkout/s" exact component={renderCheckoutResult(true)} />
      <Route path="/checkout/c" exact component={renderCheckoutResult(false)} />
    </Switch>
  );
};

export default Checkout;
