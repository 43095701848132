import React, { useState } from 'react';
import { Check } from 'react-feather';
import styled from 'styled-components';
import VerticalCenter from '../../components/VerticalCenter';
import JOB_TYPES from '../../constants/job-types';

interface IJobTypeBox {
  isActive: boolean;
}

const JobTypeContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const JobTypeTitle = styled.span`
  text-align: center;
  color: var(--dark);
`;

const JobTypeBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

const JobTypeBox = styled(VerticalCenter)<IJobTypeBox>`
  user-select: none;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  height: 90px;
  width: 200px;
  margin: 0 2em;
  transition: all 0.15s ease;
  z-index: 1;
  background: ${(props) => (props.isActive ? 'var(--primary)' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')}
    ${(props) =>
      !props.isActive &&
      `&:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 24px rgba(32, 43, 54, 0.25);
    z-index: 10;
  }`};
`;

const DoneButton = styled.div`
  color: #005fbc;
  color: var(--primary);
  cursor: pointer;
  position: absolute;
  bottom: 2em;
  svg {
    height: 60px;
    width: 60px;
    stroke-width: 1;
  }
`;

interface IJobTypeWizardStep {
  selectedJobTypes: string[];
  onComplete: (jobTypes: string[]) => void;
}

const JobTypeWizardStep: React.FC<IJobTypeWizardStep> = (props) => {
  const [selectedJobTypes, setJobTypes] = useState<string[]>(
    props.selectedJobTypes
  );
  const toggleJobTypes = (jobType: string, prevJobTypesState: any[]) => {
    const currentJobType = prevJobTypesState.find((c) => c === jobType);
    return currentJobType
      ? prevJobTypesState.filter((c) => c !== jobType)
      : [...prevJobTypesState, jobType];
  };
  return (
    <JobTypeContainer>
      <JobTypeTitle>
        Select <b>job</b> types, you would like to associate.
      </JobTypeTitle>
      <JobTypeBoxContainer>
        <JobTypeBox
          onClick={() =>
            setJobTypes((prevState) =>
              toggleJobTypes(JOB_TYPES.PHASE_ONE, prevState)
            )
          }
          isActive={!!selectedJobTypes.find((c) => c === JOB_TYPES.PHASE_ONE)}
        >
          {JOB_TYPES.PHASE_ONE}
        </JobTypeBox>
        <JobTypeBox
          onClick={() =>
            setJobTypes((prevState) =>
              toggleJobTypes(JOB_TYPES.ENVIRO_FORMS, prevState)
            )
          }
          isActive={
            !!selectedJobTypes.find((c) => c === JOB_TYPES.ENVIRO_FORMS)
          }
        >
          {JOB_TYPES.ENVIRO_FORMS}
        </JobTypeBox>
        <JobTypeBox
          onClick={() =>
            setJobTypes((prevState) =>
              toggleJobTypes(JOB_TYPES.HAZARD_MAP, prevState)
            )
          }
          isActive={!!selectedJobTypes.find((c) => c === JOB_TYPES.HAZARD_MAP)}
        >
          {JOB_TYPES.HAZARD_MAP}
        </JobTypeBox>
      </JobTypeBoxContainer>
      {selectedJobTypes.length > 0 && (
        <DoneButton onClick={() => props.onComplete(selectedJobTypes)}>
          <Check />
        </DoneButton>
      )}
    </JobTypeContainer>
  );
};

export default JobTypeWizardStep;
