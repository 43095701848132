import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { IAuthContext } from '../context/auth/auth-context';
import { RouteComponentProps } from 'react-router-dom';
import { Table, InputGroup, FormControl, Row, Col, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';
import { Search } from 'react-feather';
import LoadingButton from '../components/LoadingButton';
import { toast } from 'react-toastify';
import { ceresApp } from '../services/firebase';
import SearchPagination from '../components/search/SearchPagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import axios from 'axios';
import { projectId } from '../services/firebase';
import ReactTooltip from 'react-tooltip';

const Transition = React.forwardRef(function transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Section = styled.div`
  width: 90%;
  margin: 60px auto;
`;
const THead = styled.thead`
  background-color: #222;
  color: #fff;
  text-align: center;
  height: 60px;
`;
const TH = styled.th`
  vertical-align: top !important;
`;
const TD = styled.td`
  em {
    font-style: normal;
    font-weight: 700;
  }
`;
const SearchBarInputContainer = styled.form`
  width: 460px;
  margin-left: auto;
  margin-bottom: 20px;
`;
const DownloadTD = styled.td`
  cursor: pointer;
  text-align: center;
  color: var(--blue);
  font-weight: 500;
`;

const doStorageDownload = R.contains(R.__, ['tceq_novell']);
const getStorageRef = R.cond<string, firebase.storage.Storage | null>([
  [R.equals('tceq_novell'), R.always(ceresApp.storage(`gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
  [R.T, R.always(null)],
]);

const CorrectiveTracker: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [onload, setOnload] = useState(true);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [queryText, setQueryText] = useState('');

  const doTextSearch = (text: string = '', from = 0) => {
    setIsLoading(true);
    const requestBody: any = {};
    requestBody.records_num_from = from;
    requestBody.records_num_show = 10;
    requestBody.keyword = text;
    axios
      .get(
        'https://us-central1-' +
          projectId +
          '.cloudfunctions.net/CorrectiveActionTracker?records_num_from=' +
          from +
          '&records_num_show=30&keyword=' +
          text,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        }
      )
      .then((response) => {
        setSearchResult(response.data);
        if (from === 0) {
          setOpen(true);
          setTimeout(handleClose, 4000);
        }
      })
      .finally(() => setIsLoading(false));
  };
  const handleClose = () => {
    setOpen(false);
  };
  // if (onload === true) {
  //   setOnload(false);
  //   doTextSearch(queryText);
  // }

  useEffect(() => {
    if(window.top !== window.self) {
      const query = new URLSearchParams(props.location.search);
      const rn: any = query.get('rn');
      setQueryText(rn);
      doTextSearch(rn);
    } else {
      doTextSearch(queryText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  return (
    <Section>
      <Row>
        <Col>
          <SearchBarInputContainer
            onSubmit={(e) => {
              e.preventDefault();
              doTextSearch(queryText);
            }}
          >
            <InputGroup>
              <FormControl
                onChange={(e: any) => setQueryText(e.target.value)}
                placeholder="Search Company or Entity Number"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={queryText}
              />
              <InputGroup.Append>
                <LoadingButton isLoading={isLoading || false} >
                  <Search />
                </LoadingButton>
              </InputGroup.Append>
            </InputGroup>
          </SearchBarInputContainer>
        </Col>
      </Row>
      {searchResult !== null && (
        <>
          <Table striped bordered hover>
            <THead>
              <tr>
                <TH style={{ width: '10%' }}>Date</TH>
                <TH style={{ width: '10%' }}>Entity Name</TH>
                <TH style={{ width: '10%' }}>Event</TH>
                <TH>Corrective Action</TH>
                <TH style={{ width: '7%' }}>Doc</TH>
              </tr>
            </THead>
            <tbody>
              {searchResult.total_records > 0 &&
                searchResult.data.map((record: any, index: any) => (
                  <tr key={index}>
                    <TD>{record.date}</TD>
                    <TD>
                      <OverlayTrigger
                        placement="bottom"
                        key={index}
                        overlay={<ReactTooltip effect="solid" />}
                      >
                        <span
                          data-tip={
                            record.entity_number
                          }
                        >{record.entity_name}</span>
                      </OverlayTrigger>
                    </TD>
                    <TD>{record.event}</TD>
                    <TD
                      dangerouslySetInnerHTML={{
                        __html: record.corrective_action
                          ? record.corrective_action.replace(`/[^\x00-\x7F]/g`, '')
                          : '<h3>No Preview Available</h3>',
                      }}
                    />
                    <OverlayTrigger
                        placement="bottom"
                        key={index}
                        overlay={<ReactTooltip effect="solid" />}
                      >
                    <DownloadTD
                      onClick={() => {
                        if (doStorageDownload(record.doc_url)) {
                          const storageRef = getStorageRef(record.entitySource);
                          if (R.isNil(storageRef)) {
                            return toast.error(`Oops, could not find the file.`);
                          }
                          setDownloadTriggers(R.append(index + 1, downloadTriggers));
                          return storageRef
                            .ref(record.fileName)
                            .getDownloadURL()
                            .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                            .catch((err) => toast.error(err.message))
                            .finally(() => setDownloadTriggers(R.without([index + 1], downloadTriggers)));
                        }
                        if (!record.doc_url) {
                          return toast.warn(
                            <span>
                              No associated file was found for the content id <b>{record.contentId}</b>
                            </span>
                          );
                        }
                        window.open(record.doc_url, '_blank');
                      }}
                    >
                     <span data-tip="Click to download">Link</span>
                    </DownloadTD>
                    </OverlayTrigger>
                  </tr>
                ))}
            </tbody>
          </Table>
          <SearchPagination
            isDisabled={false}
            isFirstPage={(searchResult && searchResult.records_num_from === 0) || false}
            isLastPage={
              (searchResult &&
                searchResult.records_num_from + searchResult.records_num_show >= searchResult.total_records) ||
              false
            }
            totalRecords={(searchResult && searchResult.total_records) || 0}
            onPreviousClick={() => {
              if (!searchResult) {
                return;
              }
              const currentFrom = searchResult.records_num_from;
              const currentSize = searchResult.records_num_show;
              setIsLoading(true);
              return doTextSearch(queryText, currentFrom - currentSize);
            }}
            onNextClick={() => {
              if (!searchResult) {
                return;
              }
              const currentFrom = searchResult.records_num_from;
              const currentSize = searchResult.records_num_show;
              return doTextSearch(queryText, currentFrom + currentSize);
            }}
          />
        </>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img src="/icon/action-tracker.png" alt="Satellite App" width="220" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Section>
  );
};

export default CorrectiveTracker;
