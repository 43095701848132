import React from 'react';
import styled from 'styled-components';

interface ISidebarOverlayProps {
  isActive: boolean;
  onClick: () => void;
}

interface ISidebarOverlayContainer extends ISidebarOverlayProps {}

const SidebarOverlayContainer = styled.div<ISidebarOverlayContainer>`
  background: rgba(0, 95, 188, 0.3);
  z-index: 99;
  position: fixed;
  height: 100vh;
  width: 100vw;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;

const SidebarOverlay: React.FC<ISidebarOverlayProps> = (props: ISidebarOverlayProps) => (
  <SidebarOverlayContainer isActive={props.isActive} onClick={props.onClick} />
);

export default SidebarOverlay;
