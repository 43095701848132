import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Table } from 'react-bootstrap';

interface IEmissions {
  result: any;
  entity: any;
}

let emissionEvents = {};
let quantityReleased = {};
let dataOptions = {};

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
`;
const TableContainer = styled.div`
  margin-top: 1rem;
`;
const TD = styled.td`
  font-size: 11px;
  text-align: center;
`;
const TDR = styled(TD)`
  text-align: right;
`;
const TDL = styled(TD)`
  text-align: left;
`;
const TH = styled.th`
  font-size: 13px;
  text-align: center;
`;
const TableHeading = styled.div`
  display: flex;
  justify-content: center;
`;

const EmissionsEvent: React.FC<IEmissions> = (props) => {
  const statsResult = props.result ;
  const entity = props.entity;
  if (statsResult) {
    const eventsLabels = statsResult.events_graph.map((ele: any) => {
      return ele.year;
    });
    const eventsData = statsResult.events_graph.map((ele: any) => {
      return ele.events;
    });
    const quantityLabels = statsResult.compounds_graph.map((ele: any) => {
      return ele.year;
    });
    const quantityData = statsResult.compounds_graph.map((ele: any) => {
      return ele.pounds;
    });
    emissionEvents = {
      labels: eventsLabels,
      datasets: [
        {
          label: 'Emission Events',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: eventsData
        }
      ]
    };
    quantityReleased = {
      labels: quantityLabels,
      datasets: [
        {
          label: 'Quantity Released(pounds)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(244,134,12,0.4)',
          borderColor: 'rgba(244,134,12,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(244,134,12,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(244,134,12,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: quantityData
        }
      ]
    };
    dataOptions = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (value: any, text: any) => {
            const xLabel = text.datasets[value.datasetIndex].label;
            const yLabel = value.yLabel
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return xLabel + ': ' + yLabel;
          }
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value: any) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
          }
        ]
      }
    };
  }
  return (
    <ResultContainer>
      <Row>
        <Col md={6}>
          <Line data={emissionEvents} options={dataOptions} />
        </Col>
        <Col md={6}>
          <Line data={quantityReleased} options={dataOptions} />
        </Col>
      </Row>
      <TableContainer>
        <Row>
          <Col md={6}>
            <TableHeading>
              <h6>Rank of Emission Events</h6>
            </TableHeading>
            <Table bordered hover>
              <thead>
                <tr>
                  <TH>Rank</TH>
                  <TH>Facility</TH>
                  <TH>Events</TH>
                </tr>
              </thead>
              <tbody>
                {statsResult.events_table.map((ele: any) => {
                  return (
                    <tr key={'Events' + ele.rn}>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>{ele.rank}</b>
                        ) : (
                          ele.rank
                        )}
                      </TD>
                      <TDL>
                        {entity.value === ele.rn ? (
                          <b>{ele.name}</b>
                        ) : (
                          ele.name
                        )}
                      </TDL>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>
                            {ele.event.toLocaleString(
                              navigator.language,
                              { minimumFractionDigits: 0 }
                            )}
                          </b>
                        ) : (
                          ele.event.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0
                          })
                        )}
                      </TD>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
          <Col md={6}>
            <TableHeading>
              <h6>Rank of Quantity Released</h6>
            </TableHeading>
            <Table bordered hover>
              <thead>
                <tr>
                  <TH>Rank</TH>
                  <TH>Facility</TH>
                  <TH>Pounds</TH>
                </tr>
              </thead>
              <tbody>
                {statsResult.compounds_table.map((ele: any) => {
                  return (
                    <tr key={'Pounds' + ele.rn}>
                      <TD>
                        {entity.value === ele.rn ? (
                          <b>{ele.rank}</b>
                        ) : (
                          ele.rank
                        )}
                      </TD>
                      <TDL>
                        {entity.value === ele.rn ? (
                          <b>{ele.name}</b>
                        ) : (
                          ele.name
                        )}
                      </TDL>
                      <TDR>
                        {entity.value === ele.rn ? (
                          <b>
                            {ele.pounds.toLocaleString(
                              navigator.language,
                              { minimumFractionDigits: 2 }
                            )}
                          </b>
                        ) : (
                          ele.pounds.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 2 }
                          )
                        )}
                      </TDR>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </TableContainer>
    </ResultContainer>
  );
};

export default EmissionsEvent;
