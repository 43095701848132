import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { ISearchRecord } from '../../../types/search';

interface IAirEventTable {
  events: any[];
  record?: ISearchRecord;

}

const TD = styled.td`
  font-size: 11px;
  text-align: center;
`;

const TH = styled.th`
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
  padding: 3px !important;
  vertical-align: middle !important;
  background: #e8e8e8;
  position: sticky;
  top: -1px;
  z-index: 10;
`;

const DateTD = styled.td`
  font-size: 11px;
  white-space: nowrap;
`;

const CauseTD = styled.td`
  font-size: 11px;
  div {
    max-height: 4.5em;
    overflow: hidden;
  }
`;

const AirEventTable: React.FC<IAirEventTable> = props => {
  const { events, record } = props;
  const url = 'https://www2.tceq.texas.gov/oce/eer/index.cfm?fuseaction=main.getDetails&target=';
  if (!events) {
    return null;
  }
  return (
    <Scrollbars
      style={{
        height: 'calc(100vh - 325px)',
      }}
    >
      <Table>
        <thead>
          <tr>
            <TH>Date</TH>
            <TH>{record && record.entityNumber.toLowerCase().includes('ldeq') ? 'Function' : 'Duration'}</TH>
            <TH>{record && record.entityNumber.toLowerCase().includes('ldeq') ? 'Description' : 'Cause'}</TH>
            {record && record.entityNumber.toLowerCase().includes('ldeq') ?
            <TH>Type</TH>
            :
            <TH>Total Amount <br/>Released</TH>
            }
            <TH>More <br/> Details</TH>
          </tr>
        </thead>
        <tbody>
          {events.map(({ date, cause, duration, action, amount, incident_id, incident_url }) => (
            <tr key={`air-event-${incident_id}`}>
              <DateTD>{date}</DateTD>
              <TD>{duration}</TD>
              <CauseTD>
                <div>{cause}</div>
              </CauseTD>
              {record && record.entityNumber.toLowerCase().includes('ldeq') ?
              <TD>{action}</TD>
              :
              <TD>{amount}</TD>
               }
              <TD>
                <a href={record && record.entityNumber.toLowerCase().includes('ldeq') ? incident_url :url + incident_id} target="_blank" rel="noopener noreferrer">
                  {incident_id}
                </a>
              </TD>
            </tr>
          ))}
        </tbody>
      </Table>
    </Scrollbars>
  );
};

export default AirEventTable;
