import React, { useState, Fragment } from 'react';
import { Button, Navbar, Form } from 'react-bootstrap';
import { ChevronDown, MapPin, BarChart2, Cloud, CheckSquare, Smile, Bell, Users } from 'react-feather';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import mediaQuery from '../util/media-query';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import EnviroAILogo from '../assets/enviroai_new.svg';
import ProjectContext, { IProjectContext } from '../context/project/project-context';
import BellOverlay from './notifications/BellOverlay';
import { ISearch } from '../store/aiearth/types';

const HeaderContainer = styled.div<{ isSettings: any }>`
  width: 140px;
  position: fixed;
  top: 10px;
  left: 20px;
  z-index: 101;
`;

const BrandContainer = styled(Navbar.Brand)`
  margin-left: 0;
  width: 42px;
  span {
    color: ${({ props: { isAIAir } }) => (isAIAir ? `purple` : `#005FBC`)};
    color: ${({ props: { isAIAir } }) => (isAIAir ? `purple` : `var(--primary)`)};
    font-weight: 600;
    font-size: 25px;
    vertical-align: middle;
    & svg {
      margin-bottom: 5px;
    }
  }
`;
const SearchContainer = styled.div`
  display: flex;
  ${mediaQuery.phone`
    display: none
  `}
`;

const HamburgerMenuActionButton = styled.div<{ isSearch: boolean }>`
  color: #005fbc;
  color: var(--primary);
  line-height: 40px;
  height: ${(props) => (props.isSearch ? 'auto' : '40px')};
  margin: 0px;
  cursor: pointer;
  user-select: none;
  width: ${(props) => (props.isSearch ? 'auto' : '42px')};
  float: left;

  &.hideHamburger {
    display: none;
  }
`;

const ProjectSelectorContainer = styled.div`
  margin: 0 2em;
`;

const ProjectSelectorButton = styled(Button)`
  svg {
    margin-left: 12px;
  }
`;

const SearchFormContainer = styled(Form)`
  margin-top: 0.65em;
`;

const PlatinumAccess = styled.div`
  text-align: center;
  && img {
    width: 90%;
  }
`;
const MenuIcon = styled(ViewHeadlineIcon)<{ theme: string }>`
  color: ${(props) => props.theme};
`;
const HeaderLogo = styled.div`
  text-align: center;
  margin: 6px 10px;
  > img {
    width: 100%;
  }
  & span {
    font-size: 16px;
  }
`;
const Menubutton = styled.div`
  position: absolute;
  left: 40px;
  top: 0px;
  color: white;
  & p {
    font-size: 25px;
  }
`;

interface IHeaderProps {
  isHomePage: boolean;
  isSearch: boolean;
  isAIEarth: boolean;
  isAIEarthAir: boolean;
  isAIAir: boolean;
  isBenchmarking: boolean;
  isTrackbot: boolean;
  isPeopleFinder: boolean;
  isSentiment: boolean;
  isCorrectiveTracker: boolean;
  isGenerator: boolean;
  isStore: boolean;
  getSearchData: any;
  setSearchData: any;
  history: any;
  onHamburgerClick: () => void;
  onProjectSwitcherClick: () => void;
  searchState: ISearch;
}

const Header: React.FC<IHeaderProps & { context: IAuthContext }> = (props) => {
  const context = props.context;
  const isAuthenticated = !!(context.user && context.user.uid);
  const [notificationPopupState, setNotificationPopupState] = useState({
    show: false,
    target: null,
  });

  return (
    <HeaderContainer isSettings={props}>
      <HamburgerMenuActionButton
        onClick={() => props.onHamburgerClick()}
        isSearch={props.searchState && props.searchState.isSearch}
        data-tour="ceres-third-step"
        className={ window.top !== window.self ? 'hideHamburger' : ''}
      >
        {props.searchState && props.searchState.isSearch && (props.searchState.type !== 'default' && props.history.location.pathname === '/search') ? (
          <HeaderLogo>
            <img src={EnviroAILogo} alt="Enviro Logo" />
            {props.searchState.customSearchType === 'environmental' && <span>ENVIRONMENTAL</span>}
          </HeaderLogo>
        ) : (
          <MenuIcon
            fontSize="large"
            theme={
              (props.history && props.history.location.pathname !== '/search' && props.history.location.pathname !== '/my-tundra' ) ||
              (props.searchState && props.searchState.isSearch)
                ? '#CBDFF8'
                : '#fff'
            }
          />
        )}
        {props.isSearch && ! props.searchState.isSearch &&(
          <Menubutton>
            <p>Menu</p>
            </Menubutton>
        )
        }
      </HamburgerMenuActionButton>
      {!props.isHomePage && (
        <BrandContainer props={props} href="/" rel="noreferrer">
          {props.isAIEarth && (
            <span>
              <Fragment>
                <MapPin /> AI Earth
              </Fragment>
            </span>
          )}
          {props.isAIEarthAir && (
            <span>
              <Fragment>
                <Cloud size={32} /> AI Air
              </Fragment>
            </span>
          )}
          {props.isAIAir && (
            <span>
              <Fragment>
                <Cloud /> AI Air
              </Fragment>
            </span>
          )}

          {props.isBenchmarking && (
            <span>
              <Fragment>
                <BarChart2 /> Benchmarking
              </Fragment>
            </span>
          )}
          {props.isCorrectiveTracker && (
            <span>
              <Fragment>
                <CheckSquare size={36} /> Corrective Action Tracker
              </Fragment>
            </span>
          )}
          {props.isGenerator && (
            <span>
              <Fragment>
                <img src="/icon/generator_logo.png" alt="Generator" width="30" /> Generator
              </Fragment>
            </span>
          )}
          {props.isStore && (
            <span style={{ fontSize: '36px' }}>
              <Fragment>The Store</Fragment>
            </span>
          )}
          {props.isTrackbot && (
            <span>
              <Fragment>
                <Bell /> TrackBot
              </Fragment>
            </span>
          )}
          {props.isPeopleFinder && (
            <span>
              <Fragment>
                <Users /> People Finder
              </Fragment>
            </span>
          )}
          {props.isSentiment && (
            <span>
              <Fragment>
                <Smile /> Sentiment
              </Fragment>
            </span>
          )}
        </BrandContainer>
      )}
      {false && isAuthenticated && (
        <ProjectContext.Consumer>
          {(projectContext: IProjectContext) => {
            return (
              <ProjectSelectorContainer>
                <ProjectSelectorButton onClick={() => props.onProjectSwitcherClick()} variant="outline-secondary">
                  <span>
                    {projectContext.activeProject && projectContext.activeJob
                      ? `${projectContext.activeProject.name} - ${projectContext.activeJob.type}`
                      : `Select a project`}
                  </span>
                  <ChevronDown />
                </ProjectSelectorButton>
              </ProjectSelectorContainer>
            );
          }}
        </ProjectContext.Consumer>
      )}

      <div className="mr-auto" />
      {isAuthenticated && !props.isSearch && !props.isStore && (
        <SearchContainer>
          <SearchFormContainer
            onSubmit={(event: React.FormEvent) => {
              event.preventDefault();
            }}
          ></SearchFormContainer>
        </SearchContainer>
      )}

      {props.isStore && (
        <PlatinumAccess>
          <Link to="/store/platinum-access">
            <img src="/platinum-access.jpg" alt="Platinum Access" />
          </Link>
        </PlatinumAccess>
      )}
      <BellOverlay
        show={notificationPopupState.show}
        target={notificationPopupState.target}
        onHide={() =>
          setNotificationPopupState({
            show: false,
            target: null,
          })
        }
      />
    </HeaderContainer>
  );
};

export default withAuthContext(Header);
