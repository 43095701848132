import * as R from 'ramda';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { IMap } from '../../../store/aiearth/types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { updateMap } from '../../../store/aiearth/action';

const MapPlacesContainer = styled.div`
  width: 20rem;
  margin-left: 0.5rem;
  background-color: #a3bcf1;
  border-radius: 4px;
`;

// tslint:disable-next-line: prefer-const
let autocompleteService: any = { current: null };
const PlacesSearch: React.FC<any> = (props) => {
  const { gMaps } = props;
  const [value, setValue] = useState<any>(props.value ? props.value : '');
  const [inputValue, setInputValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const mapStore: any = useSelector<any>((state): any => state);
  const dispatch = useDispatch();
  const fetch = useMemo(
    () =>
      throttle((request: any, callback: any) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && gMaps) {
      autocompleteService.current = new gMaps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results: any) => {
      if (active) {
        let newOptions: any = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, gMaps]);

  const getCity = (geocodingResult: any) => {
    const cityComponent = R.find((addressComponents: any) => {
      return R.includes('locality', addressComponents.types);
    }, geocodingResult.address_components || []);
    return (cityComponent && cityComponent.long_name) || '';
  };
  const getZipCode = (geocodingResult: any) => {
    const zipCodeComponent = R.find((addressComponents: any) => {
      return R.includes('postal_code', addressComponents.types);
    }, geocodingResult.address_components || []);
    return (zipCodeComponent && zipCodeComponent.long_name) || '';
  };
  const getState = (geocodingResult: any) => {
    const stateComponent = R.find((addressComponents: any) => {
      return R.includes('administrative_area_level_1', addressComponents.types);
    }, geocodingResult.address_components || []);
    return (stateComponent && stateComponent.short_name) || '';
  };

  return (
    <>
      <MapPlacesContainer>
        <Autocomplete
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          openOnFocus={true}
          onChange={(event: any, newValue: any) => {
            const geocoder = new props.gMaps.Geocoder();
            if (newValue && newValue.place_id) {
              geocoder.geocode({ placeId: newValue.place_id }, (results: any, status: any) => {
                if (status === 'OK') {
                  props.gMap.setCenter(results[0].geometry.location);
                  props.gMap.setZoom(18);
                  props.gMap.panTo(results[0].geometry.location);

                  const mapState: IMap = mapStore.map;
                  mapState.places = newValue;
                  mapState.city = getCity(results[0]);
                  mapState.zip = getZipCode(results[0]);
                  mapState.state = getState(results[0]);
                  mapState.address = newValue.description
                    ? newValue.description.substring(0, newValue.description.lastIndexOf(getCity(results[0])) - 2)
                    : '';
                  dispatch(updateMap(mapState));
                } else {
                  alert('Geocode was not successful for the following reason: ' + status);
                }
              });
            }
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <>
              <TextField {...params} autoFocus placeholder="Search for a place" size="small" variant="outlined" />
            </>
          )}
          renderOption={(option: any) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length])
            );
            return (
              <Grid container alignItems="center">
                <Grid item></Grid>
                <Grid item xs>
                  {parts.map((part: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </MapPlacesContainer>
    </>
  );
};

export default PlacesSearch;
