import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ceresHome from '../assets/ceres-home.png';
import { Link, RouteComponentProps } from 'react-router-dom';

const PageWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

const PageTitle = styled.h1`
  display: block;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 0;
`;

const Paragraph = styled.p`
  display: block;
  padding: 10px 0;
  font-size: 18px;
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
`;

const PageTutorial: React.FC<{
  routerProps: RouteComponentProps<any>;
}> = (props) => {
  return (
    <PageWrapper>
      <Container>
        <Row>
          <Col>
            <PageTitle>Tutorial</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paragraph>
              CERES is a powerful search engine for environmental documents. To use CERES, navigate to{' '}
              <Link to="/">ceres.enviro.ai</Link>. Follow the prompts to log in or create an account. After logging in,
              you will see a page that looks like this:
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col>
            <ResponsiveImage src={ceresHome} alt={'Ceres Home'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Paragraph>
              You can see a text box in the middle of the page. In this text box, you can type any search term that
              interests you. When you press "Enter" or click the search button on the right side of the text box, you
              will see search results appear that are related to your search term.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paragraph>
              To the right of the search button, you can see a "filter" button. You can click that filter button to
              filter your search results by date, by location, or by regulatory ID.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paragraph>
              <a
                href="/"
                onClick={(e) => {
                  e.stopPropagation();
                  localStorage.setItem('walkthrough', 'true');
                  props.routerProps.history.push('/');
                }}
              >
                Take a Tour
              </a>
            </Paragraph>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default PageTutorial;
