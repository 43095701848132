import React, { useState } from 'react';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import styled from 'styled-components';
import VerticalCenter from '../../components/VerticalCenter';

const Label = styled<any>(Form.Label)`
  margin-bottom: 2rem;
`;

const ProjectName = styled.div`
  width: 500px;
  display: flex;
  margin: 0 auto;
  align-items: center;
`;

const FullWidthVerticalCenter = styled(VerticalCenter)`
  flex: 1;
  display: flex;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  svg {
    width: 40px;
    height: 40px;
  }
`;

interface INameWizardStep {
  onComplete: (projectName: string) => void;
  projectName?: string | null;
}

const NameWizardStep: React.FC<INameWizardStep> = props => {
  const [projectName, setProjectName] = useState(props.projectName);
  return (
    <FullWidthVerticalCenter>
      <Form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          props.onComplete(projectName || '');
        }}
      >
        <FormGroup>
          <Label>What's the name of the project?</Label>
          <ProjectName>
            <FormControl
              onChange={(e: any) => setProjectName(e.target.value)}
              value={projectName || ''}
              type="text"
              placeholder="My Project ..."
              required
            />
            <SubmitButton type="submit" variant="link">
              <ChevronRight />
            </SubmitButton>
          </ProjectName>
        </FormGroup>
      </Form>
    </FullWidthVerticalCenter>
  );
};

export default NameWizardStep;
