import * as R from 'ramda';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IJob } from '../../store/aiearth/types';
import MUIButton from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Table } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { updateJob } from '../../store/aiearth/action';
import { v4 as uuidv4 } from 'uuid';

const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  & * {
    margin-left: 2px;
  }
`;

const JobButton = styled(MUIButton)`
  margin: 0.2rem !important;
`;

const JobsIdentifyUsers: React.FC<any> = (props) => {
  const jobsIdentifyUsersInitial = {
    id: null,
    name: '',
    email: '',
    phone: '',
  };

  const [jobIdentifyUsers, setJobIdentifyUsers] = useState(jobsIdentifyUsersInitial);
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const jobState: IJob = store.job;

  return (
    <>
      <CustomForm>
        <Row>
          <TextField
            label="Name"
            autoFocus
            id="name"
            fullWidth
            variant="outlined"
            size="small"
            value={jobIdentifyUsers.name}
            onChange={(e: any) => {
              setJobIdentifyUsers({
                ...jobIdentifyUsers,
                name: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            id="email"
            label="Email"
            fullWidth
            variant="outlined"
            size="small"
            value={jobIdentifyUsers.email}
            onChange={(e: any) => {
              setJobIdentifyUsers({
                ...jobIdentifyUsers,
                email: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <TextField
            label="Phone"
            id="phone"
            variant="outlined"
            fullWidth
            size="small"
            value={jobIdentifyUsers.phone}
            onChange={(e: any) => {
              setJobIdentifyUsers({
                ...jobIdentifyUsers,
                phone: e.target.value,
              });
            }}
          />
        </Row>
        <Row>
          <JobButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => {
              const usersArray: any = jobState.activeJob.users;
              if (jobIdentifyUsers && jobIdentifyUsers.id) {
                const index = R.findIndex(R.propEq('id', jobIdentifyUsers.id))(usersArray);
                usersArray[index] = jobIdentifyUsers;
              } else {
                usersArray.push({
                  id: uuidv4(),
                  name: jobIdentifyUsers.name,
                  email: jobIdentifyUsers.email,
                  phone: jobIdentifyUsers.phone,
                });
              }
              const activeJobObj = jobState.activeJob;
              activeJobObj.users = usersArray;
              dispatch(
                updateJob({
                  ...jobState,
                  ...{
                    activeJob: activeJobObj,
                  },
                })
              );
              setJobIdentifyUsers(jobsIdentifyUsersInitial);
            }}
          >
            {jobIdentifyUsers.id ? 'Save' : 'Add'}
          </JobButton>
        </Row>
        <Table bordered hover>
          <tbody>
            {jobState.activeJob.users.length > 0 &&
              jobState.activeJob.users.map((property: any, index: any) => {
                return (
                  <tr key={'Users' + index}>
                    <td>{property.name}</td>
                    <td>{property.email}</td>
                    <td>
                      <JobButton
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setJobIdentifyUsers(property);
                        }}
                      />
                      <JobButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          const usersArray: any = jobState.activeJob.users;
                          const deleteIndex = R.findIndex(R.propEq('id', property.id))(usersArray);
                          usersArray.splice(deleteIndex, 1);
                          const activeJobUsersObj = jobState.activeJob;
                          activeJobUsersObj.users = usersArray;
                          dispatch(
                            updateJob({
                              ...jobState,
                              ...{
                                activeJob: activeJobUsersObj,
                              },
                            })
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CustomForm>
    </>
  );
};

export default JobsIdentifyUsers;
