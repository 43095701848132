import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

const PageContainer = styled.div`
  display: block;
  width: 80%;
  position: relative;
  margin: 0 auto;
  padding-top: 45px;
`;

const PageTitle = styled.h1`
  display: block;
  font-size: 28px;
  text-align: center;
`;

const ParagraphContainer = styled.div`
  display: block;
  padding: 30px 0;
  width: 100%;
  margin: 0 auto;
`;

const Paragraph = styled.p`
  display: block;
  padding: 5px 0;
  font-size: 16px;
  text-align: justify;
  .text-uppercase {
    text-transform: uppercase;
  }
`;

const PageTermsConditions: React.FC = () => {
    return (
        <PageWrapper>
            <PageContainer>
                <PageTitle>Ceres A.I. Terms and Conditions</PageTitle>
                <ParagraphContainer>
                    <Paragraph className={'text-capitalize'}>
                        YOUR USE OF THIS WEB SITE CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THESE
                        TERMS AND CONDITIONS OF USE.
                    </Paragraph>
                    <Paragraph>
                        This web site, including all of its features and content (this "Web Site") is a service made
                        available by Ceres A.I. LLC or its affiliates ("Provider") and all content and information
                        provided on or through this Web Site ("Content") may be used solely under the following
                        terms and conditions ("Terms of Use").    Use of any product, data, service or software is
                        subject to the terms & conditions of your services agreement with Provider.
                    </Paragraph>
                    <Paragraph>
                        1. Web Site Limited License. As a user of this Web Site you are granted a nonexclusive,
                        nontransferable, revocable, limited license to access and use this Web Site and Content
                        in accordance with these Terms of Use. Provider may terminate this license at any time
                        for any reason.
                    </Paragraph>
                    <Paragraph>
                        2. Limitations on Use; Third Party Communications and Web Sites.
                    </Paragraph>
                    <Paragraph>
                        2.1. Limitations on Use. The Content on this Web Site is for your personal use only and
                        not for commercial exploitation. Notwithstanding the foregoing and to the extent this Web
                        Site provides electronic commerce, such buying opportunities may be made available for
                        group as well as personal purchasing, so long as you are authorized to make purchases
                        on behalf of such group. You may not decompile, reverse engineer, disassemble, rent,
                        lease, loan, sell, sublicense, or create derivative works from this Web Site or the
                        Content, nor may you use any network monitoring or discovery software to determine
                        the site architecture or extract information about usage, individual identities or
                        users. You must retain all copyright and other proprietary notices contained on the
                        materials delivered herein. The content of the Site cannot be used, posted, sold,
                        transmitted, distributed, modified or transferred for public or commercial purposes,
                        without the prior written permission of Ceres A.I. LLC. You may not use any robot,
                        spider, other automatic software or device, or manual process to monitor or copy our
                        Web Site or the Content without Provider's prior written permission. You may not use
                        this Web Site to transmit any false, misleading, fraudulent or illegal communications.
                        You may not copy, modify, reproduce, republish, distribute, display, or transmit for
                        commercial, non-profit or public purposes all or any portion of this Web Site, except
                        to the extent permitted above. You may not use or otherwise export or re-export this
                        Web Site or any portion thereof, or the Content in violation of the export control
                        laws and regulations of the United States of America. Any unauthorized use of this
                        Web Site or its Content is prohibited.
                    </Paragraph>
                    <Paragraph>
                        2.2. Third Party Communications, Web Sites and Content. Provider disclaims all liability
                        for any Third Party Communications you may receive or any actions you may take or refrain
                        from taking as a result of any Third Party Communications. You are solely responsible for
                        assessing and verifying the identity and trustworthiness of the source and content of any
                        Third Party Communications. Provider assumes no responsibility for verifying, and makes no
                        representations or warranties regarding, the identity or trustworthiness of the source or
                        content of any Third Party Communications. As used herein, "Third Party Communications"
                        means any communications directed to you from any third party directly or indirectly in
                        connection with this Web Site.  Provider makes no representations whatsoever about any
                        other web sites, which may be accessed through this Web Site. Any such non-Provider web
                        sites are independent of Provider and Provider has no control over the content on such web
                        site. In addition, a link to a non-Provider web site does not constitute an endorsement of
                        such web site by Provider or an acceptance of responsibility by Provider for the content,
                        or the use, of such web site.  Third party content (including, without limitation, Postings)
                        may appear on this Web Site or may be accessible via links from this Web Site. Provider
                        shall not be responsible for and assumes no liability for any infringement, mistakes,
                        misstatements of law, defamation, slander, libel, omissions, falsehood, obscenity,
                        pornography or profanity in the statements, opinions, representations or any other
                        form of content contained in any third party content appearing on this Web Site.
                        You understand that the information and opinions in the third party content is
                        neither endorsed by nor does it reflect the belief or opinion of Provider. Further,
                        information and opinions provided by employees and agents of Provider in Interactive
                        Areas are not necessarily endorsed by Provider and do not necessarily represent
                        the beliefs and opinions of Provider.
                    </Paragraph>
                    <Paragraph>
                        3. Not Legal Advice. Content is not intended to and does not constitute legal advice
                        and no attorney-client relationship is formed, nor is anything submitted to this Web
                        Site treated as confidential. The accuracy, completeness, adequacy or currency of
                        the Content is not warranted or guaranteed. Your use of Content on this Web Site
                        or materials linked from this Web Site is at your own risk.
                    </Paragraph>
                    <Paragraph>
                        4. Intellectual Property Rights.
                    </Paragraph>
                    <Paragraph>
                        4.1 Except as expressly provided in these Terms of Use, nothing contained herein shall
                        be construed as conferring on you or any third party any license or right, by implication,
                        estoppel or otherwise, under any law (whether common law or statutory law), rule or
                        regulation including, without limitation those related to copyright or other
                        intellectual property rights. You agree that the Content and Web Site are protected by
                        copyrights, trademarks, service marks, patents or other proprietary rights and laws.
                        The trademarks, logos, and service marks (collectively the "Marks") appearing on this
                        Web Site are registered and unregistered Marks of Provider and others. Use of this
                        Web Site and the information contained in no way constitutes the granting by Provider
                        of any license or right to use any Marks displayed on this Web Site. You are strictly
                        prohibited from using the Marks displayed on this Web Site or any other content on this
                        Web Site, except as provided in these Legal Notices.
                    </Paragraph>
                    <Paragraph>
                        4.2 This Web site contains interactive areas which includes, without limitation, any blogs,
                        wikis, bulletin boards, discussion boards, chat rooms, email forums, and question and
                        answer features (the "Interactive Areas"). You grant to Provider an irrevocable,
                        non-exclusive, royalty-free, perpetual, worldwide license to use, authorize use
                        of and have used on its behalf any ideas, expression of ideas, text, graphics,
                        messages, blogs, links, data, information and other materials you submit
                        (collectively, "Postings") to this Web Site. Said license is without restrictions
                        of any kind and without any payment due from Provider to you or permission or
                        notification, to you or any third party. The license includes, the right to make,
                        use, sell, reproduce, publish, modify, adapt, prepare derivative works from,
                        combine with other works, translate, distribute, display, perform and sublicense
                        Postings in any form, medium, or technology now known or hereafter developed.
                    </Paragraph>
                    <Paragraph>
                        4.3. You certify and warrant that the Postings: (i) are your original works or that
                        the owner of such works has expressly granted to Provider a perpetual worldwide
                        royalty-free irrevocable, non-exclusive license for said works with all of the rights
                        granted by you in section 4.2 of these Terms of Use and (ii) do not violate and will
                        not violate the rights of any third party including any right of publicity,
                        right of privacy, copyright, patent or other intellectual property right or
                        any proprietary right.
                    </Paragraph>
                    <Paragraph>
                        4.4. You acknowledge and agree that your submitting Postings to this Web Site does not
                        create any new or alter any existing relationship between you and Provider.
                    </Paragraph>
                    <Paragraph>
                        4.5. By submitting Postings to this Web Site, you acknowledge and agree that Provider
                        may create on its own ideas that may be, or may obtain submissions that may be, similar
                        or identical to Postings you submit. You agree that you shall have no recourse against
                        Provider for any alleged or actual infringement or misappropriation of any proprietary
                        or other right in the Postings you provide to Provider.
                    </Paragraph>
                    <Paragraph>
                        5. Digital Millennium Copyright Act - Notification of Alleged Copyright Infringement.
                        Provider avails itself of the protections under the Act. Provider reserves the right
                        to remove any Content that allegedly infringes another person's copyright. Provider
                        will terminate, in appropriate circumstances, subscribers and account holders of
                        Provider's system or network who are repeat infringers of another person's copyright.
                    </Paragraph>
                    <Paragraph>
                        6. Linking to this Web Site. You may provide links to this Web Site, provided that (a)
                        you do not remove or obscure, by framing or otherwise, any portion of this Web Site,
                        including any advertisements, terms of use, copyright notice, and other notices
                        on this Web Site, (b) you immediately deactivate and discontinue providing links
                        to this Web Site if requested by Provider, and (c) Provider may deactivate any
                        link(s) at its discretion.
                    </Paragraph>
                    <Paragraph>
                        7. No Solicitation. You shall not distribute on or through this Web Site any
                        Postings containing any advertising, promotion, solicitation for goods,
                        services or funds or solicitation for others to become members of any
                        enterprise or organization without the express written permission of Provider.
                        Notwithstanding the foregoing, in any interactive areas of this Web Site,
                        where appropriate you a) may list along with your name, address and email address,
                        your own web site's URL and b) may recommend third party web sites, goods or
                        services so long as you have no financial interest in and receive no direct or
                        indirect benefit from such recommended web site, product or service or its recommendation.
                        In no event may any person or entity solicit anyone with data retrieved from this Web Site.
                    </Paragraph>
                    <Paragraph>
                        8. Advertisers. This Web Site may contain advertising and sponsorship. Advertisers and
                        sponsors are responsible for ensuring that material submitted for inclusion on this Web
                        Site is accurate and complies with applicable laws. Provider will not be responsible for
                        the illegality of or any error or inaccuracy in advertisers' or sponsors' materials or
                        for the acts or omissions of advertisers and sponsors.
                    </Paragraph>
                    <Paragraph>
                        9. Registration. Certain sections of this Web Site require you to register.
                        If registration is requested, you agree to provide accurate and complete registration
                        information. It is your responsibility to inform Provider of any changes to that
                        information. Each registration is for a single individual only, unless specifically
                        designated otherwise on the registration page. Provider does not permit a) anyone
                        other than you to use the sections requiring registration by using your name or
                        password; or b) access through a single name being made available to multiple
                        users on a network or otherwise. You are responsible for preventing such unauthorized use.
                    </Paragraph>
                    <Paragraph>
                        10. Postings in Interactive Areas of this Web Site.
                    </Paragraph>
                    <Paragraph>
                        10.1. Postings to be Lawful. If you participate in Interactive Areas on this Web Site,
                        you shall not post, publish, upload or distribute any Postings which are unlawful or
                        abusive in any way, including, but not limited to, any Postings that are defamatory,
                        libelous, pornographic, obscene, threatening, invasive of privacy or publicity rights,
                        inclusive of hate speech, or would constitute or encourage a criminal offense,
                        violate the rights of any party, or give rise to liability or violate any local,
                        state, federal or international law, or the regulations of the U.S. Securities and
                        Exchange Commission, any rules of any securities exchange such as the New York
                        Stock Exchange, the American Stock Exchange, or the NASDAQ, either intentionally or
                        unintentionally. Provider may delete your Postings at any time for any reason without
                        permission from you.
                    </Paragraph>
                    <Paragraph>
                        10.2. Postings to be in Your Name. Your Postings shall be accompanied by your real
                        name and shall not be posted anonymously. Notwithstanding the previous sentence,
                        if the applicable registration page for your participation in any of the Interactive
                        Areas allows you to create a screen name, you may also select and use an appropriate
                        screen name that is not your real name, provided that you use your real name when
                        registering for participation in the Interactive Area and attorneys agree their
                        real name may always be posted. Participants in Interactive Areas shall not misrepresent
                        their identity or their affiliation with any person or entity.
                    </Paragraph>
                    <Paragraph>
                        10.3. Postings shall not contain protected health information. You are strictly
                        prohibited from submitting Postings that are considered protected health information
                        under the Health Accountability and Portability Protection Act of 1996 (HIPAA) or the
                        Health Information Technology for Economic and Clinical Health Act of 2009 (HITECH).
                    </Paragraph>
                    <Paragraph>
                        10.4. No Monitoring of Postings. Provider has no obligation to monitor or screen Postings
                        and is not responsible for the content in such Postings or any content linked to or from
                        such Postings. Provider however reserves the right, in its sole discretion, to monitor
                        Interactive Areas, screen Postings, edit Postings, cause Postings not to be posted,
                        published, uploaded or distributed, and remove Postings, at any time and for any reason
                        or no reason.
                    </Paragraph>
                    <Paragraph>
                        10.5. Non-Commercial Use only of Interactive Areas. Interactive Areas are provided
                        solely for your personal use. Any unauthorized use of the Interactive Areas of this
                        Web Site, its Content, or Postings is expressly prohibited.
                    </Paragraph>
                    <Paragraph>
                        11. Errors and Corrections. Provider does not represent or warrant that this Web Site
                        or the Content or Postings will be error-free, free of viruses or other harmful components,
                        or that defects will be corrected or that it will always be accessible. Provider does not
                        warrant or represent that the Content or Postings available on or through this Web Site will
                        be correct, accurate, timely, or otherwise reliable. Provider may make improvements and/or
                        changes to its features, functionality or Content or Postings at any time.
                    </Paragraph>
                    <Paragraph>
                        12. Attorney Ethics Notice; Posting Rules. If you are an attorney participating
                        in any aspect of this Web Site, including but not limited to Interactive Areas, a)
                        you agree not to provide specific legal advice in any of your Postings and to draft
                        Postings which are appropriate, educational, and in accordance with attorney ethics
                        requirements, b) you represent and warrant that you are an attorney in good standing
                        with a license to practice law in at least one of the 50 United States of America or
                        the District of Columbia, c) you agree to promptly notify Provider of any grievance,
                        claim, reprimand, or censure brought against you, as well as resignation or other
                        loss of license, d) you acknowledge that the Rules of Professional Conduct of the
                        jurisdictions where you are licensed ("Rules") apply to all aspects of your
                        participation and that you will abide by these Rules. These Rules include, but are not
                        limited to, the rules relating to advertising, solicitation of clients, rules regarding
                        the establishment of attorney-client relationships, failure to maintain client
                        confidences, unauthorized practice of law, and misrepresentations of fact.
                        Provider disclaims all responsibility for your compliance with these Rules.
                        You further agree and acknowledge that when you participate in any of the Interactive
                        Areas on this Web Site, that you will not offer legal advice, but will only provide
                        general information. Provider highly recommends that you include a disclaimer at the
                        end of every Posting regarding the aforementioned advertising and ethics issues.
                        Provider will have no liability to you arising out of or related to your compliance
                        or noncompliance with such laws and rules, or related to Provider’s inclusion or
                        failure to include a disclaimer in the Interactive Areas.
                    </Paragraph>
                    <Paragraph>
                        13. Assumption of Risk. You assume all liability for any claims, suits or
                        grievances filed against you, including all damages related to your participation
                        in any of the Interactive Areas.
                    </Paragraph>
                    <Paragraph className={'text-uppercase'}>
                        14. DISCLAIMER. THIS WEB SITE, THE INTERACTIVE AREAS, THE CONTENT, AND POSTINGS
                        ARE PROVIDED ON AN "AS IS, AS AVAILABLE" BASIS. PROVIDER EXPRESSLY DISCLAIMS ALL
                        WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE AND NON-INFRINGEMENT. PROVIDER DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS,
                        INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR
                        ANY WAY RELATED TO (A) ANY ERRORS IN OR OMISSIONS FROM THIS WEB SITE, THE
                        INTERACTIVE AREAS, THE CONTENT, AND THE POSTINGS INCLUDING, BUT NOT LIMITED TO,
                        TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B) THIRD PARTY COMMUNICATIONS,
                        (C) ANY THIRD PARTY WEB SITES OR CONTENT THEREIN DIRECTLY OR INDIRECTLY ACCESSED
                        THROUGH LINKS IN THIS WEB SITE, INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR
                        OMISSIONS THEREFROM, (D) THE UNAVAILABILITY OF THIS WEB SITE, THE INTERACTIVE
                        AREAS, THE CONTENT, THE POSTINGS, OR ANY PORTION THEREOF, (E) YOUR USE OF THIS
                        WEB SITE, THE INTERACTIVE AREAS, THE CONTENT, OR THE POSTINGS, OR (F) YOUR USE
                        OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THIS WEB SITE, THE INTERACTIVE
                        AREAS, THE CONTENT, OR THE POSTINGS.  PROVIDER ALSO ASSUMES NO RESPONSIBILITY AND
                        SHALL NOT BE LIABLE FOR ANY DAMAGES OR VIRUSES THAT MAY INFECT YOUR COMPUTER
                        EQUIPMENT OR OTHER PROPERTY ARISING FROM OR RELATED TO ACCESS TO, USE OF, OR
                        BROWSING IN THE WEB SITE OR THE DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES,
                        VIDEO OR AUDIO FROM THE WEB SITE.
                    </Paragraph>
                    <Paragraph className={'text-uppercase'}>
                        15. LIMITATION OF LIABILITY. PROVIDER SHALL NOT BE LIABLE FOR ANY LOSS, INJURY, CLAIM,
                        LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM YOUR USE OF THIS WEB SITE, THE CONTENT,
                        THE POSTINGS, THE INTERACTIVE AREAS, ANY FACTS OR OPINIONS APPEARING ON OR THROUGH ANY
                        OF THE INTERACTIVE AREAS, OR ANY THIRD PARTY COMMUNICATIONS. PROVIDER SHALL NOT BE
                        LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
                        OF ANY KIND WHATSOEVER (INCLUDING, WITHOUT LIMITATION, ATTORNEYS' FEES) IN ANY WAY DUE
                        TO, RESULTING FROM, OR ARISING IN CONNECTION WITH THE USE OF OR INABILITY TO USE THIS
                        WEB SITE, THE INTERACTIVE AREAS, THE CONTENT, THE POSTINGS, OR ANY THIRD PARTY
                        COMMUNICATIONS. TO THE EXTENT THE FOREGOING LIMITATION OF LIABILITY IS PROHIBITED
                        OR FAILS OF ITS ESSENTIAL PURPOSE, PROVIDER'S SOLE OBLIGATION TO YOU FOR DAMAGES
                        SHALL BE LIMITED TO $100.00.
                    </Paragraph>
                    <Paragraph>
                        16. Indemnification. You agree to indemnify, defend and hold Provider, its officers,
                        directors, employees, agents, licensors, suppliers and any third party information
                        providers to this Web Site harmless from and against all claims, losses, expenses,
                        damages and costs, including reasonable attorneys' fees, resulting from any violation
                        of these Terms of Use by you or arising from or related to any Postings uploaded
                        or submitted by you.
                    </Paragraph>
                    <Paragraph>
                        17. Third Party Rights. The provisions of paragraphs 14 (Disclaimer),
                        15 (Limitation of Liability), and 16 (Indemnification) are for the benefit of Provider
                        and its officers, directors, employees, agents, licensors, suppliers,
                        and any third party information providers to this Web Site. Each of these
                        individuals or entities shall have the right to assert and enforce those provisions
                        directly against you on its own behalf.
                    </Paragraph>
                    <Paragraph>
                        18. Unlawful Activity; Termination of Access. Provider reserves the right to
                        investigate complaints or reported violations of our Terms of Use and to take
                        any action we deem appropriate including but not limited to reporting any suspected
                        unlawful activity to law enforcement officials, regulators, or other third parties
                        and disclosing any information necessary or appropriate to such persons or entities
                        relating to user profiles, e-mail addresses, usage history, posted materials,
                        IP addresses and traffic information. Provider may discontinue any party’s
                        participation in any of the Interactive Areas at any time for any reason or no reason.
                    </Paragraph>
                    <Paragraph>
                        19. Remedies for Violations. Provider reserves the right to seek all remedies
                        available at law and in equity for violations of these Terms of Use, including
                        but not limited to the right to block access from a particular internet address
                        to this Web Site and any other Provider web sites and their features.
                    </Paragraph>
                    <Paragraph>
                        20. Governing Law and Jurisdiction. The Terms of Use are governed by and
                        construed in accordance with the laws of the State of Georgia and any action
                        arising out of or relating to these terms shall be filed only in state or federal
                        courts located in Georgia and you hereby consent and submit to the personal
                        jurisdiction of such courts for the purpose of litigating any such action.
                    </Paragraph>
                    <Paragraph>
                        21. Privacy. Your use of this Web Site is subject to Provider's Privacy Policy.
                    </Paragraph>
                    <Paragraph>
                        22. Severability of Provisions. These Terms of Use incorporate by reference any
                        notices contained on this Web Site, the Privacy Policy and the Provider Services
                        Terms and constitute the entire agreement with respect to access to and use of
                        this Web Site, the Interactive Areas, and the Content and Postings. If any
                        provision of these Terms of Use is unlawful, void or unenforceable, or conflicts
                        with the Provider Services Terms then that provision shall be deemed severable
                        from the remaining provisions and shall not affect their validity and
                        enforceability. Notwithstanding anything to the contrary in these Terms of Use,
                        if you have a separate signed written agreement with a Provider that applies to
                        your use of any of that Provider's Content, that agreement constitutes the entire
                        agreement between you and that Provider with respect to the affected Content subject
                        thereto (the "Otherwise Covered Content"), and these Terms of Use shall be treated as
                        having no force or effect with respect to the Otherwise Covered Content.
                    </Paragraph>
                    <Paragraph>
                        23. Modifications to Terms of Use. Provider reserves the right to change these
                        Terms of Use at any time. Updated versions of the Terms of Use will appear on
                        this Web Site and are effective immediately. You are responsible for regularly
                        reviewing the Terms of Use. Continued use of this Web Site after any such
                        changes constitutes your consent to such changes.
                    </Paragraph>
                </ParagraphContainer>
            </PageContainer>
        </PageWrapper>
    );
};

export default PageTermsConditions;
