import React, { useEffect } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import Paginations from './Pagination';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      '&.MuiTableCell-head': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      '& .MuiTableSortLabel-root:hover': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-root:focus': {
        color: '#fff',
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: '#fff',
      },
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const TableCellFont = styled.div`
  font-size: 20px;
`;

interface IContactContainerProps {
  notifications: any[];
  getPeopleList: any;
  showItems: number;
  isLoad: boolean;
  orderCompany: any;
  setLoad: any;
  setOrderCompany: any;
  setOrderLocation: any;
  activeOrder: string;
  setActiveOrder: any;
  orderLocation: any;
  rows: any;
  setShowFrom: any;
  showFrom: any;
  onContactListReq: any;
}

const ContactContainer: React.FC<
  IContactContainerProps & { context: IAuthContext }
> = (props) => {
  const {
    getPeopleList,
    showItems,
    isLoad,
    orderCompany,
    setLoad,
    setOrderCompany,
    setOrderLocation,
    orderLocation,
    rows,
    setShowFrom,
    showFrom,
    activeOrder,
    setActiveOrder,
    onContactListReq,
  } = props;
  const classes = useStyles();

  const enableSorting = false;

  const onPreviousClick = async () => {
    setShowFrom(showFrom - showItems);
    getPeopleList(showFrom - showItems);
  };
  const onNextClick = async () => {
    setShowFrom(showFrom + showItems);
    getPeopleList(showFrom + showItems);
  };
  useEffect(() => {
    if (isLoad) {
      getPeopleList();
    }
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label=" table">
          <TableHead>
            <TableRow>
              {enableSorting ?
              <>
              <StyledTableCell align="center">
                <TableSortLabel
                  active={activeOrder === 'company' ? true : false}
                  direction={orderCompany}
                  onClick={() => {
                    setOrderCompany(orderCompany === 'asc' ? 'desc' : 'asc');
                    setOrderLocation('asc');
                    setLoad(true);
                    setActiveOrder('company');
                  }}
                >
                  Company
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={activeOrder === 'location' ? true : false}
                  direction={orderLocation}
                  onClick={() => {
                    setOrderLocation(orderLocation === 'asc' ? 'desc' : 'asc');
                    setOrderCompany('desc');
                    setLoad(true);
                    setActiveOrder('location');
                  }}
                >
                  Facility/Location
                </TableSortLabel>
              </StyledTableCell>
              </>
              :
              <>
              <StyledTableCell align="center">
                <TableCellFont>Company</TableCellFont>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TableCellFont>Facility/Location</TableCellFont>
              </StyledTableCell>
              </>
              }
              <StyledTableCell align="center">
                <TableCellFont>Contacts</TableCellFont>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.contacts &&
              rows.contacts.length > 0 &&
              rows.contacts.map((row: any, index: number) => (
                <TableRow key={row.entity_number + index}>
                  <TableCell component="th" scope="row" align="center">
                    {row.entity_name}
                  </TableCell>
                  <TableCell>{row.city}</TableCell>

                  <TableCell style={{textAlign: 'center'}}><Button variant="link" onClick={() => onContactListReq(row)}>Click here</Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginations
        isDisabled={false}
        isFirstPage={(rows && rows.records_num_from === 0) || false}
        isLastPage={
          (rows &&
            rows.records_num_from + rows.records_num_show >=
              rows.total_records) ||
          false
        }
        totalRecords={(rows && rows.total_records) || 0}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
    </>
  );
};
export default withAuthContext(ContactContainer);
