import { AmbientLight, DirectionalLight, Scene } from 'three';
import { ThreeJSOverlayView } from '@googlemaps/three';

export const ThreeDmap = async (gMap: any, gMaps: any) => {
  if (gMap.zoom > 9) {
    const scene = new Scene();
    const ambientLight = new AmbientLight(0xffffff, 0.75);
    scene.add(ambientLight);
    const directionalLight = new DirectionalLight(0xffffff, 0.25);
    directionalLight.position.set(0, 10, 50);
    scene.add(directionalLight);

    // Load the model.F
    let { tilt, heading, zoom } = gMap;
    const animate = () => {
      if (tilt < 67.5) {
        tilt += 0.5;
      } else if (heading <= 360) {
        heading += 0.3;
        zoom -= 0.0005;
      } else {
        // exit animation loop
        return;
      }
      gMap.moveCamera({ tilt, heading, zoom });
      requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);

    new ThreeJSOverlayView({
      // @ts-ignore
      gMap,
      scene,
      anchor: { ...gMap.center, altitude: 100 },
    });
  }
};
