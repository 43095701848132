import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { buttonFavorite } from '../../services/firebase';
import { makeStyles } from '@material-ui/core/styles';

const ButtonOptionsContainer = styled.div `
  width: 60%;
  color: #fff;
  border: solid 1px #FFF;
  border-radius: 1rem;
  padding: 0.5rem;
  margin-left: 1rem;
  background-color: #0042;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .close-button {
      border: 0;
      background: none;
      color: #fff;
      font-size: 1.75rem;
    }
  }

  .buttons-wrapper {
    display: flex;
    height: 43.5vh;
    overflow-y: auto;
    overflow-x: clip;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: inherit;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 20px;
    }

    .external-data-buttons {
      width: 58%;

      .add-link-container {
        display: flex;
        justify-content: center;
      }

      .add-link {
        width: fit-content;
      }

      div {
        column-count: 3;
      }
    }

    .internal-data-buttons {
      margin-left: 1rem;
      width: 39%;

      div {
        column-count: 2;
      }
    }

    .external-button, .internal-button {
      border-radius: 0.5rem;
      margin: 4px;
      border: solid 1px #fff;
      color: #fff;
      width: 100%;
      padding: 2px;
      background-color: rgb(128 128 128 / 80%);
    }
  }
`;

const ButtonOptions: React.FC<{
  optionsList: any;
  setOptionsList: any;
  setShowCustomizeButtons: any;
}> = ({ optionsList, setOptionsList, setShowCustomizeButtons }) => {
  const [showAddLinkModal, setShowAddLinkModal] = useState<boolean>(false);
  const initialExternalButtons = [{
    name: 'Enviro Ai Search Engine',
    type: 'link',
    href: '/search',
    buttonId: 5,
  }, {
    name: 'Benchmarking',
    type: 'link',
    href: '/benchmarking',
    buttonId: 6,
  }, {
    name: 'Emission Reduction Opportunities',
    type: 'link',
    href: '/generator',
    buttonId: 7,
  }, {
    name: 'Corrective Actions',
    type: 'link',
    href: '/corrective-tracker',
    buttonId: 8,
  }, {
    name: 'Trackbot Results',
    type: 'link',
    href: '/trackbot',
    buttonId: 9,
  }, {
    name: 'Environmental Sentiment Analysis',
    type: 'link',
    href: '/sentiment',
    buttonId: 10,
  }, {
    name: 'State Compliance History',
    type: 'link',
    href: 'https://www2.tceq.texas.gov/oce/ch/index.cfm?skipIFrame=true',
    buttonId: 11,
  }, {
    name: 'Environmental Justice Index',
    type: 'link',
    href: 'https://onemap.cdc.gov/portal/apps/sites/#/eji-explorer',
    buttonId: 12,
  }, {
    name: 'NRC Notifications',
    type: 'link',
    href: `${process.env.REACT_APP_CERES_URL}/NRC`,
    buttonId: 13,
  }, {
    name: 'EPA ECHO Database Results',
    type: 'link',
    href: 'https://echo.epa.gov/facilities/facility-search/results?skipIFrame=true',
    buttonId: 14,
  }, {
    name: 'Permit Notices',
    type: 'link',
    href: 'https://aah-airmail.org/app/notices/',
    buttonId: 15,
  }, {
    name: 'Emissions Profile',
    type: 'modal',
    buttonId: 16,
  }, {
    name: 'Current Air Quality Conditions',
    type: 'modal',
    buttonId: 17,
  }, {
    name: 'Deadline Reminders',
    type: 'link',
    href: `${process.env.REACT_APP_CERES_URL}/TCEQDeadlineReminder`,
    buttonId: 18,
  }, {
    name: 'Social Media',
    type: 'link',
    href: '/search?type=SOCIAL',
    buttonId: 19,
  }, {
    name: 'News & Internet Info About the Site',
    type: 'modal',
    buttonId: 23,
  }, {
    name: 'Enforcement Actions',
    type: 'link',
    href: `${process.env.REACT_APP_CERES_URL}/EnforcementActions`,
    buttonId: 25,
  }, {
    name: 'CAER LINE NOTIFICATIONS',
    type: 'link',
    href: 'https://tceq.liputra.com/texas-caer.php',
    buttonId: 26,
  }, {
    name: 'Environmental Property Review',
    type: 'map',
    mapType: 'jobs',
    buttonId: '28'
  }, {
    name: 'EJ Screen',
    type: 'link',
    href: 'https://ejscreen.epa.gov/mapper/',
    buttonId: 29
  }];
  const [externalButtons, setExternalButtons] = useState<any>(initialExternalButtons);
  const initialInternalButtons = [{
    name: 'Air Emissions System',
    type: 'link',
    href: 'https://eigencontrol.grafana.net/login?skipIFrame=true',
    buttonId: 20,
  }, {
    name: 'EMIS Link',
    type: 'link',
    href: '',
    buttonId: 21,
  }];
  const [internalButtons, setInternalButtons] = useState<any>(initialInternalButtons);

  const [addLinkState, setAddLinkState] = useState<any>({
    addLinkName: {
      value: '',
      valid: true,
      errorMessage: ''
    },
    addLinkUrl: {
      value: '',
      valid: true,
      errorMessage: ''
    }
  });

  const addButtonToUser = async (button: any) => {
    try {
      const resp = await buttonFavorite({
        actionType: 'create',
        record: button
      });
      return resp;
    } catch (error) {
      toast.error(`Error while adding favorite button.`);
    }
  };

  const onClickHandlerExternal = (index: number) => {
    const buttons = externalButtons;
    const selectedButton = buttons.splice(index, 1);
    setExternalButtons(buttons);
    setOptionsList([...optionsList, ...selectedButton]);
    addButtonToUser(selectedButton[0]);
  };

  const onClickHandlerInternal = (index: number) => {
    const buttons = internalButtons;
    const selectedButton = buttons.splice(index, 1);
    setInternalButtons(buttons);
    setOptionsList([...optionsList, ...selectedButton]);
    addButtonToUser(selectedButton[0]);
  };

  const addLinkChangeHandler = (e: any) => {
    const {name, value} = e.target;
    const linkState = {
      ...addLinkState,
      [name]: {
        ...addLinkState[name],
        value
      }
    };
    setAddLinkState(linkState);
  };

  const validators: any = {
    addLinkName: () => {
      if(addLinkState.addLinkName.value.length === 0) {
        return {
          valid: false,
          errorMessage: 'This is a required Field'
        };
      }

      return {
        valid: true,
        errorMessage: ''
      };
    },
    addLinkUrl: () => {
      if(addLinkState.addLinkUrl.value.length === 0) {
        return {
          valid: false,
          errorMessage: 'This is a required Field'
        };
      } else if(!isValidUrl(addLinkState.addLinkUrl.value)) {
        return {
          valid: false,
          errorMessage: 'Please enter a valid URL'
        };
      }

      return {
        valid: true,
        errorMessage: ''
      };
    }
  };

  const validateAll = () => {
    let state = addLinkState;
    let isValid = true;
    Object.keys(addLinkState).forEach((key) => {
      const fn = validators[key];
      const result = fn();
      isValid = isValid ? result.valid : false;
      state = {
        ...state,
        [key]: {
          ...state[key],
          valid: result.valid,
          errorMessage: result.errorMessage
        }
      };
    });
    setAddLinkState(state);
    return isValid;
  };

  const submitHandler = () => {
    const isValid = validateAll();
    if(isValid) {
      const newLink = [{
        name: addLinkState.addLinkName.value,
        type: 'link',
        href: addLinkState.addLinkUrl.value
      }];
      setExternalButtons([...externalButtons, ...newLink]);
      setAddLinkState({
        addLinkName: {
          value: '',
          valid: true,
          errorMessage: ''
        },
        addLinkUrl: {
          value: '',
          valid: true,
          errorMessage: ''
        }
      });
    }
  };

  const onBlurHandler = (e: any) => {
    const result = validators[e.target.name]();
    setAddLinkState({
      ...addLinkState,
      [e.target.name]: {
        ...addLinkState[e.target.name],
        valid: result.valid,
        errorMessage: result.errorMessage
      }
    });
  };

  useEffect(() => {
    const listIndices = optionsList.map((option: any) => option.buttonId);
    const externalBtns = initialExternalButtons.filter((btn: any) => !listIndices.includes(btn.buttonId));
    const internalBtns = initialInternalButtons.filter((btn: any) => !listIndices.includes(btn.buttonId));
    setExternalButtons(externalBtns);
    setInternalButtons(internalBtns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsList]);

  const useStyles = makeStyles(() => ({
    paper: { width: '35%' },
  }));
  const classes = useStyles();

  const isValidUrl = (url: string) => {
    try {
      return Boolean(new URL(url));
    } catch(e) {
      return false;
    }
  };

  return (
    <>
      <ButtonOptionsContainer>
        <div className="header-wrapper">
          <h2>CHOOSE YOUR CUSTOMIZED INFORMATION BUTTONS!</h2>
          <button className="close-button" onClick={() => setShowCustomizeButtons(false)}>X</button>
        </div>
        <div className="buttons-wrapper">
          <div className="external-data-buttons">
            <h3>External Data Buttons</h3>
            <div>
              {
                externalButtons.map((externalButton: any, index: number) => <button className="external-button" onClick={() => onClickHandlerExternal(index)}>{externalButton.name}</button>)
              }
            </div>
            <div className="add-link-container">
              <button className="external-button add-link" onClick={() => setShowAddLinkModal(true)}>+ Add Link</button>
            </div>
          </div>
          <div className="internal-data-buttons">
            <h3>Internal Data Buttons</h3>
            <div>
              {
                internalButtons.map((internalButton: any, index: number) => <button className="internal-button" onClick={() => onClickHandlerInternal(index)}>{internalButton.name}</button>)
              }
            </div>
          </div>
        </div>
      </ButtonOptionsContainer>
      <Dialog
        open={showAddLinkModal}
        onClose={() => setShowAddLinkModal(false)}
        classes={{ paper: classes.paper}}
      >
          <DialogTitle>Add Link</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              id="link-name"
              label="Link Name"
              name="addLinkName"
              value={addLinkState.addLinkName.value}
              onChange={(e) => addLinkChangeHandler(e)}
              error={!addLinkState.addLinkName.valid}
              helperText={addLinkState.addLinkName.valid ? '' : addLinkState.addLinkName.errorMessage}
              onBlur={(e) => onBlurHandler(e)}
            />
            <br /><br />
            <TextField
              fullWidth
              id="link-url"
              label="Link Url"
              name="addLinkUrl"
              value={addLinkState.addLinkUrl.value}
              onChange={(e) => addLinkChangeHandler(e)}
              error={!addLinkState.addLinkUrl.valid}
              helperText={addLinkState.addLinkUrl.valid ? '' : addLinkState.addLinkUrl.errorMessage}
              onBlur={(e) => onBlurHandler(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={submitHandler}
              color="primary"
              disabled={addLinkState.addLinkName.value.length !== 0 && addLinkState.addLinkUrl.value.length !== 0 ? false : true}
            >
              Add Link
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonOptions;
