import React, { useState, useEffect } from 'react';
import { customSearch } from '../../../services/firebase';
import { ICustomSearchRecord, ICustomSearchResult } from '../../../types/search';
import GoogleCustomSearch from '../../search/GoogleCustomSearch';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const SpinnerContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
`;

const TabResults = styled.div `
  .quote-main-container {
    flex-direction: column;

    div:first-child {
      margin-left: unset;
    }

    div:last-child {
      position: static;
      width: 100%;
    }
  }
`;

interface IMyTundraTabResultsModal {
  queryText: any;
}

const MyTundraTabResultsModal: React.FC<IMyTundraTabResultsModal> = ({
  queryText
}) => {
  const [isLoad, setLoad] = useState(false);
  const [customSearchResult, setCustomSearchResult] = useState<ICustomSearchResult<ICustomSearchRecord> | null>(null);
  const [sort, setSort] = useState('');

  const googleCustomSearch = (from: number = 1, sortVal: string = '') => {
    return customSearch({
      type: 'INTERNET',
      query: queryText,
      from,
      sort: sortVal,
    })
      .then((result) => {
        return setCustomSearchResult(result.data);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleSortOrderChange = (e: any) => {
    const value = (e && e.target && e.target.value) || '';
    setSort(value);
    googleCustomSearch(1, value);
  };

  useEffect(() => {
    setLoad(true);
    googleCustomSearch();
    // eslint-disable-next-line
  }, [queryText]);

  return (
    <TabResults>
      <MuiDialogTitle>{queryText}</MuiDialogTitle>
      {isLoad && (
        <SpinnerContainer>
          <Spinner
            as="span"
            variant="primary"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </SpinnerContainer>
      )}
      {customSearchResult && (
        <GoogleCustomSearch
          result={customSearchResult}
          onNextClick={() => {
            if (!customSearchResult || !customSearchResult.next) {
              return;
            }
            return googleCustomSearch(customSearchResult.next.startIndex);
          }}
          onPreviousClick={() => {
            if (!customSearchResult || !customSearchResult.previous) {
              return;
            }
            return googleCustomSearch(customSearchResult.previous.startIndex);
          }}
          handleSortOrderChange={handleSortOrderChange}
          isLoading={isLoad}
          sort={sort}
        />
      )}
    </TabResults>
  );
};

export default MyTundraTabResultsModal;
