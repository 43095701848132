// React imports
import React, { useState } from 'react';

// Material-UI components and icons
import { IconButton } from '@material-ui/core';
import FillStarIcon from '@material-ui/icons/Star';
import EmptyStarIcon from '@material-ui/icons/StarBorder';

// React-Bootstrap components
import { Button, Card, Modal, Spinner } from 'react-bootstrap';

// External libraries
import { toast } from 'react-toastify';

// Services
import { savePrompts } from '../../services/firebase';

interface IPropsType {
  favorites: any[];
  showQuestionsPopup: boolean;
  setShowQuestionsPopup: (flag: boolean) => void;
  onFavoriteClick: (index: number) => void;
  setFavorites: (favoritePrompt: any) => void;
  disableDraftBtns: (flag: boolean) => void;
}

const SavePromptsModal = ({ showQuestionsPopup, setShowQuestionsPopup, favorites, setFavorites, onFavoriteClick, disableDraftBtns }: IPropsType) => {
  const [showFavoriteSpinner, setShowFavoriteSpinner] = useState(false);
  const isSaveEnable = !!favorites.filter(o => o.isFavorite || o.id).length;

  const createRemovePrompt = async (type: string, prompt: any, i: number) => {
    const response = await savePrompts({
      type,
      id: prompt.id,
      record: { promptData: [prompt.question] }
    });

    if(response && response.data) {
      const updatedFavorites = favorites;
      updatedFavorites[i].id = type === 'create' ? response.data.id : '';
      setFavorites(updatedFavorites);
    }
  };

  const saveFavorites = async () => {
    try {
      disableDraftBtns(true);
      setShowFavoriteSpinner(true);

      const promptData = favorites.filter(data => data.id || data.isFavorite);
      promptData.forEach(async (item: any, i: number) => {
        if (item.id && item.isFavorite) {
          return;
        } else {
          const actionType = item.id ? 'remove' : 'create';
          await createRemovePrompt(actionType, item, i);
        }
      });

      toast.success('Data Saved Successfully.', { position: 'top-right' });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error);
    } finally {
      setShowFavoriteSpinner(false);
      disableDraftBtns(false);
      setShowQuestionsPopup(false);
    }
  };

  return (
    <Modal
      show={showQuestionsPopup}
      onHide={() => setShowQuestionsPopup(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 99999 }}
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add To Saved Prompts
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { favorites.map((favorite, i) => (
          <Card key={i} text="primary" className="mb-2">
            <Card.Body className="d-flex justify-content-between align-items-center">
              <Card.Title className="mb-0">{ favorite.question }</Card.Title>
              <IconButton
                onClick={(e: any) => { onFavoriteClick(i); }}
                aria-label="fav-icon"
                component="span"
              >
                {
                  favorite.isFavorite ? <FillStarIcon style={{color: '#faaf00'}} /> : <EmptyStarIcon style={{color: '#faaf00'}} />
                }
              </IconButton>
            </Card.Body>
          </Card>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveFavorites} disabled={!isSaveEnable}>
          Save
          { showFavoriteSpinner && <Spinner animation="border" variant="light" size="sm" className="ml-2" /> }
        </Button>
        <Button onClick={() => setShowQuestionsPopup(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SavePromptsModal;
