import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
import mediaQuery from '../../util/media-query';
import { useSelector } from 'react-redux';
import { useContainerDimensions } from '../ContainerDimensions';
import { default as Sliders } from 'react-slick';
import { Grid } from '@material-ui/core';
import GoogleSearchMenu from './GoogleSearchMenu';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: <ChevronLeft color="#7991af" />,
  nextArrow: <ChevronRight color="#7991af" />,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const SlidersI = styled(Sliders)<any>`
  width: ${(props) => props.width - 40}px;
  margin: auto;
  @media (max-width: 767px) {
    max-width: 95%;
    margin: 20px auto 25px;
  }
  & .slick-arrow:before {
    z-index: 11;
  }
  & .slick-arrow {
    height: 30px;
    width: 30px;
  }
`;
const SearchTypePillsContainer = styled.div<{ searchType: string; width: number }>`
  display: block;
  margin-top: -24px;
  ${(props) =>
    props.searchType === 'default' ? 'margin: 1em 8em' : `padding: ${props.width < 1000 ? '1em 0' : '1em 13em'}`};
  border-bottom: ${(props) => (props.searchType === 'default' ? '0' : '1px solid #ebebeb')};
  & button.default-link {
    color: #fff;
    border: none;
    background: #8ebbbb;
    font-size: 18px;
    display: block;
    width: 100%;
    margin: auto;
    padding: 0.3rem 0.75rem;
    white-space: nowrap;
    font-weight: 400;
    border-radius: 50px;
  }

  ${mediaQuery.phone`
    flex-direction: column;
    margin: 1em;
  `}
  ${mediaQuery.tablet`
    flex-direction: column;
    margin: 1em;
  `}
  button.btn.btn-outline-primary.active {
    color: #fff;
    background-color: #5dc5c4 !important;
  }
  button.btn.btn-outline-primary {
    transition: all 0.3s ease;
    &:hover:not([disabled]),
    &:active {
      color: #fff;
    }
    &:hover {
      background-color: #5dc5c4 !important;
    }
    &:hover:not([disabled]) {
      z-index: 10;
      &:disabled {
        color: var(--primary);
      }
    }
  }
`;

const ItemArea = styled.div`
  padding: 0 10px;
`;

const GridCust = styled(Grid)`
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`;

interface ISearchTypePillsProps {
  isOnlyDocumentSearch: boolean;
  isDisabled: boolean;
  active: string;
  sourceType: string | null;
  setSearchType: any;
  setFilter: any;
  filters: any;
  setQuickSerch: (type: string) => void;
  setSourceType: (type: string) => void;
  onTypeChange: (type: CUSTOM_SEARCH_TYPE, source: string) => void;
  searchType: string;
  customSearchType: string;
  setCustomSearchType: (src: string) => void;
}

const SearchTypePills: React.FC<ISearchTypePillsProps> = (props) => {
  const ref: any = React.useRef(null);
  const refBtn: any = React.useRef(null);
  const { sourceType, setSourceType, setFilter, filters, searchType } = props;
  const store: any = useSelector<any>((state): any => state);
  const allState: any = store.database.allState;
  const { width } = useContainerDimensions(ref);
  const { width: widthBtn } = useContainerDimensions(refBtn);
  const clearFilter = async () => {
    const newFilter = { ...filters, state: '', category: '', recordSeries: '' };
    setFilter(newFilter);
  };

  React.useEffect(() => {
    if (Object.values(allState).length > 0 && searchType === 'default') {
      const index = Object.values(allState).findIndex((src: any) => src.source_type === sourceType);
      const slickTrack: any = document.querySelectorAll('.activeState .slick-track')[0];
      if (index > 2) {
        slickTrack.style.transform = `translate3d(-${(widthBtn - 9) * (index - 2)}px, 0px, 0px)`;
      } else {
        slickTrack.style.transform = `translate3d(0px, 0px, 0px)`;
      }
    }
  }, [allState, sourceType, widthBtn, searchType]);

  return (
    <SearchTypePillsContainer ref={ref} searchType={searchType} width={width}>
      <GridCust container spacing={1} alignItems="center" justifyContent="space-between">
        {searchType === 'default' && (
          <Grid container item xs={12} md={6} lg={6} spacing={3}>
            {allState && (
              <SlidersI {...{ ...settings, className: 'activeState' }} width={width < 1000 ? width : width / 2}>
                {allState &&
                  Object.values(allState).map((type: any, index: number) => {
                    return (
                      <ItemArea key={index} ref={refBtn}>
                          <Button
                          active={sourceType === type.source_type}
                          disabled={
                            (props.isOnlyDocumentSearch && type.source_type !== CUSTOM_SEARCH_TYPE.DEFAULT) ||
                            props.isDisabled
                          }
                          variant="outline-primary"
                          onClick={() => {
                            props.setQuickSerch('');
                            setSourceType(type.source_type);
                            props.setSearchType(CUSTOM_SEARCH_TYPE.DEFAULT);
                            props.onTypeChange(CUSTOM_SEARCH_TYPE.DEFAULT, type.source_type);
                          }}
                          className={'default-link'}
                        >
                          {type.doc_alias}
                        </Button>
                      </ItemArea>
                    );
                  })}
              </SlidersI>
            )}
          </Grid>
        )}
        <GoogleSearchMenu
          settings={settings}
          width={width}
          active={props.active}
          isDisabled={props.isDisabled}
          isOnlyDocumentSearch={props.isOnlyDocumentSearch}
          setSourceType={setSourceType}
          clearFilter={clearFilter}
          setSearchType={props.setSearchType}
          onTypeChange={props.onTypeChange}
          searchType={searchType}
          customSearchType={props.customSearchType}
          setCustomSearchType={props.setCustomSearchType}
          setQuickSerch={props.setQuickSerch}
        />
      </GridCust>
    </SearchTypePillsContainer>
  );
};

export default SearchTypePills;
