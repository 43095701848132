import {
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Spinner,
} from 'react-bootstrap';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CeresStripePayments } from '../services/stripe';
import { ChevronRight } from 'react-feather';
import { IAuthContext } from '../context/auth/auth-context';
import PaperContainer from '../components/PaperContainer';
import { TIERS } from '../types/tiers';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../context/auth/AuthConsumer';

const NotificationPageContainer = styled(PaperContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const PlanCard = styled(Card)`
  width: 18rem;
  margin-right: 10px;
  .list-group {
    font-size: 0.85em;
  }
  .card-body {
    .card-text {
      font-weight: 500;
      font-size: 1.1em;
      text-align: center;
    }
  }
`;

const PlanCardHero = styled.div<any>`
  height: 150px;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  color: ${(props) => props.color || 'var(--dark)'};
  background: ${(props: any) => props.backgroundColor || '#fff'};
`;

const PlanCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
`;

const PlansAndPricing: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  const [isBasicLoading, setBasicLoading] = useState(false);
  const [isPlatinumLoading, setPlatinumLoading] = useState(false);
  const authContext = props.context;
  const warnAndRedirectToLogin = () => {
    toast.warn(`Please login to purchase any subscription.`);
    return props.history.push('/login');
  };
  return (
    <NotificationPageContainer>
      <PlanCardContainer>
        <PlanCard>
          <PlanCardHero color="#fff" backgroundColor={'#c58237'}>
            <div>BASIC</div>
            <div>$17.95 per month</div>
          </PlanCardHero>
          <Card.Body>
            <Card.Text>Feature List</Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              CERES Search: Prices start at $17.95 for TCEQ document search
              results.
            </ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button
              onClick={() => {
                if (!authContext.isAuthenticated) {
                  return warnAndRedirectToLogin();
                }
                if (authContext.isTier(TIERS.BASIC) && !authContext.isLubrizol()) {
                  return toast.warn(`You are already a BASIC subscriber`);
                }
                setBasicLoading(true);
                CeresStripePayments.doCheckout(
                  'BASIC20200812',
                  authContext.getUserEmail()
                )
                  .then(
                    ({ error }: { error: any }) =>
                      error && toast.error(error.message)
                  )
                  .catch((error: { message: string }) =>
                    toast.error(error.message)
                  )
                  .finally(() => setBasicLoading(false));
              }}
            >
              {!isBasicLoading && (
                <span>
                  Start Now <ChevronRight />
                </span>
              )}
              {isBasicLoading && (
                <Spinner variant="light" animation="border" size="sm" />
              )}
            </Button>
          </Card.Body>
        </PlanCard>
        <PlanCard>
          <PlanCardHero color="#fff" backgroundColor={'#005FBC'}>
            <div>PLATINUM</div>
            <div>$1575 per month</div>
          </PlanCardHero>
          <Card.Body>
            <Card.Text>Feature List</Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>CERES Search</ListGroupItem>
            <ListGroupItem>Advanced Search Filters</ListGroupItem>
            <ListGroupItem>AI Earth</ListGroupItem>
            <ListGroupItem>Eagle Eye</ListGroupItem>
            <ListGroupItem>Phase 1 Validator</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button
              onClick={() => {
                if (!authContext.isAuthenticated) {
                  return warnAndRedirectToLogin();
                }
                if (authContext.isTier(TIERS.PLATINUM) || authContext.isLubrizol()) {
                  return toast.warn(`You are already a PLATINUM subscriber`);
                }
                setPlatinumLoading(true);
                CeresStripePayments.doCheckout(
                  'PLATINUM',
                  authContext.getUserEmail()
                )
                  .then(
                    ({ error }: { error: any }) =>
                      error && toast.error(error.message)
                  )
                  .catch((error: { message: string }) =>
                    toast.error(error.message)
                  )
                  .finally(() => setPlatinumLoading(false));
              }}
            >
              {!isPlatinumLoading && (
                <span>
                  Start Now <ChevronRight />
                </span>
              )}
              {isPlatinumLoading && (
                <Spinner variant="light" animation="border" size="sm" />
              )}
            </Button>
          </Card.Body>
        </PlanCard>
        <PlanCard>
          <PlanCardHero color="#fff" backgroundColor={'#ea5647'}>
            <div>ENTERPRISE</div>
            <div>$7500 per month</div>
          </PlanCardHero>
          <Card.Body>
            <Card.Text>Feature List</Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>CERES Search</ListGroupItem>
            <ListGroupItem>Advanced Search Filters</ListGroupItem>
            <ListGroupItem>AI Earth</ListGroupItem>
            <ListGroupItem>Benchmarking</ListGroupItem>
            <ListGroupItem>Generator</ListGroupItem>
            <ListGroupItem>Eagle Eye</ListGroupItem>
            <ListGroupItem>Phase 1 Validator</ListGroupItem>
            <ListGroupItem>
              Multiple Platinum Memberships within a Firm
            </ListGroupItem>
          </ListGroup>
          <Card.Body>
            <a href="mailto:validator@enviro.ai">
              {!isPlatinumLoading && (
                <span>
                  Contact Us <ChevronRight />
                </span>
              )}
              {isPlatinumLoading && (
                <Spinner variant="light" animation="border" size="sm" />
              )}
            </a>
          </Card.Body>
        </PlanCard>
      </PlanCardContainer>
    </NotificationPageContainer>
  );
};

export default withRouter(withAuthContext(PlansAndPricing));
