import cuid from 'cuid';
import { stripeCheckout } from './firebase';

/* global Stripe */
export class CeresStripePayments {
  public static doClientCheckout(planId: string, userEmail: string) {
    const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
    if (!STRIPE_KEY) {
      throw new Error(`Failed initializing stripe sdk`);
    }
    const stripe = Stripe(STRIPE_KEY);
    stripe.redirectToCheckout({
      items: [{ plan: planId, quantity: 1 }],
      customerEmail: userEmail,
      clientReferenceId: cuid(),
      successUrl: process.env.REACT_APP_STRIPE_SUCCESS_URL || '',
      cancelUrl: process.env.REACT_APP_STRIPE_CANCEL_URL || ''
    });
  }

  public static async doCheckout(planId: string, _: string, isOneTime: boolean = false) {
    const result = await stripeCheckout({
      mode: isOneTime ? 'one_time' : '',
      plan: planId,
      successUrl: process.env.REACT_APP_STRIPE_SUCCESS_URL || '',
      cancelUrl: process.env.REACT_APP_STRIPE_CANCEL_URL || ''
    });
    const data = result.data;
    if (data && data.checkoutSessionId) {
      const stripe = this.getStripeInstance();
      return stripe.redirectToCheckout({
        sessionId: data.checkoutSessionId
      });
    }
    return Promise.reject({
      mesage: `Could not create a checkout session, please try again.`
    });
  }
  private static getStripeInstance() {
    const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
    if (!STRIPE_KEY) {
      throw new Error(`Failed initializing stripe sdk`);
    }
    return Stripe(STRIPE_KEY);
  }
}
