import {
  IAIEarth,
  IAir,
  IDatabase,
  IJob,
  IMap,
  ISearch,
  REMOVE_GLOBAL_FLAG,
  RESET_AIR,
  RESET_AISTORE,
  RESET_DATABASE,
  RESET_JOB,
  RESET_MAP,
  RESET_SEARCH,
  UPDATE_AIR,
  UPDATE_AISTORE,
  UPDATE_DATABASE,
  UPDATE_GLOBAL_FLAGS,
  UPDATE_JOB,
  UPDATE_MAP,
  UPDATE_QUICK_PROMPTS,
  UPDATE_SAVED_CHATS,
  UPDATE_SEARCH,
} from './types';

export function updateAIEarth(newState: IAIEarth) {
  return {
    type: UPDATE_AISTORE,
    payload: newState,
  };
}

export function updateAir(newState: IAir) {
  return {
    type: UPDATE_AIR,
    payload: newState,
  };
}

export function updateMap(map: IMap) {
  return {
    type: UPDATE_MAP,
    payload: map,
  };
}

export function updateJob(newJob: IJob) {
  return {
    type: UPDATE_JOB,
    payload: newJob,
  };
}

export function updateDatebase(database: IDatabase) {
  return {
    type: UPDATE_DATABASE,
    payload: database,
  };
}

export function updateSearch(search: ISearch) {
  return {
    type: UPDATE_SEARCH,
    payload: search,
  };
}

export function updateSavedChatData(chatData: any) {
  return {
    type: UPDATE_SAVED_CHATS,
    payload: chatData,
  };
}

export function updateGlobalFlags(flags: any) {
  return {
    type: UPDATE_GLOBAL_FLAGS,
    payload: flags,
  };
}

export function removeGlobalFlag(flagName: string) {
  return {
    type: REMOVE_GLOBAL_FLAG,
    payload: flagName,
  };
}

export function updateQuickPrompts(promptsData: any[]) {
  return {
    type: UPDATE_QUICK_PROMPTS,
    payload: promptsData,
  };
}

export function resetAir() {
  return {
    type: RESET_AIR,
  };
}
export function resetAIEarth() {
  return {
    type: RESET_AISTORE,
  };
}
export function resetMap() {
  return {
    type: RESET_MAP,
  };
}
export function resetJob() {
  return {
    type: RESET_JOB,
  };
}
export function resetDatabase() {
  return {
    type: RESET_DATABASE,
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}
