import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import styled from 'styled-components';

interface ITourDetailsProps {
  showTour: boolean;
  onCloseTour: () => void;
  onGotItClick: () => void;
}

const GotItButton = styled.div`
  background: #005fbc;
  background: var(--primary);
  color: #fff;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
`;
const TourContent = styled(Tour)`
  max-width: 40%;
`;
const TourImage = styled.img`
  width: 100%;
`;

const TourDetails: React.FC<ITourDetailsProps> = (props: ITourDetailsProps) => {
  const [step, setStep] = useState(0);

  const onEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      if (step === 2) {
        props.onGotItClick();
        props.onCloseTour();
      } else {
        setStep(step + 1);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEnterPress, false);
    return () => {
      document.removeEventListener('keydown', onEnterPress, false);
    };
  });

  const storeLink = 'https://storage.googleapis.com/ceres-maps/ceres_store/';

  return (
    <TourContent
      steps={[
        {
          selector: '',
          content: () => {
            return (
              <div style={{ minHeight: '40vh' }}>
                <div>
                  We know that you're interested in flammable liquids. On page
                  ii of the report, you can see this paragraph about flammable
                  liquid treatment:
                  <TourImage src={storeLink + 'tour-popup-img1.jpg'} />
                </div>
              </div>
            );
          },
          position: 'right',
        },
        {
          selector: '',
          content: () => {
            return (
              <div style={{ minHeight: '40vh' }}>
                <div>
                  We know you're interested in facility operations scoring. On
                  page 19 of the report, you can see a chart of facility
                  operations score distributions relevant to this facility.
                  <TourImage src={storeLink + 'tour-popup-img2.jpg'} />
                </div>
              </div>
            );
          },
          position: 'top',
        },
        {
          selector: '',
          content: () => {
            return (
              <div style={{ minHeight: '40vh' }}>
                We know you're interested in storage tank operations On page 40
                of the report, you can see this information about storage tank
                operations at this facility.
                <TourImage
                  src={storeLink + 'tour-popup-img3.jpg'}
                  style={{ marginTop: '20px' }}
                />
              </div>
            );
          },
          position: 'top',
        },
      ]}
      lastStepNextButton={
        <GotItButton
          onClick={() => {
            props.onGotItClick();
            props.onCloseTour();
            setStep(0);
          }}
        >
          Finish
        </GotItButton>
      }
      accentColor="#005FBC"
      badgeContent={(currentPage, totalPage) =>
        `${currentPage} of ${totalPage}`
      }
      isOpen={props.showTour}
      onRequestClose={() => props.onCloseTour()}
      goToStep={step}
    />
  );
};

export default TourDetails;
