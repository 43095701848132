import React, { useState } from 'react';
import ProjectContext, {
  IProjectContext,
  projectState
} from './project-context';
import { IJobType, IProject } from './types';

const ProjectProvider: React.FC = props => {
  const [state, setState] = useState<IProjectContext>(projectState);
  return (
    <ProjectContext.Provider
      value={{
        ...state,
        setActiveProjectAndJobType: (project: IProject, jobType: IJobType) => {
          return setState({
            ...state,
            activeJob: jobType,
            activeProject: project
          });
        }
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
